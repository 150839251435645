import { ChartInputSeries, ChartInput, StringKeyValue } from "@mwaretv/database/build/backend/util/queryGenerator";
import axios from "axios";
import { t } from "i18next";
import dayjs from 'dayjs';
import { DateTimeKeysToFill } from "@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignEnums";

class ChartInputs_Service {

  Get_ChartInputs = async (clientName: string, deploymentName: string, serviceName: string, area: string): Promise<ChartInput[]> => {

    const jsonFileName = `dashboard_${area}_input.json`;

    let path: string;
  
    if (area === "content" || area === "apps") {
  
      path = 'https://cloudtv.akamaized.net/' + clientName + '/' + deploymentName + '/dashboards/' + jsonFileName;
  
    }
    else {
  
      path = 'https://cloudtv.akamaized.net/' + clientName + '/' + deploymentName + '/' + serviceName + '/dashboards/' + jsonFileName;
  
    }
  
    let { data } = await axios.get(path);

    data?.forEach(chartInput => {
      chartInput.series?.forEach(seriesInstance => {
        this.fillMissingDateTimeKeys(seriesInstance);
        this.formatDates(seriesInstance);
        this.translateEmptyValues(seriesInstance);
      });
    });

    data = data.sort((a, b) => {
      if (a?.order == null && b?.order == null) return 0;
      if (a?.order == null) return 1;
      if (b?.order == null) return -1;
      return a?.order - b?.order;
    });

    return data;

  };

  private fillMissingDateTimeKeys = (series: ChartInputSeries): void => {

    if (!series.data) {
      return;
    }

    const keys = series.data.map(x => x.key);

    if (series.fillMissingDateTimeKeys === DateTimeKeysToFill.DAYS_IN_MONTH) {

      const enrichedSeriesData: StringKeyValue[] = [];

      const numberOfDaysInMonth = dayjs(keys[0]).daysInMonth();
      const month = dayjs(keys[0]).month();
      const year = dayjs(keys[0]).year();
    
      for (let i = 1; i <= numberOfDaysInMonth; i++) {
        const existingKeyValue = series.data.find(x => dayjs(x.key).date() === i);

        if (existingKeyValue) {
          enrichedSeriesData.push(existingKeyValue);
        }
        else {
          try {
            let fillValue = series.fillValue;

            if (dayjs(new Date(year, month, i)).isAfter(dayjs())) {
              fillValue = undefined;
            }

            enrichedSeriesData.push({ key: dayjs(new Date(year, month, i)).format("YYYY-MM-DD"), value: fillValue ?? null });
          }
          catch (error) {
            console.log(error);
          }
        }
      }

      series.data = enrichedSeriesData;
    
    }
    else if (series.fillMissingDateTimeKeys === DateTimeKeysToFill.DAYS_IN_WEEK) {

      const enrichedSeriesData: StringKeyValue[] = [];

      for (let i = 1; i <= 7; i++) {
        const existingKeyValue = series.data.find(x => x.key === i.toString());

        if (existingKeyValue) {
          enrichedSeriesData.push(existingKeyValue);
        }
        else {
          try {
            enrichedSeriesData.push({ key: i.toString(), value: series.fillValue ?? null });
          }
          catch (error) {
            console.log(error);
          }
        }
      }

      series.data = enrichedSeriesData;

    }
    else if (series.fillMissingDateTimeKeys === DateTimeKeysToFill.HOURS_IN_DAY) {
      
      const enrichedSeriesData: StringKeyValue[] = [];

      for (let i = 0; i < 24; i++) {
        const existingKeyValue = series.data.find(x => x.key === (i < 10 ? `0${i}:00` : `${i}:00`));

        if (existingKeyValue) {
          enrichedSeriesData.push(existingKeyValue);
        }
        else {
          try {
            enrichedSeriesData.push({ key: (i < 10 ? `0${i}:00` : `${i}:00`), value: series.fillValue ?? null });
          }
          catch (error) {
            console.log(error);
          }
        }
      }

      series.data = enrichedSeriesData;

    }

  }

  private formatDates = (series: ChartInputSeries): void => {

    if (series.dataKeyDateTimeFormat === "day") {
      series.data?.forEach(item => {
        item.key = dayjs(item.key).format('D MMM YYYY');
      });
    }
    else if (series.dataKeyDateTimeFormat === "dayOfWeek") {
      series.data?.forEach(item => {
        let parsedWeekday = parseInt(item.key);
        if (!isNaN(parsedWeekday)) {
          item.key = dayjs().day(parsedWeekday).format('ddd');
        }
      });
    }
    else if (series.dataKeyDateTimeFormat === "week") {
      series.data?.forEach(item => {
        const year = item.key.substring(0, 4);
        const week = parseInt(item.key.substring(5));

        item.key = `${t('week')} ${week} ${year}`;
      });
    }
    else if (series.dataKeyDateTimeFormat === "month") {
      series.data?.forEach(item => {
        item.key = dayjs(item.key).format('MMM YYYY');
      });
    }

  }

  private translateEmptyValues = (series: ChartInputSeries): void => {

    series.data?.forEach(data => {

      if (data.key == null || data.key.length === 0) {
        data.key = t('empty');
      }
    });

  }

}

export default new ChartInputs_Service();
