import { faMagnifyingGlass, faPenToSquare, faPrintMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, notification, Table, Tooltip } from 'antd';
import * as HttpStatusCodes  from 'http-status-codes';
import { useState } from 'react';
import { useParams } from 'react-router';
import { CustomNotification } from '../../../../../common/components/Notification';
import transcoderService from '../../../../../services/areas/content/transcoders/transcoders/index';
import { IMSNameParams } from '../../../../dashboard';
import { useEffectOnce } from 'react-use';
import AddModal from './add';
import UpdateModal from './details';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const PpvRules = () => {
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const [rowToBeUpdate, setRowToBeUpdate] = useState({
    _id: '',
    createdAt: new Date(),
    name: '',
    key: 'de',
    type: '',
    quantity: 0,
    updatedAt: new Date(),
    __v: 0,
  });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState([] as any);
  const {t} = useTranslation();
  const [showAddModal, setShowAddModal] = useState(false);

  const handleSubmit = async (ppvRulesData: any, transcoder: any) => {
    setIsloading(true);
    let response = await transcoderService.Add_Transcoders({
      ...ppvRulesData,
      type: transcoder,
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
    });

    if (response && response.status_code === HttpStatusCodes.OK) {
      setShowAddModal(false);
      getTranscoders();
      notification.success({
        message: t('success'),
        description: t('transcoder-added-successfully'),
      });
    } else
      CustomNotification({
        error: response.errors,
      });
    setIsloading(false);
  };

  const handleUpdate = async (values: any, transcoder: any, row: any) => {
    setIsloading(true);
    let response = await transcoderService.Update_Transcoders({
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
      ...row,
      ...values,
    });

    if (response && response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('success'),
        description: t('update-successfully'),
      });
      setShowUpdateModal(false);
      getTranscoders();
    }
    setIsloading(false);
  };

  const getTranscoders = async () => {
    setIsloading(true);
    let response = await transcoderService.List_Transcoders({
      query_params: {
        instance: clientName,
        cms: service_name || '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
    }
    setIsloading(false);
  };

  useEffectOnce(() => {
    getTranscoders();
  });

  const handleCancel = () => {
    setShowUpdateModal(false);
    setShowAddModal(false)
  };

  const testTranscoder = async (row) => {
    var url = '';
    if (row.type == t('flussonic-v3')) {
      url = row.url + 'admin/streamer/api/v3/';
    }
    if (row.type == t('flussonic-v2')) {
      url = row.url + 'admin/flussonic/api/server';
    }
    await axios
      .get(url, {
        auth: {
          username: row.user,
          password: row.pass,
        },
      })
      .then((response) => {
        
        alert(t('transcoder-found-and-api-is-accessible'));
      })
      .catch((error) => {
        alert(t('transcoder-or-api-are-not-accessible'));
      });
  };

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: t('url'),
      dataIndex: 'url',
      key: 'url',
      width: '50%',
    },
    {
      title: t('actions'),
      dataIndex: 'action',
      render: (tags: any, row: any) => (
        <span className="justify-content-end" style={{ height: 60 }}>
          {/* <Tooltip placement="top" title={`Test`}>
            <Button
              type="text"
              className="mx-2 cursor-pointer"
              onClick={() => {
                testTranscoder(row);
              }}
            >
              <FontAwesomeIcon className="action-icon" icon={faMagnifyingGlass} />
              <div>{t('test')}</div>
            </Button>
          </Tooltip> */}
          <Tooltip placement="top" title={`Edit`}>
            <Button
              type="text"
              className="mx-2 cursor-pointer"
              onClick={() => {
                
                setRowToBeUpdate(row);
                setShowUpdateModal(true);
              }}
            >
              <FontAwesomeIcon className="action-icon" icon={faPenToSquare} />
              <div>{t('edit')}</div>
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-7 mb-2">
          <h2 className="page-title">{t('transcoders')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('setup-your-transcoders-to-be-used-for-interactivetv-with-channels-and-cams')}</p>
        </div>
        <div className="col-md-5"></div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-2"></div>
        <div className="col-md-5">
          <Button className="btn btn-secondary rounded-pill btn-default float-right width-180 height-48" onClick={() => setShowAddModal(true)}>
            {t('add-transcoders')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Table columns={columns} dataSource={data} loading={isLoading} />
        </div>
      </div>
      <AddModal isLoading={isLoading} visible={showAddModal} onSubmit={handleSubmit} onCancel={handleCancel} />
      <UpdateModal isUpdating={isLoading} row={rowToBeUpdate} visible={showUpdateModal} onSubmit={handleUpdate} onCancel={handleCancel} />
    </div>
  );
};

export default PpvRules;
