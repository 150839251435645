import { Button, Form, Input, notification, Select, Switch, Tabs, } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import commonCMSService from '../../../../../services/areas/content/common';
import { IMSNameParams } from '../../../../dashboard';
import { useEffectOnce } from 'react-use';
import cmsChannels from '../../../../../services/areas/content/channels/channels';
import cmsCams from '../../../../../services/areas/content/cams/cams';
import cmsMovies from '../../../../../services/areas/content/movies/movies';
import cmsSeries from '../../../../../services/areas/content/series/series';
import cmsCourses from '../../../../../services/areas/content/courses/courses';
import cmsRadio from '../../../../../services/areas/content/radio/radio';
import cmsAlbums from '../../../../../services/areas/content/albums/albums';
import cmsPodcasts from '../../../../../services/areas/content/podcasts/podcasts';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { ContentContext } from '../../../../../contexts/contentContext';



//exporter // streams // epg info etcv
const Importer = () => {
    const [form] = useForm();
const { t } = useTranslation();
    const { clientName } = useParams<IMSNameParams>();
const { service, deployment } = useContext(ContentContext)

    return (
        <div className="container-fluid wrapper">
            <div className="row">
                <div className="col-md-6">
                    <h2 className="page-title">{t('bulk-edit')}</h2>
                    <p className="h6 font-weight-normal line-height-26">
                        {t('bulk-edit-your-content-with-easy-to-use-tools-special-designed-for-the-task')}</p>
                </div>
            </div>

                <div className="card mt-4 pb-4">
                    <div className="card-body card-ch-padding">
                        <div className="card-bg-light pl-5 pt-3 mt-4">
                            <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                                {t('your-excel')}
                            </h4>

                        </div>
                    </div>
                </div>

        </div >
    );
};
export default Importer;