import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { NestedContent } from '../../../../../common/components/Content/Curations/NestedContent';
import { IMSNameParams } from '../../../../dashboard';
import { useContext, useState } from 'react';
import cmsService from '../../../../../services/areas/content/channels/curations';
import * as HttpStatusCodes  from 'http-status-codes';
import { useEffectOnce } from 'react-use';
import { ContentContext } from '../../../../../contexts/contentContext';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const Bundles = () => {
  let params = useParams<IMSNameParams>();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const redirectUrl2 = redirectUrl.split('/').slice(0, -1).join('/');
  const { t } = useTranslation();
  const history = useHistory();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [curation, setCuration] = useState([] as any);
  const [loading, setLoading] = useState(true);
  const getBundle = async () => {
    let response = await cmsService.Curation_List({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 0,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var data = response.data as any;
      
      setCuration(data.data);
      setLoading(false);
    }
  };
  useEffectOnce(() => {
    getBundle();
  });
  const selectCuration = (index) => {
    history.push(redirectUrl2 + '/' + curation[index].name + '/' + curation[index]._id + '/' + index);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-4">
          <Link to={`${redirectUrl}`}>{t('back-to-album-curations')}</Link>
        </div>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '50%' }}>
            <h2 className="page-title">{t('your')} {params.package} curation</h2>
            <p>
              {t('drag-your-content-from-the-left-side-to-your-category-you-can-also-drag-and-drop-to-reorder')} <br /> {t('your-category-content-the-order-its-presented-here-is-the-same-as-in-your-apps')}
            </p>
            <p>{t('do-not-add-more-than-20-items-as-this-will-be-the-maximum-number-that-gets-rendered-in-the-apps-to-preserve-the-speed-of-the-app')}</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: '50%', paddingRight: 20 }}>
            {!loading && (
              <Form.Item style={{ padding: 0 }} className="col-md-6" name="type">
                <Select onChange={selectCuration} className="default-select" placeholder={t('jump-to-other-curation')}>
                  {curation.map((curation, index) => {
                    return (
                      <Select.Option value={index} key={index}>
                        {curation.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <NestedContent type={'albums'} category={params.category ?? ''}></NestedContent>
        </div>
      </div>
    </div>
  );
};

export default Bundles;
