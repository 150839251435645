import { Button, Form, Input, Select, Switch, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import apiBuilder from '../../../../../../services/areas/billing/apibuilder/index';
import { IMSNameParams } from '../../../../../dashboard';
import { BillingContext } from '../../../../../../contexts/billingContext';
import * as ShoppingCartService from '../../../../../../services/areas/billing/shoppingCartService';
import { useQuery } from '@tanstack/react-query';
import { useEffectOnce } from 'react-use';
import { ErrorModal } from '../../../../../../common/components/ErrorModal/ErrorModal';

const AddAlbum = () => {
  const history = useHistory();
  const [isAdding, setIsAdding] = useState(false);
  const [form] = useForm();
const { t } = useTranslation();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);

  const [selectedCart, setSelectedCart] = useState('');
  const [portalaccess, setPortalaccess] = useState(false);

  const [hardcodedTitle, setHardcodedTitle] = useState(false);
  const [hardcodedFirstname, setHardcodedFirstname] = useState(false);
  const [hardcodedLastname, setHardcodedLastname] = useState(false);
  const [hardcodedStreet, setHardcodedStreet] = useState(false);
  const [hardcodedZip, setHardcodedZip] = useState(false);
  const [hardcodedState, setHardcodedState] = useState(false);
  const [hardcodedCity, setHardcodedCity] = useState(false);
  const [hardcodedCountry, setHardcodedCountry] = useState(false);
  const [hardcodedPhone, setHardcodedPhone] = useState(false);
  const [hardcodedMobile, setHardcodedMobile] = useState(false);
  const [hardcodedEmail, setHardcodedEmail] = useState(false);
  const [hardcodedBase, setHardcodedBase] = useState(false);
  const [hardcodedPlan, setHardcodedPlan] = useState(false);
  const [hardcodedCurrency, setHardcodedCurrency] = useState(false);
  const [hardcodedTaxrate, setHardcodedTaxrate] = useState(false);
  const [hardcodedbilling_country, setHardcodedbilling_country] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);

  const [apiUrl, setApiUrl] = useState('https://api.tvms.io/api/v1/billing/custom?client=' + clientName + '&deployment=' + deployment?.name + '&service=' + service?.name + '&token=[gettokenaftersavingthisapi]');

  const name = Form.useWatch('name', form);
  const title = Form.useWatch('title', form);
  const firstname = Form.useWatch('firstname', form);
  const lastname = Form.useWatch('lastname', form);
  const street = Form.useWatch('street', form);
  const zip = Form.useWatch('zip', form);
  const state = Form.useWatch('state', form);
  const city = Form.useWatch('city', form);
  const country = Form.useWatch('country', form);
  const email = Form.useWatch('email', form);
  const phone = Form.useWatch('phone', form);
  const mobile = Form.useWatch('mobile', form);
  const username = Form.useWatch('username', form);
  const password = Form.useWatch('password', form);
  const base = Form.useWatch('base', form);
  const plan = Form.useWatch('plan', form);
  const expire = Form.useWatch('expire', form);
  const currency = Form.useWatch('currency', form);
  const action = Form.useWatch('action', form);
  const taxrate = Form.useWatch(['mappings', 'taxrate'], form);
  const billing_country = Form.useWatch(['mappings', 'billing_country'], form);

  //country hardcoded then show drop down customers

  const generateApiUrl = () => {
    var newApiUrl = 'https://api.tvms.io/v1/billing/custom/' + name + '?client=' + clientName + '&deployment=' + deployment?.name + '&service=' + service?.name + '&token=[gettokenaftersavingthisapi]';
    if (title != undefined && title != '') {
      if (!hardcodedTitle) {
        newApiUrl += '&'+ title + '=[from_your_system]';
      } else {
        newApiUrl += '&title=' + title;
      }
    }
    if (firstname != undefined && firstname != '') {
      if (!hardcodedFirstname) {
        newApiUrl += '&' + firstname + '=[from_your_system]';
      } else {
        newApiUrl += '&firstname=' + firstname;
      }
    }
    if (lastname != undefined && lastname != '') {
      if (!hardcodedLastname) {
        newApiUrl += '&'+ lastname + '=[from_your_system]';
      } else {
        newApiUrl += '&lastname=' + lastname;
      }
    }
    if (street != undefined && street != '') {
      if (!hardcodedStreet) {
        newApiUrl +=  '&' + street + '=[from_your_system]';
      } else {
        newApiUrl += '&street=' + street;
      }
    }
    if (zip != undefined && zip != '') {
      if (!hardcodedZip) {
        newApiUrl +=  '&' + zip + '=[from_your_system]';
      } else {
        newApiUrl += '&zip=' + zip;
      }
    }
    if (city != undefined && city != '') {
      if (!hardcodedCity) {
        newApiUrl += '&' + city + '=[from_your_system]';
      } else {
        newApiUrl += '&city=' + city;
      }
    }
    if (state != undefined && state != '') {
      if (!hardcodedState) {
        newApiUrl +=  '&' + state + '=[from_your_system]';
      } else {
        newApiUrl += '&state=' + state;
      }
    }

    if (country != undefined && country != '') {
      if (!hardcodedCountry) {
        newApiUrl +=  '&' + country + '=[from_your_system]';
      } else {
        newApiUrl += '&country=' + country;
      }
    }
    if (email != undefined && email != '') {
      if (!hardcodedEmail) {
        newApiUrl +=  '&' + email + '=[from_your_system]';
      } else {
        newApiUrl += '&email=' + email;
      }
    }
    if (phone != undefined && phone != '') {
      if (!hardcodedPhone) {
        newApiUrl +=  '&' + phone + '=[from_your_system_format_like_+0011234567890]';
      } else {
        newApiUrl += '&phone=' + phone;
      }
    }
    if (mobile != undefined && mobile != '') {
      if (!hardcodedMobile) {
        newApiUrl += '&' + mobile + '=[from_your_system_format_like_+0011234567890]';
      } else {
        newApiUrl += '&mobile=' + mobile;
      }
    }
    if (username != undefined && username != '') {
      newApiUrl +=  '&' + username + '=[from_your_system]';
    }
    if (password != undefined && password != '') {
      newApiUrl +=  '&' + password + '=[from_your_system]';
    }
    if (base != undefined && base != '') {
      if (!hardcodedBase) {
        newApiUrl +=  '&'+ base + '=[from_your_system]';
      } else {
        newApiUrl += '&Base=' + base;
      }
    }
    if (plan != undefined && plan != '') {
      if (!hardcodedPlan) {
        newApiUrl +=  '&'+ plan + '=[from_your_system]';
      } else {
        newApiUrl += '&plan=' + plan;
      }
    }
    if (taxrate != undefined && plan != '') {
      if (!hardcodedTaxrate) {
        newApiUrl +=  '&' + taxrate + '=[from_your_system]';
      } else {
        newApiUrl += '&taxrate=' + taxrate;
      }
    }
    if (billing_country != undefined && plan != '') {
      if (!hardcodedbilling_country) {
        newApiUrl +=  '&' + billing_country + '=[from_your_system_ISO3166_format';
      } else {
        newApiUrl += '&billing_country=' + billing_country;
      }
    }
    if (expire != undefined && expire != '') {
      newApiUrl +=  '&' + expire + '=[from_your_system]';
    }
    if (currency != undefined && currency != '') {
      if (!hardcodedCurrency) {
        newApiUrl +=  '&' + currency + '=[from_your_system_ISO4217_format]';
      } else {
        newApiUrl += '&currency=' + currency;
      }
    }
    if (action != undefined && action != '') {
      newApiUrl +=  '&' + action + '=[one_of_the_actions_defined]';
    }
    setApiUrl(newApiUrl);
  };

  const handleSubmit = async (values: any) => {
    setIsAdding(true);

    let response = await apiBuilder.Add_ApiBuilder({
      name: values.name,
      shoppingcart: selectedCart,
      portalaccess: portalaccess,
      mappings: {
        title: values.title,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone,
        mobile: values.mobile,
        street: values.street,
        zip: values.zip,
        state: values.state,
        city: values.city,
        country: values.country,
        username: values.username,
        password: values.password,
        action: values.action, //dit moet anders
        add: values.add,
        renew: values.renew,
        disable: values.disable,
        enable: values.enable,

        title_hardcoded: hardcodedTitle,
        firstname_hardcoded: hardcodedFirstname,
        lastname_hardcoded: hardcodedLastname,
        email_hardcoded: hardcodedEmail,
        phone_hardcoded: hardcodedPhone,
        mobile_hardcoded: hardcodedMobile,
        street_hardcoded: hardcodedStreet,
        zip_hardcoded: hardcodedZip,
        state_hardcoded: hardcodedState,
        city_hardcoded: hardcodedCity,
        country_hardcoded: hardcodedCountry,

        subscriptions: {
          base: values.base,
          base_hardcoded: hardcodedBase,
          plan: values.plan,
          plan_hardcoded: hardcodedPlan,
          expire: '',
          format: '',
          currency: values.currency,
          currency_hardcoded: hardcodedCurrency,
          taxrate: values.taxrate,
          taxrate_hardcoded: hardcodedTaxrate,
          billing_country: values.billing_country,
          billing_country_hardcoded: hardcodedbilling_country,
        },
      },

      query_params: {
        cms: deployment?.name ?? '',
        crm: service?.name ?? '',
        instance: clientName,
      },
    });
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };
  const { status, data, error, refetch } = useQuery(
    ['shopping-carts', 1, 100],
    async () => {
      return (
        await ShoppingCartService.list({
          params: {
            cms: deployment?.name || '',
            crm: service?.name || '',
            instance: clientName,
            page: 1,
            limit: 100,
          },
        })
      ).data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: false, // disable this query from automatically running
      
    }
  );
  useEffectOnce(() => {
    refetch();
  });

  const setCartSelection = (cart) => {
    setSelectedCart(cart);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-custom-api-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-custom-api')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('create-custom-api-integrations-if-the-standard-billing-apis-can-not-work-with-your-3rd-party-system')}</p>
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          setModalData(errorInfo?.errorFields);
          setOpenErrorModal(true);
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5 pt-3 mt-5">
              <h4 className="text-primary mt-3 mb-3">{t('general')}</h4>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-7"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('name-of-custom-api')} />
                </Form.Item>
              </div>
            </div>

            <div className="card-bg-light pl-5 pt-3 mb-4 p-5 mt-5">
              <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                {t('linked-cart')}
              </h4>
              <p>{t('select-the-cart-to-link-this-api-with-this-will-be-used-to-for-the-basic-cart-settings-as-well-as-sending-the-registration-emails')}</p>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('cart-and-portal-selection')}</label>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t('cart-selection-is-required'),
                    },
                  ]}
                  className="col-md-5"
                  name={['settings', 'webshop_id']}
                >
                  <Select defaultValue={selectedCart} onChange={setCartSelection} className="default-select" placeholder={t('select-cart-and-portal')}>
                    <Select.Option value={'nocart'} key={'nocart'}>
                      {t('we-use-the-apis-therefore-cart-and-portal-linking-needed')}
                    </Select.Option>
                    {data?.docs.map((doc) => {
                      return (
                        <Select.Option value={doc._id} key={doc._id}>
                          {doc.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('portal-access')}</label>
                <Form.Item className="col-md-2" name="portalaccess">
                  <Switch className="ch-switch" checked={portalaccess} defaultChecked={false} onChange={() => setPortalaccess(!portalaccess)} />
                </Form.Item>
              </div>
            </div>

            <div className="card-bg-light pl-5 pt-3 mt-5">
              <h4 className="text-primary mt-3 mb-3">{t('api-url')}</h4>
              <div className="row">
                <div className="col-md-12 mb-3">{apiUrl}</div>
              </div>
              <div className="text-right">
                <Button onClick={generateApiUrl} className="mt-1 mb-4 mr-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                  {t('generate-api-url')}
                </Button>
              </div>
            </div>

            <div className="card-bg-light pl-5 pt-3 mt-5">
              <h4 className="text-primary mt-3 mb-3">{t('parameter-mapping')}</h4>
              <p>{t('add-customer-we-need-all-the-params-below-either-hardcoded-or-dynamic')}</p>

              <div className="row">
                <label className="col-md-2 col-form-label">{t('title')}</label>
                <Form.Item
                  className="col-md-5"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="title_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedTitle} defaultChecked={false} onChange={() => setHardcodedTitle(!hardcodedTitle)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('firstname')}</label>
                <Form.Item
                  className="col-md-5"
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="firstname_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedFirstname} defaultChecked={false} onChange={() => setHardcodedFirstname(!hardcodedFirstname)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('lastname')}</label>
                <Form.Item
                  className="col-md-5"
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="lastname_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedLastname} defaultChecked={false} onChange={() => setHardcodedLastname(!hardcodedLastname)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('street')}</label>
                <Form.Item
                  className="col-md-5"
                  name="street"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="street_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedStreet} defaultChecked={false} onChange={() => setHardcodedStreet(!hardcodedStreet)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('zip')}</label>
                <Form.Item
                  className="col-md-5"
                  name="zip"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="zip_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedZip} defaultChecked={false} onChange={() => setHardcodedZip(!hardcodedZip)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('city')}</label>
                <Form.Item
                  className="col-md-5"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="city_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedCity} defaultChecked={false} onChange={() => setHardcodedCity(!hardcodedCity)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('state')}</label>
                <Form.Item
                  className="col-md-5"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="state_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedState} defaultChecked={false} onChange={() => setHardcodedState(!hardcodedState)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('country-english')}</label>
                <Form.Item
                  className="col-md-5"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="country_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedCountry} defaultChecked={false} onChange={() => setHardcodedCountry(!hardcodedCountry)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('email')}</label>
                <Form.Item
                  className="col-md-5"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="email_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedEmail} defaultChecked={false} onChange={() => setHardcodedEmail(!hardcodedEmail)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('phone')}</label>
                <Form.Item
                  className="col-md-5"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="phone_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedPhone} defaultChecked={false} onChange={() => setHardcodedPhone(!hardcodedPhone)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('mobile')}</label>
                <Form.Item
                  className="col-md-5"
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="mobile_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedMobile} defaultChecked={false} onChange={() => setHardcodedMobile(!hardcodedMobile)} />
                </Form.Item>
              </div>
            </div>
            <div className="card-bg-light pl-5 pt-3 mt-5">
              <h4 className="text-primary mt-3 mb-3">{t('product')}</h4>
              <p>{t('renew-customer-we-need-just-the-username-and-password-but-also-the-product-id-and-plan-id')}</p>
              <div className="row">
                <label className="col-md-2 col-form-label">
                  {t('currency')}{' '}
                  <a href="https://en.wikipedia.org/wiki/ISO_4217" target="blank">
                    ISO4217
                  </a>
                </label>
                <Form.Item
                  className="col-md-5"
                  name="currency"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="currency_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedCurrency} defaultChecked={false} onChange={() => setHardcodedCurrency(!hardcodedCurrency)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('tax-rate')}</label>
                <Form.Item
                  className="col-md-5"
                  name={['mappings', 'subscriptions', 'taxrate']}
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input type={'number'} className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="currency_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedTaxrate} defaultChecked={false} onChange={() => setHardcodedTaxrate(!hardcodedTaxrate)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">
                  {t('billing-country')}{' '}
                  <a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3#Officially_assigned_code_elements" target="blank">
                    ISO3166
                  </a>
                </label>
                <Form.Item
                  className="col-md-5"
                  name={['mappings', 'subscriptions', 'billing_country']}
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="currency_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedbilling_country} defaultChecked={false} onChange={() => setHardcodedbilling_country(!hardcodedbilling_country)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('product-id')}</label>
                <Form.Item
                  className="col-md-5"
                  name="base"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="base_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedBase} defaultChecked={false} onChange={() => setHardcodedBase(!hardcodedBase)} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('plan-id')}</label>
                <Form.Item
                  className="col-md-5"
                  name="plan"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
                <label className="col-form-label">{t('query-paremeter-hardcoded-value')}</label>
                <Form.Item className="col-md-2" name="plan_hardcoded">
                  <Switch className="ch-switch" checked={hardcodedPlan} defaultChecked={false} onChange={() => setHardcodedPlan(!hardcodedPlan)} />
                </Form.Item>
              </div>
            </div>
            <div className="card-bg-light pl-5 pt-3 mt-5">
              <h4 className="text-primary mt-3 mb-3">{t('authentication')}</h4>
              <p>{t('disable-customer-we-need-just-the-username-and-password')}</p>
              <p>{t('enable-customer-we-need-just-the-username-and-password')}</p>
              <p>{t('renew-customer-we-need-just-the-username-and-password-but-also-the-product-id-and-plan-id')}</p>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('username')}</label>
                <Form.Item
                  className="col-md-8"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('password')}</label>
                <Form.Item
                  className="col-md-8"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
              </div>
              {/* <div className="row">
                <label className="col-md-2 col-form-label">Expiry DateTime</label>
                <Form.Item
                  className="col-md-8"
                  name="expire"
                  rules={[
                    {
                      required: true,
                      message: 'A value is required',
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder="Enter a parameter or value" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">Format DateTime</label>
                <Form.Item
                  className="col-md-8"
                  name="format"
                  rules={[
                    {
                      required: true,
                      message: 'A value is required',
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder="Like: YYYYMMDDHHMMSS" />
                </Form.Item>
              </div> */}
            </div>
            <div className="card-bg-light pl-5 pt-3 mt-5">
              <h4 className="text-primary mt-3 mb-3">{t('connection')}</h4>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('client')}</label>
                <Form.Item className="col-md-8" name="client">
                  <Input disabled={true} defaultValue={clientName} className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('deployment')}</label>
                <Form.Item className="col-md-8" name="deployment">
                  <Input disabled={true} defaultValue={deployment?.name} className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('service')}</label>
                <Form.Item className="col-md-8" name="service">
                  <Input disabled={true} defaultValue={service?.name} className="form-control bg-white font-size-14 text-default" placeholder={t('enter-a-parameter-or-value')} />
                </Form.Item>
              </div>
            </div>

            <div className="card-bg-light pl-5 pt-3 mt-5">
              <h4 className="text-primary mt-3 mb-3">{t('api-actions')}</h4>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('action')}</label>
                <Form.Item
                  className="col-md-8"
                  name="action"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('set-action-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('add')}</label>
                <Form.Item
                  className="col-md-8"
                  name="add"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('set-add-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('renew')}</label>
                <Form.Item
                  className="col-md-8"
                  name="renew"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('set-renew-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('enable')}</label>
                <Form.Item
                  className="col-md-8"
                  name="enable"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('set-enable-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('disable')}</label>
                <Form.Item
                  className="col-md-8"
                  name="disable"
                  rules={[
                    {
                      required: true,
                      message: t('a-value-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('set-disable-name')} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-custom-api')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddAlbum;
