import { Button, Form, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomNotification } from '../../../../common/components/Notification';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import appService from '../../../../services/areas/appbuilder/applications/application';

import drawer from '../../../../assets/images/appbuilder/Drawer.png';
import left from '../../../../assets/images/appbuilder/DrawerFixed.png';
import top from '../../../../assets/images/appbuilder/TopMenu.png';
import bottom from '../../../../assets/images/appbuilder/BottomMenu.png';
import split from '../../../../assets/images/appbuilder/SplitMenu.png';

import EditGeneral from '../Edit/Edit_Nav_General';
import EditComponents from '../Edit/Edit_Components';
import EditClock from '../Edit/Edit_Clock';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlockBrick, faFillDrip } from '@fortawesome/pro-regular-svg-icons';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { Spin } from '../../spinner';

const Authentication = (activeKey: any) => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [theme, setTheme] = useState([] as any);
  const [application, setApplication] = useState([] as any);
  const [form] = useForm();
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const [formDetails, setFormDetails] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [template, setTemplate] = useState('Select a Type');
  const [settings, setSettings] = useState({
    general: {
      background_color1: '#000000',
      background_color2: '#00000040',
      background_closed: '#000000',
    },

    clock: {
      time_color: '#ffffff',
      time_size: 'Large', //Small, Normal, Large, Extra Large
      time_bold: true,
      date_color: '#ffffff',
      date_size: 'Normal', //Small, Normal, Large, Extra Large
      date_bold: true,
      show_time: true,
      show_date: true,
    },
    components: {
      button: {
        _id: '',
      },
    },
  });
  const [editData, setEditData] = useState([] as any);
  const [editType, setEditType] = useState('');

  const [EditShowGeneralModal, setEditShowGeneralModal] = useState(false);
  const [EditShowClockModal, setEditShowClockModal] = useState(false);
  const [editComponentModal, setEditComponentModal] = useState(false);

  const editComponent = async (data: any) => {
    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...application,
      navigators: {
        ...application.navigators,
        mobile: {
          type: template,
          ...data,
        },
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getApplication();
      notification.success({
        message: t('updated-successfully'),
      });
      setEditComponentModal(false);
      setEditShowGeneralModal(false);
      setEditShowClockModal(false);
    }
  };

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...application,
      navigators: {
        ...application.navigators,
        mobile: {
          ...settings,
          type: template,
        },
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getApplication();
      notification.success({
        message: t('updated-successfully'),
      });
    } else CustomNotification({ error: response.errors });
    setIsLoading(false);
  };

  const getApplication = async () => {
    setIsLoading(true);
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      setApplication(resp);
      setTheme(resp.settings.theme);
      if (resp.navigators != undefined) {
        if (resp.navigators.mobile != undefined) {
          setTemplate(resp.navigators.mobile.type);
          setSettings(resp.navigators.mobile);
          form.setFieldsValue(resp.navigators.mobile);
        }
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getApplication();
  }, [activeKey]);

  const selectTemplate = (value: any) => {
    setTemplate(value);
  };

  const getEditButtons = (element: any) => {
    if (element == undefined) {
      return null;
    }
    return (
      <div className="row w-100 h-100" style={{ marginLeft: 15, marginBottom: 10 }}>
        <div className="row w-100 h-100" style={{ height: '100vh', width: '100vh', backgroundColor: '#dee2e6', borderRadius: 5 }}>
          <div className="col"></div>
          <div style={{ width: 5, height: 45, cursor: 'pointer', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, backgroundColor: '#ffffff', opacity: '1', marginTop: 5, marginBottom: 5 }}></div>

          {element.general && (
            <div className="ant-card-hoverable" style={{ padding: 0, width: 80, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: '#ffffff', opacity: '1', marginTop: 5, marginBottom: 5 }}>
              <div
                onClick={() => {
                  setEditData(element);
                  setEditType(element.type);
                  setEditShowGeneralModal(true);
                }}
                className="h-100 d-flex align-items-center justify-content-center text-center"
              >
                <div>
                  <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faFillDrip} />
                  <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>MENU</div>
                </div>
              </div>
            </div>
          )}
          <div className="ant-card-hoverable" style={{ padding: 0, width: 70, height: 45, cursor: 'pointer', backgroundColor: '#ffffff', opacity: '1', marginTop: 5, marginBottom: 5 }}>
            <div
              onClick={() => {
                setEditData(element);
                setEditType(element.type);
                setEditComponentModal(true);
              }}
              className="h-100 d-flex align-items-center justify-content-center  text-center"
            >
              <div>
                <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faBlockBrick} />
                <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>COMPONENTS</div>
              </div>
            </div>
          </div>
          <div style={{ width: 5, height: 45, cursor: 'pointer', borderTopRightRadius: 5, borderBottomRightRadius: 5, backgroundColor: '#ffffff', opacity: '1', marginTop: 5, marginBottom: 5, marginRight: 5 }}></div>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid wrapper">
      {isLoading && (
        <div>
          <div className="text-center  h-100 w-100">
            <div className="col-md-12 align-self-center">
              <Spin size="large" spinning={true} />
            </div>
          </div>
        </div>
      )}
      {!isLoading && (
        <div className="row">
          <EditClock
            theme={theme}
            visible={EditShowClockModal}
            onSubmit={editComponent}
            onCancel={() => {
              setEditShowClockModal(false);
            }}
            data={editData}
            type={editType}
            index={0}
          />
          <EditComponents
            visible={editComponentModal}
            onSubmit={editComponent}
            onCancel={() => {
              setEditComponentModal(false);
            }}
            application={application}
            data={editData}
            type={editType}
            index={0}
          />
          <EditGeneral
            theme={theme}
            visible={EditShowGeneralModal}
            onSubmit={editComponent}
            onCancel={() => {
              setEditShowGeneralModal(false);
            }}
            data={editData}
            type={editType}
            index={0}
          />
          <div className="col-md-12 mb-2">
            <div className="container-fluid">
              <Form
                form={form}
                initialValues={{
                  ...formDetails,
                }}
                onFinish={async (values) => {
                  await handleSubmit(values);
                }}
                onFinishFailed={(errorInfo) => {}}
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4" style={{ paddingLeft: 0 }}>
                        <div className="card-bg-light p-3">
                          <h5 className="text-primary mb-4" style={{ paddingLeft: 0 }}>
                            {t('navigation-types')}
                          </h5>
                          <div className="col">
                            <Form.Item
                              style={{ padding: 0 }}
                              className="col-md-12"
                              rules={[
                                {
                                  required: true,
                                  message: t('template-selection-is-required'),
                                },
                              ]}
                              name="type"
                            >
                              <Select defaultValue={template} onChange={selectTemplate} className="default-select " placeholder={t('select-type')}>
                                {/* <Select.Option value={'Drawer'} key={'Drawer'}>Drawer</Select.Option>
                                <Select.Option value={'Left Menu'} key={'Left Menu'}>Left Menu</Select.Option> */}
                                <Select.Option value={'Top Menu'} key={'Top Menu'}>
                                  {t('top-menu')}
                                </Select.Option>
                                <Select.Option value={'Bottom Menu'} key={'Bottom Menu'}>
                                  {t('bottom-menu')}
                                </Select.Option>
                                <Select.Option value={'Split Menu'} key={'Split Menu'}>
                                  Split Menu
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                          <div className="text-right mr-3 mt-3">
                            <Button loading={isLoading} htmlType="submit" className="mb-4 ant-btn btn btn-secondary rounded-pill width-200 height-48">
                              {t('update')}
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                          <div className="row">
                            {getEditButtons(settings)}
                            {template == 'Drawer' && <img src={drawer} alt={''} width={'100%'}></img>}
                            {template == 'Left Menu' && <img src={left} alt={''} width={'100%'}></img>}
                            {template == 'Top Menu' && <img src={top} alt={''} width={'100%'}></img>}
                            {template == 'Bottom Menu' && <img src={bottom} alt={''} width={'100%'}></img>}
                            {template == 'Split Menu' && <img src={split} alt={''} width={'100%'}></img>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Authentication;
