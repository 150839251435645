import { Button, Row, Tooltip, Col, Modal } from 'antd';
import * as HttpStatusCodes  from 'http-status-codes';
import { useCallback, useEffect, useState } from 'react';
import { SortingRule } from 'react-table';
import { ICMS_Lists } from '../../../interfaces/forms/common/ICMS_Lists';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import ReactBCTable from '../ReactBCTable';
import { IReactBCTableColumn } from '../ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import affiliates from '../../../services/areas/marketing/affiliates/affiliates';
import coupons from '../../../services/areas/marketing/coupons/coupons';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

const Table = (params: { deployment: any; service: any; clientName: any; type: any }) => {
  const { url } = useRouteMatch();
  let { confirm } = Modal;
  const {t} = useTranslation();
  const [selectedRowKeys, setRowSelectionState] = useState<string[]>([]);
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 1,
    pageSize: 50,
    query_params: {
      instance: params.clientName,
      cms: params.deployment ?? '',
      crm: params.service,
    },
    filters: [],
  });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);

  useEffect(() => {
    getContent();
  }, [tableSearchState.filters?.length, tableSearchState.pageNumber]);

  const deleteItem = async (id: string) => {
    setIsLoading(true);
    switch (params.type) {
      case 'affiliates':
        await deleteAffiliate(id);
        break;
      case 'coupons':
        await deleteCoupon(id);
        break;
    }
  };

  const openDetails = (_id: string) => {
    return `${url}/details/${_id}`;
  };

  const getContent = async () => {
    switch (params.type) {
      case 'affiliates':
        await getAffiliates(tableSearchState);
        break;
      case 'coupons':
        await getCoupons(tableSearchState);
        break;
    }
  };

  //affiliates
  const deleteAffiliate = async (_id: string) => {
    const response = await affiliates.Delete_Affiliates({
      _id: _id,
      query_params: {
        instance: params.clientName,
        cms: params.deployment ?? '',
        crm: params.service,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAffiliates(tableSearchState);
    }
    setIsLoading(false);
  };
  const getAffiliates = async (list: ICMS_Lists) => {
    const response = await affiliates.List_Affiliates(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  //coupons
  const deleteCoupon = async (_id: string) => {
    const response = await coupons.Delete_Coupon({
      _id: _id,
      query_params: {
        instance: params.clientName,
        cms: params.deployment ?? '',
        crm: params.service,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAffiliates(tableSearchState);
    }
    setIsLoading(false);
  };
  const getCoupons = async (list: ICMS_Lists) => {
    const response = await coupons.List_Coupons(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };

  const columnsShorts: IReactBCTableColumn<any>[] = [
    {
      title: t('name'),
      dataKey: 'name',
      width: '55%',
      filter: 'text',
      hasSort: true,
    },
    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row' }}>
            <Row className="align-items-center">
              <Tooltip placement="top" title={`Delete`}>
                <Button
                  onClick={() => {
                    confirm({
                      title: t('are-you-sure-you-want-to-delete'),
                      okText: t('yes'),
                      okType: 'danger',
                      cancelText: t('no'),
                      onOk() {
                        deleteItem(row._id);
                      },
                      onCancel() {},
                    });
                  }}
                  className="mx-2 cursor-pointer"
                  style={{ height: '100%' }}
                  type="text"
                >
                  <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                  <div>{t('delete')}</div>
                </Button>
              </Tooltip>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Tooltip placement="top" title={`Details`}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faMemo} />
                    <div>{t('open-details')}</div>
                  </Button>
                </Tooltip>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];

  return (
    <div>
      <ReactBCTable isLoading={isLoading} totalRecords={pagination.count} pageSize={pagination.pageSize} tableId={'cms_table'} columns={columnsShorts} onRowSelectionChange={(rows) => setRowSelectionState(rows.map((x) => x._id))} hasSelection={true} data={data}  />
    </div>
  );
};
export default Table;
