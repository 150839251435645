import ReactBCTable from '../../../../common/components/ReactBCTable';
import { useTranslation } from 'react-i18next';
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { SubscriptionsReportData } from "@mwaretv/database/build/backend/models/reporting/subscriptionsReport/subscriptionsReportInput"; 
import dayjs from 'dayjs';
dayjs.extend(localizedFormat);

export const SubscriptionsReportTable = ({ subscriptionsReportData }: { subscriptionsReportData: SubscriptionsReportData[] }) => {
  const { t } = useTranslation();

  return (
    <ReactBCTable
      isLoading={false}
      totalRecords={subscriptionsReportData.length ?? 0}
      pageSize={50}
      tableId={'subscription_report_table'}
      columns={[
        {
          title: t('subscription-name'),
          dataKey: 'subscription',
          width: '20%',
          cell: (row) => {
            return <div>{row.subscription}</div>;
          },
        },
        {
          title: t('chart-design-count-operator'),
          dataKey: 'count',
          width: '20%',
          cell: (row) => {
            return <div>{row.count}</div>;
          },
        },
        {
          title: t('amount-including-vat'),
          dataKey: 'amount_inc_vat',
          width: '20%',
          cell: (row) => {
            return <div>{row.amount_inc_vat}</div>;
          },
        },
        {
          title: t('amount-vat'),
          dataKey: 'amount_vat',
          width: '20%',
          cell: (row) => {
            return <div>{row.amount_vat}</div>;
          },
        },
        {
          title: t('amount-excluding-vat'),
          dataKey: 'amount_ex_vat',
          width: '20%',
          cell: (row) => {
            return <div>{row.amount_ex_vat}</div>;
          },
        },
      ]}
      data={subscriptionsReportData || []}
    />
  );
};
