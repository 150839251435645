import { notification } from 'antd';

import * as HttpStatusCodes  from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CustomNotification } from '../../../common/components/Notification';
import userService from '../../../services/user';
import SystemUserForm from '../form';
import { User, Data } from "../../../interfaces/responses/users/user";
import { useTranslation } from 'react-i18next';

const DetailsUser = () => {
  let history = useHistory();
  const { _id, userId } = useParams<{ _id: string, userId: string }>();
  const [initialData, setInitialData] = useState<Data>();
  const {t} = useTranslation();
  
  useEffect(() => {
    userService.DetailsUser({ _id: userId }).then((data) => {
      
      setInitialData(data.data as unknown as Data); 
    })
  }, [userId]);

  const handleSubmit = async (values: any) => {
    let response = await userService.UpdateUserDetails({
      ...values,
      _id: userId,
      pincode: '0',
      enabled: true,
      type: 'TVMS',
      avatar: '',
    });
    if (response.status_code === HttpStatusCodes.OK || response.status_code === HttpStatusCodes.NO_CONTENT) {
      notification.success({
        message: t('user-updated'),
        description: `User updated successfully`,
      });
      history.push('../');
    } else {
      CustomNotification({
        error: response.errors,
      });
    }
  };

  return (
    <section className="content">
      <div className=" m-auto p-0">
        <div className="row mb-4">
          <div className="col-12 ml-4 mt-5">
            <h4 className="page-title">{t('update-user-details')}</h4>
          </div>
        </div>
        <div className="container-fluid wrapper p-3">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body card-ch-padding" style={{ margin: 25, marginTop: 50 }}>
                  {initialData && <SystemUserForm type={'update'} initialData={initialData} onSubmit={handleSubmit} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DetailsUser;
