import { Route, Switch, useRouteMatch } from 'react-router';
import Add from '../add';
import Branding from './branding';
import Authentication from './authentication';
import Settings from './settings';
import Theme from './theme';
import Navigation from './navigators';
import Player from './players';
import Pages from './pages';
import TVGuide from './tvguide';
import Components from './components';
import Appupdate from './appupdates/index';
import WebTV from './webtv';
import { Alert } from 'antd';
import { Link, useParams } from 'react-router-dom';
import appService from '../../../../../services/areas/appbuilder/applications/application';
import { useContext, useState } from 'react';
import { AppBuilderContext } from '../../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../dashboard';
import * as HttpStatusCodes  from 'http-status-codes';
import { useEffectOnce } from 'react-use';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const { path, url } = useRouteMatch();
  const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [app, setApp] = useState([] as any);
  const [lastPart, setLastPart] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const urlPart = url.split('/').slice(0, -1).join('/') + '/details';

  const loadApp = async (id) => {
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      setApp(resp);
      setIsLoaded(true);
    }
  };
  useEffectOnce(() => {
    const str = window.location.href;
    const parts = str.split('/');
    const lastPart = parts[parts.length - 1];
    setLastPart(lastPart);
    loadApp(lastPart);
  });
  return (
    <>
      <div className="mt-3 ml-5 mr-5">
        {app.settings == undefined && isLoaded && (
          <Alert
            className="mt-4"
            message={t('settings')}
            showIcon
            description={t('before-you-can-use-your-app-you-have-to-fill-setup-the-settings')}
            type="warning"
            action={
              <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${urlPart}/settings/${lastPart}`}>
                {t('setup-settings')}
              </Link>
            }
          />
        )}
        {app.authentication == undefined && isLoaded && (
          <Alert
            className="mt-4"
            message={t('authentication')}
            showIcon
            description={t('before-you-can-use-your-app-you-have-to-setup-the-authentication-pages')}
            type="warning"
            action={
              <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${urlPart}/authentication/${lastPart}`}>
                {t('setup-authentication')}
              </Link>
            }
          />
        )}
        {app.navigators == undefined && isLoaded && (
          <Alert
            className="mt-4"
            message={t('navigators')}
            showIcon
            description={t('before-you-can-use-your-app-you-have-to-fill-setup-the-app-navigators')}
            type="warning"
            action={
              <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${urlPart}/navigators/${lastPart}`}>
                {t('setup-navigation')}
              </Link>
            }
          />
        )}
        {app.players == undefined && isLoaded && (
          <Alert
            className="mt-4"
            message={t('players')}
            showIcon
            description={t('before-you-can-use-your-app-you-have-to-fill-setup-the-players')}
            type="warning"
            action={
              <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${urlPart}/players/${lastPart}`}>
                {t('setup-players')}
              </Link>
            }
          />
        )}
        {app.components == undefined && isLoaded && (
          <Alert
            className="mt-4"
            message={t('components')}
            showIcon
            description={t('before-you-can-use-your-app-you-have-to-fill-setup-the-components')}
            type="warning"
            action={
              <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${urlPart}/components/${lastPart}`}>
                {t('setup-components')}
              </Link>
            }
          />
        )}
      </div>
      <Switch>
        <Route path={`${path}/add`}>
          <Add />
        </Route>
        <Route path={`${path}/branding/:id`}>
          <Branding />
        </Route>
        <Route path={`${path}/navigators/:id`}>
          <Navigation />
        </Route>
        <Route path={`${path}/settings/:id`}>
          <Settings />
        </Route>
        <Route path={`${path}/theme/:id`}>
          <Theme />
        </Route>
        <Route path={`${path}/players/:id`}>
          <Player />
        </Route>
        <Route path={`${path}/tvguide/:id`}>
          <TVGuide />
        </Route>
        <Route path={`${path}/authentication/:id`}>
          <Authentication />
        </Route>
        <Route path={`${path}/components/:id`}>
          <Components />
        </Route>
        <Route path={`${path}/pages/:id`}>
          <Pages />
        </Route>
        <Route path={`${path}/appupdates/:id`}>
          <Appupdate />
        </Route>
        <Route path={`${path}/webtv/:id`}>
          <WebTV />
        </Route>
      </Switch>
    </>
  );
};

export default Index;
