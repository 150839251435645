import { Button, Col, Form, Modal, notification, Table, Tooltip } from 'antd';
import { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { ContainerWrapper } from '../../../../../common/components/containerWrapper';
import { CardForm, FormHeading, ModalFormWrapper, PrimaryButton } from '../../../../../common/components/form';
import { PageTitle } from '../../../../../common/components/pageTitle';
import { BillingContext } from '../../../../../contexts/billingContext';
import FormFields from './shared/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { Spin } from '../../../../../common/components/spinner';
import { useEmailTemplateList } from '../../../../../services/areas/billing/emailTemplate';
import { useSmsTemplateList } from '../../../../../services/areas/billing/smsTemplate';
import { SmsTemplate } from '../../../../../interfaces/forms/areas/billing/smsTemplate';
import { EmailTemplate } from '../../../../../interfaces/forms/areas/billing/emailTemplate';
import { useAddExtraSubscriptionMessageAdd, useAddExtraSubscriptionMessageDelete, useAddExtraSubscriptionMessageList, useAddExtraSubscriptionMessagePut } from '../../../../../services/areas/billing/addExtraSubscriptionService';
import { AddExtraSubscriptionMessage } from '../../../../../interfaces/forms/areas/billing/addExtraSubscriptionMessage';
import { useAppTemplateList } from '../../../../../services/areas/billing/appTemplate';
import { AppTemplate } from '../../../../../interfaces/forms/areas/billing/appTemplate';
import { usePushTemplateList } from '../../../../../services/areas/billing/pushTemplate';
import { PushTemplate } from '../../../../../interfaces/forms/areas/billing/pushTemplate';
import { useTranslation } from 'react-i18next';

type MessageType = AddExtraSubscriptionMessage;

const List = () => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const { isLoading, isError, data, refetch } = useAddExtraSubscriptionMessageList({ params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' } });
  const {t} = useTranslation();

  const { data: smsTemplates } = useSmsTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: emailTemplates } = useEmailTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: appTemplates } = useAppTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: pushTemplates } = usePushTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('add-extra-subscription-messages')} subtitle={t('add-extra-subscription-messages-are-sent-when-a-customer-adds-an-extra-subscription')} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 offset-md-7">
          <ActionBar onChangesMade={() => refetch()} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {isLoading && <Spin />}
          {isError && <span>{t('something-went-wrong-fetching-messages')}</span>}
          {!isLoading && !isError && <WellcomeMessagesTable welcomeMessages={data.data} onChangesMade={() => refetch()} pushTemplates={pushTemplates?.data.docs ?? []} appTemplates={appTemplates?.data.docs ?? []} smsTemplates={smsTemplates?.data.docs ?? []} emailTemplates={emailTemplates?.data.docs ?? []} />}
        </div>
      </div>
    </ContainerWrapper>
  );
};

type WelcomeMessagesTableProps = {
  welcomeMessages: MessageType[];
  onChangesMade: () => void;
  smsTemplates: SmsTemplate[];
  emailTemplates: EmailTemplate[];
  appTemplates: AppTemplate[];
  pushTemplates: PushTemplate[];
};

const WellcomeMessagesTable = ({ welcomeMessages, onChangesMade, appTemplates, smsTemplates, emailTemplates, pushTemplates }: WelcomeMessagesTableProps) => {
  const dataSource = welcomeMessages.map((r) => ({
    ...r,
    sms_template: smsTemplates.find((t) => t._id === r.sms_template_id),
    email_template: emailTemplates.find((t) => t._id === r.email_template_id),
    app_template: appTemplates.find((t) => t._id === r.app_template_id),
    push_template: pushTemplates.find((t) => t._id === r.push_template_id),
  }));
  const {t} = useTranslation();

  return (
    <Table
      dataSource={dataSource}
      columns={[
        { title: t('name'), dataIndex: 'name', width: '30%' },
        { title: t('email-template'), dataIndex: ['email_template', 'name'], width: '20%' },
        { title: t('sms-template'), dataIndex: ['sms_template', 'name'], width: '20%' },
        { title: t('app-template'), dataIndex: ['app_template', 'name'], width: '20%' },
        { title: t('actions'), render: (row) => <TableActions {...{ onChangesMade }} welcomeMessage={row} /> },
      ]}
    />
  );
};

type TableActionsProps = {
  welcomeMessage: MessageType;
  onChangesMade: () => void;
};

const TableActions = ({ welcomeMessage, onChangesMade }: TableActionsProps) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const {t} = useTranslation();

  const { isLoading, mutate } = useAddExtraSubscriptionMessageDelete(
    { params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('message-deleted') });
        onChangesMade();
      },
      onError: () => {
        notification.error({ message: t('message-could-not-be-deleted-please-try-again') });
      },
    }
  );

  return (
    <>
      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
        {welcomeMessage._id !== undefined && (
          <Tooltip placement="top" title={t('delete')}>
            <Button
              type="text"
              className="mx-2"
              disabled={isLoading}
              onClick={() => {
                mutate(welcomeMessage._id as string);
              }}
            >
              <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
              <div>{t('delete')}</div>
            </Button>
          </Tooltip>
        )}
        <Tooltip placement="top" title={t('details')}>
          <Button
            type="text"
            className="mx-2"
            disabled={isLoading}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <FontAwesomeIcon className="action-icon" icon={faMemo} />
            <div>{t('open-details')}</div>
          </Button>
        </Tooltip>

        <EditFormModal
          {...{ welcomeMessage }}
          open={modalOpen}
          onCancel={(refetch) => {
            setModalOpen(false);
            if (refetch) onChangesMade();
          }}
        />
      </Col>
    </>
  );
};

type ActionBarProps = {
  onChangesMade: () => void;
};

const ActionBar = ({ onChangesMade }: ActionBarProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const {t} = useTranslation();

  return (
    <>
      <div className="d-flex justify-content-center justify-content-md-end align-items-start">
        <PrimaryButton className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" onClick={() => setModalOpen(true)}>
          {t('add-message')}
        </PrimaryButton>
      </div>
      <AddFormModal
        open={modalOpen}
        onCancel={(refetch) => {
          setModalOpen(false);
          if (refetch) {
            onChangesMade();
          }
        }}
      />
    </>
  );
};

type AddFormModalProps = {
  open: boolean;
  onCancel: (refetch: boolean) => void;
};

const AddFormModal = ({ open, onCancel }: AddFormModalProps) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const [form] = Form.useForm();
  const {t} = useTranslation();

  const { data: smsTemplates } = useSmsTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: emailTemplates } = useEmailTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: appTemplates } = useAppTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: pushTemplates } = usePushTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { isLoading, mutate } = useAddExtraSubscriptionMessageAdd(
    { params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('message-added') });
        form.resetFields();
        onCancel(true);
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-adding-the-reminder-please-try-again') });
      },
    }
  );

  return (
    <Modal width={720} {...{ open }} onCancel={() => onCancel(false)} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
      <ModalFormWrapper>
        <CardForm
          buttonBgWhite
          layout="vertical"
          {...{ form }}
          disabled={isLoading}
          onFinish={(values) => {
            mutate(values);
          }}
        >
          <FormHeading>{t('new-message')}</FormHeading>
          <FormFields pushTemplates={pushTemplates?.data.docs ?? []} appTemplates={appTemplates?.data.docs ?? []} smsTemplates={smsTemplates?.data.docs ?? []} emailTemplates={emailTemplates?.data.docs ?? []} />
        </CardForm>
      </ModalFormWrapper>
    </Modal>
  );
};

type EditFormModalProps = {
  open: boolean;
  onCancel: (refetch: boolean) => void;
  welcomeMessage: MessageType;
};

const EditFormModal = ({ open, onCancel, welcomeMessage }: EditFormModalProps) => {
  const { clientName } = useParams<{ clientName: string }>();
  const { deployment, service } = useContext(BillingContext);
  const [form] = Form.useForm();
  const {t} = useTranslation();

  const { data: smsTemplates } = useSmsTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: emailTemplates } = useEmailTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: appTemplates } = useAppTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { data: pushTemplates } = usePushTemplateList({
    params: { instance: clientName, cms: deployment?.name || '', crm: service?.name ?? '', page: 1, limit: 99999 },
  });

  const { isLoading, mutate } = useAddExtraSubscriptionMessagePut(
    { params: { instance: clientName, cms: deployment?.name || '', crm: service?.name || '' }, id: welcomeMessage._id as string },
    {
      onSuccess: () => {
        notification.success({ message: t('message-updated') });
        onCancel(true);
      },
      onError: () => {
        notification.error({ message: t('something-went-wrong-updating-the-reminder-please-try-again') });
      },
    }
  );

  return (
    <Modal width={720} {...{ open }} onCancel={() => onCancel(false)} okButtonProps={{ hidden: true }} cancelButtonProps={{ hidden: true }}>
      <div className="mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <CardForm
              buttonBgWhite
              layout="vertical"
              {...{ form }}
              disabled={isLoading}
              initialValues={welcomeMessage}
              onFinish={(values) => {
                mutate(values);
              }}
            >
              <FormHeading>{t('edit-message')}</FormHeading>
              <FormFields pushTemplates={pushTemplates?.data.docs ?? []} appTemplates={appTemplates?.data.docs ?? []} smsTemplates={smsTemplates?.data.docs ?? []} emailTemplates={emailTemplates?.data.docs ?? []} />
            </CardForm>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default List;
