import { Button, Col, Tooltip } from 'antd';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ICMS_Lists } from '../../../../interfaces/forms/common/ICMS_Lists';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import ReactBCTable from '../../ReactBCTable';
import { IReactBCTableColumn } from '../../ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { faEdit, faList, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NestedCurationContent from './NestedCurationContent';

import cmsAlbums from '../../../../services/areas/content/albums/curations';
import cmsApps from '../../../../services/areas/content/apps/curations';
import cmsPodcasts from '../../../../services/areas/content/podcasts/curations';
import cmsMovies from '../../../../services/areas/content/movies/curations';
import cmsSeries from '../../../../services/areas/content/series/curations';
import cmsShorts from '../../../../services/areas/content/shorts/curations';
import cmsChannels from '../../../../services/areas/content/channels/curations';
import cmsRadios from '../../../../services/areas/content/radio/curations';
import cmsCams from '../../../../services/areas/content/cams/curations';
import cmsCourses from '../../../../services/areas/content/courses/curations';
import cmsGames from '../../../../services/areas/content/games/curations';

import moment from 'moment';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { ContentContext } from '../../../../contexts/contentContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import { useParams, useRouteMatch } from 'react-router-dom';
import CurationModal from './Curations/CurationModal';
import { useTranslation } from 'react-i18next';

const NestedCurations = (params: { type: any }) => {
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const { url } = useRouteMatch();
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCuration, setSelectedCuration] = useState([] as any);
  const { t } = useTranslation();

  const filterValidationAccepted = {
    id: 'provider_status',
    value: { filterValue: 'accepted', filterType: 'text' },
  } as any;

  const filterValidationEmpty = {
    id: 'provider_status',
    value: { filterValue: '', filterType: 'text' },
  } as any;

  const filterValidationUndefined = {
    id: 'provider_status',
    value: { filterValue: 'undefined', filterType: 'undefined' },
  } as any;

  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 1,
    pageSize: 250,
    filters: [filterValidationUndefined, filterValidationEmpty, filterValidationAccepted],
    query_params: {
      instance: clientName,
      cms: deployment?.name ?? '',
    },
  });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);

  useEffectOnce(() => {
    switch (params.type) {
      case 'Albums':
        (async () => await getAlbumCurations(tableSearchState))();
        break;
      case 'Apps':
        (async () => await getAppCurations(tableSearchState))();
        break;
      case 'Podcasts':
        (async () => await getPodcastCurations(tableSearchState))();
        break;
      case 'Channels':
        (async () => await getChannelCurations(tableSearchState))();
        break;
      case 'Radios':
        (async () => await getRadioCurations(tableSearchState))();
        break;
      case 'Movies':
        (async () => await getMovieCurations(tableSearchState))();
        break;
      case 'Shorts':
        (async () => await getShortsCurations(tableSearchState))();
        break;
      case 'Courses':
        (async () => await getCoursesCurations(tableSearchState))();
        break;
      case 'Series':
        (async () => await getSeriesCurations(tableSearchState))();
        break;
      case 'Cams':
        (async () => await getCamCurations(tableSearchState))();
        break;
      case 'Games':
        (async () => await getGamesCurations(tableSearchState))();
        break;
    }
  });

  const addAlbumCuration = async (values: any) => {
    setIsLoading(true);
    let response = await cmsAlbums.Add_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAlbumCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateAlbumCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsAlbums.Update_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAlbumCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteAlbumCuration = async (row: any) => {
    setIsLoading(true);
    let response = await cmsAlbums.Delete_Curation({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAlbumCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const getAlbumCurations = async (curation: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsAlbums.Curation_List(curation);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishAlbumCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsAlbums.Publish_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.ALBUMS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAlbumCurations(tableSearchState);
    }
    setIsLoading(false);
  };

  const addAppCuration = async (values: any) => {
    setIsLoading(true);
    let response = await cmsApps.Add_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAppCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateAppCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsApps.Update_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAppCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteAppCuration = async (row: any) => {
    setIsLoading(true);
    let response = await cmsApps.Delete_Curation({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAppCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const getAppCurations = async (curation: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsApps.Curation_List(curation);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishAppCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsApps.Publish_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.APPS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAppCurations(tableSearchState);
    }
    setIsLoading(false);
  };

  const addPodcastCuration = async (values: any) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Add_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getPodcastCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const updatePodcastCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Update_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getPodcastCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const deletePodcastCuration = async (row: any) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Delete_Curation({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getPodcastCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const getPodcastCurations = async (curation: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Curation_List(curation);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishPodcastCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsPodcasts.Publish_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.PODCASTS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getPodcastCurations(tableSearchState);
    }
    setIsLoading(false);
  };

  const addCamCuration = async (values: any) => {
    setIsLoading(true);
    let response = await cmsCams.Add_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCamCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateCamCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsCams.Update_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCamCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteCamCuration = async (row: any) => {
    setIsLoading(true);
    let response = await cmsCams.Delete_Curation({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCamCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const getCamCurations = async (curation: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsCams.Curation_List(curation);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishCamCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsCams.Publish_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CAMS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCamCurations(tableSearchState);
    }
    setIsLoading(false);
  };

  // const addAppCuration = async (values: any) => {
  //     setIsLoading(true);
  //     let response = await cmsApps.Add_App_Curations({
  //         query_params: {
  //             instance: clientName,
  //             cms: deployment?.name ?? '',
  //         },
  //         ...values,
  //         publish: moment().format('llll')
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //         getAppCurations(tableSearchState);
  //     }
  //     setIsLoading(false);
  // };
  // const updateAppCuration = async (package_: any) => {
  //     setIsLoading(true);
  //     let response = await cmsApps.Update_App_Curations({
  //         query_params: {
  //             instance: clientName,
  //             cms: deployment?.name ?? '',
  //         },
  //         ...package_
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //         getAppCurations(tableSearchState);
  //     }
  //     setIsLoading(false);
  // };
  // const deleteAppCuration = async (row: any) => {
  //     setIsLoading(true);
  //     let response = await cmsApps.Delete_App_Curations({
  //         _id: row._id,
  //         query_params: {
  //             instance: clientName,
  //             cms: deployment?.name ?? '',
  //         },
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //         getAppCurations(tableSearchState);
  //     }
  //     setIsLoading(false);
  // };
  // const getAppCurations = async (curation: ICMS_Lists) => {
  //     setIsLoading(true);
  //     let response = await cmsApps.Curation_App_Curations(curation);
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //         setData(response.data.data);
  //         setPagination(response.data);
  //     }
  //     setIsLoading(false);
  // };
  // const publishAppCuration = async (package_: any) => {
  //     setIsLoading(true);
  //     let response = await cmsApps.Publish_App_Curations({
  //         query_params: {
  //             instance: clientName,
  //             cms: deployment?.name ?? '',
  //         },
  //         ...package_
  //     });
  //     if (response && response.status_code === HttpStatusCodes.OK) {
  //         getAppCurations(tableSearchState);
  //     }
  //     setIsLoading(false);
  // };

  const addChannelCuration = async (values: any) => {
    setIsLoading(true);
    let response = await cmsChannels.Add_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getChannelCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateChannelCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsChannels.Update_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getChannelCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteChannelCuration = async (row: any) => {
    setIsLoading(true);
    let response = await cmsChannels.Delete_Curation({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getChannelCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const getChannelCurations = async (curation: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsChannels.Curation_List(curation);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishChannelCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsChannels.Publish_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.CHANNELS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getChannelCurations(tableSearchState);
    }
    setIsLoading(false);
  };

  const addRadioCuration = async (values: any) => {
    setIsLoading(true);
    let response = await cmsRadios.Add_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getRadioCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateRadioCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsRadios.Update_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getRadioCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteRadioCuration = async (row: any) => {
    setIsLoading(true);
    let response = await cmsRadios.Delete_Curation({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getRadioCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const getRadioCurations = async (curation: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsRadios.Curation_List(curation);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishRadioCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsRadios.Publish_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.RADIOS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getRadioCurations(tableSearchState);
    }
    setIsLoading(false);
  };

  const addMovieCuration = async (values: any) => {
    setIsLoading(true);
    let response = await cmsMovies.Add_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getMovieCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateMovieCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsMovies.Update_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getMovieCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteMovieCuration = async (row: any) => {
    setIsLoading(true);
    let response = await cmsMovies.Delete_Curation({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getMovieCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const getMovieCurations = async (curation: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsMovies.Curation_List(curation);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishMovieCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsMovies.Publish_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.MOVIES.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getMovieCurations(tableSearchState);
    }
    setIsLoading(false);
  };

  const addShortsCuration = async (values: any) => {
    setIsLoading(true);
    let response = await cmsShorts.Add_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getShortsCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateShortsCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsShorts.Update_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getShortsCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteShortsCuration = async (row: any) => {
    setIsLoading(true);
    let response = await cmsShorts.Delete_Curation({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getShortsCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const getShortsCurations = async (curation: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsShorts.Curation_List(curation);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishShortsCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsShorts.Publish_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SHORTS.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getShortsCurations(tableSearchState);
    }
    setIsLoading(false);
  };

  const addSeriesCuration = async (values: any) => {
    setIsLoading(true);
    let response = await cmsSeries.Add_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getSeriesCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateSeriesCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsSeries.Update_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getSeriesCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteSeriesCuration = async (row: any) => {
    setIsLoading(true);
    let response = await cmsSeries.Delete_Curation({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getSeriesCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const getSeriesCurations = async (curation: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsSeries.Curation_List(curation);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishSeriesCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsSeries.Publish_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.SERIES.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getSeriesCurations(tableSearchState);
    }
    setIsLoading(false);
  };

  const addCoursesCuration = async (values: any) => {
    setIsLoading(true);
    let response = await cmsCourses.Add_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCoursesCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateCoursesCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsCourses.Update_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCoursesCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteCoursesCuration = async (row: any) => {
    setIsLoading(true);
    let response = await cmsCourses.Delete_Curation({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCoursesCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const getCoursesCurations = async (curation: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsCourses.Curation_List(curation);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishCoursesCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsCourses.Publish_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.COURSES.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getCoursesCurations(tableSearchState);
    }
    setIsLoading(false);
  };

  const addGamesCuration = async (values: any) => {
    setIsLoading(true);
    let response = await cmsGames.Add_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...values,
      publish: moment().format('llll'),
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getGamesCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const updateGamesCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsGames.Update_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getGamesCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const deleteGamesCuration = async (row: any) => {
    setIsLoading(true);
    let response = await cmsGames.Delete_Curation({
      _id: row._id,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getGamesCurations(tableSearchState);
    }
    setIsLoading(false);
  };
  const getGamesCurations = async (curation: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsGames.Curation_List(curation);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
    }
    setIsLoading(false);
  };
  const publishGamesCuration = async (package_: any) => {
    setIsLoading(true);
    let response = await cmsGames.Publish_Curation({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, service?.name).CONTENT.GAMES.LISTS,
      },
      ...package_,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getGamesCurations(tableSearchState);
    }
    setIsLoading(false);
  };

  // const deleteCuration = (row: any) => {
  //   switch (params.type) {
  //     case 'Albums':
  //       (async () => await deleteAlbumCuration(row))();
  //       break;
  //     case 'Cams':
  //       (async () => await deleteCamCuration(row))();
  //       break;
  //     case 'Channels':
  //       (async () => await deleteChannelCuration(row))();
  //       break;
  //     case 'Radios':
  //       (async () => await deleteRadioCuration(row))();
  //       break;
  //     case 'Podcasts':
  //       (async () => await deletePodcastCuration(row))();
  //       break;
  //     case 'Movies':
  //       (async () => await deleteMovieCuration(row))();
  //       break;
  //     case 'Shorts':
  //       (async () => await deleteShortsCuration(row))();
  //       break;
  //     case 'Series':
  //       (async () => await deleteSeriesCuration(row))();
  //       break;
  //     case 'Courses':
  //       (async () => await deleteCoursesCuration(row))();
  //       break;
  //       case 'Games':
  //         (async () => await deleteGamesCuration(row))();
  //         break;
  //   }
  // };

  const onSubmitModal = (values: any) => {
    var curation = {
      ...selectedCuration,
      name: values.name,
      ...values,
    };
    setSelectedCuration(curation);
    console.log(values);
    switch (params.type) {
      case 'Albums':
        if (selectedCuration._id != undefined) {
          (async () => await updateAlbumCuration(curation))();
          setSelectedCuration(undefined);
        } else {
          (async () => await addAlbumCuration(values))();
        }
        break;
      case 'Apps':
        if (selectedCuration._id != undefined) {
          (async () => await updateAppCuration(curation))();
          setSelectedCuration(undefined);
        } else {
          (async () => await addAppCuration(values))();
        }
        break;
      case 'Cams':
        if (selectedCuration._id != undefined) {
          (async () => await updateCamCuration(curation))();
          setSelectedCuration(undefined);
        } else {
          (async () => await addCamCuration(values))();
        }
        break;
      case 'Games':
        if (selectedCuration._id != undefined) {
          (async () => await updateGamesCuration(curation))();
          setSelectedCuration(undefined);
        } else {
          (async () => await addGamesCuration(values))();
        }
        break;
      case 'Channels':
        if (selectedCuration._id != undefined) {
          (async () => await updateChannelCuration(curation))();
          setSelectedCuration(undefined);
        } else {
          (async () => await addChannelCuration(values))();
        }
        break;
      case 'Radios':
        if (selectedCuration._id != undefined) {
          (async () => await updateRadioCuration(curation))();
          setSelectedCuration(undefined);
        } else {
          (async () => await addRadioCuration(values))();
        }
        break;
      case 'Shorts':
        if (selectedCuration._id != undefined) {
          (async () => await updateShortsCuration(curation))();
          setSelectedCuration(undefined);
        } else {
          (async () => await addShortsCuration(values))();
        }
        break;
      case 'Movies':
        if (selectedCuration._id != undefined) {
          (async () => await updateMovieCuration(curation))();
          setSelectedCuration(undefined);
        } else {
          (async () => await addMovieCuration(values))();
        }
        break;
      case 'Series':
        if (selectedCuration._id != undefined) {
          (async () => await updateSeriesCuration(curation))();
          setSelectedCuration(undefined);
        } else {
          (async () => await addSeriesCuration(values))();
        }
        break;
      case 'Courses':
        if (selectedCuration._id != undefined) {
          (async () => await updateCoursesCuration(curation))();
          setSelectedCuration(undefined);
        } else {
          (async () => await addCoursesCuration(values))();
        }
        break;
      case 'Podcasts':
        if (selectedCuration._id != undefined) {
          (async () => await updatePodcastCuration(curation))();
          setSelectedCuration(undefined);
        } else {
          (async () => await addPodcastCuration(values))();
        }
        break;
    }
    setShowModal(false);
  };

  const onSubmitCategories = (package_: any) => {
    switch (params.type) {
      case 'Albums':
        (async () => await updateAlbumCuration(package_))();
        break;
      case 'Apps':
        (async () => await updateAppCuration(package_))();
        break;
      case 'Cams':
        (async () => await updateCamCuration(package_))();
        break;
      case 'Channels':
        (async () => await updateChannelCuration(package_))();
        break;
      case 'Radios':
        (async () => await updateRadioCuration(package_))();
        break;
      case 'Movies':
        (async () => await updateMovieCuration(package_))();
        break;
      case 'Series':
        (async () => await updateSeriesCuration(package_))();
        break;
      case 'Shorts':
        (async () => await updateShortsCuration(package_))();
        break;
      case 'Podcasts':
        (async () => await updatePodcastCuration(package_))();
        break;
      case 'Courses':
        (async () => await updateCoursesCuration(package_))();
        break;
      case 'Games':
        (async () => await updateGamesCuration(package_))();
        break;
    }
    setShowModal(false);
  };

  const refreshCuration = () => {
    switch (params.type) {
      case 'Albums':
        (async () => await getAlbumCurations(tableSearchState))();
        break;
      case 'Apps':
        (async () => await getAppCurations(tableSearchState))();
        break;
      case 'Cams':
        (async () => await getCamCurations(tableSearchState))();
        break;
      case 'Channels':
        (async () => await getChannelCurations(tableSearchState))();
        break;
      case 'Radios':
        (async () => await getRadioCurations(tableSearchState))();
        break;
      case 'Movies':
        (async () => await getMovieCurations(tableSearchState))();
        break;
      case 'Series':
        (async () => await getSeriesCurations(tableSearchState))();
        break;
      case 'Shorts':
        (async () => await getShortsCurations(tableSearchState))();
        break;
      case 'Podcasts':
        (async () => await getPodcastCurations(tableSearchState))();
        break;
      case 'Courses':
        (async () => await getCoursesCurations(tableSearchState))();
        break;
      case 'Games':
        (async () => await getGamesCurations(tableSearchState))();
        break;
    }
    setShowModal(false);
  };

  const publishCuration = (row: any) => {
    switch (params.type) {
      case 'Albums':
        (async () => await publishAlbumCuration(row))();
        break;
      case 'Apps':
        (async () => await publishAppCuration(row))();
        break;
      case 'Cams':
        (async () => await publishCamCuration(row))();
        break;
      case 'Channels':
        (async () => await publishChannelCuration(row))();
        break;
      case 'Radios':
        (async () => await publishRadioCuration(row))();
        break;
      case 'EpgTv':
        (async () => await publishChannelCuration(row))();
        break;
      case 'EpgRadio':
        (async () => await publishRadioCuration(row))();
        break;
      case 'Movies':
        (async () => await publishMovieCuration(row))();
        break;
      case 'Series':
        (async () => await publishSeriesCuration(row))();
        break;
      case 'Shorts':
        (async () => await publishShortsCuration(row))();
        break;
      case 'Podcasts':
        (async () => await publishPodcastCuration(row))();
        break;
      case 'Courses':
        (async () => await publishCoursesCuration(row))();
        break;
      case 'Games':
        (async () => await publishGamesCuration(row))();
        break;
    }
    setShowModal(false);
  };

  // const publishCurations = async () => {
  //   setIsLoading(true);
  //   data.forEach((item: any) => {
  //     let response = common.PublishBundle({
  //       query_params: {
  //         type: params.type,
  //         _id: item._id,
  //         cms: deployment?.name ?? '',
  //         instance: clientName,
  //       },
  //     });
  //   });
  //   refreshCuration();
  //   setIsLoading(false);
  // };

  const packageColumns: IReactBCTableColumn<[]>[] = [
    {
      title: t('curation-cms-type'),
      dataKey: '_id',
      width: '20%',
      cell: (row: any) => (
        <>
          {(row.external === undefined || row.external === null) && (
            <div>
              <div style={{ alignSelf: 'center' }}>{t('tvms-internal')}</div>
            </div>
          )}
          {(row.external !== undefined || row.external === null) && (
            <div>
              <div style={{ alignSelf: 'center' }}>
                {row.external?.cms_type
                  .match(/([A-Z]?[^A-Z]*)/g)
                  .slice(0, -1)
                  .join(' ')}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: t('curation-name'),
      dataKey: 'name',
      width: '30%',
      cell: (row: any) => <span>{row.name}</span>,
    },
    {
      title: t('publish-date'),
      dataKey: 'publish',
      width: '25%',
      cell: (row: any) => (
        <div>
          <div style={{ alignSelf: 'center' }}>{row.publish}</div>
        </div>
      ),
    },
    {
      title: t('actions'),
      dataKey: 'action',
      width: '25%',
      cell: (row: any, index) => (
        <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
          <Tooltip placement="top" title={t('publish-curation')}>
            <Button className="cursor-pointer" onClick={() => publishCuration(row)} type="text">
              <FontAwesomeIcon className="action-icon" icon={faUpload} />
              <div>{t('publish')}</div>
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={t('edit')}>
            <Button
              className="mx-2 cursor-pointer"
              onClick={() => {
                setSelectedCuration(row);
                setShowModal(true);
              }}
              type="text"
            >
              <FontAwesomeIcon className="action-icon" icon={faEdit} />
              <div>{t('edit')}</div>
            </Button>
          </Tooltip>
          {(row.external === undefined || row.external === null) && (
            <Tooltip placement="top" title={t('curation-details')} key={'details'}>
              <a href={`${url}` + '/content/' + row.name + '/' + row._id + '/' + index}>
                <Button className="mx-2 cursor-pointer" type="text">
                  <FontAwesomeIcon className="action-icon" icon={faList} />
                  <div>
                    {params.type} ({row.items.length})
                  </div>
                </Button>
              </a>
            </Tooltip>
          )}
        </Col>
      ),
    },
  ];

  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <div className="row mb-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start">
          <span className="ml-3 ml-md-4 "></span>
        </div>
        <div className="col-md-12">
          <div className="row float-right">
            {/* <Button loading={isLoading} disabled={isLoading} className="btn btn-secondary rounded-pill btn-default float-right  height-48 mr-2" onClick={() => publishCurations()}>
              {t('publish-all-curations')}
            </Button> */}
            <Button disabled={isLoading} className="btn btn-secondary rounded-pill btn-default float-right  height-48 mr-5" onClick={() => setShowModal(!showModal)}>
              {t('add-curation')}
            </Button>
          </div>
        </div>
      </div>
      {showModal && (
        <CurationModal
          initialData={{
            type: params.type,
            ...selectedCuration,
          }}
          visible={showModal}
          onSubmit={onSubmitModal}
          onCancel={() => setShowModal(!showModal)}
        />
      )}
      <ReactBCTable
        key={JSON.stringify(data)}
        tableId={'user_interfaces_tables'}
        columns={packageColumns}
        data={data}
        isLoading={isLoading}
        totalRecords={pagination.count}
        pageSize={pagination.pageSize}
        currentPage={pagination.pageNumber}
        hasChild={true}
        childComponent={(row: any, index) => {
          return (row.external === undefined || row.external === null) ? <NestedCurationContent package={data[index]} deployment={deployment} clientName={clientName} type={params.type} data={row} isLoading={isLoading} onSubmit={onSubmitCategories} /> : null;
        }}
      />
    </div>
  );
};
export default NestedCurations;
