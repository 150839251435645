import React from 'react';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { usePushGatewaysSchemas } from './pushGatewaySchemas';
import * as PushGatewayService from '../../../../services/areas/billing/pushGatewayService';
import { Card, Form, Modal, notification, Typography } from 'antd';
import { useParams } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { BillingContext } from '../../../../contexts/billingContext';
import { IMSNameParams } from '../../../dashboard';
import { PrimaryButton } from '../../../../common/components/form';
import GatewayForm from './shared/form';
import { useTranslation } from 'react-i18next';
import { IPushGateway } from '@mwaretv/communication/dist/push/definitions/interfaces/IPushGateway';

const List = () => {
  const { data: pushGateways } = usePushGatewaysSchemas();
  const { t } = useTranslation();
  return (
    <>
      {pushGateways && (
        <ContainerWrapper>
          <div className="row">
            <div className="col-md-6 mb-2">
              <PageTitle title={t('push-gateways')} subtitle={t('push-gateways-are-used-to-send-pushs-to-your-customer-your-configured-gateways-are-saved-under-my-push-gateways')} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4">
              <GatewayGrid gateways={pushGateways} />
            </div>
          </div>
        </ContainerWrapper>
      )}
    </>
  );
};

type GatewayCardProps = {
  gateway: IPushGateway;
};

const getProviderLogo = (logo: string) => {
  if (logo.includes('localhost')) {
    return new URL(new URL(logo).pathname, process.env.REACT_APP_BACKEND_API_BASE_URL).href;
  } else {
    return logo;
  }
};

const GatewayCard = ({ gateway }: GatewayCardProps) => {
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Card>
        <img height={100} src={getProviderLogo(gateway.logo)} alt={gateway.name} />
        <div className="card-bg-light p-3">
          <p className="mt-1">{gateway.helpText}</p>
        </div>
        <div className="text-right">
          <PrimaryButton onClick={() => setSettingsOpen(true)}>{t('setup-gateway')}</PrimaryButton>
        </div>
      </Card>
      <GatewaySettingsModal {...{ gateway }} open={settingsOpen} onCancel={() => setSettingsOpen(false)} />
    </>
  );
};

type GatewaySettingsModalProps = {
  open: boolean;
  gateway: IPushGateway;
  onCancel: () => void;
};

const GatewaySettingsModal = ({ open, gateway, onCancel }: GatewaySettingsModalProps) => {
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = React.useContext(BillingContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const { isLoading: mutationIsLoading, mutate } = useMutation(
    async (values: any) => {
      const { data } = await PushGatewayService.add({ data: values, params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' } });
      return data;
    },
    {
      onSuccess: () => {
        notification.success({ message: t('push-gateway-saved') });
        form.resetFields();
        onCancel();
      },
      onError: () => notification.error({ message: t('push-gateway-could-not-be-saved-please-try-again-later') }),
    }
  );

  return (
    <Modal
      {...{ open, onCancel }}
      width={1000}
      okText={t('save')}
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{ disabled: mutationIsLoading }}
    >
      <div className="container">
        <div className="row">
          <div className="col col-lg-6">
            <ModalGatewayInfo {...{ gateway }} />
          </div>
          <div className="col col-lg-6">
<div className='ml-3'>
<Typography.Title level={5}>{t('gateway-settings')}</Typography.Title>
</div>
      
            <Form
              {...{ form }}
              initialValues={{ push_gateway_id: gateway.push_gateway_id }}
              onFinish={(values) => {
                mutate(values);
              }}
            >
              <GatewayForm {...{ gateway }} />
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

type ModalGatewayInfoProps = {
  gateway: IPushGateway;
};

const ModalGatewayInfo = ({ gateway }: ModalGatewayInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className="pt-4">
      <div className="card-bg-light mt-3 mb-2 p-3">
        <img height={75} src={gateway.logo} alt={gateway.name} />
      </div>
      <div className="card-bg-light mb-2 p-3">
        <Typography.Title level={5} className="mt-2">
          {t('setup-information')}
        </Typography.Title>
      </div>
      <div className="card-bg-light mb-2 p-3">
        {(gateway?.infotext as string)?.split('\n').map((text) => (
          <p className="mt-3">{text}</p>
        ))}
      </div>
      <div className="card-bg-light mb-2 p-3">
        {gateway.url && (
          <>
            <Typography.Title level={5}>{t('more-info')}</Typography.Title>
            <a target="_blank" rel="noreferrer" href={gateway?.url || ''}>
              {t('visit-website')}
            </a>
          </>
        )}
      </div>
    </div>
  );
};

type GatewayGridProps = {
  gateways: IPushGateway[];
};

const GatewayGrid = ({ gateways }: GatewayGridProps) => {
  return (
    <div className="row">
      {gateways.map((gateway, i) => (
        <div className="col-12 col-md-6 mb-4" key={i}>
          <GatewayCard {...{ gateway }} />
        </div>
      ))}
    </div>
  );
};

export default List;
