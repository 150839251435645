import { Button, notification, Table, Tooltip } from 'antd';
import * as HttpStatusCodes  from 'http-status-codes';
import { useState } from 'react';
import { useParams } from 'react-router';
import { CustomNotification } from '../../../../../common/components/Notification';
import providerService from '../../../../../services/areas/content/provider/provider/index';
import { IMSNameParams } from '../../../../dashboard';
import { useEffectOnce } from 'react-use';
import AddModal from './add';
import UpdateModal from './details';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

const PpvRules = () => {
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const [rowToBeUpdate, setRowToBeUpdate] = useState({
    _id: '',
    createdAt: new Date(),
    name: '',
    key: 'de',
    type: '',
    quantity: 0,
    updatedAt: new Date(),
    __v: 0,
  });
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState([] as any);
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = useState(false);

  const handleSubmit = async (ppvRulesData: any) => {
    setIsloading(true);
    let response = await providerService.Add_Provider({
      ...ppvRulesData,
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
    });

    if (response && response.status_code === HttpStatusCodes.OK) {
      setShowAddModal(false);
      getPPV();
      notification.success({
        message: t('success'),
        description: t('rule-added-successfully'),
      });
    } else
      CustomNotification({
        error: response.errors,
      });
    setIsloading(false);
  };

  const handleUpdate = async (values: any) => {
    setIsloading(true);
    let response = await providerService.Update_Provider({
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
      ...values,
    });

    if (response && response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('success'),
        description: t('update-successfully'),
      });
      setShowUpdateModal(false);
    }
    setIsloading(false);
  };

  const handleDelete = async (row: any) => {
    setIsloading(true);
    let response = await providerService.Delete_Provider({
      query_params: {
        cms: service_name || '',
        instance: clientName,
      },
      _id: row._id,
    });

    if (response && response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('success'),
        description: t('delete-successfully'),
      });
      getPPV();
      setShowUpdateModal(false);
    }
    setIsloading(false);
  };
  const getPPV = async () => {
    setIsloading(true);
    let response = await providerService.List_Provider({
      query_params: {
        instance: clientName,
        cms: service_name || '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      if (response.data != undefined && response.data.data != undefined) {
        setData(response.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      }
    }
    setIsloading(false);
  };

  useEffectOnce(() => {
    getPPV();
  });

  const handleCancel = () => {
    setShowUpdateModal(false);
    setShowAddModal(false);
  };

  const columns = [
    {
      title: t('id'),
      dataIndex: '_id',
      key: '_id',
      width: '50%',
    },
    {
      title: t('name'),
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: t('actions'),
      dataIndex: 'action',
      render: (tags: any, row: any) => (
        <span className="justify-content-end" style={{ height: 60 }}>
          <Tooltip placement="top" title={`Edit`}>
            <Button
              type="text"
              className="mx-2 cursor-pointer"
              onClick={() => {
                handleDelete(row);
              }}
            >
              <FontAwesomeIcon className="action-icon" icon={faTrashCan} />
              <div>{t('delete')}</div>
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-7 mb-2">
          <h2 className="page-title">{t('providers')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('setup-your-content-providers-that-you-can-use-within-your-content-items')}</p>
        </div>
        <div className="col-md-5"></div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-2"></div>
        <div className="col-md-5">
          <Button className="btn btn-secondary rounded-pill btn-default float-right width-180 height-48" onClick={() => setShowAddModal(true)}>
            {t('add-provider')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <Table columns={columns} dataSource={data} loading={isLoading} />
        </div>
      </div>
      <AddModal isLoading={isLoading} visible={showAddModal} onSubmit={handleSubmit} onCancel={handleCancel} />
      <UpdateModal isUpdating={isLoading} row={rowToBeUpdate} visible={showUpdateModal} onSubmit={(values) => handleUpdate(values)} onCancel={handleCancel} />
    </div>
  );
};

export default PpvRules;
