import React from 'react';
import { Input, Item } from '../../../../../common/components/form';
import { ISMSGateway } from '@mwaretv/communication/dist/sms/definitions/interfaces/ISMSGateway';
import { Form, Switch } from 'antd';
import { Input as AntdInput } from 'antd';
import { useTranslation } from 'react-i18next';

type SmsGatewayFormProps = {
  gateway: ISMSGateway | null;
};

const SMSGatewayForm = ({ gateway }: SmsGatewayFormProps) => {
  const form = Form.useFormInstance();
  const provider_id = form.getFieldValue('sms_gateway_id');
  const {t} = useTranslation();
  
  return (
    <div className="col-12  mt-3">
      {!gateway && <span>{t('invalid-email-gateway')}</span>}
      {gateway && (
        <>
          <div className="card-bg-light pl-3 pr-3 pt-3 mt-2 pb-4">
            <Item name={['sms_gateway_id']} hidden />
            <Item name={['name']} label={t('name')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: t('name-is-required') }]}>
              <Input />
            </Item>
          </div>
          <div className="card-bg-light pl-3 pr-3 pt-3 mt-2 pb-4">
            <Item name={['enabled']} label={t('enabled')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Switch defaultChecked={form.getFieldValue('enabled')} />
            </Item>
          </div>
          <div className="card-bg-light pl-3 pr-3 pt-3 mt-2 pb-4">
            {Object.keys(gateway.fields).map((k, i) => {
              return (
                <div key={i}>
                  {gateway.fields[k].type === 'string' && (
                    <Item className="mt-3" name={['settings', k]} label={gateway.fields[k].label} labelCol={{ span: 24 }}>
                      <Input />
                    </Item>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default SMSGatewayForm;
