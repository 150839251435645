import { App_Curations } from "../../../../../interfaces/forms/areas/content/apps/curations";
import { IGetForm } from "../../../../../interfaces/forms/common/ICMSExtraQuerytParams";
import { ICMS_Lists } from "../../../../../interfaces/forms/common/ICMS_Lists";
import { App_Curations as Response } from "../../../../../interfaces/responses/areas/content/apps/curations";

import { IBaseResponse } from "../../../../../interfaces/responses/IBaseResponse";
import axiosInstance from "../../../../../utils/axios";
import { API_ENDPOINTS } from "../../../../_APIConstants";

class Curation_Service {
    Add_Curation = async (data: App_Curations): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.CMS.APPS.CURATION.ADD, data, {
                params: {
                    instance: data.query_params.instance,
                    cms: data.query_params.cms,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Curation_List = async (
        data: ICMS_Lists
    ): Promise<Response> => {
        return await axiosInstance
            .post(API_ENDPOINTS.V1.CMS.APPS.CURATION.LIST, data, {
                params: data.query_params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Details_Curation = async (
        data: IGetForm
    ): Promise<Response> => {
        return await axiosInstance
            .get(`${API_ENDPOINTS.V1.CMS.APPS.CURATION.DETAILS}/${data._id}`, {
                params: {
                    instance: data.query_params.instance,
                    cms: data.query_params.cms,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Update_Curation = async (data: App_Curations): Promise<IBaseResponse> => {
        return await axiosInstance
            .put(`${API_ENDPOINTS.V1.CMS.APPS.CURATION.UPDATE}/${data._id}`, data, {
                params: {
                    instance: data.query_params.instance,
                    cms: data.query_params.cms,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };

    Delete_Curation = async (data: IGetForm): Promise<IBaseResponse> => {
        return await axiosInstance
            .delete(`${API_ENDPOINTS.V1.CMS.APPS.CURATION.DELETE}/${data._id}`, {
                params: {
                    instance: data.query_params.instance,
                    cms: data.query_params.cms,
                },
                data,
            })
            .then((response) => {
                return response.data;
            })
            .catch((err) => {
                return err;
            });
    };
    Publish_Curation = async (data: App_Curations): Promise<IBaseResponse> => {
        return await axiosInstance
          .put(`${API_ENDPOINTS.V1.CMS.APPS.CURATION.PUBLISH}/${data._id}`, data, {
            params: {
              instance: data.query_params.instance,
              cms: data.query_params.cms,
              path: data.query_params.path
            },
          })
          .then((response) => {
            return response.data;
          })
          .catch((err) => {
            return err;
          });
      };

     
}

export default new Curation_Service();
