import { Button, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
type IUpdatePackageModalProps = {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialData: {
    _id?: string;
    name?: string;
    type: string;
    external: any;
  };
};

const PackageModal = (props: IUpdatePackageModalProps) => {
  const { onSubmit, onCancel, initialData } = props;
  const { t } = useTranslation();
  const [externalType, setExternalType] = useState('');
  let [form] = useForm();

  useEffect(() => {
    setExternalType(initialData?.external?.cms_type);
    form.setFieldsValue(initialData);
  }, [initialData]);

  const onChangeExternalType = (value) => {
    setExternalType(value);
  };

  const scriptxCountries = [
    { id: 4672, country: 'United States', country_code: 'US', country_id: 1 },
    { id: 333, country: 'Canada', country_code: 'CA', country_id: 2 },
    { id: 14338, country: 'Belize', country_code: 'BZ', country_id: 3 },
    { id: 7340, country: 'Costa Rica', country_code: 'CR', country_id: 4 },
    { id: 173, country: 'Guatemala', country_code: 'GT', country_id: 5 },
    { id: 7341, country: 'Honduras', country_code: 'HN', country_id: 6 },
    { id: 6836, country: 'Mexico', country_code: 'MX', country_id: 8 },
    { id: 168, country: 'Nicaragua', country_code: 'NI', country_id: 7 },
    { id: 145, country: 'Panama', country_code: 'PA', country_id: 9 },
    { id: 6874, country: 'Argentina', country_code: 'AR', country_id: 10 },
    { id: 7298, country: 'Bolivia', country_code: 'BO', country_id: 11 },
    { id: 356, country: 'Brazil', country_code: 'BR', country_id: 12 },
    { id: 7295, country: 'Chile', country_code: 'CL', country_id: 13 },
    { id: 6839, country: 'Colombia', country_code: 'CO', country_id: 14 },
    { id: 7302, country: 'Ecuador', country_code: 'EC', country_id: 15 },
    { id: 23491, country: 'French Guiana', country_code: '', country_id: 16 },
    { id: 14734, country: 'Guyana', country_code: 'GY', country_id: 17 },
    { id: 7333, country: 'Paraguay', country_code: 'PY', country_id: 18 },
    { id: 7252, country: 'Peru', country_code: 'PE', country_id: 19 },
    { id: 14752, country: 'Suriname', country_code: 'SR', country_id: 20 },
    { id: 7309, country: 'Uruguay', country_code: 'UY', country_id: 21 },
    { id: 203, country: 'Venezuela', country_code: 'VE', country_id: 22 },
    { id: 8214, country: 'Cuba', country_code: 'CU', country_id: 23 },
    { id: 210, country: 'Dominican Republic', country_code: 'DO', country_id: 24 },
    { id: 8218, country: 'Haiti', country_code: 'HT', country_id: 25 },
    { id: 22787, country: 'Guadeloupe', country_code: '', country_id: 26 },
    { id: 22786, country: 'Martinique', country_code: '', country_id: 27 },
    { id: 7313, country: 'Puerto Rico', country_code: 'PR', country_id: 28 },
    { id: 23514, country: 'Saint Barthélemy', country_code: '', country_id: 29 },
    { id: 23516, country: 'Saint Martin', country_code: '', country_id: 30 },
  ];

  return (
    <div className="p-5" style={{ border: '1px solid #ebebeb', borderTop: 0 }}>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <Form initialValues={initialData} form={form} onFinish={(values) => onSubmit(values)} onFinishFailed={(errorInfo) => {}}>
            <label className="col-form-label">{t('cms-provider')}</label>
            <Form.Item initialValue={t('select-a-cms')} name={['external', 'cms_type']}>
              <Select onChange={(value) => onChangeExternalType(value)} className="default-select" defaultActiveFirstOption={true} defaultValue={'JSON'}>
                <Select.Option value={'BrightcoveCmsApi'} key={'BrightcoveCmsApi'}>
                  Brightcove CMS API
                </Select.Option>
                <Select.Option value={'ScriptxDeeplinks'} key={'ScriptxDeeplinks'}>
                  ScriptX Top 20 Deeplinks
                </Select.Option>
                {/* <Select.Option  value={'VideoElephant'} key={'VideoElephant'}>
                  Video Elephant
                </Select.Option>
                <Select.Option value={'DistroTV'} key={'DistroTV'}>
                  DistroTV
                </Select.Option>
                <Select.Option value={'Comcast'} key={'Comcast'}>
                  Comcast
                </Select.Option> */}
              </Select>
            </Form.Item>
            {externalType == 'ScriptxDeeplinks' && (
              <div className="card-bg-light pl-5 pt-3 mb-4 p-5 mt-4">
                <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                  ScriptX Settings
                </h4>
                <label className="col-form-label">Streaming Service</label>
                <Form.Item initialValue={'Select a Service'} name={['external', 'scriptx', 'service']}>
                  <Select className="default-select" defaultActiveFirstOption={true}>
                  <Select.Option value={1} key={1}>
                      Prime
                    </Select.Option>
                    <Select.Option value={2} key={2}>
                      AppleTV
                    </Select.Option>
                    <Select.Option value={3} key={3}>
                      Disney+
                    </Select.Option>
                    <Select.Option value={4} key={4}>
                      Netflix
                    </Select.Option>
                    <Select.Option value={5} key={5}>
                      Hulu
                    </Select.Option>
                    <Select.Option value={6} key={6}>
                      HBO
                    </Select.Option>
                  </Select>
                </Form.Item>
                <label className="col-form-label">Top 20 Region</label>
                <Form.Item initialValue={'Select a Country'} name={['external', 'scriptx', 'country']}>
                  <Select className="default-select" defaultActiveFirstOption={true}>
                    {scriptxCountries.map((country) => {
                      return (
                        <Select.Option value={country.country_id} key={country.country_id}>
                          {country.country}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            )}
            {externalType == 'BrightcoveCmsApi' && (
              <div className="card-bg-light pl-5 pt-3 mb-4 p-5 mt-4">
                <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                  {t('brightcove-settings')}
                </h4>
                <label className="col-form-label">{t('account-id')}</label>
                <Form.Item
                  name={['external', 'brightcove', 'account_id']}
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: t('account-id-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="j1324j124j" />
                </Form.Item>
                <label className="col-form-label">{t('policy-key')}</label>
                <Form.Item
                  name={['external', 'brightcove', 'policy_key']}
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: t('policy-key-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="j1324j124j" />
                </Form.Item>
                <label className="col-form-label">{t('client-id')}</label>
                <Form.Item
                  name={['external', 'brightcove', 'client_id']}
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: t('client-id-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="23jdjaj23" />
                </Form.Item>
                <label className="col-form-label">{t('client-secret')}</label>
                <Form.Item
                  name={['external', 'brightcove', 'client_secret']}
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: t('client-secret-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="23jdjaj23" />
                </Form.Item>
                <label className="col-form-label">{t('playlist-id')}</label>
                <Form.Item
                  name={['external', 'brightcove', 'playlist_id']}
                  className="form-group mb-0"
                  extra={t('external-list-brightcove-playlist-id-extra')}
                  rules={[
                    {
                      required: true,
                      message: t('playlist-id-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="J#JDjf23jd" />
                </Form.Item>
                <label className="col-form-label">{t('refresh-rate')}</label>
                <Form.Item initialValue={t('select-a-rate')} name={['external', 'refresh_rate']}>
                  <Select className="default-select" defaultActiveFirstOption={true} defaultValue={'Manual'}>
                    <Select.Option value={'Manual'} key={'Manual'}>
                      {t('manual')}
                    </Select.Option>
                    <Select.Option value={'OncePerDay'} key={'OncePerDay'}>
                      {t('once-per-day')}
                    </Select.Option>
                    <Select.Option value={'OncePerWeek'} key={'OncePerWeek'}>
                      {t('once-per-week')}
                    </Select.Option>
                    <Select.Option value={'OncePerMonth'} key={'OncePerMonth'}>
                      {t('once-per-month')}
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            )}
            <Form.Item name="_id">
              <Input hidden value={initialData?._id}></Input>
            </Form.Item>
            <div className="col-md-12 p-0">
              {initialData._id == undefined && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('add-list')}
                </Button>
              )}
              {initialData._id != undefined && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('update-list')}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PackageModal;
