import { Route, Switch, useRouteMatch } from 'react-router';
import Ratings from './ageratings';
import RatingsAdd from './ageratings/add';
import RatingsDetails from './ageratings/details';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}>
        <RatingsAdd />
      </Route>
      <Route path={`${path}/details/:_id`}>
        <RatingsDetails />
      </Route>
      <Route path={`${path}/`}>
        <Ratings />
      </Route>
    </Switch>
  );
};

export default Index;
