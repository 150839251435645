import { AxiosResponse } from 'axios';
import axiosInstance from '../../../utils/axios';
import { API_ENDPOINTS } from '../../_APIConstants';
import { PushTemplate } from '../../../interfaces/forms/areas/billing/pushTemplate';
import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';

const { BILLING: BILLING_ENDPONTS } = API_ENDPOINTS;
const { PUSH_TEMPLATES: ENDPOINTS } = BILLING_ENDPONTS;

type Params = { instance: string; cms: string; crm: string };

type PaginationParams = {
  page: number;
  limit: number;
};

type ResponseFormat<T> = {
  docs: T;
};

type PaginatedResults<T> = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  nextPage: number | null;
  page: number;
  pagingCounter: number;
  prevPage: null | number;
  totalDocs: number;
  totalPages: number;
} & ResponseFormat<T>;

export const list = ({ params }: { params: Params & PaginationParams }): Promise<AxiosResponse<PaginatedResults<PushTemplate[]>>> => {
  return axiosInstance.get<PaginatedResults<PushTemplate[]>>(ENDPOINTS.LIST, { params });
};

type GetParams = { params: Params; id: string };

export const get = ({ params, id }: GetParams): Promise<AxiosResponse<PushTemplate>> => {
  return axiosInstance.get(`${ENDPOINTS.GET}/${id}`, { params });
};

export type AddParams = { params: Params } & { data: PushTemplate };

export const add = ({ data, params }: AddParams): Promise<AxiosResponse<PushTemplate>> => {
  return axiosInstance.post<PushTemplate>(ENDPOINTS.ADD, data, { params });
};

type DeleteParams = { params: Params; id: string };

export const del = ({ params, id }: DeleteParams): Promise<AxiosResponse> => {
  return axiosInstance.delete(`${ENDPOINTS.DELETE}/${id}`, { params });
};

export type PutParams = { params: Params } & { data: PushTemplate; id: string };

export const put = ({ data, params, id }: PutParams): Promise<AxiosResponse<PushTemplate>> => {
  return axiosInstance.put<PushTemplate>(`${ENDPOINTS.PUT}/${id}`, data, { params });
};

export type PatchParams = { params: Params } & { data: PushTemplate; id: string };

export const patch = ({ data, params, id }: PatchParams): Promise<AxiosResponse<PushTemplate>> => {
  return axiosInstance.patch<PushTemplate>(`${ENDPOINTS.PATCH}/${id}`, data, { params });
};

export const usePushTemplateList = ({ params }: { params: Params & PaginationParams }) =>
  useQuery(['billing', 'settings', 'push-templates', 'list', params], async () => {
    return list({ params })
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  });

export const usePushTemplateGet = ({ params, id }: GetParams) =>
  useQuery(['billing', 'settings', 'push-templates', 'get', params, id], async () => {
    return get({ params, id })
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  });

export const usePushTemplateAdd = ({ params }: { params: Params }, options: Omit<UseMutationOptions<AxiosResponse<PushTemplate>, unknown, PushTemplate, unknown>, 'mutationFn'>) =>
  useMutation(async (values: PushTemplate) => {
    return add({ params, data: values })
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  }, options);

export const usePushTemplatePut = ({ params, id }: Omit<PutParams, 'data'>, options: Omit<UseMutationOptions<AxiosResponse<PushTemplate>, unknown, PushTemplate, unknown>, 'mutationFn'>) =>
  useMutation(async (values: PushTemplate) => {
    return put({ params, data: values, id })
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  }, options);

export const usePushTemplateDelete = ({ params }: { params: Params }, options: Omit<UseMutationOptions<AxiosResponse, unknown, string, unknown>, 'mutationFn'>) =>
  useMutation(async (id) => {
    return del({ params, id })
      .then((r) => Promise.resolve(r))
      .catch((e) => Promise.reject(e));
  }, options);
