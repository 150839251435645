import { faEnvelope, faMemo, faMobile, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notification, Form as AntdForm, Typography, Col } from 'antd';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { Button, Form, Input, Item, Modal, Table, Tooltip } from '../../../../common/components/form';
import { PageTitle } from '../../../../common/components/pageTitle';
import { BillingContext } from '../../../../contexts/billingContext';
import { IMSNameParams } from '../../../dashboard';
import PushGatewayForm from './shared/form';
import { Spin } from '../../../../common/components/spinner';
import { usePushGatewaysSchemas } from './pushGatewaySchemas';
import { useDeleteMutation, usePushGateways, usePutMutation } from '../../../../services/areas/billing/pushGatewayService';
import { PushGateway } from '../../../../interfaces/forms/areas/billing/pushGateway';
import TestService from '../../../../services/areas/billing/tests/index';
import { useTranslation } from 'react-i18next';

const MyGatewaysList = () => {
  const { t } = useTranslation();
  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('my-push-gateways')} subtitle={t('overview-of-all-your-configured-push-gateways')} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <GatewayTable />
        </div>
      </div>
    </ContainerWrapper>
  );
};

const GatewayTable = () => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const [pagination, setPagination] = useState<{ limit: number; page: number }>({ limit: 50, page: 1 });
  const { t } = useTranslation();
  const { isLoading, isError, isSuccess, data, refetch } = usePushGateways({
    params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '', ...pagination },
  });

  return (
    <>
      {isLoading && <Spin />}
      {isError && <span>{t('something-went-wrong-please-try-again-later')}</span>}
      {isSuccess && (
        <Table
          dataSource={data.data.docs}
          columns={[
            { title: t('name'), dataIndex: 'name', width: '33%' },
            {
              title: t('base-provider'),
              width: '33%',
              render: (row) => <span>{row.push_gateway_id}</span>,
            },
            {
              title: t('enabled'),
              width: '33%',
              render: (row) => <span>{row.enabled ? t('yes') : t('no')}</span>,
            },
            {
              title: t('actions'),

              render: (row) => <TableActions {...{ row }} onChangesMade={refetch} />,
            },
          ]}
          pagination={{
            onChange: (page, pageSize) => setPagination({ page, limit: pageSize }),
          }}
        />
      )}
    </>
  );
};

type TableActionsProps = {
  row: any;
  onChangesMade: () => void;
};

const TableActions = ({ row, onChangesMade }: TableActionsProps) => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [testModalOpen, setTestModalOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const deleteMutation = useDeleteMutation(
    { params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('push-gateway-deleted') });
        onChangesMade();
      },
      onError: () => {
        notification.error({
          message: t('push-gateway-could-not-be-deleted-please-try-again-later'),
        });
      },
    }
  );

  return (
    <>
      <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
        <Tooltip placement="top" title={t('delete')}>
          <Button
            type="text"
            className="mx-2"
            disabled={deleteMutation.isLoading}
            onClick={() => {
              deleteMutation.mutate(row._id);
            }}
          >
            <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
            <div>{t('delete')}</div>
          </Button>
        </Tooltip>
        <Tooltip placement="top" title={t('test')}>
          <Button type="text" className="mx-2" disabled={deleteMutation.isLoading} onClick={() => setTestModalOpen(true)}>
            <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faMobile} />
            <div>{t('test-message')}</div>
          </Button>
        </Tooltip>
        <Tooltip placement="top" title={t('details')}>
          <Button type="text" className="mx-2" disabled={deleteMutation.isLoading} onClick={() => setModalOpen(true)}>
            <FontAwesomeIcon className="action-icon" icon={faMemo} />
            <div>{t('open-details')}</div>
          </Button>
        </Tooltip>
      </Col>
      <ProviderModal {...{ providerData: row, onChangesMade }} open={modalOpen} onCancel={() => setModalOpen(false)} />
      <TestModal {...{ providerData: row }} open={testModalOpen} onCancel={() => setTestModalOpen(false)} />
    </>
  );
};

type ProviderModalProps = {
  providerData: PushGateway;
  open: boolean;
  onCancel: () => void;
  onChangesMade: () => void;
};

const getProviderLogo = (logo: string) => {
  if (logo.includes('localhost')) {
    return new URL(new URL(logo).pathname, process.env.PUBLIC_URL).href;
  } else {
    return logo;
  }
};

export const ProviderModal = ({ providerData, open, onCancel, onChangesMade }: ProviderModalProps) => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const { data: pushGatewaySchemas } = usePushGatewaysSchemas();
  const provider = pushGatewaySchemas?.find((x) => x.push_gateway_id === providerData.push_gateway_id) ?? null;
  const [form] = AntdForm.useForm();
  const { t } = useTranslation();
  const { isLoading, mutate } = usePutMutation(
    {
      params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' },
      id: providerData._id || '',
    },
    {
      onSuccess: () => {
        notification.success({ message: t('push-gateway-updated') });
        onCancel();
      },
      onError: () => {
        notification.error({
          message: t('push-gateway-could-not-be-updated-please-try-again-later'),
        });
      },
    }
  );

  return (
    <Modal
      {...{ open, onCancel }}
      width={1000}
      onOk={() => {
        form.submit();
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col col-lg-6">
            <div className="card-bg-light p-3 mb-2 mt-3">
              <img height={75} src={provider ? getProviderLogo(provider.logo) : undefined} alt={provider?.name} />
            </div>
            <div className="card-bg-light p-3 mb-2">
              <Typography.Title level={5} className="mt-2">
                {t('setup-information')}
              </Typography.Title>
              {(provider?.infotext as string)?.split('\n').map((text) => (
                <p className="mt-3">{text}</p>
              ))}
            </div>
            <div className="card-bg-light p-3 pb-4 mb-2">
              <Typography.Title level={5} className="mt-3">
                {t('more-info')}
              </Typography.Title>
              <a target="_blank" rel="noreferrer" href={provider?.url || ''}>
                {t('visit-website')}
              </a>
            </div>
          </div>
          <div className="col col-lg-6">
            <Form
              {...{ form }}
              layout="vertical"
              initialValues={providerData}
              disabled={isLoading}
              onFinish={(values) => {
                mutate(values);
                onChangesMade();
                onCancel();
              }}
            >
              <PushGatewayForm gateway={provider} />
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

type TestModalProps = {
  providerData: PushGateway;
  open: boolean;
  onCancel: () => void;
};
export const TestModal = ({ providerData, open, onCancel }: TestModalProps) => {
  const { data: pushGatewaySchemas } = usePushGatewaysSchemas();
  const gateway = pushGatewaySchemas?.find((x) => x.push_gateway_id === providerData.push_gateway_id) ?? null;
  const [form] = AntdForm.useForm();
  const { t } = useTranslation();
  const submit = async (values) => {
    var response = await TestService.TestPush({
      pushGatewayData: providerData,
      device: values.device,
    });
    var res = await response;
    console.log(res)
    if (res.success) {
      notification.success({
        message: t('push-has-been-sent'),
      });
    } else {
      notification.success({
        message: t('push-has-not-been-sent-check-your-setup'),
      });
    }
    onCancel();
  };

  return (
    <Modal
      onOk={() => {
        form.submit();
      }}
      {...{ open, onCancel }}
      width={600}
    >
      <div className="container">
        <div className="col">
          <div className="row-md-12 mb-3">
            <img height={75} src={gateway ? getProviderLogo(gateway.logo) : undefined} alt={gateway?.name} />
            <p className={'mt-4 mb-4'}>{t('send-a-test-push-to-test-if-your-settings-are-correct-and-the-gateway-is-working-correctly')}</p>
          </div>
          <div className="row-md-12">
            <Form
              onFinish={(values: any) => {
                submit(values);
              }}
              {...{ form }}
              layout="vertical"
            >
              <Item extra={t('test-push-message-extra')} name={['device']} label="" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: t('push-is-required') }]}>
                <Input />
              </Item>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MyGatewaysList;
