import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
type IUpdatePackageModalProps = {
  onSubmit: (values: any, newCat) => void;
  onCancel: () => void;
  initialData: {
    _id?: string;
    name?: string;
    type: string;
  };
};

const PackageModal = (props: IUpdatePackageModalProps) => {
  const { onSubmit, onCancel, initialData } = props;
  const { t } = useTranslation();
  let [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(initialData);
  }, []);

  return (
    <div className="p-5" style={{ border: '1px solid #ebebeb', borderTop: 0 }}>
      <div className="row justify-content-center">
        <div className="col-md-12">
      
          <Form initialValues={initialData} form={form} onFinish={(values) => onSubmit(values, false)} onFinishFailed={(errorInfo) => {}}>
            <label className="col-form-label">{t('name')}</label>
            <Form.Item
              name="name"
              className="form-group mb-0"
              extra={t('bundle-general-tab-extra')}
              rules={[
                {
                  required: true,
                  message: t('please-enter-bundle-name'),
                },
              ]}
            >
              <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('enter-bundle-name')} />
            </Form.Item>

            <Form.Item name="_id">
              <Input hidden value={initialData?._id}></Input>
            </Form.Item>
            <div className="col-md-12 p-0">
              {initialData._id == undefined && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('add-bundle')}
                </Button>
              )}
              {initialData._id != undefined && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('update-bundle')}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PackageModal;
