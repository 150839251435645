import { Form, Input, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

const ParentalControl = () => {
  const { t } = useTranslation();
  return (

    <div>
      <h4 className="text-primary mt-5 mb-3">{t('parental-control')}</h4>
      <div className="">
        <div className="col-md-12 card-bg-light">
          <div className="col-md-12 p-3 ">
            <div className="">
              <div className="card-body card-col-width rounded">
                <div className="row">
                  <label className="col-md-3 col-form-label">Parental Control</label>
                  <Form.Item extra={t('enabling-this-will-make-the-app-ask-for-a-pin-before-access-to-the-content-is-granted')} className="col-md-5 pb-0 mb-0" name={['parental', 'enabled']}>
                    <Switch

                      className="mt-2"
                    />
                  </Form.Item>
                  </div>
                  <div className="row">
                  <label className="col-md-3 col-form-label">Content Rating</label>
                  <Form.Item extra={'Content visibility will be controlled by the settings specified here and the chosen age rating in the customers app profile.'} className="col-md-5 pb-0 mb-0" name={['parental', 'rating']}>
                    <Input onInput={(e:any) => e.target.value = e.target.value.toUpperCase()} className="form-control bg-white font-size-14 text-default" placeholder="ALL" />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
};

export default ParentalControl;
