import { Button, Form, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { IMSNameParams } from '../../../../../../pages/dashboard';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { useParams } from 'react-router-dom';
import nestsService from '../../../../../../services/areas/content/nests/index';
import { useEffectOnce } from 'react-use';
import * as HttpStatusCodes  from 'http-status-codes';

type IUpdatePackageModalProps = {
  onSubmit: (values: any, newCat) => void;
  onCancel: () => void;
  initialData: any
};

const CategoryModal = (props: IUpdatePackageModalProps) => {
  const { onSubmit, onCancel, initialData } = props;
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [nests, setNests] = useState([] as any);
  const { t } = useTranslation();
  const [type, setType] = useState('');
  let [form] = useForm();

  const getNests = async () => {
    let response = await nestsService.List_Nests({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      if (response.data != undefined && response.data.data != undefined) {
        setNests(response.data.data);
      }
    }
  };

  useEffectOnce(() => {
    getNests();
  });
  useEffect(() => {
    if (initialData.selectedCategory.nested != undefined && nests.length > 0) {
      var index_ = 0;
      var saved = initialData.nested as any;
      if (saved != undefined && saved.length > 0) {
        nests.forEach((nest, index) => {
          nest.translations.forEach((translation) => {
            if (translation?.description != undefined) {
              if (translation.description == saved[0].description) {
                index_ = index;
              }
            }
          });
        });
        form.setFieldValue('nested', index_);
      }
    }
  }, [initialData, nests]);

  useEffect(() => {
    async function fetchData() {
      setType(initialData.selectedCategory.type);
    }
    fetchData();
  }, []);

  const onSubmitLocal = (values) => {
    var id = initialData._id;
    onSubmit(
      {
        ...initialData.selectedCategory,
        ...values,
        nested: values.nested != 999999 && nests[values.nested] != undefined ? nests[values.nested].translations : undefined,
        id,
      },
      false
    );
  };

  return (
    <div className="p-5" style={{ border: '1px solid #e2e2e2', borderTop: 0 }}>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <Form initialValues={initialData} form={form} onFinish={(values) => onSubmitLocal(values)} onFinishFailed={(errorInfo) => {}}>
            <div className="mt-2"></div>

            {type != 'Channels' && (
              <div className="row mt-5">
                <label className="col-md-4 col-form-label">{t('nested-category')}</label>
                <div className="col">
                  <Form.Item extra={t('when-you-select-a-nested-it-auto-enables-a-nested-category-structure-in-the-apps-by-combining-the-categories-under-one-button')} className="form-group pb-0" name="nested">
                    <Select className="default-select" defaultActiveFirstOption={true}>
                      <Select.Option value={999999} key={999999}>
                        {t('no-nest')}
                      </Select.Option>
                      {nests.map((el: any, index: any) => {
                        return (
                          <Select.Option value={index} key={index}>
                            {el.translations.map((t) => {
                              return (
                                <div>
                                  <b>{t.name}: </b>
                                  {t.description}
                                </div>
                              );
                            })}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            )}

            <div className="col-md-12 p-0">
            {initialData.newCat && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-5 width-180 height-48">
                  {t('add-category')}
                </Button>
              )}
                {!initialData.newCat && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-5 width-180 height-48">
                  {t('update-category')}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
