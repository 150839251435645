import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { createBrowserHistory } from 'history';
import * as HttpStatusCodes from 'http-status-codes';
import { API_ENDPOINTS } from '../services/_APIConstants';
import TokenStorageService from './TokenStorageService';
const history = createBrowserHistory({ forceRefresh: true });
const base_url = process.env.REACT_APP_BACKEND_API_BASE_URL;
const api_base_url = base_url + '/api/';
const axiosInstance = axios.create({
  baseURL: api_base_url,
});
const requestHandler = (request: any) => {
  if (!request.url?.includes('auth')) {
    let mware_auth_token = TokenStorageService.getAccessToken();
    if (mware_auth_token !== undefined && mware_auth_token !== '') {
      request.headers!.Authorization = 'Bearer ' + mware_auth_token;
    } else {
      history.push('/login');
    }
  }
  return request;
};

const responseHandler = (response: AxiosResponse) => {
  return response;
};

axiosInstance.interceptors.request.use(requestHandler, (error: AxiosError) => {
  Promise.reject(error);
});
axiosInstance.interceptors.response.use(responseHandler, (error: AxiosError) => {
  const refreshEndpoint = API_ENDPOINTS.V1.Auth.REFRESH_ENDPOINT;
  const loginEndpoint = API_ENDPOINTS.V1.Auth.LOGIN_ENDPOINT;
  const originalRequest: any = error.config;
  if (error.response && error.response.status !== HttpStatusCodes.UNAUTHORIZED) {
    if (error.response.data) return Promise.reject(error.response.data);
    return Promise.reject(error);
  }
  if (error.response && error.response.status === HttpStatusCodes.UNAUTHORIZED) {
    if (error.response.status === 401 && (originalRequest.url === refreshEndpoint || originalRequest.url === loginEndpoint)) {
      TokenStorageService.clearToken();
      if (error.response.data) return Promise.reject(error.response.data);
      history.push('/login');
      return Promise.reject(error);
    }

    if (error.response.status === HttpStatusCodes.UNAUTHORIZED && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = TokenStorageService.getRefreshToken();
      return axiosInstance
        .post(
          refreshEndpoint,
          {},
          {
            headers: {
              Authorization: `bearer ${refreshToken}`,
            },
          }
        )
        .then((res) => {
          if (res.status === HttpStatusCodes.OK) {
            let tokenData = {
              token: res.data.data.token,
              refresh_token: res.data.data.refresh_token,
            };
            TokenStorageService.setToken(tokenData as any);
            axios.defaults.headers.common['Authorization'] = 'bearer ' + TokenStorageService.getAccessToken();
            return axiosInstance(originalRequest);
          }
        })
        .catch((err) => {
          history.push('/login');
          TokenStorageService.clearToken();
        });
    }
    if (error.response.data) return Promise.reject(error.response.data);
    return Promise.reject(error);
  }
});

export default axiosInstance;
