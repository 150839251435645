import { Route, Switch, useRouteMatch } from 'react-router'
import Courses from './courses'
import Bundles from './bundles'
import CourseAdd from './courses/add'
import CourseDetails from './courses/details'
import BundleContent from './bundles/content'
import Lists from './lists'
import ListContent from './lists/content'
import Providers from './providers'
import Upload from './courses/upload'
import Curations from './curations';
import CurationContent from './curations/content';


const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}><CourseAdd /></Route>
      <Route path={`${path}/details/:_id`}><CourseDetails /></Route>
      <Route path={`${path}/upload`}>
        <Upload />
      </Route>
      <Route path={`${path}/bundles/content/:package/:_id/:category/:index`}><BundleContent /></Route>
      <Route path={`${path}/bundles`}><Bundles /></Route>
      <Route path={`${path}/curations/content/:package/:_id/:index`}><CurationContent /></Route>
      <Route path={`${path}/curations`}><Curations /></Route>
      <Route path={`${path}/lists/content/:package/:_id/:index`}><ListContent /></Route>
      <Route path={`${path}/lists`}><Lists /></Route>
      <Route path={`${path}/providers`}><Providers /></Route>
      <Route path={`${path}/`}><Courses /></Route>
    </Switch>
  )
}

export default Index
