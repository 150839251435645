import { Button, Form, Input, Select, Switch, Table, Tooltip, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { CustomNotification } from '../../../../../common/components/Notification';
import { IMSNameParams } from '../../../../dashboard';
import { useEffectOnce } from 'react-use';
import appService from '../../../../../services/areas/appbuilder/applications/application';
import { AppBuilderContext } from '../../../../../contexts/appbuilderContext';
import { STORAGE_ENDPOINTS } from '../../../../../services/storage_paths';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FontSelector from './FontSelector';
import { ColorPicker } from '../../../../../common/components/ColorPicker';
import EditAnimation from '../../../../../common/components/AppBuilder/Edit/Edit_Focus';
import EditSelected from '../../../../../common/components/AppBuilder/Edit/Edit_Selected';
import ImagePng from '../../../../../assets/images/appbuilder/ImageNew.png';
import IconPng from '../../../../../assets/images/appbuilder/Icon.png';
import ProgressPng from '../../../../../assets/images/appbuilder/Progress.png';
import MenuBuilder from './helpers/menu';

const AddApp = () => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [form] = useForm();
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const [isLoading, setIsLoading] = useState(false);

  const [app, setApp] = useState([] as any);
  const history = useHistory();

  const [visibleColorPickerBaseColor1, setVisibleColorPickerBaseColor1] = useState(false);
  const [visibleColorPickerBaseColor2, setVisibleColorPickerBaseColor2] = useState(false);
  const [baseColor1, setBaseColor1] = useState('#000000');

  const [EditShowAnimationModal, setEditShowAnimationModal] = useState(false);
  const [EditShowSelectedModal, setEditShowSelectedModal] = useState(false);

  const [editData, setEditData] = useState([] as any);
  const [editType, setEditType] = useState('');

  const [colorBar, setColorBar] = useState('#000000');
  const [colorGradient1, setColorGradient1] = useState('#000000');
  const [colorGradient2, setColorGradient2] = useState('#999999');

  const [visibleColorPickerGradient1, setVisibleColorPickerGradient1] = useState(false);
  const [visibleColorPickerGradient2, setVisibleColorPickerGradient2] = useState(false);
  const [visibleColorPickerBar, setVisibleColorPickerBar] = useState(false);

  const [theme, setTheme] = useState([] as any);
  const [colors, setColors] = useState([] as any);

  const [visibleColorPickerImageColor, setVisibleColorPickerImageColor] = useState(false);
  const [imageColor, setImageColor] = useState('#999999');

  const [progressColor, setProgressColor] = useState('#000000');
  const [baseColor, setBaseColor] = useState('#ffffff');

  const [showProgress, setShowProgress] = useState(true);
  const [showPlay, setShowPlay] = useState(false);
  const [showFavorite, setShowFavorite] = useState(true);
  const [showLive, setShowLive] = useState(true);

  const [visibleColorPickerIconColor, setVisibleColorPickerIconColor] = useState(false);
  const [iconColor, setIconColor] = useState('#ffffff');

  const [settingsFontColor, setSettingsFontColor] = useState('#ffffff');
  const [settingBackground1, setSettingBackground1] = useState('#000000');
  const [settingBackground2, setSettingBackground2] = useState('#999999');

  const [visibleFontColor, setVisibleFontColor] = useState(false);
  const [visibleBackground1, setVisibleBackground1] = useState(false);
  const [visibleBackground2, setVisibleBackground2] = useState(false);

  const [exitModal, setExitModal] = useState('');
  const [parentalModal, setParentalModal] = useState('');
  const [recordingModal, setRecordingModal] = useState('');
  const [reminderModal, setReminderModal] = useState('');
  const [sleeptimerModal, setSleeptimerModal] = useState('');
  const [payperviewModal, setPaypverviewModal] = useState('');
  const [subscriptionModal, setSubscriptionModal] = useState('');
  const [longpressModal, setLongpressModal] = useState('');

  const [searchKeyboard, setSearchKeyboard] = useState('');
  const [filterKeyboard, setFilterKeyboard] = useState('');

  const [keyboards, setKeyboards] = useState([] as any);
  const [modals, setModals] = useState([] as any);

  const [visibleTagBackgroundColor, setVisibleTagBackgroundColor] = useState(false);
  const [tagBackground, setTagBackground] = useState('#000000');
  const [visibleTagFontColor, setVisibleTagFontColor] = useState(false);
  const [tagFontColor, setTagFontColor] = useState('#ffffff');

  const [messageFontColor, setMessageFontColor] = useState('#ffffff');
  const [visibleMessageFontColor, setVisibleMessageFontColor] = useState(false);

  const [messageBackgroundColor, setMessageBackgroundColor] = useState('#000000');
  const [visibleMessageBackgroundColor, setVisibleMessageBackgroundColor] = useState(false);

  const [messageBorderColor, setMessageBorderColor] = useState('#000000');
  const [visibleMessageBorderColor, setVisibleMessageBorderColor] = useState(false);

  const [adsFontColor, setAdsFontColor] = useState('#ffffff');

  const [adsBackgroundColor, setAdsBackgroundColor] = useState('#000000');
  const [visibleAdsBackgroundColor, setVisibleAdsBackgroundColor] = useState(false);

  const [adsBorderColor, setAdsBorderColor] = useState('#000000');
  const [visibleAdsBorderColor, setVisibleAdsBorderColor] = useState(false);

  const [focus, setFocus] = useState([
    {
      element: 'Rails',
      type: 'border',
      focus: {
        duration: 150,
        borderColor: '#999999',
        borderWidth: 2,
        backgroundColor: '#999999',
      },
      blur: {
        borderWidth: 0,
        backgroundColor: 'transparent',
      },
    },
    {
      element: 'Grids',
      type: 'border',
      focus: {
        duration: 150,
        borderColor: '#999999',
        borderWidth: 2,
        backgroundColor: '#999999',
      },
      blur: {
        borderWidth: 0,
        backgroundColor: 'transparent',
      },
    },
    {
      element: 'Buttons',
      type: 'background',
      focus: {
        duration: 150,
        backgroundColor: '#000000',
      },
      blur: {
        backgroundColor: 'transparent',
      },
    },
    {
      element: 'Dropdowns',
      type: 'background',
      focus: {
        duration: 150,
        backgroundColor: '#000000',
      },
      blur: {
        backgroundColor: 'transparent',
      },
    },
    {
      element: 'Inputs',
      type: 'border',
      focus: {
        duration: 150,
        borderColor: '#999999',
        borderWidth: 2,
        backgroundColor: '#000000',
      },
      blur: {
        borderWidth: 0,
        backgroundColor: 'transparent',
      },
    },
    {
      element: 'Menus',
      type: 'background',
      focus: {
        duration: 150,
        backgroundColor: '#999999',
      },
      blur: {
        borderWidth: 0,
        backgroundColor: 'transparent',
      },
    },
    {
      element: 'Navigators',
      type: 'background',
      focus: {
        duration: 150,
        backgroundColor: '#999999',
      },
      blur: {
        borderWidth: 0,
        backgroundColor: 'transparent',
      },
    },
    {
      element: 'Players',
      type: 'background',
      focus: {
        duration: 150,
        backgroundColor: '#999999',
      },
      blur: {
        borderWidth: 0,
        backgroundColor: 'transparent',
      },
    },
  ] as any);

  const [selected, setSelected] = useState([
    {
      element: 'Menus',
      background_color: '#444444',
      text_color: '#999999',
      text_bold: true,
      border_bottom_width: 0,
      border_bottom_color: '#999999',
    },
    {
      element: 'Navigators',
      background_color: '#444444',
      text_color: '#999999',
      text_bold: true,
      border_bottom_width: 0,
      border_bottom_color: '#999999',
    },
  ] as any);

  const handleSubmit2 = async (values: any) => {
    setIsLoading(true);

    var selectedOut = {} as any;
    selected.forEach((element) => {
      switch (element.element) {
        case 'Menus':
          selectedOut['menus'] = element;
          break;
        case 'Navigators':
          selectedOut['navigators'] = element;
          break;
        default:
          break;
      }
    });

    var focussedOut = {} as any;
    focus.forEach((element) => {
      switch (element.element) {
        case 'Menus':
          focussedOut['menus'] = element;
          break;
        case 'Navigators':
          focussedOut['navigators'] = element;
          break;
        case 'Rails':
          focussedOut['rails'] = element;
          break;
        case 'Grids':
          focussedOut['grids'] = element;
          break;
        case 'Buttons':
          focussedOut['buttons'] = element;
          break;
        case 'Dropdowns':
          focussedOut['dropdowns'] = element;
          break;
        case 'Inputs':
          focussedOut['inputs'] = element;
          break;
        case 'Players':
          focussedOut['players'] = element;
          break;
        default:
          break;
      }
    });
    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', app.branding.packageid).APPBUILDER.APP,
      },
      ...app,
      _id: id,
      tp: 'sZP7IYe6T6Pg3Sbi0lhL06k1Tu5iFSaZIlf-ClA6IOkg0S5r0lho0lUeClb6FOPlUY3zWokgbgjNIOf9flPe3uRL3ofZFDC_3lB-3QatCZzrIQfZFSeLCKhk3lbo3oBcImfVfK4_bQgZCYxNWoryIQXzImf90Sbc0Sbi3lai0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lRc3L0Z0Lbk0SezFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikf6i6CDrebKjNIOfVfKXpIwPqdDxzU6fVfKINbK4zU6fVfKgqbZfVfGxNsK4pf6i6UwIqbZfVfGUgCKjLfgzVfG3gWKxydDkibK4LbogqW6f9UwPkImi6IK41Uw4ZIY06Tg-Uya',
      deleted: false,
      client: clientName,
      deployment: deployment?.name ?? '',
      theme: {
        ...app.theme,
        focus: focussedOut,
        selected: selectedOut,
        images: {
          background: imageColor,
          margin: values.images.margin ?? 0,
          radius: values.images.radius ?? 0,
        },
        progresses: {
          base: baseColor,
          progress: progressColor,
          show_progress: showProgress,
        },
        icons: {
          background: iconColor,
          show_play: showPlay,
          show_favorite: showFavorite,
          show_live: showLive,
        },
        app: {
          background_color1: colorGradient1,
          background_color2: colorGradient2,
          devicebar_color: colorBar,
        },
        elements: {
          background_color: '',
          border_radius: 0,
          border_width: 0,
          border_color: '',
        },
        settings: {
          background_color1: settingBackground1,
          background_color2: settingBackground2,
          font_color: settingsFontColor,
          border_radius: values.settings.border_radius ?? 0,
        },
        messages: {
          border_width: values.messages.border_width,
          border_radius: values.messages.border_radius,
          border_color: messageBorderColor,
          background_color: messageBackgroundColor,
          font_color: messageFontColor,
        },
        ads: {
          border_width: values.ads.border_width,
          border_radius: values.ads.border_radius,
          border_color: adsBorderColor,
          background_color: adsBackgroundColor,
          font_color: adsFontColor,
        },
        modals: {
          exit: exitModal,
          parental: parentalModal,
          recording: recordingModal,
          reminder: reminderModal,
          sleeptimer: sleeptimerModal,
          payperview: payperviewModal,
          subscription: subscriptionModal,
          longpress: longpressModal,
        },
        keyboard: {
          search: searchKeyboard,
          filter: filterKeyboard,
        },
        tags: {
          background: tagBackground,
          color: tagFontColor,
          radius: values.tags.radius ?? 0,
        },
        colors: colors,
        fonts: values.fonts,
        home_details_left_margin: values.home_details_left_margin,
        rails_top_margin: values.rails_top_margin,
        rails_bottom_margin: values.rails_bottom_margin,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('update-successfully'),
      });
    //  history.go(0);
    } else CustomNotification({ error: response.errors });
    setIsLoading(false);
  };

  const handleFormFill = async () => {
    setIsLoading(true);
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      if (resp != undefined) {
        form.setFieldsValue(resp.theme);
        setApp(resp);
        if (resp.theme != undefined) {
          setTheme(resp.theme.colors);
          setColors(resp.theme.colors);
          // } else {
          //   setColors(resp.settings.theme);
          //   setTheme(resp.settings.theme);
        }

        setModals(resp.components.modals);
        setKeyboards(resp.components.keyboards);

        if (resp.theme != undefined) {
          setColorGradient1(resp.theme.app.background_color1);
          setColorGradient2(resp.theme.app.background_color2);
          setColorBar(resp.theme.app.devicebar_color);

          setImageColor(resp.theme.images.background);

          setIconColor(resp.theme.icons.background);
          setShowFavorite(resp.theme.icons.show_favorite);
          setShowLive(resp.theme.icons.show_live);
          setShowPlay(resp.theme.icons.show_play);

          setBaseColor(resp.theme.progresses.base);
          setProgressColor(resp.theme.progresses.progress);
          setShowProgress(resp.theme.progresses.show_progress);

          if (resp.theme.focus != undefined) {
            var focusOut = [] as any;
            focusOut.push(resp.theme.focus.menus);
            focusOut.push(resp.theme.focus.rails);
            focusOut.push(resp.theme.focus.grids);
            focusOut.push(resp.theme.focus.buttons);
            focusOut.push(resp.theme.focus.inputs);
            focusOut.push(resp.theme.focus.navigators);
            focusOut.push(resp.theme.focus.players);
            setFocus(focusOut);
          }
          if (resp.theme.selected != undefined) {
            var selectedOut = [] as any;
            selectedOut.push(resp.theme.selected.menus);
            selectedOut.push(resp.theme.selected.navigators);
            setSelected(selectedOut);
          }
          if (resp.theme.modals != undefined) {
            setExitModal(resp.theme.modals.exit);
            setParentalModal(resp.theme.modals.parental);
            setRecordingModal(resp.theme.modals.recording);
            setReminderModal(resp.theme.modals.reminder);
            setSleeptimerModal(resp.theme.modals.sleeptimer);
            setPaypverviewModal(resp.theme.modals.payperview);
            setSubscriptionModal(resp.theme.modals.subscription);
            setLongpressModal(resp.theme.models?.longpress ?? '');
          }
          if (resp.theme.keyboard != undefined) {
            setSearchKeyboard(resp.theme.keyboard.search);
            setFilterKeyboard(resp.theme.keyboard.filter);
          }
          if (resp.theme.tags != undefined) {
            setTagBackground(resp.theme.tags.background);
            setTagFontColor(resp.theme.tags.color);
          }
          if (resp.theme.settings != undefined) {
            setSettingBackground1(resp.theme.settings.background_color1);
            setSettingBackground2(resp.theme.settings.background_color2);
            setSettingsFontColor(resp.theme.settings.font_color);
          }
          if (resp.theme.messages != undefined) {
            setMessageBackgroundColor(resp.theme.messages.background_color);
            setMessageBorderColor(resp.theme.messages.border_color);
            setMessageFontColor(resp.theme.messages.font_color);
          }
          if (resp.theme.ads != undefined) {
            setAdsBackgroundColor(resp.theme.ads.background_color);
            setAdsBorderColor(resp.theme.ads.border_color);
            setAdsFontColor(resp.theme.ads.font_color);
          }
        }
      }
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    (async () => await handleFormFill())();
  });

  const columnsFocus = [
    {
      title: t('element'),
      dataIndex: 'element',
    },
    {
      title: t('type'),
      dataIndex: 'type',
    },
    {
      title: t('width'),
      dataIndex: 'focus',
      width: 100,
      render: (row: any, record: any) => {
        return <div>{row?.borderWidth}</div>;
      },
    },
    {
      title: t('border'),
      dataIndex: 'focus',
      width: 100,
      render: (row: any, record: any) => {
        return (
          <div
            className="height-48 font-size-14 text-default"
            style={{
              backgroundColor: row?.borderColor,
              borderRadius: '5px',
              border: '#e9edf4 1px solid',
              width: 48,
            }}
          ></div>
        );
      },
    },
    {
      title: t('background'),
      dataIndex: 'focus',
      width: 100,
      render: (row: any, record: any) => {
        return (
          <div
            className="height-48 font-size-14 text-default"
            style={{
              backgroundColor: row?.backgroundColor,
              borderRadius: '5px',
              border: '#e9edf4 1px solid',
              width: 48,
            }}
          ></div>
        );
      },
    },
    {
      title: t('action'),
      width: 100,
      render: (row: any) => {
        return (
          <Tooltip placement="top" title={`Edit`}>
            <Button
              className="mx-2 cursor-pointer"
              onClick={() => {
                setEditData(row);
                setEditType(row.element);
                setEditShowAnimationModal(true);
              }}
              type="text"
            >
              <FontAwesomeIcon className="action-icon" icon={faEdit} />
              <div>{t('edit')}</div>
            </Button>
          </Tooltip>
        );
      },
    },
  ];
  const columnsSelected = [
    {
      title: t('element'),
      dataIndex: 'element',
    },

    {
      title: t('width'),
      dataIndex: 'border_bottom_width',
      width: 100,
    },
    {
      title: t('border'),
      dataIndex: 'border_bottom_color',
      width: 100,
      render: (row: any, record: any) => {
        return (
          <div
            className="height-48 font-size-14 text-default"
            style={{
              backgroundColor: row,
              borderRadius: '5px',
              border: '#e9edf4 1px solid',
              width: 48,
            }}
          ></div>
        );
      },
    },
    {
      title: t('text'),
      dataIndex: 'text_color',
      width: 100,
      render: (row: any, record: any) => {
        return (
          <div
            className="height-48 font-size-14 text-default"
            style={{
              backgroundColor: row,
              borderRadius: '5px',
              border: '#e9edf4 1px solid',
              width: 48,
            }}
          ></div>
        );
      },
    },
    {
      title: t('background'),
      dataIndex: 'background_color',
      width: 100,
      render: (row: any, record: any) => {
        return (
          <div
            className="height-48 font-size-14 text-default"
            style={{
              backgroundColor: row,
              borderRadius: '5px',
              border: '#e9edf4 1px solid',
              width: 48,
            }}
          ></div>
        );
      },
    },
    {
      title: t('action'),
      width: 100,
      render: (row: any) => {
        return (
          <Tooltip placement="top" title={`Edit`}>
            <Button
              className="mx-2 cursor-pointer"
              onClick={() => {
                setEditData(row);
                setEditType(row.element);
                setEditShowSelectedModal(true);
              }}
              type="text"
            >
              <FontAwesomeIcon className="action-icon" icon={faEdit} />
              <div>{t('edit')}</div>
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  const submitFocus = (data, element) => {
    var index = focus.findIndex((f) => f.element == element);
    var focusOld = focus;
    focusOld[index] = data;
    setFocus(focusOld);
    setEditShowAnimationModal(false);
  };

  const submitSelected = (data, element) => {
    var index = selected.findIndex((f) => f.element == element);
    var selectedOld = selected;
    selectedOld[index] = data;
    setSelected(selectedOld);
    setEditShowSelectedModal(false);
  };

  const resyncColors = async () => {
    var colors = [] as any;

    if (colors.find((c) => c.color == tagFontColor) == undefined) {
      colors.push({
        color: tagFontColor,
      });
    }
    if (colors.find((c) => c.color == tagBackground) == undefined) {
      colors.push({
        color: tagBackground,
      });
    }
    if (colors.find((c) => c.color == adsFontColor) == undefined) {
      colors.push({
        color: adsFontColor,
      });
    }
    if (colors.find((c) => c.color == adsBackgroundColor) == undefined) {
      colors.push({
        color: adsBackgroundColor,
      });
    }
    if (colors.find((c) => c.color == adsBorderColor) == undefined) {
      colors.push({
        color: adsBorderColor,
      });
    }
    if (colors.find((c) => c.color == messageFontColor) == undefined) {
      colors.push({
        color: messageFontColor,
      });
    }
    if (colors.find((c) => c.color == messageBackgroundColor) == undefined) {
      colors.push({
        color: messageBackgroundColor,
      });
    }
    if (colors.find((c) => c.color == messageBorderColor) == undefined) {
      colors.push({
        color: messageBorderColor,
      });
    }
    if (colors.find((c) => c.color == settingsFontColor) == undefined) {
      colors.push({
        color: settingsFontColor,
      });
    }
    if (colors.find((c) => c.color == settingBackground2) == undefined) {
      colors.push({
        color: settingBackground2,
      });
    }
    if (colors.find((c) => c.color == settingBackground1) == undefined) {
      colors.push({
        color: settingBackground1,
      });
    }
    if (colors.find((c) => c.color == colorBar) == undefined) {
      colors.push({
        color: colorBar,
      });
    }
    if (colors.find((c) => c.color == colorGradient2) == undefined) {
      colors.push({
        color: colorGradient2,
      });
    }
    if (colors.find((c) => c.color == colorGradient1) == undefined) {
      colors.push({
        color: colorGradient1,
      });
    }
    if (colors.find((c) => c.color == iconColor) == undefined) {
      colors.push({
        color: iconColor,
      });
    }
    if (colors.find((c) => c.color == baseColor) == undefined) {
      colors.push({
        color: baseColor,
      });
    }
    if (colors.find((c) => c.color == progressColor) == undefined) {
      colors.push({
        color: progressColor,
      });
    }
    if (colors.find((c) => c.color == imageColor) == undefined) {
      colors.push({
        color: imageColor,
      });
    }
    setColors(colors);
    setTheme(colors);
  };

  const addColor = () => {
    var colors_ = colors;
    if (colors_.find((c) => c.color == baseColor1) == undefined) {
      colors_.push({
        color: baseColor1,
      });
    }
    setColors(colors_);
    setTheme(colors_);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <div className="container-fluid">
            <div className="row-md-12">
              <div className="col-md-12 mb-2">
                <h2 className="page-title mt-4 mb-4">
                  {t('theme-parenthesis')}{app.branding?.name}/{app.branding?.packageid}{t('close-parenthesis')}
                </h2>
              </div>
              <MenuBuilder application={app} redirectUrl={redirectUrl} id={id}></MenuBuilder>
            </div>
            {EditShowAnimationModal && (
              <EditAnimation
                theme={theme}
                visible={EditShowAnimationModal}
                onSubmit={submitFocus}
                onCancel={() => {
                  setEditShowAnimationModal(false);
                }}
                data={editData}
                type={editType}
                index={0}
              />
            )}
            {EditShowSelectedModal && (
              <EditSelected
                theme={theme}
                visible={EditShowSelectedModal}
                onSubmit={submitSelected}
                onCancel={() => {
                  setEditShowSelectedModal(false);
                }}
                data={editData}
                type={editType}
                index={0}
              />
            )}
            {!isLoading && (
              <Form
                form={form}
                onFinish={async (values) => {
                  await handleSubmit2(values);
                }}
                onFinishFailed={(errorInfo) => {}}
              >
                <div className="card p-5">
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-3" style={{ marginTop: 20 }}>
                      {t('app-builder-colors')}
                    </h4>
                    <p>{t('app-builder-colors-can-be-used-accross-the-setup-of-your-app-they-will-show-up-in-the-color-pallete-when-choosing-a-color-for-a-certain-element-such-as-background-texts-etc')}</p>
                    <div className="row mt-5">
                      <div className="col-md-12">
                        <div className="row" style={{ margin: 0 }}>
                          {colors.map((color) => {
                            return (
                              <div style={{ marginRight: 10 }}>
                                <div
                                  className=" col-form-label "
                                  style={{
                                    backgroundColor: color.color,
                                    borderRadius: '5px',
                                    width: '75px',
                                    border: '#999999 2px dashed',
                                    height: '48px',
                                  }}
                                ></div>
                                <div className={'text-center'} style={{ fontSize: 9 }}>
                                  {color.color}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="row mt-3" style={{ margin: 0 }}>
                          <div
                            className=" col-form-label "
                            onClick={() => {
                              setVisibleColorPickerBaseColor1(!visibleColorPickerBaseColor1);
                            }}
                            style={{
                              backgroundColor: baseColor1,
                              borderRadius: '5px',
                              width: '75px',
                              border: '#999999 2px dashed',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleColorPickerBaseColor1}
                            color={baseColor1}
                            onChange={(color) => {
                              setBaseColor1(color);
                              setVisibleColorPickerBaseColor1(!visibleColorPickerBaseColor1);
                            }}
                            setVisible={setVisibleColorPickerBaseColor1}
                          />
                          {/* <Button onClick={addColor} className="ml-2 ant-btn btn btn-secondary rounded-pill height-48">
                            Add Color
                          </Button> */}
                          <Button onClick={resyncColors} className="ml-2 ant-btn btn btn-secondary rounded-pill height-48">
                            {t('resync-colors')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-3">{t('focus-settings')}</h4>
                    <p>{t('with-the-focus-settings-you-set-how-you-want-your-focussable-elements-and-objects-to-gain-focus')}</p>
                    <div className="row-md-12">
                      <Table pagination={false} rowClassName="editable-row" loading={isLoading} columns={columnsFocus} dataSource={focus} key={JSON.stringify(focus)} />
                    </div>
                  </div>
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-3">{t('selected-settings')}</h4>
                    <p>{t('with-the-selected-settings-you-set-how-your-want-your-menu-and-navigation-to-show-it-has-been-selected')}</p>
                    <div className="row-md-12">
                      <Table pagination={false} rowClassName="editable-row" loading={isLoading} columns={columnsSelected} dataSource={selected} key={JSON.stringify(selected)} />
                    </div>
                  </div>
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-3" style={{ marginTop: 20 }}>
                      {t('app-font-selection')}
                    </h4>
                    <FontSelector />
                  </div>
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      {t('colors-app')}
                    </h4>
                    <p>{t('set-background-and-devicbar-colors-in-the-app')}</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('background-color-one')}</label>
                          <div
                            className="col-form-label "
                            onClick={() => {
                              setVisibleColorPickerGradient1(!visibleColorPickerGradient1);
                            }}
                            style={{
                              backgroundColor: colorGradient1,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleColorPickerGradient1}
                            color={colorGradient1}
                            onChange={(color) => {
                              setColorGradient1(color);
                              setVisibleColorPickerGradient1(!visibleColorPickerGradient1);
                            }}
                            setVisible={setVisibleColorPickerGradient1}
                          />
                        </div>
                        <div className="row mt-4">
                          <label className="col-md-6 col-form-label">{t('background-color-two')}</label>

                          <div
                            className="col-form-label "
                            onClick={() => {
                              setVisibleColorPickerGradient2(!visibleColorPickerGradient2);
                            }}
                            style={{
                              backgroundColor: colorGradient2,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleColorPickerGradient2}
                            color={colorGradient2}
                            onChange={(color) => {
                              setColorGradient2(color);
                              setVisibleColorPickerGradient2(!visibleColorPickerGradient2);
                            }}
                            setVisible={setVisibleColorPickerGradient2}
                          />
                        </div>
                        <h4 className="text-primary mb-2" style={{ marginTop: 60 }}>
                          {t('system-bar-phone-tablets')}
                        </h4>
                        <div className="row mt-4">
                          <label className="col-md-6 col-form-label">{t('device-bar-color')}</label>
                          <div
                            className=" col-form-label  "
                            onClick={() => {
                              setVisibleColorPickerBar(!visibleColorPickerBar);
                            }}
                            style={{
                              backgroundColor: colorBar,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleColorPickerBar}
                            color={colorBar}
                            onChange={(color) => {
                              setColorBar(color);
                              setVisibleColorPickerBar(!visibleColorPickerBar);
                            }}
                            setVisible={setVisibleColorPickerBar}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="text-center" style={{ background: colorBar, width: 400, color: 'red', border: '1px solid #000', borderBottomColor: 'transparent' }}>
                          {t('systembar-color')}
                        </div>
                        <div
                          className="text-center"
                          style={{
                            color: 'yellow',
                            paddingTop: 120,
                            border: '1px solid #000',
                            height: 270,
                            width: 400,
                            background: 'linear-gradient(180deg, ' + colorGradient1 + ' 0%, ' + colorGradient2 + ' 100%)',
                          }}
                        >
                          {t('app-background-gradient-color')}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-3" style={{ marginTop: 20 }}>
                      {t('images')}
                    </h4>
                    <p>{t('style-the-images-used-in-the-app')}</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('background')}</label>
                          <div
                            className="col-form-label "
                            onClick={() => {
                              setVisibleColorPickerImageColor(!visibleColorPickerImageColor);
                            }}
                            style={{
                              backgroundColor: imageColor,
                              borderRadius: '5px',
                              margin: '10px',
                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleColorPickerImageColor}
                            color={imageColor}
                            onChange={(color) => {
                              setImageColor(color);
                              setVisibleColorPickerImageColor(!visibleColorPickerImageColor);
                            }}
                            setVisible={setVisibleColorPickerBaseColor1}
                          />
                        </div>
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('margin')}</label>
                          <Form.Item className="col-md-1 col-form-label " name={['images', 'margin']} style={{ paddingLeft: 10 }}>
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                        </div>
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('radius')}</label>
                          <Form.Item className="col-md-1 col-form-label " name={['images', 'radius']} style={{ paddingLeft: 10 }}>
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 h-100">
                          <img src={ImagePng} alt={''}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-3" style={{ marginTop: 20 }}>
                      {t('progress-bars')}
                    </h4>
                    <p>{t('style-the-progress-bars-used-in-the-app')}</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('base-color')}</label>
                          <div
                            className=" col-form-label "
                            onClick={() => {
                              setVisibleColorPickerBaseColor1(!visibleColorPickerBaseColor1);
                            }}
                            style={{
                              backgroundColor: baseColor,
                              borderRadius: '5px',
                              margin: '10px',
                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleColorPickerBaseColor1}
                            color={baseColor}
                            onChange={(color) => {
                              setBaseColor(color);
                              setVisibleColorPickerBaseColor1(!visibleColorPickerBaseColor1);
                            }}
                            setVisible={setVisibleColorPickerBaseColor1}
                          />
                        </div>
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('progress-color')}</label>

                          <div
                            className=" col-form-label "
                            onClick={() => {
                              setVisibleColorPickerBaseColor2(!visibleColorPickerBaseColor2);
                            }}
                            style={{
                              backgroundColor: progressColor,
                              borderRadius: '5px',
                              margin: '10px',
                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleColorPickerBaseColor2}
                            color={progressColor}
                            onChange={(color) => {
                              setProgressColor(color);
                              setVisibleColorPickerBaseColor2(!visibleColorPickerBaseColor2);
                            }}
                            setVisible={setVisibleColorPickerBaseColor2}
                          />
                        </div>
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('show-progress-bars')}</label>
                          <Form.Item className="col-md-5" name={['progresses', 'show_progress']} valuePropName="checked">
                            <Switch className="ch-switch" checked={showProgress} defaultChecked={false} onChange={() => setShowProgress(!showProgress)} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {' '}
                        <div className="w-100 h-100">
                          <img src={ProgressPng} alt={''}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-3" style={{ marginTop: 20 }}>
                      {t('icons')}
                    </h4>
                    <p>{t('style-the-icons-used-in-the-app')}</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('icon-color')}</label>
                          <div
                            className=" col-form-label "
                            onClick={() => {
                              setVisibleColorPickerIconColor(!visibleColorPickerIconColor);
                            }}
                            style={{
                              backgroundColor: iconColor,
                              borderRadius: '5px',
                              margin: '10px',
                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleColorPickerIconColor}
                            color={iconColor}
                            onChange={(color) => {
                              setIconColor(color);
                              setVisibleColorPickerIconColor(!visibleColorPickerIconColor);
                            }}
                            setVisible={setVisibleColorPickerBaseColor1}
                          />
                        </div>
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('show-play')}</label>

                          <Form.Item className="col-md-5" name={['icons', 'show_play']} valuePropName="checked">
                            <Switch className="ch-switch" checked={showPlay} defaultChecked={false} onChange={() => setShowPlay(!showPlay)} />
                          </Form.Item>
                        </div>
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('show-favorite')}</label>

                          <Form.Item className="col-md-5" name={['icons', 'show_favorite']} valuePropName="checked">
                            <Switch className="ch-switch" checked={showFavorite} defaultChecked={false} onChange={() => setShowFavorite(!showFavorite)} />
                          </Form.Item>
                        </div>
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('show-live')}</label>

                          <Form.Item className="col-md-5" name={['icons', 'show_live']} valuePropName="checked">
                            <Switch className="ch-switch" checked={showLive} defaultChecked={false} onChange={() => setShowLive(!showLive)} />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 h-100">
                          <img src={IconPng} alt={''}></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-3" style={{ marginTop: 20 }}>
                      Elements
                    </h4>
                    <p>Elements </p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <label className="col-md-6 col-form-label">Element Color</label>
                          <div
                            className=" col-form-label "
                            onClick={() => {
                              setVisibleColorPickerElementColor(!visibleColorPickerElementColor);
                            }}
                            style={{
                              backgroundColor: elementColor,
                              borderRadius: '5px',
                              margin: '10px',
                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleColorPickerElementColor}
                            color={elementColor}
                            onChange={(color) => {
                              setElementColor(color);
                              setVisibleColorPickerElementColor(!visibleColorPickerElementColor);
                            }}
                            setVisible={setVisibleColorPickerElementColor}
                          />
                        </div>
                        <div className="row">
                          <label className="col-md-6 col-form-label">Border Color</label>
                          <div
                            className=" col-form-label "
                            onClick={() => {
                              setVisibleColorPickerElementBorderColor(!visibleColorPickerElementBorderColor);
                            }}
                            style={{
                              backgroundColor: elementBorderColor,
                              borderRadius: '5px',
                              margin: '10px',
                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleColorPickerElementBorderColor}
                            color={elementBorderColor}
                            onChange={(color) => {
                              setElementBorderColor(color);
                              setVisibleColorPickerElementBorderColor(!visibleColorPickerElementBorderColor);
                            }}
                            setVisible={setVisibleColorPickerElementBorderColor}
                          />
                        </div>
                        <div className="row">
                          <label className="col-md-6 col-form-label">Border Width</label>
                          <Form.Item className="col-md-1 col-form-label " name={['elements', 'border_width']} style={{ paddingLeft: 10 }}>
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                        </div>
                        <div className="row">
                          <label className="col-md-6 col-form-label">Border Radius</label>
                          <Form.Item className="col-md-1 col-form-label " name={['elements', 'border_radius']} style={{ paddingLeft: 10 }}>
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 h-100">
                          <img src={ElementPng} alt={''}></img>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      {t('keyboards')}
                    </h4>
                    <p>{t('select-the-keyboard-to-be-used-per-type')}</p>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('search-keyboard')}</label>
                      <div className="col">
                        <Form.Item className="col-md-6" name={['keyboard', 'search']}>
                          <Select onChange={setSearchKeyboard} className="default-select" placeholder={t('select-a-keyboard-component')}>
                            {keyboards.map((keyboard) => {
                              return (
                                <Select.Option value={keyboard._id} key={keyboard._id}>
                                  {keyboard.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('filter-keyboard')}</label>
                      <div className="col">
                        <Form.Item className="col-md-6" name={['keyboard', 'filter']}>
                          <Select onChange={setFilterKeyboard} className="default-select" placeholder={t('select-a-keyboard-component')}>
                            {keyboards.map((keyboard) => {
                              return (
                                <Select.Option value={keyboard._id} key={keyboard._id}>
                                  {keyboard.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      {t('modals')}
                    </h4>
                    <p>{t('select-the-modal-to-be-used-per-type')}</p>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('exit-app-modal')}</label>
                      <div className="col">
                        <Form.Item className="col-md-6" name={['modals', 'exit']}>
                          <Select onChange={setExitModal} className="default-select" placeholder={t('select-a-modal-component')}>
                            {modals.map((modal) => {
                              return (
                                <Select.Option value={modal._id} key={modal._id}>
                                  {modal.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('parental-modal')}</label>
                      <div className="col">
                        <Form.Item className="col-md-6" name={['modals', 'parental']}>
                          <Select onChange={setParentalModal} className="default-select" placeholder={t('select-a-modal-component')}>
                            {modals.map((modal) => {
                              return (
                                <Select.Option value={modal._id} key={modal._id}>
                                  {modal.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('recording-modal')}</label>
                      <div className="col">
                        <Form.Item className="col-md-6" name={['modals', 'recording']}>
                          <Select onChange={setRecordingModal} className="default-select" placeholder={t('select-a-modal-component')}>
                            {modals.map((modal) => {
                              return (
                                <Select.Option value={modal._id} key={modal._id}>
                                  {modal.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('reminder-modal')}</label>
                      <div className="col">
                        <Form.Item className="col-md-6" name={['modals', 'reminder']}>
                          <Select onChange={setReminderModal} className="default-select" placeholder={t('select-a-modal-component')}>
                            {modals.map((modal) => {
                              return (
                                <Select.Option value={modal._id} key={modal._id}>
                                  {modal.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('sleeptimer-modal')}</label>
                      <div className="col">
                        <Form.Item className="col-md-6" name={['modals', 'sleeptimer']}>
                          <Select onChange={setSleeptimerModal} className="default-select" placeholder={t('select-a-modal-component')}>
                            {modals.map((modal) => {
                              return (
                                <Select.Option value={modal._id} key={modal._id}>
                                  {modal.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('payperview-model')}</label>
                      <div className="col">
                        <Form.Item className="col-md-6" name={['modals', 'payperview']}>
                          <Select onChange={setPaypverviewModal} className="default-select" placeholder={t('select-a-modal-component')}>
                            {modals.map((modal) => {
                              return (
                                <Select.Option value={modal._id} key={modal._id}>
                                  {modal.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('subscription-modal')}</label>
                      <div className="col">
                        <Form.Item className="col-md-6" name={['modals', 'subscription']}>
                          <Select onChange={setSubscriptionModal} className="default-select" placeholder={t('select-a-modal-component')}>
                            {modals.map((modal) => {
                              return (
                                <Select.Option value={modal._id} key={modal._id}>
                                  {modal.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-md-3 col-form-label">{t('longpress-modal')}</label>
                      <div className="col">
                        <Form.Item className="col-md-6" name={['modals', 'longpress']}>
                          <Select onChange={setLongpressModal} className="default-select" placeholder={t('select-a-modal-component')}>
                            {modals.map((modal) => {
                              return (
                                <Select.Option value={modal._id} key={modal._id}>
                                  {modal.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      {t('account-profile-pages')}
                    </h4>
                    <p>{t('style-the-account-and-profile-pages-in-the-app')}</p>
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('header-color')}</label>
                          <div
                            className="col-form-label "
                            onClick={() => {
                              setVisibleBackground1(!visibleBackground1);
                            }}
                            style={{
                              backgroundColor: settingBackground1,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleBackground1}
                            color={settingBackground1}
                            onChange={(color) => {
                              setSettingBackground1(color);
                              setVisibleBackground1(!visibleBackground1);
                            }}
                            setVisible={setVisibleBackground1}
                          />
                        </div>
                        <div className="row mt-4">
                          <label className="col-md-6 col-form-label">{t('main-color')}</label>

                          <div
                            className="col-form-label "
                            onClick={() => {
                              setVisibleBackground2(!visibleBackground2);
                            }}
                            style={{
                              backgroundColor: settingBackground2,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleBackground2}
                            color={settingBackground2}
                            onChange={(color) => {
                              setSettingBackground2(color);
                              setVisibleBackground2(!visibleBackground2);
                            }}
                            setVisible={setVisibleBackground2}
                          />
                        </div>
                        <div className="row mt-4">
                          <label className="col-md-6 col-form-label">{t('font-color')}</label>
                          <div
                            className=" col-form-label  "
                            onClick={() => {
                              setVisibleFontColor(!visibleFontColor);
                            }}
                            style={{
                              backgroundColor: settingsFontColor,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleFontColor}
                            color={settingsFontColor}
                            onChange={(color) => {
                              setSettingsFontColor(color);
                              setVisibleFontColor(!visibleFontColor);
                            }}
                            setVisible={setVisibleFontColor}
                          />
                        </div>
                        <div className="row mt-2">
                          <label className="col-md-6 col-form-label mt-2">{t('radius')}</label>
                          <Form.Item className="col-md-1 col-form-label " name={['settings', 'border_radius']} style={{ paddingLeft: 0 }}>
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>

                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      {t('tags')}
                    </h4>
                    <p>{t('styling-options-for-the-on-screen-tags-that-show-actors-teachers-writers-etc')}</p>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('background-color')}</label>
                          <div
                            className="col-form-label "
                            onClick={() => {
                              setVisibleTagBackgroundColor(!visibleTagBackgroundColor);
                            }}
                            style={{
                              backgroundColor: tagBackground,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleTagBackgroundColor}
                            color={tagBackground}
                            onChange={(color) => {
                              setTagBackground(color);
                              setVisibleTagBackgroundColor(!visibleTagBackgroundColor);
                            }}
                            setVisible={setVisibleTagBackgroundColor}
                          />
                        </div>
                        <div className="row mt-4">
                          <label className="col-md-6 col-form-label">{t('text-color')}</label>

                          <div
                            className="col-form-label"
                            onClick={() => {
                              setVisibleTagFontColor(!visibleTagFontColor);
                            }}
                            style={{
                              backgroundColor: tagFontColor,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleTagFontColor}
                            color={tagFontColor}
                            onChange={(color) => {
                              setTagFontColor(color);
                              setVisibleTagFontColor(!visibleTagFontColor);
                            }}
                            setVisible={setVisibleTagFontColor}
                          />
                        </div>
                        <div className="row mt-2">
                          <label className="col-md-6 col-form-label mt-2">{t('radius')}</label>
                          <Form.Item className="col-md-1 col-form-label " name={['tags', 'radius']} style={{ paddingLeft: 0 }}>
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>

                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      {t('chart-design-page-type-messages')}
                    </h4>
                    <p>{t('styling-options-for-the-on-screen-messages')}</p>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('background-color')}</label>
                          <div
                            className="col-form-label "
                            onClick={() => {
                              setVisibleMessageBackgroundColor(!visibleMessageBackgroundColor);
                            }}
                            style={{
                              backgroundColor: messageBackgroundColor,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleMessageBackgroundColor}
                            color={messageBackgroundColor}
                            onChange={(color) => {
                              setMessageBackgroundColor(color);
                              setVisibleMessageBackgroundColor(!visibleMessageBackgroundColor);
                            }}
                            setVisible={setVisibleMessageBackgroundColor}
                          />
                        </div>
                        <div className="row mt-4">
                          <label className="col-md-6 col-form-label">{t('text-color')}</label>

                          <div
                            className="col-form-label"
                            onClick={() => {
                              setVisibleMessageFontColor(!visibleMessageFontColor);
                            }}
                            style={{
                              backgroundColor: messageFontColor,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleMessageFontColor}
                            color={messageFontColor}
                            onChange={(color) => {
                              setMessageFontColor(color);
                              setVisibleMessageFontColor(!visibleMessageFontColor);
                            }}
                            setVisible={setVisibleMessageFontColor}
                          />
                        </div>
                        <div className="row mt-4">
                          <label className="col-md-6 col-form-label">{t('border-color')}</label>

                          <div
                            className="col-form-label"
                            onClick={() => {
                              setVisibleMessageBorderColor(!visibleMessageBorderColor);
                            }}
                            style={{
                              backgroundColor: messageBorderColor,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleMessageBorderColor}
                            color={messageBorderColor}
                            onChange={(color) => {
                              setMessageBorderColor(color);
                              setVisibleMessageBorderColor(!visibleMessageBorderColor);
                            }}
                            setVisible={setVisibleMessageBorderColor}
                          />
                        </div>
                        <div className="row mt-2">
                          <label className="col-md-6 col-form-label mt-2">{t('border')}</label>
                          <Form.Item className="col-md-1 col-form-label " name={['messages', 'border_width']} style={{ paddingLeft: 0 }}>
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                        </div>
                        <div className="row mt-2">
                          <label className="col-md-6 col-form-label mt-2">{t('radius')}</label>
                          <Form.Item className="col-md-1 col-form-label " name={['messages', 'border_radius']} style={{ paddingLeft: 0 }}>
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      {t('ads-and-promotions')}
                    </h4>
                    <p>{t('styling-options-for-ads-and-promotions')}</p>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="row">
                          <label className="col-md-6 col-form-label">{t('background-color')}</label>
                          <div
                            className="col-form-label "
                            onClick={() => {
                              setVisibleAdsBackgroundColor(!visibleAdsBackgroundColor);
                            }}
                            style={{
                              backgroundColor: adsBackgroundColor,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleAdsBackgroundColor}
                            color={adsBackgroundColor}
                            onChange={(color) => {
                              setAdsBackgroundColor(color);
                              setVisibleAdsBackgroundColor(!visibleAdsBackgroundColor);
                            }}
                            setVisible={setVisibleAdsBackgroundColor}
                          />
                        </div>

                        <div className="row mt-4">
                          <label className="col-md-6 col-form-label">{t('border-color')}</label>

                          <div
                            className="col-form-label"
                            onClick={() => {
                              setVisibleAdsBorderColor(!visibleAdsBorderColor);
                            }}
                            style={{
                              backgroundColor: adsBorderColor,
                              borderRadius: '5px',

                              width: '60px',
                              border: '#e9edf4 1px solid',
                              height: '48px',
                            }}
                          ></div>
                          <ColorPicker
                            theme={theme}
                            visible={visibleAdsBorderColor}
                            color={adsBorderColor}
                            onChange={(color) => {
                              setAdsBorderColor(color);
                              setVisibleAdsBorderColor(!visibleAdsBorderColor);
                            }}
                            setVisible={setVisibleAdsBorderColor}
                          />
                        </div>
                        <div className="row mt-2">
                          <label className="col-md-6 col-form-label mt-2">{t('border')}</label>
                          <Form.Item className="col-md-1 col-form-label " name={['ads', 'border_width']} style={{ paddingLeft: 0 }}>
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                        </div>
                        <div className="row mt-2">
                          <label className="col-md-6 col-form-label mt-2">{t('radius')}</label>
                          <Form.Item className="col-md-1 col-form-label " name={['ads', 'border_radius']} style={{ paddingLeft: 0 }}>
                            <Input defaultValue={0} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="0" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      {t('home-and-details-placement')}
                    </h4>
                    <p>{t('adjust-the-margins-on-both-the-home-page-and-all-detail-pages')}</p>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="row mt-2">
                          <label className="col-md-6 col-form-label mt-2">{t('margin-left')}</label>
                          <Form.Item className="col-md-1 col-form-label " name={['home_details_left_margin']} style={{ paddingLeft: 0 }}>
                            <Input defaultValue={50} min={50} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="10" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>
                  <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      {t('rails-spacing')}
                    </h4>
                    <p>{t('modify-the-margins-of-every-rails-element-that-appears-in-the-app')}</p>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="row mt-2">
                          <label className="col-md-6 col-form-label mt-2">{t('margin-top')}</label>
                          <Form.Item className="col-md-1 col-form-label " name={['rails_top_margin']} style={{ paddingLeft: 0 }}>
                            <Input defaultValue={10} min={10} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="10" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <div className="row mt-2">
                          <label className="col-md-6 col-form-label mt-2">{t('margin-bottom')}</label>
                          <Form.Item className="col-md-1 col-form-label " name={['rails_bottom_margin']} style={{ paddingLeft: 0 }}>
                            <Input defaultValue={10} min={10} type={'number'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="10" />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                  </div>
                </div>
                <div className="text-right">
                  <Button loading={isLoading} htmlType="submit" className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                    {t('update-theme')}
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddApp;
