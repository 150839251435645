/* eslint-disable eqeqeq */
import { Button, DatePicker, Form, Input, notification, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import * as xlsx from 'xlsx';
import { IMSNameParams } from '../../../../dashboard';

import cmsChannels from '../../../../../services/areas/content/channels/channels';
import cmsGames from '../../../../../services/areas/content/games/games';
import cmsCams from '../../../../../services/areas/content/cams/cams';
import cmsMovies from '../../../../../services/areas/content/movies/movies';
import cmsShorts from '../../../../../services/areas/content/shorts/shorts';
import cmsSeries from '../../../../../services/areas/content/series/series';
import cmsCourses from '../../../../../services/areas/content/courses/courses';
import cmsRadio from '../../../../../services/areas/content/radio/radio';
import cmsAlbums from '../../../../../services/areas/content/albums/albums';
import cmsApps from '../../../../../services/areas/content/apps/apps';
import cmsPodcasts from '../../../../../services/areas/content/podcasts/podcasts';

import cmsAlbumsBundles from '../../../../../services/areas/content/albums/bundles';
import cmsAppsBundles from '../../../../../services/areas/content/apps/bundles';
import cmsPodcastsBundles from '../../../../../services/areas/content/podcasts/bundles';
import cmsMoviesBundles from '../../../../../services/areas/content/movies/bundles';
import cmsShortsBundles from '../../../../../services/areas/content/shorts/bundles';
import cmsGamesBundles from '../../../../../services/areas/content/games/bundles';
import cmsSeriesBundles from '../../../../../services/areas/content/series/bundles';
import cmsChannelsBundles from '../../../../../services/areas/content/channels/bundles';
import cmsRadiosBundles from '../../../../../services/areas/content/radio/bundles';
import cmsCamsBundles from '../../../../../services/areas/content/cams/bundles';
import cmsCoursesBundles from '../../../../../services/areas/content/courses/bundles';

import { ContentContext } from '../../../../../contexts/contentContext';
import { STORAGE_ENDPOINTS } from '../../../../../services/storage_paths';
import { searchIMDBApi } from '../apis/scriptx';
import { Spin } from '../../../../../common/components/spinner';
import dayjs from 'dayjs';
import { MW_BASE_DB_CURRENCY } from '@mwaretv/database/build/backend/models/base/currency';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import providerService from '../../../../../services/areas/content/provider/provider/index';
import { useEffectOnce } from 'react-use';
import * as XLSX from 'xlsx';
import { getActorsContent, getCurrenciesContent, getDescriptionsContent, getTagsContent, getTeachersContent } from './helper';
import { useTranslation } from 'react-i18next';

//exporter // streams // epg info etcv
const Importer = () => {
  const [form] = useForm();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const { t } = useTranslation();

  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const currenciesDeployment = (deployment?.currencies as unknown as MW_BASE_DB_CURRENCY[]) || [];

  let providers = [] as any;

  const [isAdding, setIsAdding] = useState(false);
  const history = useHistory();
  const { url } = useRouteMatch();

  const [columns, setColumns] = useState([] as any);
  const [columns1, setColumns1] = useState([] as any);
  const [columns2, setColumns2] = useState([] as any);

  const [excel, setExcel] = useState([] as any);
  const [excel1, setExcel1] = useState([] as any);
  const [excel2, setExcel2] = useState([] as any);

  const [extraSheets, setExtraSheets] = useState([] as any);
  const [selectedContent, setSelectedContent] = useState('');

  const [options, setOptions] = useState([] as any);
  const [keys, setKeys] = useState([] as any);
  const [options1, setOptions1] = useState([] as any);
  const [keys1, setKeys1] = useState([] as any);
  const [options2, setOptions2] = useState([] as any);
  const [keys2, setKeys2] = useState([] as any);

  const [remove, setRemove] = useState(false);
  const [overwrite, setOverwrite] = useState(false);
  const [append, setAppend] = useState(true);
  const [update, setUpdate] = useState(false);
  const [extend, setExtend] = useState(false);

  const [fillGapsWithIMDB, setFillGapsWithIMDB] = useState(false);
  const [autoFill, setAutoFill] = useState(false);
  const [aitranslation, setAiTranslation] = useState(false);

  const [tab1, setTab1] = useState('');
  const [tab2, setTab2] = useState('');
  const [tab3, setTab3] = useState('');

  const [ads, changeAds] = useState(true);
  const [placement, changePlacement] = useState(true);
  const [epg, changeEpg] = useState(true);
  const [security, changeSecurity] = useState(true);
  const [moviestream, changeMovieStream] = useState(true);
  const [provider, changeProvider] = useState(true);
  const [rtvstream, changeRtvStream] = useState(true);
  const [singlestream, changeSingleStream] = useState(true);
  const [rtvimages, changeRtvImages] = useState(true);
  const [backgroundimages, changeBackdropImages] = useState(true);
  const [iconimages, changeIconImages] = useState(true);
  const [storeimages, changeStoreImages] = useState(true);
  const [episodeimages, changeEpisodeImages] = useState(true);
  const [parental, changeParental] = useState(true);
  const [translations, changeTranslations] = useState(true);
  const [translationsEpisodes, changeTranslationsEpisodes] = useState(true);
  const [itv, changeItv] = useState(true);

  const [general1, changeGeneral1] = useState(true);
  const [general2, changeGeneral2] = useState(true);
  const [general3, changeGeneral3] = useState(true);

  const [channelKeys] = useState([{ name: 'Mapping' }, { name: 'Name' }, { name: 'Number' }, { name: 'Notes' }, { name: 'Provider' }]);
  const [channelOptions] = useState([{ name: 'Monetizing' }, { name: 'Placement' }, { name: 'Security' }, { name: 'RTV Streams' }, { name: 'Guide' }, { name: 'InteractiveTV' }, { name: 'RTV Images' }, { name: 'Parental Control' }]);
  const [camKeys] = useState([{ name: 'Mapping' }, { name: 'Name' }, { name: 'Number' }, { name: 'Notes' }, { name: 'Provider' }]);
  const [camOptions] = useState([{ name: 'Monetizing' }, { name: 'Placement' }, { name: 'Security' }, { name: 'RTV Streams' }, { name: 'InteractiveTV' }, { name: 'RTV Images' }, { name: 'Parental Control' }]);
  const [radioKeys] = useState([{ name: 'Mapping' }, { name: 'Name' }, { name: 'Number' }, { name: 'Notes' }, { name: 'Provider' }]);
  const [radioOptions] = useState([{ name: 'Placement' }, { name: 'Security' }, { name: 'RTV Streams' }, { name: 'Guide' }, { name: 'Radio Images' }]);

  const [albumKeys] = useState([{ name: 'Mapping' }, { name: 'Name' }, { name: 'Artist' }, { name: 'Provider' }]);
  const [albumOptions] = useState([{ name: 'Security' }, { name: 'Icon Images' }, { name: 'Monetizing' }, { name: 'Placement' }, { name: 'Descriptions' }]);
  const [songKeys] = useState([{ name: 'Album' }, { name: 'Name' }, { name: 'Producer' }, { name: 'Writer' }, { name: 'Duration' }]);
  const [songOptions] = useState([{ name: 'Song Stream Url' }]);

  const [appKeys] = useState([{ name: 'Mapping' }, { name: 'Name' }, { name: 'Deeplink' }, { name: 'Package ID' }]);
  const [appOptions] = useState([{ name: 'Icon Images' }, { name: 'Monetizing' }, { name: 'Placement' }, { name: 'Descriptions' }]);
 

  const [podcastKeys] = useState([{ name: 'Mapping' }, { name: 'Name' }, { name: 'Creator' }, { name: 'Tagline' }, { name: 'Release Period' }, { name: 'Average Length' }, { name: 'Language' }, { name: 'Provider' }]);
  const [podcastOptions] = useState([{ name: 'Security' }, { name: 'Icon Images' }, { name: 'Monetizing' }, { name: 'Placement' }, { name: 'Descriptions' }]);
  const [podsKeys] = useState([{ name: 'Podcast' }, { name: 'Name' }, { name: 'Producer' }, { name: 'Writer' }, { name: 'Duration_Seconds' }]);
  const [podsOptions] = useState([{ name: 'Podcast Stream Url' }]);

  const [seriesKeys] = useState([{ name: 'IMDB' }, { name: 'TMDB' }, { name: 'Name' }, { name: 'Provider' }, { name: 'Trailer' }]);
  const [seriesOptions] = useState([{ name: 'Series Image' }, { name: 'Monetizing' }, { name: 'Security' }, { name: 'Parental Control' }, { name: 'Placement' }]);
  const [seasonsKeys] = useState([{ name: 'Name' }, { name: 'Number' }, { name: 'Rating' }, { name: 'Released' }, { name: 'Language' }, { name: 'Studio' }, { name: 'Actors' }]);
  const [seasonOptions] = useState([{ name: 'Poster & Backdrop Images' }, { name: 'Descriptions' }]);
  const [episodeKeys] = useState([{ name: 'Name' }, { name: 'Number' }, { name: 'Duration_Seconds' }]);
  const [episodeOptions] = useState([{ name: 'Episode Image' }, { name: 'Episode Stream Url' }, { name: 'Description Episodes' }]);

  const [courseKeys] = useState([{ name: 'Mapping' }, { name: 'Name' }, { name: 'Released' }, { name: 'Difficulty' }, { name: 'Language' }, { name: 'Teachers' }, { name: 'Provider' }]);
  const [courseOptions] = useState([{ name: 'Monetizing' }, { name: 'Security' }, { name: 'Parental Control' }, { name: 'Placement' }, { name: 'Poster, Backdrop & Widescreen Images' }, { name: 'Descriptions' }]);
  const [lessonKeys] = useState([{ name: 'Course' }, { name: 'Name' }, { name: 'Number' }, { name: 'Duration_Seconds' }]);
  const [lessonOptions] = useState([{ name: 'Lesson Stream Url' }, { name: 'Lesson Image' }, { name: 'Descriptions' }]);

  const [movieKeys] = useState([{ name: 'Mapping' }, { name: 'Name' }, { name: 'Released' }, { name: 'Runtime_Minutes' }, { name: 'Duration_Seconds)' }, { name: 'Writers' }, { name: 'Language' }, { name: 'Studio' }, { name: 'Rating' }, { name: 'Actors' }, { name: 'IMDB' }, { name: 'TMDB' }, { name: 'Provider' }]);
  const [movieOptions] = useState([{ name: 'Monetizing' }, { name: 'Security' }, { name: 'Logo, Poster & Backdrop Images' }, { name: 'Parental Control' }, { name: 'Placement' }, { name: 'Movie Streams' }, { name: 'Descriptions' }]);

  const [shortsKeys] = useState([{ name: 'Mapping' }, { name: 'Name' }, { name: 'Studio' }, { name: 'Duration_Seconds' }]);
  const [shortsOptions] = useState([{ name: 'Monetizing' }, { name: 'Security' }, { name: 'Widescreen & Backdrop Images' }, { name: 'Parental Control' }, { name: 'Placement' }, { name: 'Short Streams' }, { name: 'Descriptions' }]);

  const [gamesKeys] = useState([{ name: 'Mapping' }, { name: 'Name' }, { name: 'Game_ID' }, { name: 'Affiliate' }, { name: 'Provider' }]);
  const [gamesOptions] = useState([{ name: 'Widescreen & Backdrop Images' }, { name: 'Placement' }, { name: 'Descriptions' }, { name: 'Tags' }]);

  const [bundles, setBundles] = useState([] as any);
  const [addToCats, setAddToCats] = useState(false);
  const [createCats, setCreateCats] = useState(false);
  const [avoidDoubles, setAvoidDoubles] = useState(false);
  const [numberOfImports, setNumberOfImports] = useState(0);

  const readUploadFile = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet, { defval: '' });

          const columnsArray = xlsx.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 })[0] as any;
          var columnsIn = columnsArray as any;

          if (columnsIn.length <= 2) {
            setAutoFill(true);
            setAiTranslation(true);
            setFillGapsWithIMDB(true);
          } else {
            columnsIn.push('Empty_Value');
            columnsIn.push('IMDB_Auto_Fill');
          }

          //get data from the other sheets
          var sheets = [] as any;
          workbook.SheetNames.forEach((sheet: any, index) => {
            if (index > 0) {
              var worksheet_ = workbook.Sheets[sheet];
              var json_ = xlsx.utils.sheet_to_json(worksheet_, { defval: '' });
              sheets.push({
                sheetName: sheet,
                sheetData: json_,
              });
            }
          });
          setExtraSheets(sheets);

          if (workbook.SheetNames.length > 1) {
            //get columns if sheet names are seasons,episodes, courses,lessons
            workbook.SheetNames.forEach((sheet: any, index) => {
              if (sheet == 'Seasons' || sheet == 'Episodes' || sheet == 'Pods' || sheet == 'Lessons' || sheet == 'Songs') {
                const sheetName = workbook.SheetNames[index];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet, { defval: '' });

                const columnsArray = xlsx.utils.sheet_to_json(worksheet, { header: 1 })[0] as any;
                var columnsIn = columnsArray as any;

                if (sheet == 'Songs') {
                  columnsIn.push('IMDB_Auto_Fill');
                  setColumns1(columnsIn);
                  setExcel1(json);
                }
                if (sheet == 'Pods') {
                  columnsIn.push('IMDB_Auto_Fill');
                  setColumns1(columnsIn);
                  setExcel1(json);
                }
                if (selectedContent == 'Series') {
                  if (sheet == 'Seasons') {
                    columnsIn.push('IMDB_Auto_Fill');
                    setColumns1(columnsIn);
                    setExcel1(json);
                  }
                  if (sheet == 'Episodes') {
                    columnsIn.push('IMDB_Auto_Fill');
                    setColumns2(columnsIn);
                    setExcel2(json);
                  }
                }
                if (selectedContent == 'Seasons') {
                  if (sheet == 'Episodes') {
                    columnsIn.push('IMDB_Auto_Fill');
                    setColumns1(columnsIn);
                    setExcel1(json);
                  }
                }

                if (sheet == 'Lessons') {
                  setColumns1(columnsIn);
                  setExcel1(json);
                }
              }
            });
          } else {
            setExcel1(json);
            setExcel2(json);
          }
          setColumns(columnsIn);
          if (selectedContent == 'Movies') {
            setExcel(json.slice(0, 500));
          } else if (selectedContent == 'Series') {
            setExcel(json.slice(0, 250));
          } else {
            setExcel(json);
          }
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const setSelectedContentType = (type: any) => {
    setSelectedContent(type);
    switch (type) {
      case 'Movies':
        setKeys(movieKeys);
        setOptions(movieOptions);
        setTab1('Movies');
        setTab2('');
        setTab3('');
        break;
      case 'Shorts':
        setKeys(shortsKeys);
        setOptions(shortsOptions);
        setTab1('Shorts');
        setTab2('');
        setTab3('');
        break;
      case 'Games':
        setKeys(gamesKeys);
        setOptions(gamesOptions);
        setTab1('Games');
        setTab2('');
        setTab3('');
        break;
      case 'Series':
        setKeys(seriesKeys);
        setOptions(seriesOptions);
        setKeys1(seasonsKeys);
        setOptions1(seasonOptions);
        setKeys2(episodeKeys);
        setOptions2(episodeOptions);
        setTab1('Series');
        setTab2('Seasons');
        setTab3('Episodes');
        break;
      case 'Seasons':
        changeUpdate(true);
        setKeys(seasonsKeys);
        setOptions(seasonOptions);
        setKeys1(episodeKeys);
        setOptions1(episodeOptions);
        setTab1('Seasons');
        setTab2('Episodes');
        setTab3('');
        break;
        case 'Apps':
          setKeys(appKeys);
          setOptions(appOptions);
          setKeys1(songKeys);
          setOptions1(songOptions);
          setTab1('Albums');
          setTab2('Songs');
          setTab3('');
          break;
      case 'Albums':
        setKeys(albumKeys);
        setOptions(albumOptions);
        setTab1('Apps');
        setTab2('');
        setTab3('');
        break;
      case 'Podcasts':
        setKeys(podcastKeys);
        setOptions(podcastOptions);
        setKeys1(podsKeys);
        setOptions1(podsOptions);
        setTab1('Podcasts');
        setTab2('Pods');
        setTab3('');
        break;
      case 'Channels':
        setKeys(channelKeys);
        setOptions(channelOptions);
        setTab1('Channels');
        setTab2('');
        setTab3('');
        break;
      case 'Courses':
        setKeys(courseKeys);
        setOptions(courseOptions);
        setKeys1(lessonKeys);
        setOptions1(lessonOptions);
        setTab1('Courses');
        setTab2('Lessons');
        setTab3('');
        break;
      case 'Radio':
        setKeys(radioKeys);
        setOptions(radioOptions);
        setTab1('Radios');
        setTab2('');
        setTab3('');
        break;
      case 'Cams':
        setKeys(camKeys);
        setOptions(camOptions);
        setTab1('Cams');
        setTab2('');
        setTab3('');
        break;
    }
  };

  const handleSubmit = (values: any) => {
    switch (selectedContent) {
      case 'Albums':
        (async () => await addAlbums(values))();
        break;
        case 'Apps':
          (async () => await addApps(values))();
          break;
      case 'Movies':
        (async () => await addMovies(values))();
        break;
      case 'Shorts':
        (async () => await addShorts(values))();
        break;
      case 'Series':
        (async () => await addSeries(values))();
        break;
      case 'Seasons':
        (async () => await addSeasons(values))();
        break;
      case 'Podcasts':
        (async () => await addPodcasts(values))();
        break;
      case 'Channels':
        (async () => await addChannels(values))();
        break;
      case 'Cams':
        (async () => await addCams(values))();
        break;
      case 'Courses':
        (async () => await addCourses(values))();
        break;
      case 'Radio':
        (async () => await addRadios(values))();
        break;
      case 'Games':
        (async () => await addGames(values))();
        break;
    }
  };
  const getObjectKeyValue = (row: any, selectedKeyIndex: any, isBoolean?: boolean) => {
    if (selectedKeyIndex != undefined) {
      if (isBoolean) {
        var test = Object.values(row)[selectedKeyIndex];
        if (test == 1 || test == 'True' || test == '1' || test == 'TRUE') {
          test = true;
        } else if (test == 0 || test == 'False' || test == 0 || test == 'FALSE') {
          test = false;
        }
        if (typeof test != 'boolean') {
          test = false;
        }
        return test;
      } else {
        if (selectedKeyIndex != 'Use Override') {
          return Object.values(row)[selectedKeyIndex];
        } else {
          return;
        }
      }
    } else {
      return undefined;
    }
  };

  const getProviders = async () => {
    let response = await providerService.List_Provider({
      query_params: {
        instance: clientName,
        cms: deployment?.name || '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      providers = response.data.data;
    }
  };
  useEffectOnce(() => {
    getProviders();
  });

  const addProvider = async (provider) => {
    if (provider != '') {
      const isprovider1Present = providers.find((t) => t.name === provider);
      if (isprovider1Present == undefined) {
        providerService.Add_Provider({
          name: provider,
          query_params: {
            cms: deployment?.name || '',
            instance: clientName,
          },
        });
        var providerArray = providers;
        providerArray.push({
          name: provider,
        });
        providers = providerArray;
      }
    }
  };

  const addChannels = async (values: any) => {
    try {
      await getProviders();
      setIsAdding(true);
      let valuesOut = [] as any;
      let offset = 0;
      excel.forEach(async (row: any, index: any) => {
        if (getObjectKeyValue(row, values.Provider) != undefined) {
          addProvider(getObjectKeyValue(row, values.Provider));
        }

        let tags = getTagsContent(autoFill, languagesDeployment, extraSheets, offset, aitranslation, [], getObjectKeyValue(row, values.Mapping) ?? '');
        let tagArray = tags.tags;
        offset = tags.numberOfTags;
        let currenciesOut = getCurrenciesContent(extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values);

        var valuesIn: any = {
          _id: getObjectKeyValue(row, values._Id) ?? 'no_id',
          name: getObjectKeyValue(row, values.Name) ?? '',
          number: getObjectKeyValue(row, values.Number) ?? '',
          meta: getObjectKeyValue(row, values.Notes) ?? '',
          interactivetv: {
            catchuptv_enabled: getObjectKeyValue(row, values.catchuptv_enabled, true) ?? values.catchuptv_enabled_overide ?? false,
            recordings_enabled: getObjectKeyValue(row, values.recordings_enabled, true) ?? values.recordings_enabled_overide ?? false,
            pausetv_enabled: getObjectKeyValue(row, values.pausetv_enabled, true) ?? values.pausetv_enabled_overide ?? false,
            alternative_url: getObjectKeyValue(row, values.alternative_url) ?? values.alternative_url_overide ?? '',
            transcoder: getObjectKeyValue(row, values.transcoder_id) ?? values.transcoder_id_overide ?? undefined,
          },
          provider: {
            name: getObjectKeyValue(row, values.Provider) ?? 'No Provider',
          },
          parental: {
            enabled: getObjectKeyValue(row, values.parental_control, true) ?? values.parental_control_overide ?? false,
            rating: getObjectKeyValue(row, values.content_rating, true) ?? values.content_rating_overide ?? '',
          },
          tags: tagArray ?? [],
          placement: {
            ut_start: getObjectKeyValue(row, values.placement_start, false) ?? dayjs(values.placement_start_overide).unix() ?? dayjs().unix(),
            ut_end: getObjectKeyValue(row, values.placement_end, false) ?? dayjs(values.placement_end_overide).unix() ?? dayjs().add(99, 'year').unix(),
            no_play_on_trial: getObjectKeyValue(row, values.placement_supress_on_trial, true) ?? values.placement_supress_on_trial_overide ?? false,
          },
          security: {
            akamai_token: getObjectKeyValue(row, values.akamai_token, true) ?? values.akamai_token_overide ?? false,
            flussonic_token: getObjectKeyValue(row, values.flussonic_token, true) ?? values.flussonic_token_overide ?? false,
            legacy_token: getObjectKeyValue(row, values.legacy_token, true) ?? values.legacy_token_overide ?? false,
            drm: {
              drm_enabled: getObjectKeyValue(row, values.drm_enabled, true) ?? values.drm_enabled_overide ?? false,
              drm_type: getObjectKeyValue(row, values.drm_provider, false) ?? '',
            },
          },
          payperview: {
            enable: getObjectKeyValue(row, values.payperview_enable, true) ?? false,
            rule: undefined,
            currencies: currenciesOut,
          },
          ads: {
            vast_url: getObjectKeyValue(row, values.vast_url) ?? values.vast_url_overide ?? '',
          },
          images: {
            square: getImage(row, values.icon, values.prefix_url),
            tile: getImage(row, values.tile, values.prefix_url),
          },
          streams: {
            channels: {
              urls: {
                ios_tvos: getObjectKeyValue(row, values.ios_tvos) ?? '',
                tizen_webos: getObjectKeyValue(row, values.tizen_webos) ?? '',
                standard_url: getObjectKeyValue(row, values.standard_url) ?? '',
              },
            },
            params: getObjectKeyValue(row, values.query_params) ?? '',
          },
          epg: {
            epg_url_id: getObjectKeyValue(row, values.epg_url_id) ?? '',
            epg_url_channel: getObjectKeyValue(row, values.epg_url_channel) ?? '',
          },
        };

        if (append || remove || values._id == 'no_id') {
          delete valuesIn._id;
        }
        if (!general1) {
          delete valuesIn.name;
          delete valuesIn.number;
          delete valuesIn.meta;
          delete valuesIn.tags;
          delete valuesIn.provider;
        }
        if (!itv) {
          delete valuesIn.interactivetv;
        }
        if (!parental) {
          delete valuesIn.parental;
        }
        if (!security) {
          delete valuesIn.security;
        }
        if (!provider) {
          delete valuesIn.provider;
        }
        if (!ads) {
          delete valuesIn.ads;
        }
        if (!rtvimages) {
          delete valuesIn.images;
        }
        if (!rtvstream) {
          delete valuesIn.streams;
        }
        if (!placement) {
          delete valuesIn.placement;
        }
        if (!epg) {
          delete valuesIn.epg;
        }
        if (!ads) {
          delete valuesIn.payperview;
        }
        valuesOut.push(valuesIn);
      });

      values = {
        channels: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.CHANNELS.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };

      let response = await cmsChannels.Import_Channels(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: 'Added Successfully',
        });
        history.push(url);
      } else {
        notification.error({
          message: 'An error occured',
        });
      }
      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };
  const addCams = async (values: any) => {
    try {
      await getProviders();
      setIsAdding(true);
      let valuesOut = [] as any;
      let offset = 0;
      excel.forEach(async (row: any, index: any) => {
        if (getObjectKeyValue(row, values.Provider) != undefined) {
          addProvider(getObjectKeyValue(row, values.Provider));
        }
        let tags = getTagsContent(autoFill, languagesDeployment, extraSheets, offset, aitranslation, [], getObjectKeyValue(row, values.Mapping) ?? '');
        let tagArray = tags.tags;
        offset = tags.numberOfTags;

        let currenciesOut = getCurrenciesContent(extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values);

        var valuesIn: any = {
          _id: getObjectKeyValue(row, values._Id) ?? 'no_id',
          name: getObjectKeyValue(row, values.Name) ?? '',
          number: getObjectKeyValue(row, values.Number) ?? '',
          meta: getObjectKeyValue(row, values.Notes) ?? '',
          interactivetv: {
            catchuptv_enabled: getObjectKeyValue(row, values.catchuptv_enabled, true) ?? values.catchuptv_enabled_overide ?? false,
            recordings_enabled: getObjectKeyValue(row, values.recordings_enabled, true) ?? values.recordings_enabled_overide ?? false,
            pausetv_enabled: getObjectKeyValue(row, values.pausetv_enabled, true) ?? values.pausetv_enabled_overide ?? false,
            alternative_url: getObjectKeyValue(row, values.alternative_url) ?? values.alternative_url_overide ?? '',
            transcoder: getObjectKeyValue(row, values.transcoder_id) ?? values.transcoder_id_overide ?? undefined,
          },
          provider: {
            name: getObjectKeyValue(row, values.Provider) ?? 'No Provider',
          },
          parental: {
            enabled: getObjectKeyValue(row, values.parental_control, true) ?? values.parental_control_overide ?? false,
            rating: getObjectKeyValue(row, values.content_rating, true) ?? values.content_rating_overide ?? '',
          },
          tags: tagArray ?? [],
          placement: {
            ut_start: getObjectKeyValue(row, values.placement_start, false) ?? dayjs(values.placement_start_overide).unix() ?? dayjs().unix(),
            ut_end: getObjectKeyValue(row, values.placement_end, false) ?? dayjs(values.placement_end_overide).unix() ?? dayjs().add(99, 'year').unix(),
            no_play_on_trial: getObjectKeyValue(row, values.placement_supress_on_trial, true) ?? values.placement_supress_on_trial_overide ?? false,
          },
          security: {
            akamai_token: getObjectKeyValue(row, values.akamai_token, true) ?? values.akamai_token_overide ?? false,
            flussonic_token: getObjectKeyValue(row, values.flussonic_token, true) ?? values.flussonic_token_overide ?? false,
            legacy_token: getObjectKeyValue(row, values.legacy_token, true) ?? values.legacy_token_overide ?? false,
            drm: {
              drm_enabled: getObjectKeyValue(row, values.drm_enabled, true) ?? values.drm_enabled_overide ?? false,
              drm_type: getObjectKeyValue(row, values.drm_provider, false) ?? '',
            },
          },
          payperview: {
            enable: getObjectKeyValue(row, values.payperview_enable, true) ?? false,
            rule: undefined,
            currencies: currenciesOut,
          },
          ads: {
            vast_url: getObjectKeyValue(row, values.vast_url) ?? values.vast_url_overide ?? '',
          },
          images: {
            square: getImage(row, values.icon, values.prefix_url),
            tile: getImage(row, values.tile, values.prefix_url),
          },
          streams: {
            cams: {
              urls: {
                ios_tvos: getObjectKeyValue(row, values.ios_tvos) ?? '',
                tizen_webos: getObjectKeyValue(row, values.tizen_webos) ?? '',
                standard_url: getObjectKeyValue(row, values.standard_url) ?? '',
              },
            },
          },
        };
        if (append || remove || values._id == 'no_id') {
          delete valuesIn._id;
        }
        if (!general1) {
          delete valuesIn.name;
          delete valuesIn.number;
          delete valuesIn.meta;
          delete valuesIn.tags;
          delete valuesIn.provider;
        }
        if (!itv) {
          delete valuesIn.interactivetv;
        }
        if (!parental) {
          delete valuesIn.parental;
        }
        if (!security) {
          delete valuesIn.security;
        }
        if (!provider) {
          delete valuesIn.provider;
        }
        if (!ads) {
          delete valuesIn.ads;
        }
        if (!rtvimages) {
          delete valuesIn.images;
        }
        if (!rtvstream) {
          delete valuesIn.streams;
        }
        if (!placement) {
          delete valuesIn.placement;
        }
        if (!ads) {
          delete valuesIn.payperview;
        }
        valuesOut.push(valuesIn);
      });

      values = {
        cams: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.CAMS.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };
      let response = await cmsCams.Import_Cams(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: 'Added Successfully',
        });
        history.push(url);
      } else {
        notification.error({
          message: 'An error occured',
        });
      }
      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };
  const addRadios = async (values: any) => {
    try {
      await getProviders();
      setIsAdding(true);
      let valuesOut = [] as any;
      let offset = 0;
      excel.forEach(async (row: any, index: any) => {
        let tags = getTagsContent(autoFill, languagesDeployment, extraSheets, offset, aitranslation, [], getObjectKeyValue(row, values.Mapping) ?? '');
        let tagArray = tags.tags;
        offset = tags.numberOfTags;
        let currenciesOut = getCurrenciesContent(extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values);

        if (getObjectKeyValue(row, values.Provider) != undefined) {
          addProvider(getObjectKeyValue(row, values.Provider));
        }

        var valuesIn: any = {
          _id: getObjectKeyValue(row, values._Id) ?? 'no_id',
          name: getObjectKeyValue(row, values.Name) ?? '',
          number: getObjectKeyValue(row, values.Number) ?? '',
          meta: getObjectKeyValue(row, values.Notes) ?? '',

          security: {
            akamai_token: getObjectKeyValue(row, values.akamai_token, true) ?? values.akamai_token_overide ?? false,
            flussonic_token: getObjectKeyValue(row, values.flussonic_token, true) ?? values.flussonic_token_overide ?? false,
            legacy_token: getObjectKeyValue(row, values.legacy_token, true) ?? values.legacy_token_overide ?? false,
            drm: {
              drm_enabled: getObjectKeyValue(row, values.drm_enabled, true) ?? values.drm_enabled_overide ?? false,
              drm_type: getObjectKeyValue(row, values.drm_provider, false) ?? '',
            },
          },
          provider: {
            name: getObjectKeyValue(row, values.Provider) ?? 'No Provider',
          },
          placement: {
            ut_start: getObjectKeyValue(row, values.placement_start, false) ?? dayjs(values.placement_start_overide).unix() ?? dayjs().unix(),
            ut_end: getObjectKeyValue(row, values.placement_end, false) ?? dayjs(values.placement_end_overide).unix() ?? dayjs().add(99, 'year').unix(),
            no_play_on_trial: getObjectKeyValue(row, values.placement_supress_on_trial, true) ?? values.placement_supress_on_trial_overide ?? false,
          },
          tags: tagArray ?? [],
          images: {
            square: getImage(row, values.icon, values.prefix_url),
            tile: getImage(row, values.tile, values.prefix_url),
            background: getImage(row, values.background, values.prefix_url),
          },
          payperview: {
            enable: getObjectKeyValue(row, values.payperview_enable, true) ?? false,
            rule: undefined,
            currencies: currenciesOut,
          },
          streams: {
            radios: {
              urls: {
                ios_tvos: getObjectKeyValue(row, values.ios_tvos) ?? '',
                tizen_webos: getObjectKeyValue(row, values.tizen_webos) ?? '',
                standard_url: getObjectKeyValue(row, values.standard_url) ?? '',
              },
            },
          },
          epg: {
            epg_url_id: getObjectKeyValue(row, values.epg_url_id) ?? '',
            epg_url_channel: getObjectKeyValue(row, values.epg_url_channel) ?? '',
          },
        };
        if (append || remove || values._id == 'no_id') {
          delete valuesIn._id;
        }
        if (!general1) {
          delete valuesIn.name;
          delete valuesIn.number;
          delete valuesIn.meta;
          delete valuesIn.tags;
          delete valuesIn.provider;
        }
        if (!itv) {
          delete valuesIn.interactivetv;
        }
        if (!parental) {
          delete valuesIn.parental;
        }
        if (!security) {
          delete valuesIn.security;
        }
        if (!provider) {
          delete valuesIn.provider;
        }
        if (!ads) {
          delete valuesIn.ads;
        }
        if (!rtvimages) {
          delete valuesIn.images;
        }
        if (!rtvstream) {
          delete valuesIn.streams;
        }
        if (!placement) {
          delete valuesIn.placement;
        }
        if (!epg) {
          delete valuesIn.epg;
        }
        if (!ads) {
          delete valuesIn.payperview;
        }
        valuesOut.push(valuesIn);
      });
      values = {
        radios: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.RADIOS.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };
      let response = await cmsRadio.Import_Radios(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: 'Added Successfully',
        });
        history.push(url);
      } else {
        notification.error({
          message: 'An error occured',
        });
      }
      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };
  const addApps = async (values: any) => {
    try {
      setIsAdding(true);
      let valuesOut = [] as any;
      let offset = 0;
      await excel.forEach(async (row: any, index: any) => {
 
        let tags = getTagsContent(autoFill, languagesDeployment, extraSheets, offset, aitranslation, [], getObjectKeyValue(row, values.Mapping) ?? '');
        let tagArray = tags.tags;
        offset = tags.numberOfTags;
        let currenciesOut = getCurrenciesContent(extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values);
        let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, fillGapsWithIMDB, [], languagesDeployment, index, aitranslation);

        var valuesIn: any = {
          _id: getObjectKeyValue(row, values._Id) ?? 'no_id',
          name: getObjectKeyValue(row, values.Name) ?? '',
          deeplink: getObjectKeyValue(row, values.Deeplink) ?? '',
          packageid: getObjectKeyValue(row, values.PackageID) ?? '',
          translations: descriptionsOut,
   
  
          placement: {
            ut_start: getObjectKeyValue(row, values.placement_start, false) ?? dayjs(values.placement_start_overide).unix() ?? dayjs().unix(),
            ut_end: getObjectKeyValue(row, values.placement_end, false) ?? dayjs(values.placement_end_overide).unix() ?? dayjs().add(99, 'year').unix(),
            no_play_on_trial: getObjectKeyValue(row, values.placement_supress_on_trial, true) ?? values.placement_supress_on_trial_overide ?? false,
          },
          tags: tagArray?.sort((a, b) => a.name - b.name) ?? [],
          payperview: {
            enable: getObjectKeyValue(row, values.payperview_enable, true) ?? false,
            rule: undefined,
            currencies: currenciesOut,
          },
          images: {
            square: getImage(row, values.icon, values.prefix_url),
            widescreen: getImage(row, values.widescreen, values.prefix_url),
          },
        };
        if (append || remove || values._id == 'no_id') {
          delete valuesIn._id;
        }
        if (!translations) {
          delete valuesIn.translations;
        }
        if (!general1) {
          delete valuesIn.name;
          delete valuesIn.deeplink;
          delete valuesIn.packageid;
          delete valuesIn.tags;
        }
        if (!languagesDeployment) {
          delete valuesIn.translations;
        }
        if (!iconimages) {
          delete valuesIn.images;
        }
        if (!placement) {
          delete valuesIn.placement;
        }
        if (!ads) {
          delete valuesIn.payperview;
        }
        valuesOut.push(valuesIn);
      });
      values = {
        albums: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.APPS.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };
      //
      let response = await cmsApps.Import_Apps(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: 'Added Successfully',
        });
        history.push(url);
      } else {
        notification.error({
          message: 'An error occured',
        });
      }
      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };
  const addAlbums = async (values: any) => {
    try {
      setIsAdding(true);
      let valuesOut = [] as any;
      let offset = 0;
      await excel.forEach(async (row: any, index: any) => {
        var songs = await addSongs(values, getObjectKeyValue(row, values.Name) ?? '');

        if (getObjectKeyValue(row, values.Provider) != undefined) {
          addProvider(getObjectKeyValue(row, values.Provider));
        }

        let tags = getTagsContent(autoFill, languagesDeployment, extraSheets, offset, aitranslation, [], getObjectKeyValue(row, values.Mapping) ?? '');
        let tagArray = tags.tags;
        offset = tags.numberOfTags;
        let currenciesOut = getCurrenciesContent(extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values);
        let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, fillGapsWithIMDB, [], languagesDeployment, index, aitranslation);

        var valuesIn: any = {
          _id: getObjectKeyValue(row, values._Id) ?? 'no_id',
          name: getObjectKeyValue(row, values.Name) ?? '',
          artist: getObjectKeyValue(row, values.Artist) ?? '',
          translations: descriptionsOut,
          security: {
            akamai_token: getObjectKeyValue(row, values.akamai_token, true) ?? values.akamai_token_overide ?? false,
            flussonic_token: getObjectKeyValue(row, values.flussonic_token, true) ?? values.flussonic_token_overide ?? false,
            legacy_token: getObjectKeyValue(row, values.legacy_token, true) ?? values.legacy_token_overide ?? false,
            drm: {
              drm_enabled: getObjectKeyValue(row, values.drm_enabled, true) ?? values.drm_enabled_overide ?? false,
              drm_type: getObjectKeyValue(row, values.drm_provider, false) ?? '',
            },
          },
          provider: {
            name: getObjectKeyValue(row, values.Provider) ?? 'No Provider',
          },
          placement: {
            ut_start: getObjectKeyValue(row, values.placement_start, false) ?? dayjs(values.placement_start_overide).unix() ?? dayjs().unix(),
            ut_end: getObjectKeyValue(row, values.placement_end, false) ?? dayjs(values.placement_end_overide).unix() ?? dayjs().add(99, 'year').unix(),
            no_play_on_trial: getObjectKeyValue(row, values.placement_supress_on_trial, true) ?? values.placement_supress_on_trial_overide ?? false,
          },
          tags: tagArray?.sort((a, b) => a.name - b.name) ?? [],
          payperview: {
            enable: getObjectKeyValue(row, values.payperview_enable, true) ?? false,
            rule: undefined,
            currencies: currenciesOut,
          },
          images: {
            square: getImage(row, values.icon, values.prefix_url),
            widescreen: getImage(row, values.tile, values.prefix_url),
          },
          songs: songs,
        };
        if (append || remove || values._id == 'no_id') {
          delete valuesIn._id;
        }
        if (!translations) {
          delete valuesIn.translations;
        }
        if (!general1) {
          delete valuesIn.name;
          delete valuesIn.artist;
          delete valuesIn.tags;
          delete valuesIn.provider;
        }
        if (!languagesDeployment) {
          delete valuesIn.translations;
        }
        if (!security) {
          delete valuesIn.security;
        }
        if (!provider) {
          delete valuesIn.provider;
        }
        if (!iconimages) {
          delete valuesIn.images;
        }
        if (!placement) {
          delete valuesIn.placement;
        }
        if (!ads) {
          delete valuesIn.payperview;
        }
        valuesOut.push(valuesIn);
      });
      values = {
        albums: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.ALBUMS.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };
      //
      let response = await cmsAlbums.Import_Albums(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: 'Added Successfully',
        });
        history.push(url);
      } else {
        notification.error({
          message: 'An error occured',
        });
      }
      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };
  const addSongs = async (values: any, name: any) => {
    let valuesOut = [] as any;
    excel1.forEach((row: any, index: any) => {
      if (getObjectKeyValue(row, values.Album_key1, false) == name) {
        var valuesIn: any = {
          name: getObjectKeyValue(row, values.Name_key1, false) ?? '',
          producer: getObjectKeyValue(row, values.Producer_key1, false) ?? '',
          writer: getObjectKeyValue(row, values.Writer_key1, false) ?? '',
          duration: getObjectKeyValue(row, values.Duration_key1, false) ?? 0,
          streams: {
            albums: {
              url: getObjectKeyValue(row, values.stream_url_key1, false),
            },
          },
        };
        if (!general2) {
          delete valuesIn.name;
          delete valuesIn.producer;
          delete valuesIn.writer;
          delete valuesIn.duration;
        }
        if (!singlestream) {
          delete valuesIn.streams;
        }
        valuesOut.push(valuesIn);
      }
    });
    return valuesOut;
  };
  const addPodcasts = async (values: any) => {
    try {
      await getProviders();
      setIsAdding(true);
      let valuesOut = [] as any;
      let offset = 0;
      await excel.forEach(async (row: any, index: any) => {
        var pods = await addPods(values, getObjectKeyValue(row, values.Name));

        if (getObjectKeyValue(row, values.Provider) != undefined) {
          addProvider(getObjectKeyValue(row, values.Provider));
        }

        let tags = getTagsContent(autoFill, languagesDeployment, extraSheets, offset, aitranslation, [], getObjectKeyValue(row, values.Mapping) ?? '');
        let tagArray = tags.tags;
        offset = tags.numberOfTags;
        let currenciesOut = getCurrenciesContent(extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values);
        let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, fillGapsWithIMDB, [], languagesDeployment, index, aitranslation);

        var valuesIn: any = {
          _id: getObjectKeyValue(row, values._Id) ?? 'no_id',
          name: getObjectKeyValue(row, values.Name) ?? '',
          creator: getObjectKeyValue(row, values.Creator) ?? '',
          tagline: getObjectKeyValue(row, values.Tagline) ?? '',
          release_period: getObjectKeyValue(row, values.Release_Period) ?? '',
          average_length: getObjectKeyValue(row, values.Average_Length) ?? '',
          language: getObjectKeyValue(row, values.Language) ?? '',
          translations: descriptionsOut,
          provider: {
            name: getObjectKeyValue(row, values.Provider) ?? 'No Provider',
          },
          security: {
            akamai_token: getObjectKeyValue(row, values.akamai_token, true) ?? values.akamai_token_overide ?? false,
            flussonic_token: getObjectKeyValue(row, values.flussonic_token, true) ?? values.flussonic_token_overide ?? false,
            legacy_token: getObjectKeyValue(row, values.legacy_token, true) ?? values.legacy_token_overide ?? false,
            drm: {
              drm_enabled: getObjectKeyValue(row, values.drm_enabled, true) ?? values.drm_enabled_overide ?? false,
              drm_type: getObjectKeyValue(row, values.drm_provider, false) ?? '',
            },
          },
          payperview: {
            enable: getObjectKeyValue(row, values.payperview_enable, true) ?? false,
            rule: undefined,
            currencies: currenciesOut,
          },
          placement: {
            ut_start: getObjectKeyValue(row, values.placement_start, false) ?? dayjs(values.placement_start_overide).unix() ?? dayjs().unix(),
            ut_end: getObjectKeyValue(row, values.placement_end, false) ?? dayjs(values.placement_end_overide).unix() ?? dayjs().add(99, 'year').unix(),
            no_play_on_trial: getObjectKeyValue(row, values.placement_supress_on_trial, true) ?? values.placement_supress_on_trial_overide ?? false,
          },
          tags: tagArray ?? [],
          images: {
            square: getImage(row, values.icon, values.prefix_url),
            widescreen: getImage(row, values.tile, values.prefix_url),
          },
          episodes: pods,
        };
        if (append || remove || values._id == 'no_id') {
          delete valuesIn._id;
        }
        if (!translations) {
          delete valuesIn.translations;
        }
        if (!general1) {
          delete valuesIn.name;
          delete valuesIn.creator;
          delete valuesIn.tagline;
          delete valuesIn.language;
          delete valuesIn.release_period;
          delete valuesIn.average_length;
          delete valuesIn.tags;
          delete valuesIn.provider;
        }
        if (!languagesDeployment) {
          delete valuesIn.translations;
        }
        if (!security) {
          delete valuesIn.security;
        }
        if (!provider) {
          delete valuesIn.provider;
        }
        if (!iconimages) {
          delete valuesIn.images;
        }
        if (!placement) {
          delete valuesIn.placement;
        }
        if (!ads) {
          delete valuesIn.payperview;
        }
        valuesOut.push(valuesIn);
      });

      values = {
        podcasts: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.PODCASTS.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };

      let response = await cmsPodcasts.Import_Podcasts(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: 'Added Successfully',
        });
        history.push(url);
      } else {
        notification.error({
          message: 'An error occured',
        });
      }

      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };
  const addPods = async (values: any, name: any) => {
    let valuesOut = [] as any;
    excel1.forEach((row: any, index: any) => {
      if (getObjectKeyValue(row, values.Podcast_key1, false) == name) {
        var valuesIn: any = {
          name: getObjectKeyValue(row, values.Name_key1, false) ?? '',
          producer: getObjectKeyValue(row, values.Producer_key1, false) ?? '',
          writer: getObjectKeyValue(row, values.Writer_key1, false) ?? '',
          duration: getObjectKeyValue(row, values.Duration_key1, false) ?? 0,
          streams: {
            podcasts: {
              url: getObjectKeyValue(row, values.stream_url_key1, false),
            },
          },
        };
        if (!general2) {
          delete valuesIn.name;
          delete valuesIn.producer;
          delete valuesIn.writer;
          delete valuesIn.duration;
        }
        if (!singlestream) {
          delete valuesIn.streams;
        }
        valuesOut.push(valuesIn);
      }
    });
    return valuesOut;
  };
  const addCourses = async (values: any) => {
    try {
      await getProviders();
      setIsAdding(true);
      let valuesOut = [] as any;
      let offset = 0;
      await excel.forEach(async (row: any, index: any) => {
        var lessons = await addLessons(values, getObjectKeyValue(row, values.Name));

        let tags = getTagsContent(autoFill, languagesDeployment, extraSheets, offset, aitranslation, [], getObjectKeyValue(row, values.Mapping) ?? '');
        let tagArray = tags.tags;
        offset = tags.numberOfTags;
        let currenciesOut = getCurrenciesContent(extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values);
        let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, fillGapsWithIMDB, [], languagesDeployment, index, aitranslation);
        let teachersArray = getTeachersContent(autoFill, columns, row, values, getObjectKeyValue);

        var valuesIn: any = {
          _id: getObjectKeyValue(row, values._Id) ?? 'no_id',
          name: getObjectKeyValue(row, values.Name) ?? '',
          released: getObjectKeyValue(row, values.Released) ?? '',
          difficulty: getObjectKeyValue(row, values.Difficulty) ?? '',
          language: getObjectKeyValue(row, values.Language) ?? '',
          tags: tagArray ?? [],
          parental: {
            enabled: getObjectKeyValue(row, values.parental_control, true) ?? values.parental_control_overide ?? false,
            rating: getObjectKeyValue(row, values.content_rating, true) ?? values.content_rating_overide ?? '',
          },
          provider: {
            name: getObjectKeyValue(row, values.Provider) ?? 'No Provider',
          },
          security: {
            akamai_token: getObjectKeyValue(row, values.akamai_token, true) ?? values.akamai_token_overide ?? false,
            flussonic_token: getObjectKeyValue(row, values.flussonic_token, true) ?? values.flussonic_token_overide ?? false,
            legacy_token: getObjectKeyValue(row, values.legacy_token, true) ?? values.legacy_token_overide ?? false,
            drm: {
              drm_enabled: getObjectKeyValue(row, values.drm_enabled, true) ?? values.drm_enabled_overide ?? false,
              drm_type: getObjectKeyValue(row, values.drm_provider, false) ?? '',
            },
          },
          payperview: {
            enable: getObjectKeyValue(row, values.payperview_enable, true) ?? false,
            rule: undefined,
            currencies: currenciesOut,
          },
          placement: {
            ut_start: getObjectKeyValue(row, values.placement_start, false) ?? dayjs(values.placement_start_overide).unix() ?? dayjs().unix(),
            ut_end: getObjectKeyValue(row, values.placement_end, false) ?? dayjs(values.placement_end_overide).unix() ?? dayjs().add(99, 'year').unix(),
            no_play_on_trial: getObjectKeyValue(row, values.placement_supress_on_trial, true) ?? values.placement_supress_on_trial_overide ?? false,
          },
          ads: {
            vast_url: getObjectKeyValue(row, values.vast_url) ?? values.vast_url_overide ?? '',
          },
          images: {
            widescreen: getImage(row, values.widescreen, values.prefix_url),
            background: getImage(row, values.background, values.prefix_url),
            poster: getImage(row, values.poster, values.prefix_url),
          },
          translations: descriptionsOut,
          teachers: teachersArray,
          lessons: lessons,
        };

        if (append || remove || overwrite || values._id == 'no_id') {
          delete valuesIn._id;
        }
        if (!general1) {
          delete valuesIn.name;
          delete valuesIn.released;
          delete valuesIn.difficulty;
          delete valuesIn.language;
          delete valuesIn.teachers;
        }
        if (!parental) {
          delete valuesIn.parental;
        }
        if (!security) {
          delete valuesIn.security;
        }
        if (!provider) {
          delete valuesIn.provider;
        }
        if (!ads) {
          delete valuesIn.ads;
        }
        if (!translations) {
          delete valuesIn.translations;
        }
        if (!backgroundimages) {
          delete valuesIn.images;
        }
        if (!placement) {
          delete valuesIn.placement;
        }
        if (!ads) {
          delete valuesIn.payperview;
        }

        valuesOut.push(valuesIn);
      });

      values = {
        courses: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.COURSES.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };
      let response = await cmsCourses.Import_Courses(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: 'Added Successfully',
        });
        history.push(url);
      } else {
        notification.error({
          message: 'An error occured',
        });
      }
      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };
  const addLessons = (values: any, name: any) => {
    let valuesOut = [] as any;
    excel1.forEach((row: any, index: any) => {
      if (getObjectKeyValue(row, values.Course_key1, false) == name) {
        let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, fillGapsWithIMDB, [], languagesDeployment, index, aitranslation);

        var valuesIn: any = {
          name: getObjectKeyValue(row, values.Name_key1) ?? '',
          number: getObjectKeyValue(row, values.Number_key1) ?? '',
          duration: getObjectKeyValue(row, values.Runtime_Seconds) ?? 0,
          translations: descriptionsOut,
          streams: {
            courses: {
              url: getObjectKeyValue(row, values.stream_url_key1),
              allow_downloads: getObjectKeyValue(row, values.allow_downloads_key1) ?? false,
              expiration_downloads: getObjectKeyValue(row, values.expiration_downloads_key1) ?? 365,
            },
          },
          images: {
            widescreen: getImage(row, values.lesson_key1, values.prefix_url_key1),
          },
        };
        if (!general2) {
          delete valuesIn.name;
          delete valuesIn.duration;
          delete valuesIn.number;
        }
        if (!translations) {
          delete valuesIn.translations;
        }
        if (!languagesDeployment) {
          delete valuesIn.translations;
        }
        if (!storeimages) {
          delete valuesIn.images;
        }
        if (!singlestream) {
          delete valuesIn.streams;
        }
        valuesOut.push(valuesIn);
      }
    });
    return valuesOut;
  };
  const addSeries = async (values: any) => {
    try {
      await getProviders();
      setIsAdding(true);
      let valuesOut = [] as any;
      let index = -1;
      var missedSeries = [] as any;
      //setFillGapsWithIMDB
      //var offset = 0;
      for (let row of excel) {
        index++;
        var id = getObjectKeyValue(row, values.IMDB);
        const seriesIMDB = fillGapsWithIMDB ? await searchIMDBApi(id, 'series') : ([] as any);

        if (!autoFill || (seriesIMDB != undefined && seriesIMDB.name != undefined)) {
          var seriesName = autoFill || columns[values.Name] == 'IMDB_Auto_Fill' ? seriesIMDB.name : getObjectKeyValue(row, values.Name) ?? getObjectKeyValue(row, values._Id);

          let tags = getTagsContent(fillGapsWithIMDB, languagesDeployment, extraSheets, 0, aitranslation, seriesIMDB, getObjectKeyValue(row, values.Mapping) ?? '');
          let tagArray = tags.tags;

          if (getObjectKeyValue(row, values.Provider) != undefined) {
            addProvider(getObjectKeyValue(row, values.Provider));
          }

          let currenciesOut = getCurrenciesContent(extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values);
          var valuesIn: any = {
            _id: getObjectKeyValue(row, values._Id) ?? 'no_id',
            name: autoFill || columns[values.Name] == 'IMDB_Auto_Fill' ? seriesIMDB.name : getObjectKeyValue(row, values.Name) ?? '',
            trailer: autoFill || columns[values.Trailer] == 'IMDB_Auto_Fill' ? seriesIMDB.trailer : getObjectKeyValue(row, values.Trailer) ?? '',
            imdbid: autoFill || columns[values.IMDB] == 'IMDB_Auto_Fill' ? seriesIMDB.imdb : getObjectKeyValue(row, values.IMDB) ?? '',
            tmdbid: autoFill || columns[values.IMDB] == 'IMDB_Auto_Fill' ? seriesIMDB.tmdb : getObjectKeyValue(row, values.TMDB) ?? '',
            images: {
              widescreen: getImage(row, values.series_widescreen, values.prefix_url, columns[values.series_widescreen] == 'IMDB_Auto_Fill' || autoFill, 'series', seriesIMDB),
              logo: getImage(row, values.series_logo, values.prefix_url, columns[values.series_logo] == 'IMDB_Auto_Fill' || autoFill, 'logo', seriesIMDB),
            },
            parental: {
              enabled: getObjectKeyValue(row, values.parental_control, true) ?? values.parental_control_overide ?? false,
              rating: getObjectKeyValue(row, values.content_rating, true) ?? values.content_rating_overide ?? '',
            },
            provider: {
              name: getObjectKeyValue(row, values.Provider) ?? 'No Provider',
            },
            security: {
              akamai_token: getObjectKeyValue(row, values.akamai_token, true) ?? values.akamai_token_overide ?? false,
              flussonic_token: getObjectKeyValue(row, values.flussonic_token, true) ?? values.flussonic_token_overide ?? false,
              legacy_token: getObjectKeyValue(row, values.legacy_token, true) ?? values.legacy_token_overide ?? false,
              drm: {
                drm_enabled: getObjectKeyValue(row, values.drm_enabled, true) ?? values.drm_enabled_overide ?? false,
                drm_type: getObjectKeyValue(row, values.drm_provider, false) ?? '',
              },
            },
            payperview: {
              enable: getObjectKeyValue(row, values.payperview_enable, true) ?? false,
              rule: undefined,
              currencies: currenciesOut,
            },
            placement: {
              ut_start: getObjectKeyValue(row, values.placement_start, false) ?? dayjs(values.placement_start_overide).unix() ?? dayjs().unix(),
              ut_end: getObjectKeyValue(row, values.placement_end, false) ?? dayjs(values.placement_end_overide).unix() ?? dayjs().add(99, 'year').unix(),
              no_play_on_trial: getObjectKeyValue(row, values.placement_supress_on_trial, true) ?? values.placement_supress_on_trial_overide ?? false,
            },
            tags: tagArray ?? [],
            ads: {
              vast_url: getObjectKeyValue(row, values.vast_url) ?? values.vast_url_overide ?? '',
            },
            seasons: await addSeasons_(values, getObjectKeyValue(row, values.series_id_1), seriesName, seriesIMDB.seasons, tagArray),
          };

          if (append || remove || values._id == 'no_id') {
            delete valuesIn._id;
          }
          if (!general1) {
            delete valuesIn.name;
            delete valuesIn.tags;
            delete valuesIn.imdbid;
            delete valuesIn.tmdbid;
            delete valuesIn.provider;
            delete valuesIn.trailer;
          }
          if (!parental) {
            delete valuesIn.parental;
          }
          if (!security) {
            delete valuesIn.security;
          }
          if (!provider) {
            delete valuesIn.provider;
          }
          if (!ads) {
            delete valuesIn.ads;
          }
          if (!storeimages) {
            delete valuesIn.images;
          }
          if (!placement) {
            delete valuesIn.placement;
          }
          if (!ads) {
            delete valuesIn.payperview;
          }
          valuesOut.push(valuesIn);
        } else {
          missedSeries.push({
            id: id,
          });
        }
      }

      setNumberOfImports(valuesOut.length);
      values = {
        series: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.SERIES.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };

      let response = await cmsSeries.Import_Series(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        if (index == numberOfImports - 1) {
          notification.success({
            message: 'Added Successfully',
          });
        }
      } else {
        notification.error({
          message: 'An error occured',
        });
      }

      if (missedSeries.length > 0) {
        const workbook = XLSX.utils.book_new();
        const worksheet1 = XLSX.utils.json_to_sheet(missedSeries);
        XLSX.utils.book_append_sheet(workbook, worksheet1, 'Failed Imports');
        XLSX.writeFile(workbook, 'Failed_Imports_Series_' + clientName + '_' + deployment?.name + '_' + dayjs().format('YYYY-MM-DD') + '.xlsx');
      }
      history.push(url);
      setNumberOfImports(0);
      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };
  const addSeasons_ = async (values: any, seriesId: any, seriesName: any, seasonsIMDB: any, tagArray: any) => {
    setIsAdding(true);

    let valuesOut = [] as any;
    let index = 0;
    let seasonIndex = 0;

    let rows = seasonsIMDB != undefined && autoFill ? seasonsIMDB : excel1;
    //let offset = 0;
    for (let row of rows) {
      if (getObjectKeyValue(row, values.series_id_1) == seriesId) {
        let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, fillGapsWithIMDB, row, languagesDeployment, index, aitranslation);
        let actorArray = getActorsContent(fillGapsWithIMDB, columns1, row, values, getObjectKeyValue);
        var seasonName = (columns1[values.Name_key1] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].name : getObjectKeyValue(row, values.Name_key1) ?? getObjectKeyValue(row, values._Id_key1);

        var valuesIn: any = {
          _id: getObjectKeyValue(row, values.season_id_2),
          name: seasonName,
          series: seriesName,
          number: (columns1[values.Number_key1] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].number : getObjectKeyValue(row, values.Number_key1) ?? '',
          rating: (columns1[values.Rating_key1] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].rating : getObjectKeyValue(row, values.Rating_key1) ?? '',
          released: (columns1[values.Released_key1] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].released : getObjectKeyValue(row, values.Released_key1) ?? '',
          language: (columns1[values.Language_key1] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].language : getObjectKeyValue(row, values.Language_key1) ?? '',
          studio: (columns1[values.Studio_key1] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].studio : getObjectKeyValue(row, values.Studio_key1) ?? '',

          translations: descriptionsOut,
          tags: tagArray ?? [],
          actors: (columns1[values.Actors_key1] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].actors : actorArray ?? [],

          images: {
            poster: getImage(row, values.poster_key1, values.prefix_url_key1, columns1[values.poster_key1] == 'IMDB_Auto_Fill' || autoFill, 'poster', seasonsIMDB != undefined ? seasonsIMDB[seasonIndex] : []),
            background: getImage(row, values.background_key1, values.prefix_url_key1, columns1[values.background_key1] == 'IMDB_Auto_Fill' || autoFill, 'background', seasonsIMDB != undefined ? seasonsIMDB[seasonIndex] : []),
          },
          episodes: await addEpisodes_(values, getObjectKeyValue(row, values.season_id_2), seasonName, seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].episodes : []),
        };

        if (append || remove) {
          delete valuesIn._id;
        }

        if (!general2) {
          delete valuesIn.name;
          delete valuesIn.rating;
          delete valuesIn.released;
          delete valuesIn.language;
          delete valuesIn.studio;
          delete valuesIn.tags;
          delete valuesIn.actors;
          delete valuesIn.number;
        }
        if (!translations) {
          delete valuesIn.translations;
        }
        if (!backgroundimages) {
          delete valuesIn.images;
        }
        valuesOut.push(valuesIn);
        seasonIndex++;
      }
      index++;
    }
    return valuesOut;
  };
  const addEpisodes_ = (values: any, seasonId: any, seasonName: any, episodesIMDB: any) => {
    let valuesOut = [] as any;
    let index = 0;
    let episodeIndex = 0;
    let rows = episodesIMDB != undefined && autoFill ? episodesIMDB : excel2;

    for (let row of rows) {
      if (getObjectKeyValue(row, values.episode_id_2) == seasonId) {
        let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, fillGapsWithIMDB, row, languagesDeployment, index, aitranslation);

        var valuesIn: any = {
          // _id: getObjectKeyValue(row, values._Id_key2) ?? getObjectKeyValue(row, values._episode),
          name: (columns2[values.Name_key2] == 'IMDB_Auto_Fill' || autoFill) && episodesIMDB[episodeIndex] != undefined ? episodesIMDB[episodeIndex].name : getObjectKeyValue(row, values.Name_key2) ?? '',
          number: (columns2[values.Number_key2] == 'IMDB_Auto_Fill' || autoFill) && episodesIMDB[episodeIndex] != undefined ? episodesIMDB[episodeIndex].number : getObjectKeyValue(row, values.Number_key2) ?? '',
          //  duration: autoFill || columns[values.Runtime_Seconds] == 'IMDB_Auto_Fill' ? episodesIMDB.duration : getObjectKeyValue(row, values.Runtime_Seconds) ?? 0,
          duration: (columns2[values.Duration_key2] == 'IMDB_Auto_Fill' || autoFill) && episodesIMDB[episodeIndex] != undefined ? episodesIMDB[episodeIndex].duration : getObjectKeyValue(row, values.Duration_key2) ?? '',
          translations: descriptionsOut,
          streams: {
            series: {
              url: autoFill ? getObjectKeyValue(excel2[episodeIndex], values.stream_url_key2) : getObjectKeyValue(row, values.stream_url_key2),
              allow_downloads: getObjectKeyValue(row, values.allow_downloads_key2) ?? false,
              expiration_downloads: getObjectKeyValue(row, values.expiration_downloads_key2) ?? 365,
            },
          },
          images: {
            widescreen: getImage(row, values.episode_key2, values.prefix_url_key2, columns2[values.episode_key2] == 'IMDB_Auto_Fill' || autoFill, 'episode', episodesIMDB[episodeIndex]),
          },
        };

        // if (append || remove) {
        //   delete valuesIn._id;
        // }
        if (!general3) {
          delete valuesIn.name;
          delete valuesIn.duration;
          delete valuesIn.number;
        }
        if (!episodeimages) {
          delete valuesIn.images;
        }
        if (!translationsEpisodes) {
          delete valuesIn.translations;
        }
        if (!singlestream) {
          delete valuesIn.streams;
        }
        valuesOut.push(valuesIn);
        episodeIndex++;
      }
      index++;
    }
    return valuesOut;
  };
  const addSeasons = async (values: any) => {
    try {
      await getProviders();
      setIsAdding(true);

      let valuesOut = [] as any;
      let index = 0;
      let seasonIndex = 0;

      var missedSeasons = [] as any;
      const seasonsIMDB = fillGapsWithIMDB ? await searchIMDBApi('IMDB', 'series') : ([] as any);

      if (!autoFill || seasonsIMDB.length > 0) {
        for (let row of excel) {
          if (getObjectKeyValue(row, values.Provider) != undefined) {
            addProvider(getObjectKeyValue(row, values.Provider));
          }

          let tags = getTagsContent(fillGapsWithIMDB, languagesDeployment, extraSheets, index, aitranslation, seasonsIMDB[seasonIndex], getObjectKeyValue(row, values.Mapping) ?? '');
          let tagArray = tags.tags;
          let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, fillGapsWithIMDB, seasonsIMDB[seasonIndex], languagesDeployment, index, aitranslation);
          let actorArray = getActorsContent(fillGapsWithIMDB, columns, row, values, getObjectKeyValue);

          var seasonName = (columns[values.Name] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].name : getObjectKeyValue(row, values.Name) ?? '';
          var valuesIn: any = {
            _id: getObjectKeyValue(row, values._Id) ?? '',
            name: seasonName,
            series: getObjectKeyValue(row, values.Series),
            number: (columns[values.Number] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].number : getObjectKeyValue(row, values.Number) ?? '',
            rating: (columns[values.Rating] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].rating : getObjectKeyValue(row, values.Rating) ?? '',
            released: (columns[values.Released] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].released : getObjectKeyValue(row, values.Released) ?? '',
            language: (columns[values.Language] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].language : getObjectKeyValue(row, values.Language) ?? '',
            studio: (columns[values.Studio] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].studio : getObjectKeyValue(row, values.Studio) ?? '',

            translations: descriptionsOut,
            tags: (columns[values.Tags] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].tags : tagArray?.sort((a, b) => a.name - b.name) ?? [],
            actors: (columns[values.Actors] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].actors : actorArray ?? [],

            images: {
              poster: getImage(row, values.poster, values.prefix_url, columns[values.poster] == 'IMDB_Auto_Fill' || autoFill, 'poster', seasonsIMDB != undefined ? seasonsIMDB[seasonIndex] : []),
              background: getImage(row, values.background, values.prefix_url, columns[values.background] == 'IMDB_Auto_Fill' || autoFill, 'background', seasonsIMDB != undefined ? seasonsIMDB[seasonIndex] : []),
              // getImage(row, values.logo, values.prefix_url, columns[values.logo] == 'IMDB_Auto_Fill' || autoFill, 'logo', seasonsIMDB != undefined ? seasonsIMDB[seasonIndex] : []),
            },
            episodes: await addEpisodes(values, getObjectKeyValue(row, values._Id), (columns[values._Id] == 'IMDB_Auto_Fill' || autoFill) && seasonsIMDB != undefined ? seasonsIMDB[seasonIndex].episodes : []),
          };
          if (!general1) {
            delete valuesIn.name;
            delete valuesIn.rating;
            delete valuesIn.released;
            delete valuesIn.language;
            delete valuesIn.studio;
            delete valuesIn.tags;
            delete valuesIn.actors;
            delete valuesIn.number;
            delete valuesIn.series;
          }
          if (!translations) {
            delete valuesIn.translations;
          }
          if (!backgroundimages) {
            delete valuesIn.images;
          }
          valuesOut.push(valuesIn);
          seasonIndex++;
          index++;
        }
      } else {
      }
      values = {
        seasons: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.SERIES.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };
      let response = await cmsSeries.Import_Seasons(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: 'Added Successfully',
        });
        if (missedSeasons.length > 0) {
          const workbook = XLSX.utils.book_new();
          const worksheet1 = XLSX.utils.json_to_sheet(missedSeasons);
          XLSX.utils.book_append_sheet(workbook, worksheet1, 'Failed Imports');
          XLSX.writeFile(workbook, 'Failed_Imports_Seasons_' + clientName + '_' + deployment?.name + '_' + dayjs().format('YYYY-MM-DD') + '.xlsx');
        }
        history.push(url);
      } else {
        notification.error({
          message: 'An error occured',
        });
      }
      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };
  const addEpisodes = (values: any, seasonId: any, episodesIMDB: any) => {
    let valuesOut = [] as any;
    let index = 0;
    let episodeIndex = 0;
    let rows = episodesIMDB.length > 0 && autoFill ? episodesIMDB : excel1;
    for (let row of rows) {
      if (getObjectKeyValue(row, values.Season_key1) == seasonId) {
        let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, fillGapsWithIMDB, episodesIMDB[episodeIndex], languagesDeployment, index, aitranslation);

        var valuesIn: any = {
          name: (columns1[values.Name_key1] == 'IMDB_Auto_Fill' || autoFill) && episodesIMDB[episodeIndex] != undefined ? episodesIMDB[episodeIndex].name : getObjectKeyValue(row, values.Name_key1) ?? '',
          number: (columns1[values.Number_key1] == 'IMDB_Auto_Fill' || autoFill) && episodesIMDB[episodeIndex] != undefined ? episodesIMDB[episodeIndex].number : getObjectKeyValue(row, values.Number_key1) ?? '',
          // duration: autoFill || columns[values.Runtime_Seconds] == 'IMDB_Auto_Fill' ? episodesIMDB.duration : getObjectKeyValue(row, values.Runtime_Seconds) ?? 0,
          duration: (columns1[values.Duration_key1] == 'IMDB_Auto_Fill' || autoFill) && episodesIMDB[episodeIndex] != undefined ? episodesIMDB[episodeIndex].number : getObjectKeyValue(row, values.Duration_key1) ?? '',
          translations: descriptionsOut,
          streams: {
            series: {
              url: autoFill ? getObjectKeyValue(excel1[episodeIndex], values.stream_url_key1) : getObjectKeyValue(row, values.stream_url_key1),
              allow_downloads: getObjectKeyValue(row, values.allow_downloads_key1) ?? false,
              expiration_downloads: getObjectKeyValue(row, values.expiration_downloads_key1) ?? 365,
            },
          },
          images: {
            widescreen: getImage(row, values.episode_key1, values.prefix_url_key1, columns1[values.episode_key1] == 'IMDB_Auto_Fill' || autoFill, 'episode', episodesIMDB[episodeIndex]),
          },
        };
        if (!general2) {
          delete valuesIn.name;
          delete valuesIn.duration;
          delete valuesIn.number;
        }
        if (!translationsEpisodes) {
          delete valuesIn.translations;
        }
        if (!singlestream) {
          delete valuesIn.streams;
        }
        valuesOut.push(valuesIn);
        episodeIndex++;
      }
      index++;
    }
    return valuesOut;
  };
  const addMovies = async (values: any) => {
    try {
      await getProviders();
      setIsAdding(true);
      let valuesOut = [] as any;
      let index = -1;
      var missedMovies = [] as any;
      let offset = 0;
      for (let row of excel) {
        index++;
        var id = getObjectKeyValue(row, values.IMDB);
        if (id == undefined) {
          id = getObjectKeyValue(row, values.IMDB_Auto_Fill);
        }
        const movieIMDB = aitranslation ? await searchIMDBApi(getObjectKeyValue(row, values.IMDB), 'movies') : ([] as any);
        if (!autoFill || (movieIMDB != undefined && movieIMDB.name != undefined)) {
          let tags = getTagsContent(fillGapsWithIMDB, languagesDeployment, extraSheets, offset, aitranslation, movieIMDB, getObjectKeyValue(row, values.Mapping) ?? '');
          let tagArray = tags.tags;
          offset = tags.numberOfTags;
          let currenciesOut = getCurrenciesContent(extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values);
          let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, fillGapsWithIMDB, movieIMDB, languagesDeployment, index, aitranslation);
          let actorArray = getActorsContent(autoFill, columns, row, values, getObjectKeyValue);

          if (getObjectKeyValue(row, values.Provider) != undefined) {
            addProvider(getObjectKeyValue(row, values.Provider));
          }

          var valuesIn: any = {
            _id: getObjectKeyValue(row, values._Id) ?? 'no_id',
            name: autoFill || columns[values.Name] == 'IMDB_Auto_Fill' ? movieIMDB.name : getObjectKeyValue(row, values.Name) ?? '',
            released: autoFill || columns[values.Released] == 'IMDB_Auto_Fill' ? movieIMDB.released : getObjectKeyValue(row, values.Released) ?? '',
            runtime: autoFill || columns[values.Runtime_Minutes] == 'IMDB_Auto_Fill' ? movieIMDB.runtime : getObjectKeyValue(row, values.Runtime_Minutes) ?? '',
            duration: autoFill || columns[values.Runtime_Seconds] == 'IMDB_Auto_Fill' ? movieIMDB.duration : getObjectKeyValue(row, values.Runtime_Seconds) ?? 0,
            writers: autoFill || columns[values.Writer] == 'IMDB_Auto_Fill' ? movieIMDB.writers : getObjectKeyValue(row, values.Writers) ?? '',
            language: autoFill || columns[values.Language] == 'IMDB_Auto_Fill' ? movieIMDB.language : getObjectKeyValue(row, values.Language) ?? '',
            studio: autoFill || columns[values.Studio] == 'IMDB_Auto_Fill' ? movieIMDB.studio : getObjectKeyValue(row, values.Studio) ?? '',
            rating: autoFill || columns[values.Rating] == 'IMDB_Auto_Fill' ? movieIMDB.rating : getObjectKeyValue(row, values.Rating) ?? '',
            imdbid: autoFill || columns[values.IMDB] == 'IMDB_Auto_Fill' ? movieIMDB.imdb : getObjectKeyValue(row, values.IMDB) ?? '',
            tmdbid: autoFill || columns[values.TMDB] == 'IMDB_Auto_Fill' ? movieIMDB.tmdb : getObjectKeyValue(row, values.TMDB) ?? '',
            provider: {
              name: getObjectKeyValue(row, values.Provider) ?? 'No Provider',
            },
            translations: descriptionsOut,
            tags: tagArray ?? [],
            actors: autoFill || columns[values.Name] == 'IMDB_Auto_Fill' ? movieIMDB.actors : actorArray ?? [],
            images: {
              poster: getImage(row, values.poster, values.prefix_url, columns[values.poster] == 'IMDB_Auto_Fill', 'poster', movieIMDB), ///resizing on upload
              background: getImage(row, values.background, values.prefix_url, columns[values.background] == 'IMDB_Auto_Fill', 'background', movieIMDB),
              logo: getImage(row, values.logo, values.prefix_url, columns[values.logo] == 'IMDB_Auto_Fill', 'logo', movieIMDB),
            },
            placement: {
              ut_start: getObjectKeyValue(row, values.placement_start, false) ?? dayjs(values.placement_start_overide).unix() ?? dayjs().unix(),
              ut_end: getObjectKeyValue(row, values.placement_end, false) ?? dayjs(values.placement_end_overide).add(99, 'year').unix() ?? dayjs().add(99, 'year').unix(),
              no_play_on_trial: getObjectKeyValue(row, values.placement_supress_on_trial, true) ?? values.placement_supress_on_trial_overide ?? false,
            },
            parental: {
              enabled: getObjectKeyValue(row, values.parental_control, true) ?? values.parental_control_overide ?? false,
              rating: getObjectKeyValue(row, values.content_rating, true) ?? values.content_rating_overide ?? '',
            },
            security: {
              akamai_token: getObjectKeyValue(row, values.akamai_token, true) ?? values.akamai_token_overide ?? false,
              flussonic_token: getObjectKeyValue(row, values.flussonic_token, true) ?? values.flussonic_token_overide ?? false,
              legacy_token: getObjectKeyValue(row, values.legacy_token, true) ?? values.legacy_token_overide ?? false,
              drm: {
                drm_enabled: getObjectKeyValue(row, values.drm_enabled, true) ?? values.drm_enabled_overide ?? false,
                drm_type: getObjectKeyValue(row, values.drm_provider, false) ?? '',
              },
            },
            payperview: {
              enable: getObjectKeyValue(row, values.payperview_enable, true) ?? false,
              rule: undefined,
              currencies: currenciesOut,
            },
            ads: {
              vast_url: getObjectKeyValue(row, values.vast_url) ?? values.vast_url_overide ?? '',
            },
            streams: {
              movies: {
                url: getObjectKeyValue(row, values.stream_url),
                thumbnails: getObjectKeyValue(row, values.thumbnails) ?? '',
                subtitles: getObjectKeyValue(row, values.subtitles) ?? '',
                trailer: autoFill || columns[values.trailer] == 'IMDB_Auto_Fill' ? movieIMDB.trailer : getObjectKeyValue(row, values.trailer) ?? '',
                allow_downloads: getObjectKeyValue(row, values.allow_downloads) ?? false,
                expiration_downloads: getObjectKeyValue(row, values.expiration_downloads) ?? 365,
              },
            },
          };
          if (append || remove || values._id == 'no_id') {
            delete valuesIn._id;
          }
          if (!general1) {
            delete valuesIn.name;
            delete valuesIn.rating;
            delete valuesIn.released;
            delete valuesIn.language;
            delete valuesIn.runtime;
            delete valuesIn.writers;
            delete valuesIn.language;
            delete valuesIn.studio;
            delete valuesIn.actors;
            delete valuesIn.tags;
            delete valuesIn.imdbid;
            delete valuesIn.tmdbid;
            delete valuesIn.provider;
          }
          if (!languagesDeployment) {
            delete valuesIn.translations;
          }

          if (!parental) {
            delete valuesIn.parental;
          }
          if (!security) {
            delete valuesIn.security;
          }
          if (!provider) {
            delete valuesIn.provider;
          }
          if (!ads) {
            delete valuesIn.ads;
          }
          if (!backgroundimages) {
            delete valuesIn.images;
          }
          if (!moviestream) {
            delete valuesIn.streams;
          }
          if (!provider) {
            delete valuesIn.provider;
          }
          if (!placement) {
            delete valuesIn.placement;
          }
          if (!translations) {
            delete valuesIn.translations;
          }
          if (!ads) {
            delete valuesIn.payperview;
          }
          valuesOut.push(valuesIn);
        } else {
          missedMovies.push({
            imdb: id,
          });
        }
      }

      setNumberOfImports(valuesOut.length);

      values = {
        movies: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.MOVIES.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };

      let response = await cmsMovies.Import_Movies(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        if (index == numberOfImports - 1) {
          notification.success({
            message: 'Added Successfully',
          });
        }
      } else {
        notification.error({
          message: 'An error occured',
        });
      }

      if (missedMovies.length > 0) {
        const workbook = XLSX.utils.book_new();
        const worksheet1 = XLSX.utils.json_to_sheet(missedMovies);
        XLSX.utils.book_append_sheet(workbook, worksheet1, 'Failed Imports');
        XLSX.writeFile(workbook, 'Failed_Imports_Movies_' + clientName + '_' + deployment?.name + '_' + dayjs().format('YYYY-MM-DD') + '.xlsx');
      }
      history.push(url);
      setNumberOfImports(0);
      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };
  const addShorts = async (values: any) => {
    try {
      await getProviders();
      setIsAdding(true);
      let valuesOut = [] as any;
      let index = -1;
      let offset = 0;
      for (let row of excel) {
        index++;
        var id = getObjectKeyValue(row, values.IMDB);
        if (id == undefined) {
          id = getObjectKeyValue(row, values.IMDB_Auto_Fill);
        }

        let tags = getTagsContent(autoFill, languagesDeployment, extraSheets, offset, aitranslation, [], getObjectKeyValue(row, values.Mapping) ?? '');
        let tagArray = tags.tags;
        offset = tags.numberOfTags;
        let currenciesOut = getCurrenciesContent(extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values);
        let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, fillGapsWithIMDB, [], languagesDeployment, index, aitranslation);

        if (getObjectKeyValue(row, values.Provider) != undefined) {
          addProvider(getObjectKeyValue(row, values.Provider));
        }

        var valuesIn: any = {
          _id: getObjectKeyValue(row, values._Id) ?? 'no_id',
          name: getObjectKeyValue(row, values.Name) ?? '',

          studio: getObjectKeyValue(row, values.Studio) ?? '',
          rating: getObjectKeyValue(row, values.Rating) ?? '',
          duration: getObjectKeyValue(row, values.Runtime_Seconds) ?? 0,
          translations: descriptionsOut,
          tags: tagArray ?? [],

          images: {
            widescreen: getImage(row, values.widescreen, values.prefix_url, false, 'widescren'), ///resizing on upload
            background: getImage(row, values.background, values.prefix_url, false, 'background'),
          },
          placement: {
            ut_start: getObjectKeyValue(row, values.placement_start, false) ?? dayjs(values.placement_start_overide).unix() ?? dayjs().unix(),
            ut_end: getObjectKeyValue(row, values.placement_end, false) ?? dayjs(values.placement_end_overide).add(99, 'year').unix() ?? dayjs().add(99, 'year').unix(),
            no_play_on_trial: getObjectKeyValue(row, values.placement_supress_on_trial, true) ?? values.placement_supress_on_trial_overide ?? false,
          },
          provider: {
            name: getObjectKeyValue(row, values.Provider) ?? 'No Provider',
          },
          parental: {
            enabled: getObjectKeyValue(row, values.parental_control, true) ?? values.parental_control_overide ?? false,
            rating: getObjectKeyValue(row, values.content_rating, true) ?? values.content_rating_overide ?? '',
          },
          security: {
            akamai_token: getObjectKeyValue(row, values.akamai_token, true) ?? values.akamai_token_overide ?? false,
            flussonic_token: getObjectKeyValue(row, values.flussonic_token, true) ?? values.flussonic_token_overide ?? false,
            legacy_token: getObjectKeyValue(row, values.legacy_token, true) ?? values.legacy_token_overide ?? false,
            drm: {
              drm_enabled: getObjectKeyValue(row, values.drm_enabled, true) ?? values.drm_enabled_overide ?? false,
              drm_type: getObjectKeyValue(row, values.drm_provider, false) ?? '',
            },
          },
          payperview: {
            enable: getObjectKeyValue(row, values.payperview_enable, true) ?? false,
            rule: undefined,
            currencies: currenciesOut,
          },
          ads: {
            vast_url: getObjectKeyValue(row, values.vast_url) ?? values.vast_url_overide ?? '',
          },
          streams: {
            shorts: {
              url: getObjectKeyValue(row, values.stream_url),
              allow_downloads: getObjectKeyValue(row, values.allow_downloads) ?? false,
              expiration_downloads: getObjectKeyValue(row, values.expiration_downloads) ?? 365,
            },
          },
        };
        if (append || remove || values._id == 'no_id') {
          delete valuesIn._id;
        }
        if (!general1) {
          delete valuesIn.name;
          delete valuesIn.rating;
          delete valuesIn.studio;
          delete valuesIn.tags;
          delete valuesIn.provider;
        }
        if (!languagesDeployment) {
          delete valuesIn.translations;
        }
        if (!parental) {
          delete valuesIn.parental;
        }
        if (!security) {
          delete valuesIn.security;
        }
        if (!provider) {
          delete valuesIn.provider;
        }
        if (!ads) {
          delete valuesIn.ads;
        }
        if (!backgroundimages) {
          delete valuesIn.images;
        }
        if (!moviestream) {
          delete valuesIn.streams;
        }
        if (!placement) {
          delete valuesIn.placement;
        }
        if (!translations) {
          delete valuesIn.translations;
        }
        if (!ads) {
          delete valuesIn.payperview;
        }
        valuesOut.push(valuesIn);
      }

      values = {
        shorts: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.SHORTS.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };

      let response = await cmsShorts.Import_Shorts(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: 'Added Successfully',
        });
        history.push(url);
      } else {
        notification.error({
          message: 'An error occured',
        });
      }
      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };
  const addGames = async (values: any) => {
    try {
      await getProviders();
      setIsAdding(true);
      let valuesOut = [] as any;
      let index = -1;
      for (let row of excel) {
        index++;
        var id = getObjectKeyValue(row, values.IMDB);
        if (id == undefined) {
          id = getObjectKeyValue(row, values.IMDB_Auto_Fill);
        }

        let tags = getTagsContent(autoFill, languagesDeployment, extraSheets, index, aitranslation, undefined);
        let tagArray = tags.tags;
        // let currenciesOut = getCurrenciesContent(extraSheets, currenciesDeployment, index, getObjectKeyValue, row, values);
        let descriptionsOut = getDescriptionsContent(extraSheets, autoFill, false, undefined, languagesDeployment, index, aitranslation);

        if (getObjectKeyValue(row, values.Provider) != undefined) {
          addProvider(getObjectKeyValue(row, values.Provider));
        }

        var valuesIn: any = {
          _id: getObjectKeyValue(row, values._Id) ?? 'no_id',
          name: getObjectKeyValue(row, values.Name) ?? '',

          game_id: getObjectKeyValue(row, values.Game_ID) ?? '',
          game_slug: getObjectKeyValue(row, values.Game_Slug) ?? '',
          game_affiliate: getObjectKeyValue(row, values.Affiliate) ?? '',
          translations: descriptionsOut,
          tags: tagArray ?? [],
          images: {
            widescreen: getImage(row, values.widescreen, values.prefix_url, false, 'widescren'),
          },
          placement: {
            ut_start: getObjectKeyValue(row, values.placement_start, false) ?? dayjs(values.placement_start_overide).unix() ?? dayjs().unix(),
            ut_end: getObjectKeyValue(row, values.placement_end, false) ?? dayjs(values.placement_end_overide).add(99, 'year').unix() ?? dayjs().add(99, 'year').unix(),
            no_play_on_trial: getObjectKeyValue(row, values.placement_supress_on_trial, true) ?? values.placement_supress_on_trial_overide ?? false,
          },
          provider: {
            name: getObjectKeyValue(row, values.Provider) ?? 'No Provider',
          },
        };
        if (append || remove || values._id == 'no_id') {
          delete valuesIn._id;
        }
        if (!general1) {
          delete valuesIn.name;
          delete valuesIn.game_id;
          delete valuesIn.game_slug;
          delete valuesIn.game_provider;
          delete valuesIn.game_affiliate;
          delete valuesIn.tags;
          delete valuesIn.provider;
        }
        if (!languagesDeployment) {
          delete valuesIn.translations;
        }

        if (!provider) {
          delete valuesIn.provider;
        }

        if (!backgroundimages) {
          delete valuesIn.images;
        }

        if (!placement) {
          delete valuesIn.placement;
        }
        if (!translations) {
          delete valuesIn.translations;
        }

        valuesOut.push(valuesIn);
      }

      values = {
        games: valuesOut,
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name).CONTENT.GAMES.IMAGES,
          remove: remove,
          overwrite: overwrite,
          update: update,
          extend: extend,
          addcats: addToCats,
          createcats: createCats,
          bundles: values.bundles,
          avoidDoubles: avoidDoubles,
        },
      };

      let response = await cmsGames.Import_Games(values as any);
      if (response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: 'Added Successfully',
        });
        history.push(url);
      } else {
        notification.error({
          message: 'An error occured',
        });
      }

      setIsAdding(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: 'An error occured',
      });
      setIsAdding(false);
    }
  };

  const getImage = (row: any, index: any, prefix_url: any, autoFillColumn?: boolean, type?: any, content?: any) => {
    if ((autoFill || autoFillColumn) && content != undefined) {
      if (type == 'poster') {
        return content.poster;
      }
      if (type == 'background') {
        return content.background;
      }
      if (type == 'logo') {
        return content.logo;
      }
      if (type == 'series') {
        return content.background;
      }
      if (type == 'episode') {
        return content.episode_image;
      }
    } else {
      if (prefix_url == undefined) {
        if (getObjectKeyValue(row, index) == undefined) {
          return '';
        } else {
          return getObjectKeyValue(row, index);
        }
      } else {
        if (getObjectKeyValue(row, index) == undefined) {
          return '';
        } else {
          return prefix_url + '/' + getObjectKeyValue(row, index);
        }
      }
    }
  };
  const optionsDivs = (key_: any, index: any, columns: any, addition: string) => {
    switch (key_.name) {
      case 'Guide':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{ads ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={epg} defaultChecked={false} onChange={changeEpg} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {epg && (
              <>
                <div key={index} className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('epg-id')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'epg_url_id' + addition} initialValue={getDefaulValueExcelColumn('epg_url_id')}>
                      <Select defaultValue={getDefaulValueExcelColumn('epg_url_id')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div key={index} className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('epg-channel')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'epg_url_channel' + addition} initialValue={getDefaulValueExcelColumn('epg_url_channel')}>
                      <Select defaultValue={getDefaulValueExcelColumn('epg_url_channel')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Placement':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{ads ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={placement} defaultChecked={false} onChange={changePlacement} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {placement && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('start-time')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'placement_start' + addition} initialValue={getDefaulValueExcelColumn('placement_start')}>
                      <Select defaultValue={getDefaulValueExcelColumn('placement_start')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'placement_start_overide' + addition}>
                        <DatePicker showToday className="form-control height-48 bg-white font-size-14 text-default" />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('end-time')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'placement_end' + addition} initialValue={getDefaulValueExcelColumn('placement_end')}>
                      <Select defaultValue={getDefaulValueExcelColumn('placement_end')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'placement_end_overide' + addition}>
                        <DatePicker showToday className="form-control height-48 bg-white font-size-14 text-default" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('surpress-playing-on-trials')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'placement_supress_on_trial' + addition} initialValue={getDefaulValueExcelColumn('hide_on_trial')}>
                      <Select defaultValue={getDefaulValueExcelColumn('hide_on_trial')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'placement_supress_on_trial_override' + addition}>
                        <Switch className="ch-switch" defaultChecked={false} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Monetizing':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{ads ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={ads} defaultChecked={false} onChange={changeAds} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {ads && (
              <>
                {selectedContent != 'Apps' && selectedContent != 'Albums' && selectedContent != 'Podcasts' && selectedContent != 'Radio' && (
                  <div className="row pb-5">
                    <label className="col-md-3 col-form-label">{t('vast-url')}</label>
                    <div className="col-md-5">
                      <Form.Item name={'vast_url' + addition} initialValue={getDefaulValueExcelColumn('vast_url')}>
                        <Select defaultValue={getDefaulValueExcelColumn('vast_url')} className="default-select" defaultActiveFirstOption={true}>
                          <Select.Option value={'Use Override'} key={999999}>
                            {t('use-override')}
                          </Select.Option>
                          {columns.map((el: any, index: any) => {
                            if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                              return null;
                            }
                            return (
                              <Select.Option value={index} key={index}>
                                {el}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                      <Form.Item name={'vast_url_overide' + addition}>
                        <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com" />
                      </Form.Item>
                    </div>
                  </div>
                )}
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('enable-pay-per-view')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'payperview_enable' + addition} initialValue={getDefaulValueExcelColumn('payperview_enabled')}>
                      <Select defaultValue={getDefaulValueExcelColumn('payperview_enabled')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <Form.Item name={'payperview_enable_overide' + addition}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('rule-_id')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'payperview_ruleid' + addition} initialValue={getDefaulValueExcelColumn('payperview_ruleid')}>
                      <Select defaultValue={getDefaulValueExcelColumn('payperview_ruleid')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <Form.Item name={'payperview_ruleid_overide' + addition}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com" />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Security':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{security ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={security} defaultChecked={false} onChange={changeSecurity} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>

            {security && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('enable-akamai-token')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'akamai_token' + addition} initialValue={getDefaulValueExcelColumn('akamai_token')}>
                      <Select defaultValue={getDefaulValueExcelColumn('akamai_token')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 20, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'akamai_token_override' + addition} initialValue={false}>
                        <Switch className="ch-switch" defaultChecked={false} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('enable-flussonic-token')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'flussonic_token' + addition} initialValue={getDefaulValueExcelColumn('flussonic_token')}>
                      <Select defaultValue={getDefaulValueExcelColumn('flussonic_token')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'flussonic_token_override' + addition} initialValue={false}>
                        <Switch className="ch-switch" defaultChecked={false} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div key={index} className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('enable-legacy-token')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'legacy_token' + addition} initialValue={getDefaulValueExcelColumn('legacy_token')}>
                      <Select defaultValue={getDefaulValueExcelColumn('legacy_token')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'legacy_token_override' + addition} initialValue={false}>
                        <Switch className="ch-switch" defaultChecked={false} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('enable-drm')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'drm_enabled' + addition} initialValue={getDefaulValueExcelColumn('drm_enabled')}>
                      <Select defaultValue={getDefaulValueExcelColumn('drm_enabled')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'drm_enabled_overide'} initialValue={false}>
                        <Switch className="ch-switch" defaultChecked={false} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('drm-provider')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'drm_provider' + addition} initialValue={getDefaulValueExcelColumn('drm_type')}>
                      <Select defaultValue={getDefaulValueExcelColumn('drm_type')} className="default-select" placeholder="- Select Provider -" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'drm_provider_override' + addition}>
                        <Select className="default-select" placeholder={t('select-provider')} defaultActiveFirstOption={true}>
                          <Select.Option key={'buydrm'} value={'buydrm'}>
                            BuyDRM
                          </Select.Option>
                          <Select.Option key={'irdeto'} value={'irdeto'}>
                            Irdeto
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'RTV Streams':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{rtvstream ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={rtvstream} defaultChecked={false} onChange={changeRtvStream} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>

            {rtvstream && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('standard')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'standard_url' + addition} initialValue={getDefaulValueExcelColumn('standard_url_stream')}>
                      <Select defaultValue={getDefaulValueExcelColumn('standard_url_stream')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">iOS/tvOS</label>
                  <div className="col-md-5">
                    <Form.Item name={'ios_tvos' + addition} initialValue={getDefaulValueExcelColumn('ios_tvos_stream')}>
                      <Select defaultValue={getDefaulValueExcelColumn('ios_tvos_stream')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">Tizen/Webos</label>
                  <div className="col-md-5">
                    <Form.Item name={'tizen_webos' + addition} initialValue={getDefaulValueExcelColumn('tizen_webos_stream')}>
                      <Select defaultValue={getDefaulValueExcelColumn('tizen_webos_stream')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('query-params')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'params' + addition} initialValue={getDefaulValueExcelColumn('params')}>
                      <Select defaultValue={getDefaulValueExcelColumn('params')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Provider':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{provider ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={provider} defaultChecked={false} onChange={changeProvider} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {provider && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('stream-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'provider' + addition} initialValue={getDefaulValueExcelColumn('provider')}>
                      <Select defaultValue={getDefaulValueExcelColumn('provider')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Movie Streams':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{moviestream ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={moviestream} defaultChecked={false} onChange={changeMovieStream} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {moviestream && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('stream-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'stream_url' + addition} initialValue={getDefaulValueExcelColumn('movie_stream')}>
                      <Select defaultValue={getDefaulValueExcelColumn('movie_stream')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('trailer-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'trailer' + addition} initialValue={getDefaulValueExcelColumn('movie_trailer')}>
                      <Select defaultValue={getDefaulValueExcelColumn('movie_trailer')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('thumbnail-vtt-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'thumbnail' + addition} initialValue={getDefaulValueExcelColumn('movie_thumbnail')}>
                      <Select defaultValue={getDefaulValueExcelColumn('movie_thumbnail')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('subtitle-vtt-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'subtitle' + addition} initialValue={getDefaulValueExcelColumn('movie_subtitle')}>
                      <Select defaultValue={getDefaulValueExcelColumn('movie_subtitle')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('allow-downloads')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'allow_downloads' + addition} initialValue={getDefaulValueExcelColumn('movie_allow_downloads')}>
                      <Select defaultValue={getDefaulValueExcelColumn('movie_allow_downloads')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('expiration-downloads')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'expiration_downloads' + addition} initialValue={getDefaulValueExcelColumn('movie_expiration_downloads')}>
                      <Select defaultValue={getDefaulValueExcelColumn('movie_expiration_downloads')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Short Streams':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{moviestream ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={moviestream} defaultChecked={false} onChange={changeMovieStream} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {moviestream && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('stream-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'stream_url' + addition} initialValue={getDefaulValueExcelColumn('short_stream')}>
                      <Select defaultValue={getDefaulValueExcelColumn('short_stream')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('thumbnail-vtt-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'thumbnail' + addition} initialValue={getDefaulValueExcelColumn('short_thumbnail')}>
                      <Select defaultValue={getDefaulValueExcelColumn('short_thumbnail')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('subtitle-vtt-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'subtitle' + addition} initialValue={getDefaulValueExcelColumn('short_subtitle')}>
                      <Select defaultValue={getDefaulValueExcelColumn('short_subtitle')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('allow-downloads')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'allow_downloads' + addition} initialValue={getDefaulValueExcelColumn('short_allow_downloads')}>
                      <Select defaultValue={getDefaulValueExcelColumn('short_allow_downloads')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('expiration-downloads')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'expiration_downloads' + addition} initialValue={getDefaulValueExcelColumn('short_expiration_downloads')}>
                      <Select defaultValue={getDefaulValueExcelColumn('short_expiration_downloads')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Lesson Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{singlestream ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={singlestream} defaultChecked={false} onChange={changeSingleStream} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {singlestream && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('stream-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'stream_url' + addition} initialValue={getDefaulValueExcelColumn('lesson_stream')}>
                      <Select defaultValue={getDefaulValueExcelColumn('lesson_stream')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('thumbnail-vtt-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'thumbnail' + addition} initialValue={getDefaulValueExcelColumn('lesson_thumbnail')}>
                      <Select defaultValue={getDefaulValueExcelColumn('lesson_thumbnail')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('subtitle-vtt-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'subtitle' + addition} initialValue={getDefaulValueExcelColumn('lesson_subtitle')}>
                      <Select defaultValue={getDefaulValueExcelColumn('lesson_subtitle')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('allow-downloads')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'allow_downloads' + addition} initialValue={getDefaulValueExcelColumn('lesson_allow_downloads')}>
                      <Select defaultValue={getDefaulValueExcelColumn('lesson_allow_downloads')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('expiration-downloads')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'expiration_downloads' + addition} initialValue={getDefaulValueExcelColumn('lesson_expiration_downloads')}>
                      <Select defaultValue={getDefaulValueExcelColumn('lesson_expiration_downloads')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Episode Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{singlestream ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={singlestream} defaultChecked={false} onChange={changeSingleStream} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>

            {singlestream && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('stream-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'stream_url' + addition} initialValue={getDefaulValueExcelColumn1('stream')}>
                      <Select defaultValue={getDefaulValueExcelColumn1('stream')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('thumbnail-vtt-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'thumbnail' + addition} initialValue={getDefaulValueExcelColumn('episode_thumbnail')}>
                      <Select defaultValue={getDefaulValueExcelColumn('episode_thumbnail')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('subtitle-vtt-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'subtitle' + addition} initialValue={getDefaulValueExcelColumn('episode_subtitle')}>
                      <Select defaultValue={getDefaulValueExcelColumn('episode_subtitle')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('allow-downloads')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'allow_downloads' + addition} initialValue={getDefaulValueExcelColumn('episode_allow_downloads')}>
                      <Select defaultValue={getDefaulValueExcelColumn('episode_allow_downloads')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('expiration-downloads')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'expiration_downloads' + addition} initialValue={getDefaulValueExcelColumn('episode_expiration_downloads')}>
                      <Select defaultValue={getDefaulValueExcelColumn('episode_expiration_downloads')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Song Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{singlestream ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={singlestream} defaultChecked={false} onChange={changeSingleStream} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>

            {singlestream && (
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('stream-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'stream_url' + addition} initialValue={getDefaulValueExcelColumn('stream')}>
                    <Select defaultValue={getDefaulValueExcelColumn('stream')} className="default-select" defaultActiveFirstOption={true}>
                      {columns.map((el: any, index: any) => {
                        if (el == 'IMDB_Auto_Fill') {
                          return null;
                        }
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            )}
          </div>
        );
      case 'Podcast Stream Url':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{singlestream ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={singlestream} defaultChecked={false} onChange={changeSingleStream} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>

            {singlestream && (
              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('stream-url')}</label>
                <div className="col-md-5">
                  <Form.Item name={'stream_url' + addition} initialValue={getDefaulValueExcelColumn('stream')}>
                    <Select defaultValue={getDefaulValueExcelColumn('stream')} className="default-select" defaultActiveFirstOption={true}>
                      {columns.map((el: any, index: any) => {
                        if (el == 'IMDB_Auto_Fill') {
                          return null;
                        }
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            )}
          </div>
        );
      case 'Description Episodes':
        return (
          <div key={index + 'options'}>
            {!autoFill && (
              <div className="card-bg-light p-5 mb-3 bg-white">
                {extraSheets.length == 0 && (
                  <div key={index} className="row pb-5">
                    <div className="row-md-12">
                      <h4 className="text-primary">{key_.name}</h4>
                      <p>{t('should-the-offered-description-in-the-excel-not-match-the-needed-languages-in-the-deployment-we-will-use-this-description-and-your-chosen-language-to-translate-to-the-correct-description-this-only-works-if-you-have-the-ai-translation-option-switch-on-else-we-will-use-the-first-description-and-import-this')}</p>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <label className="col-md-3 col-form-label">{t('description-episodes')}</label>
                        <div className="col-md-5">
                          <Form.Item name={'description_episodes' + addition} initialValue={getDefaulValueExcelColumn('description')}>
                            <Select defaultValue={getDefaulValueExcelColumn('description')} className="default-select" defaultActiveFirstOption={true}>
                              {columns.map((el: any, index: any) => {
                                if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                  return null;
                                }
                                return (
                                  <Select.Option value={index} key={index}>
                                    {el}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <label className="col-md-3 col-form-label">{t('description-language')}</label>
                        <div className="col-md-5">
                          <Form.Item name={'description_language_episodes' + addition} initialValue={getDefaulValueExcelColumn('description_language')}>
                            <Select defaultValue={getDefaulValueExcelColumn('description_language')} className="default-select" defaultActiveFirstOption={true}>
                              {languagesDeployment.map((el: any, index: any) => {
                                if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                  return null;
                                }
                                return (
                                  <Select.Option value={el.name} key={el.name}>
                                    {el.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {extraSheets.length > 0 && (
                  <div className="row">
                    <div className="col-md-9">
                      <h4 className="text-primary">{key_.name}</h4>
                      <p>{t('no-mapping-needed-the-importer-will-automatically-read-your-second-excel-sheet-for-the-available-languages-and-import-the-descriptions-accordingly-should-the-offered-description-in-the-excel-not-match-the-needed-languages-in-the-deployment-we-will-use-the-first-description-and-translate-to-the-correct-language-this-is-if-you-have-the-ai-translation-option-switch-on-else-we-will-use-the-first-description-and-import-this')}</p>
                    </div>

                    {update && (
                      <div className="col-md-3 text-right">
                        <Form.Item name={'delete_content' + addition}>
                          <div className="row">
                            <div className="col mt-2">{translationsEpisodes ? 'Enabled' : 'Disabled'}</div>
                            <div>
                              <Switch className="ch-switch" checked={translationsEpisodes} defaultChecked={false} onChange={changeTranslationsEpisodes} />
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      case 'Descriptions':
        return (
          <div key={index + 'options'}>
            {!autoFill && (
              <div className="card-bg-light p-5 mb-3 bg-white">
                {extraSheets.length == 0 && (
                  <div key={index} className="row pb-5">
                    <div className="row-md-12">
                      <h4 className="text-primary">{key_.name}</h4>
                      <p>{t('should-the-offered-description-in-the-excel-not-match-the-needed-languages-in-the-deployment-we-will-use-this-description-and-your-chosen-language-to-translate-to-the-correct-description-this-only-works-if-you-have-the-ai-translation-option-switch-on-else-we-will-use-the-first-description-and-import-this')}</p>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <label className="col-md-3 col-form-label">{t('descriptions')}</label>
                        <div className="col-md-5">
                          <Form.Item name={'description' + addition} initialValue={getDefaulValueExcelColumn('description')}>
                            <Select defaultValue={getDefaulValueExcelColumn('description')} className="default-select" defaultActiveFirstOption={true}>
                              {columns.map((el: any, index: any) => {
                                if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                  return null;
                                }
                                return (
                                  <Select.Option value={index} key={index}>
                                    {el}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <label className="col-md-3 col-form-label">{t('description-language')}</label>
                        <div className="col-md-5">
                          <Form.Item name={'description_language' + addition} initialValue={getDefaulValueExcelColumn('description_language')}>
                            <Select defaultValue={getDefaulValueExcelColumn('description_language')} className="default-select" defaultActiveFirstOption={true}>
                              {languagesDeployment.map((el: any, index: any) => {
                                if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                  return null;
                                }
                                return (
                                  <Select.Option value={el.name} key={el.name}>
                                    {el.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {extraSheets.length > 0 && (
                  <div className="row">
                    <div className="col-md-9">
                      <h4 className="text-primary">{key_.name}</h4>
                      <p>{t('no-mapping-needed-the-importer-will-automatically-read-your-second-excel-sheet-for-the-available-languages-and-import-the-descriptions-accordingly-should-the-offered-description-in-the-excel-not-match-the-needed-languages-in-the-deployment-we-will-use-the-first-description-and-translate-to-the-correct-language-this-is-if-you-have-the-ai-translation-option-switch-on-else-we-will-use-the-first-description-and-import-this')}</p>
                    </div>

                    {update && (
                      <div className="col-md-3 text-right">
                        <Form.Item name={'delete_content' + addition}>
                          <div className="row">
                            <div className="col mt-2">{translations ? 'Enabled' : 'Disabled'}</div>
                            <div>
                              <Switch className="ch-switch" checked={translations} defaultChecked={false} onChange={changeTranslations} />
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      case 'InteractiveTV':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{itv ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={itv} defaultChecked={false} onChange={changeItv} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {itv && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('enable-catchuptv')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'catchuptv_enabled' + addition} initialValue={getDefaulValueExcelColumn('catchuptv_enabled')}>
                      <Select defaultValue={getDefaulValueExcelColumn('catchuptv_enabled')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'catchuptv_enabled_overide' + addition}>
                        <Switch className="ch-switch" defaultChecked={false} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('enable-recordings')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'recordings_enabled' + addition} initialValue={getDefaulValueExcelColumn('recordings_enabled')}>
                      <Select defaultValue={getDefaulValueExcelColumn('recordings_enabled')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'recordings_enabled_overide' + addition}>
                        <Switch className="ch-switch" defaultChecked={false} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('transcoder-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'transcoder_id' + addition} initialValue={getDefaulValueExcelColumn('transcoder_id')}>
                      <Select defaultValue={getDefaulValueExcelColumn('transcoder_id')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'transcoder_id_overide' + addition}>
                        <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('enable-pausetv')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'pausetv_enabled' + addition} initialValue={getDefaulValueExcelColumn('pausetv_enabled')}>
                      <Select defaultValue={getDefaulValueExcelColumn('pausetv_enabled')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'pausetv_enabled_overide' + addition}>
                        <Switch className="ch-switch" defaultChecked={false} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('alternative-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'alternative_url' + addition} initialValue={getDefaulValueExcelColumn('alternative_url')}>
                      <Select defaultValue={getDefaulValueExcelColumn('alternative_url')} className="default-select" defaultActiveFirstOption={true}>
                        <Select.Option value={'Use Override'} key={999999}>
                          {t('use-override')}
                        </Select.Option>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                    <div>
                      <Form.Item name={'alternative_url_overide' + addition}>
                        <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'RTV Images':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{rtvimages ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={rtvimages} defaultChecked={false} onChange={changeRtvImages} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {rtvimages && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('square-image')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'icon' + addition} initialValue={getDefaulValueExcelColumn('square_image')}>
                      <Select defaultValue={getDefaulValueExcelColumn('square_image')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('tile-image')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'tile' + addition} initialValue={getDefaulValueExcelColumn('tile_image')}>
                      <Select defaultValue={getDefaulValueExcelColumn('tile_image')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Radio Images':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{rtvimages ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={rtvimages} defaultChecked={false} onChange={changeRtvImages} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {rtvimages && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('square-image')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'icon' + addition} initialValue={getDefaulValueExcelColumn('square_image')}>
                      <Select defaultValue={getDefaulValueExcelColumn('square_image')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('tile-image')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'tile' + addition} initialValue={getDefaulValueExcelColumn('tile_image')}>
                      <Select defaultValue={getDefaulValueExcelColumn('tile_image')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('background-image')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'background' + addition} initialValue={getDefaulValueExcelColumn('background_image')}>
                      <Select defaultValue={getDefaulValueExcelColumn('background_image')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Poster & Backdrop Images':
        return (
          <div key={index + 'options'}>
            {!autoFill && (
              <div className="card-bg-light p-5 mb-3 bg-white">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="text-primary">{key_.name}</h4>
                  </div>
                  {update && (
                    <div className="col-md-6 text-right">
                      <Form.Item name={'delete_content' + addition}>
                        <div className="row">
                          <div className="col mt-2">{backgroundimages ? 'Enabled' : 'Disabled'}</div>
                          <div>
                            <Switch className="ch-switch" checked={backgroundimages} defaultChecked={false} onChange={changeBackdropImages} />
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  )}
                </div>
                {backgroundimages && (
                  <>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('poster-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'poster' + addition} initialValue={getDefaulValueExcelColumn('poster_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('poster_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('background-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'background' + addition} initialValue={getDefaulValueExcelColumn('background_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('background_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    {/* <div className="row pb-5">
                      <label className="col-md-3 col-form-label">Logo Image</label>
                      <div className="col-md-5">
                        <Form.Item name={'logo' + addition} initialValue={getDefaulValueExcelColumn('logo_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('logo_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div> */}

                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                          <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        );
      case 'Logo, Poster & Backdrop Images':
        return (
          <div key={index + 'options'}>
            {!autoFill && (
              <div className="card-bg-light p-5 mb-3 bg-white">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="text-primary">{key_.name}</h4>
                  </div>
                  {update && (
                    <div className="col-md-6 text-right">
                      <Form.Item name={'delete_content' + addition}>
                        <div className="row">
                          <div className="col mt-2">{backgroundimages ? 'Enabled' : 'Disabled'}</div>
                          <div>
                            <Switch className="ch-switch" checked={backgroundimages} defaultChecked={false} onChange={changeBackdropImages} />
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  )}
                </div>
                {backgroundimages && (
                  <>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('poster-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'poster' + addition} initialValue={getDefaulValueExcelColumn('poster_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('poster_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('background-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'background' + addition} initialValue={getDefaulValueExcelColumn('background_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('background_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('logo-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'logo' + addition} initialValue={getDefaulValueExcelColumn('logo_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('logo_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                          <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        );
      case 'Widescreen & Backdrop Images':
        return (
          <div key={index + 'options'}>
            {!autoFill && (
              <div className="card-bg-light p-5 mb-3 bg-white">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="text-primary">{key_.name}</h4>
                  </div>
                  {update && (
                    <div className="col-md-6 text-right">
                      <Form.Item name={'delete_content' + addition}>
                        <div className="row">
                          <div className="col mt-2">{backgroundimages ? 'Enabled' : 'Disabled'}</div>
                          <div>
                            <Switch className="ch-switch" checked={backgroundimages} defaultChecked={false} onChange={changeBackdropImages} />
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  )}
                </div>
                {backgroundimages && (
                  <>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('background-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'background' + addition} initialValue={getDefaulValueExcelColumn('background_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('background_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'widescreen' + addition} initialValue={getDefaulValueExcelColumn('widescreen_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('widescreen_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                          <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        );

      case 'Poster, Backdrop & Widescreen Images':
        return (
          <div key={index + 'options'}>
            {!autoFill && (
              <div className="card-bg-light p-5 mb-3 bg-white">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="text-primary">{key_.name}</h4>
                  </div>
                  {update && (
                    <div className="col-md-6 text-right">
                      <Form.Item name={'delete_content' + addition}>
                        <div className="row">
                          <div className="col mt-2">{backgroundimages ? 'Enabled' : 'Disabled'}</div>
                          <div>
                            <Switch className="ch-switch" checked={backgroundimages} defaultChecked={false} onChange={changeBackdropImages} />
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  )}
                </div>
                {backgroundimages && (
                  <>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('poster-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'poster' + addition} initialValue={getDefaulValueExcelColumn('poster_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('poster_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('background-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'background' + addition} initialValue={getDefaulValueExcelColumn('background_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('background_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'widescreen' + addition} initialValue={getDefaulValueExcelColumn('widescreen_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('widescreen_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                          <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        );

      case 'Icon Images':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{iconimages ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={iconimages} defaultChecked={false} onChange={changeIconImages} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {iconimages && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('square-image')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'icon' + addition} initialValue={getDefaulValueExcelColumn('square_image')}>
                      <Select defaultValue={getDefaulValueExcelColumn('square_image')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Level Image':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{iconimages ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={iconimages} defaultChecked={false} onChange={changeIconImages} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {iconimages && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'course' + addition} initialValue={getDefaulValueExcelColumn('course')}>
                      <Select defaultValue={getDefaulValueExcelColumn('course')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div key={index} className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Year Image':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{iconimages ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={iconimages} defaultChecked={false} onChange={changeIconImages} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {iconimages && (
              <>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('widescreen-image')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'course' + addition} initialValue={getDefaulValueExcelColumn('course')}>
                      <Select defaultValue={getDefaulValueExcelColumn('course')} className="default-select" defaultActiveFirstOption={true}>
                        {columns.map((el: any, index: any) => {
                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                            return null;
                          }
                          return (
                            <Select.Option value={index} key={index}>
                              {el}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="row pb-5">
                  <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                      <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      case 'Series Image':
        return (
          <div key={index + 'options'}>
            {!autoFill && (
              <div className="card-bg-light p-5 mb-3 bg-white">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="text-primary">{key_.name}</h4>
                  </div>
                  {update && (
                    <div className="col-md-6 text-right">
                      <Form.Item name={'delete_content' + addition}>
                        <div className="row">
                          <div className="col mt-2">{storeimages ? 'Enabled' : 'Disabled'}</div>
                          <div>
                            <Switch className="ch-switch" checked={storeimages} defaultChecked={false} onChange={changeStoreImages} />
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  )}
                </div>
                {storeimages && (
                  <>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('widscreen-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'series_widescreen' + addition} initialValue={getDefaulValueExcelColumn('widescreen_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('widescreen_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('logo-image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'series_logo' + addition} initialValue={getDefaulValueExcelColumn('logo_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn('logo_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                          <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        );
      case 'Lesson Image':
        return (
          <div key={index + 'options'}>
            {!autoFill && (
              <div className="card-bg-light p-5 mb-3 bg-white">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="text-primary">{key_.name}</h4>
                  </div>
                  {update && (
                    <div className="col-md-6 text-right">
                      <Form.Item name={'delete_content' + addition}>
                        <div className="row">
                          <div className="col mt-2">{storeimages ? 'Enabled' : 'Disabled'}</div>
                          <div>
                            <Switch className="ch-switch" checked={storeimages} defaultChecked={false} onChange={changeStoreImages} />
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  )}
                </div>
                {storeimages && (
                  <>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'lesson' + addition} initialValue={getDefaulValueExcelColumn1('widescreen_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn1('widescreen_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                          <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        );
      case 'Episode Image':
        return (
          <div key={index + 'options'}>
            {!autoFill && (
              <div className="card-bg-light p-5 mb-3 bg-white">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="text-primary">{key_.name}</h4>
                  </div>
                  {update && (
                    <div className="col-md-6 text-right">
                      <Form.Item name={'delete_content' + addition}>
                        <div className="row">
                          <div className="col mt-2">{episodeimages ? 'Enabled' : 'Disabled'}</div>
                          <div>
                            <Switch className="ch-switch" checked={episodeimages} defaultChecked={false} onChange={changeEpisodeImages} />
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  )}
                </div>
                {episodeimages && (
                  <>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('image')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'episode' + addition} initialValue={getDefaulValueExcelColumn1('widescreen_image')}>
                          <Select defaultValue={getDefaulValueExcelColumn1('widescreen_image')} className="default-select" defaultActiveFirstOption={true}>
                            {columns.map((el: any, index: any) => {
                              if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                return null;
                              }
                              return (
                                <Select.Option value={index} key={index}>
                                  {el}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <label className="col-md-3 col-form-label">{t('prefix-image-url')}</label>
                      <div className="col-md-5">
                        <Form.Item name={'prefix_url' + addition} extra={t('use-this-option-if-your-images-do-not-have-full-url-where-to-download-them-from')}>
                          <Input className="form-control bg-white font-size-14 text-default" placeholder="https://streams.com/" />
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        );
      case 'Parental Control':
        return (
          <div className="card-bg-light p-5 mb-3 bg-white" key={index + 'options'}>
            <div className="row">
              <div className="col-md-6">
                <h4 className="text-primary">{key_.name}</h4>
              </div>
              {update && (
                <div className="col-md-6 text-right">
                  <Form.Item name={'delete_content' + addition}>
                    <div className="row">
                      <div className="col mt-2">{parental ? 'Enabled' : 'Disabled'}</div>
                      <div>
                        <Switch className="ch-switch" checked={parental} defaultChecked={false} onChange={changeParental} />
                      </div>
                    </div>
                  </Form.Item>
                </div>
              )}
            </div>
            {parental && (
              <>

              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('parental-control')}</label>
                <div className="col-md-5">
                  <Form.Item name={'parental_control' + addition} initialValue={getDefaulValueExcelColumn('parental_enabled')}>
                    <Select.Option value={'Use Override'} key={999999}>
                      {t('use-override')}
                    </Select.Option>
                    <Select defaultValue={getDefaulValueExcelColumn('parental_enabled')} className="default-select" defaultActiveFirstOption={true}>
                      {columns.map((el: any, index: any) => {
                        if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                          return null;
                        }
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'parental_control_overide' + addition}>
                      <Switch className="ch-switch" defaultChecked={false} />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="row pb-5">
                <label className="col-md-3 col-form-label">{t('content-rating')}</label>
                <div className="col-md-5">
                  <Form.Item name={'content_rating' + addition} initialValue={getDefaulValueExcelColumn('content_rating')}>
                    <Select.Option value={'Use Override'} key={999999}>
                      {t('use-override')}
                    </Select.Option>
                    <Select defaultValue={getDefaulValueExcelColumn('content_rating')} className="default-select" defaultActiveFirstOption={true}>
                      {columns.map((el: any, index: any) => {
                        if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                          return null;
                        }
                        return (
                          <Select.Option value={index} key={index}>
                            {el}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <div style={{ fontSize: 18, marginBottom: 25, marginLeft: 20 }}>OR</div>
                  <div>
                    <Form.Item name={'content_rating_overide' + addition}>
                    <Input className="form-control bg-white font-size-14 text-default" placeholder="" />
                    </Form.Item>
                  </div>
                </div>
              </div>

              </>
            )}
          </div>
        );
      default:
        break;
    }
  };

  const changeDelete = (event: any) => {
    setRemove(event);
    if (event == true) {
      changeElements(true);
      setAppend(false);
      setOverwrite(false);
      setUpdate(false);
      setExtend(false);
    }
    if (event == false) {
      setAppend(true);
    }
  };
  const changeOverwrite = (event: any) => {
    setOverwrite(event);
    if (event == true) {
      changeElements(true);
      setAppend(false);
      setUpdate(false);
      setExtend(false);
      setRemove(false);
    }
    if (event == false) {
      setAppend(true);
    }
  };
  const changeAppend = (event: any) => {
    setAppend(event);
    if (event == true) {
      changeElements(true);
      setOverwrite(false);
      setUpdate(false);
      setExtend(false);
      setRemove(false);
    }
    if (event == false) {
      changeElements(true);
      setAppend(false);
    }
  };
  const changeUpdate = (event: any) => {
    setUpdate(event);
    if (event == true) {
      changeElements(false);
      setOverwrite(false);
      setAppend(false);
      setExtend(false);
      setRemove(false);
    }
    if (event == false) {
      changeElements(true);
      setAppend(true);
    }
  };
  const changeExtend = (event: any) => {
    setExtend(event);
    if (event == true) {
      changeElements(true);
      setOverwrite(false);
      setAppend(false);
      setUpdate(false);
      setRemove(false);
    }
    if (event == false) {
      changeElements(true);
      setAppend(true);
    }
  };

  const changeElements = (event) => {
    changeAds(event);
    changePlacement(event);
    changeEpg(event);
    changeSecurity(event);
    changeMovieStream(event);
    changeRtvStream(event);
    changeSingleStream(event);
    changeRtvImages(event);
    changeBackdropImages(event);
    changeIconImages(event);
    changeStoreImages(event);
    changeParental(event);
    changeTranslations(event);
    changeItv(event);
    changeGeneral1(event);
    changeGeneral2(event);
    changeGeneral3(event);
    changeEpisodeImages(event);
    changeTranslationsEpisodes(event);
  };

  const getDefaulValueExcelColumn = (value: any) => {
    var search = value.toLowerCase().toLowerCase().replace(' ', '_');
    var item = columns.findIndex((col) => col == search);
    if (item == -1) {
      return getDefaulValueExcelColumn1(value);
    } else {
      return item;
    }
  };
  const getDefaulValueExcelColumn1 = (value: any) => {
    var search = value.toLowerCase().toLowerCase().replace(' ', '_');
    var item = columns1.findIndex((col) => col == search);
    if (item == -1) {
      return getDefaulValueExcelColumn2(value);
    } else {
      return item;
    }
  };
  const getDefaulValueExcelColumn2 = (value: any) => {
    var search = value.toLowerCase().toLowerCase().replace(' ', '_');
    var item = columns2.findIndex((col) => col == search);
    if (item > -1) {
      return item;
    }
  };

  const getAlbumBundles = async () => {
    let response = await cmsAlbumsBundles.List_Album_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };
  const getAppBundles = async () => {
    let response = await cmsAppsBundles.List_App_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };


  const getChannelsBundles = async () => {
    let response = await cmsChannelsBundles.List_Channel_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };
  const getRadioBundles = async () => {
    let response = await cmsRadiosBundles.List_Radio_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };
  const getMovieBundles = async () => {
    let response = await cmsMoviesBundles.List_Movie_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };
  const getSeriesBundles = async () => {
    let response = await cmsSeriesBundles.List_Series_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };
  const getCoursesBundles = async () => {
    let response = await cmsCoursesBundles.List_Course_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };
  const getPodcastsBundles = async () => {
    let response = await cmsPodcastsBundles.List_Podcast_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };
  const getShortsBundles = async () => {
    let response = await cmsShortsBundles.List_Shorts_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };
  const getGamesBundles = async () => {
    let response = await cmsGamesBundles.List_Games_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };
  const getCamsBundles = async () => {
    let response = await cmsCamsBundles.List_Cam_Bundless({
      pageNumber: 1,
      pageSize: 250,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var result = response.data as any;
      var bundles_ = [] as any;
      result?.forEach((data) => {
        bundles_.push({
          label: data.name,
          value: data._id,
        });
      });
      setBundles(bundles_);
    }
  };

  useEffect(() => {
    switch (selectedContent) {
      case 'Albums':
        (async () => await getAlbumBundles())();
        break;
        case 'Apps':
          (async () => await getAppBundles())();
          break;
      case 'Podcasts':
        (async () => await getPodcastsBundles())();
        break;
      case 'Channels':
        (async () => await getChannelsBundles())();
        break;
      case 'Radio':
        (async () => await getRadioBundles())();
        break;
      case 'Movies':
        (async () => await getMovieBundles())();
        break;
      case 'Shorts':
        (async () => await getShortsBundles())();
        break;
      case 'Games':
        (async () => await getGamesBundles())();
        break;
      case 'Courses':
        (async () => await getCoursesBundles())();
        break;
      case 'Series':
        (async () => await getSeriesBundles())();
        break;
      case 'Cams':
        (async () => await getCamsBundles())();
        break;
    }
  }, [selectedContent]);

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12">
          <h2 className="page-title">{t('excel-import')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('import-your-content-via-excel-files-through-an-easy-to-use-mapping-tool')}</p>
        </div>
      </div>
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <div className="card-bg-light pl-5 pt-4 mt-4">
              <h4 className="text-primary mb-2" style={{ marginTop: 30 }}>
                {t('how-does-it-work')}
              </h4>
              <div className="col mb-5 mt-4" style={{ paddingLeft: 0 }}>
                <div className="col mb-2" style={{ paddingLeft: 0 }}>
                  <div>
                    <b>{t('file-types')}</b>
                  </div>
                  <div>{t('we-only-accept-excel-xlsx-documents-for-importing')}</div>
                </div>
                <div className="col mb-2" style={{ paddingLeft: 0 }}>
                  <div>
                    <b>{t('easy-to-use-mappings')}</b>
                  </div>
                  <div>
                    {t('the-import-tool-lets-you-map-your-columns-with-the-tvms-required-data-fields-should-your-excel-not-be-able-to-fill')}
                    <br />
                    {t('the-required-data-field-with-a-column-then-its-always-possible-to-add-an-overide-default-value')}
                  </div>
                </div>
                <div className="col mb-2" style={{ paddingLeft: 0 }}>
                  <div>
                    <b>{t('series-and-courses')}</b>
                  </div>
                  <div>
                    {t('these-two-content-type-are-different-from-the-rest-since-they-have-nested-content-for-example-series-have-seasons')} <br />
                    {t('and-episodes-your-excel-should-be-setup-that-you-have-a-tab-seperate-series-seasons-and-episodes-the-first-column-of')} <br />
                    {t('seasons-and-episode-should-have-respectivily-series_name-in-season-tab-and-season_name-in-episode-tab-the-same')}
                    <br />
                    {t('goes-for-education-here-we-expect-total-for-four-tabs-courses-years-courses-and-lessons-you-need-those-columns')}
                    <br />
                    {t('to-map-the-nestings-to-eachother')}
                  </div>
                </div>
                <div className="col mb-2" style={{ paddingLeft: 0 }}>
                  <div>
                    <b>{t('actors-and-teachers')}</b>
                  </div>
                  <div>
                    {t('we-can-import-these-based-on-a-comma-seperated-field-like-bruce-willis-jim-carrey-etc')} <br />
                  </div>
                </div>
                <div className="col mb-2" style={{ paddingLeft: 0 }}>
                  <div>
                    <b>{t('import-images')}</b>
                  </div>
                  <div>
                    {t('it-is-possible-to-import-images-this-is-done-by-providing-a-working-url-to-the-image-asset-with-importing-images-it-is')}
                    <br />
                    {t('very-important-to-respect-the-aspect-ratios-and-the-maximum-image-sizes-by-not-doing-this-one-can-expect-strange-results')}
                    <br />
                    {t('in-the-apps-we-have-handle-these-type-of-images')}
                    <br />
                    <br />
                    {t('poster-340x500')} <br />
                    {t('background-1920x1080')} <br />
                    {t('widescreen-640x360')} <br />
                    {t('square-300x300')} <br />
                    {t('tile-384x216')} <br />
                    <br />
                    {t('images-that-have-a-correct-image-ratio-but-the-sizes-are-to-big-our-import-tool-will-automatically-resize-them')}
                    <br />
                  </div>
                </div>
                <div>
                  <b>{t('example-import-files')}</b>
                </div>
                <div className="col mb-4" style={{ paddingLeft: 0 }}>
                  {t('download-example-excels-to-get-you-started-and-nbsp')}
                  <a href="https://cloudtv.akamaized.net/donotremove/tvms/examples/Excels/Channels_.xlsx">{t('channels')}</a>, &nbsp;
                  <a href="https://cloudtv.akamaized.net/donotremove/tvms/examples/Excels/Cams_.xlsx">{t('cams')}</a>,&nbsp; <a href="https://cloudtv.akamaized.net/donotremove/tvms/examples/Excels/Radio_.xlsx">{t('radio')}</a>,&nbsp; <a href="https://cloudtv.akamaized.net/donotremove/tvms/examples/Excels/Movies_.xlsx">{t('movies')}</a>, &nbsp;
                  <a href="https://cloudtv.akamaized.net/donotremove/tvms/examples/Excels/Series.xlsx">{t('series')}</a>, &nbsp;<a href="https://cloudtv.akamaized.net/donotremove/tvms/examples/Excels/Courses_.xlsx">{t('courses')}</a>, &nbsp;
                  <a href="https://cloudtv.akamaized.net/donotremove/tvms/examples/Excels/Podcasts_.xlsx">{t('podcasts')}</a>, &nbsp;<a href="https://cloudtv.akamaized.net/donotremove/tvms/examples/Excels/Albums_.xlsx">{t('albums')}</a>, &nbsp;<a href="https://cloudtv.akamaized.net/donotremove/tvms/examples/Excels/movies_imdb.xlsx">{t('movies-via-imdb')}</a>, &nbsp;<a href="https://cloudtv.akamaized.net/donotremove/tvms/examples/Excels/series_imdb.xlsx">{t('series-via-imdb')}</a>
                </div>
                <div>
                  <b>{t('important-message')}</b>
                </div>
                <div>
                  {t('if-you-have-empty-columns-so-column-that-are-there-but-hold-no-value-then-remove-them-cause-they-will-cause-issues')}
                  <br />
                </div>
              </div>
            </div>
            {!isAdding && (
              <div className="card-bg-light pl-5 pt-4 mt-4 pb-5">
                <h4 className="text-primary mb-2 mt-4">{t('content-type')}</h4>
                <p>{t('select-the-content-type-where-you-would-like-to-import-your-excel-to')}</p>
                <div className="row">
                  <div className="col-md-5">
                    <Form.Item name={'columns'}>
                      <Select className="default-select" onSelect={setSelectedContentType} defaultActiveFirstOption={true}>
                        <Select.Option value={'Albums'} key={1}>
                          {t('albums')}
                        </Select.Option>
                        <Select.Option value={'Apps'} key={1}>
                          {t('apps')}
                        </Select.Option>
                        <Select.Option value={'Cams'} key={2}>
                          {t('cams')}
                        </Select.Option>
                        <Select.Option value={'Channels'} key={3}>
                          {t('channels')}
                        </Select.Option>
                        <Select.Option value={'Courses'} key={4}>
                          {t('courses')}
                        </Select.Option>
                        <Select.Option value={'Games'} key={11}>
                          {t('games')}
                        </Select.Option>
                        <Select.Option value={'Movies'} key={5}>
                          {t('movies')}
                        </Select.Option>
                        <Select.Option value={'Shorts'} key={6}>
                          {t('shorts')}
                        </Select.Option>
                        <Select.Option value={'Podcasts'} key={7}>
                          {t('podcasts')}
                        </Select.Option>
                        <Select.Option value={'Radio'} key={8}>
                          {t('radio')}
                        </Select.Option>
                        <Select.Option value={'Series'} key={9}>
                          {t('series')}
                        </Select.Option>
                        {/* <Select.Option value={'Seasons'} key={10}>
                          {t('seasons')}
                        </Select.Option> */}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {selectedContent != '' && (
                  <>
                    <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                      {t('upload-your-excel-xlsx')}
                    </h4>

                    <div className="row-md-5 mb-5">
                      <Input className="form-control bg-white text-default col-md-7 btn-secondary" type="file" name="upload" id="upload" onChange={readUploadFile} />
                    </div>
                  </>
                )}
                {excel.length > 0 && selectedContent != '' && (
                  <div>
                    {selectedContent != 'Seasons' && (
                      <>
                        <h4 className="text-primary mb-2 mt-4">{t('append-new-content')}</h4>
                        <p>{t('by-enabling-this-option-you-add-the-content-from-the-excel-to-your-existing-content')}</p>

                        <div className="row">
                          <label className="col-md-3 col-form-label">{t('append-content')}</label>
                          <div className="col-md-5">
                            <Form.Item name={'delete_content'}>
                              <Switch className="ch-switch" checked={append} defaultChecked={false} onChange={changeAppend} />
                            </Form.Item>
                          </div>
                        </div>
                      </>
                    )}
                    {selectedContent != 'Seasons' && (
                      <>
                        <h4 className="text-primary mb-2 mt-4">{t('delete-existing-content')}</h4>
                        <p>
                          {t('by-enabling-this-option-all-your-currently-stored-content-will-be-deleted-use-this-feature-carefully')}
                          <br />
                          {t('next-to-that-the-bundles-and-lists-will-also-be-cleared-of-the-linked-content-to-avoid-issues')}
                        </p>
                        <div className="row">
                          <label className="col-md-3 col-form-label">{t('delete-content')}</label>
                          <div className="col-md-5">
                            <Form.Item name={'delete_content'}>
                              <Switch className="ch-switch" checked={remove} defaultChecked={false} onChange={changeDelete} />
                            </Form.Item>
                          </div>
                        </div>
                      </>
                    )}
                    {selectedContent != 'Seasons' && (
                      <>
                        <h4 className="text-primary mb-2 mt-4">{t('overwrite-existing-content')}</h4>
                        <p>
                          {t('by-enabling-this-option-all-you-currently-stored-content-will-be-overwritten-use-this-if-your-are-sure-the')}
                          <br />
                          {t('content-stored-matches-this-excel-and-you-are-only-making-small-changes-like-mass-changing-urls')}
                          <br />
                          {t('or-mass-enabling-disabled-features-your-bundles-and-lists-will-be-left-intact')}
                          <br />
                          The excel file is leading so you can use this to add or remove unwanted content.
                        </p>
                        <div className="row">
                          <label className="col-md-3 col-form-label">{t('overwrite-content')}</label>
                          <div className="col-md-5">
                            <Form.Item name={'overwrite_content'}>
                              <Switch className="ch-switch" checked={overwrite} defaultChecked={false} onChange={changeOverwrite} />
                            </Form.Item>
                          </div>
                        </div>
                      </>
                    )}
                    <>
                      <h4 className="text-primary mb-2 mt-4">{t('update-existing-content')}</h4>
                      <p>
                        {t('by-enabling-this-option-part-of-your-currently-stored-content-will-be-updated-by-the-contents-of-this-excel')}
                        <br />
                        {t('use-this-feature-if-you-want-to-update-just-a-few-content-items-only-they-will-be-matched-on-the-_id-field')}
                        <br />
                        {t('your-bundles-and-lists-will-be-left-intact')}
                      </p>
                      <div className="row">
                        <label className="col-md-3 col-form-label">{t('update-content')}</label>
                        <div className="col-md-5">
                          <Form.Item name={'update_content'}>
                            <Switch className="ch-switch" checked={update} defaultChecked={false} onChange={changeUpdate} />
                          </Form.Item>
                        </div>
                      </div>
                    </>
                    {selectedContent != 'Channels' && selectedContent != 'Cams' && selectedContent != 'Radio' && selectedContent != 'Movies' && (
                      <>
                        <h4 className="text-primary mb-2 mt-4">{t('extend-existing-content')}</h4>
                        <p>
                          {t('by-enabling-this-option-part-of-your-currently-stored-content-will-be-extended-by-the-contents-of-this-excel')}
                          <br />
                          {t('use-this-feature-if-you-want-to-update-just-a-few-content-items-only-such-as-adding-a-season-or-episodes-to')}
                          <br />
                          {t('an-existing-series-or-adding-lessons-to-an-existing-course-they-will-be-matched-on-the-_id-field')}
                          <br />
                          {t('your-bundles-and-lists-will-be-left-intact')}
                        </p>
                        <div className="row">
                          <label className="col-md-3 col-form-label">{t('extend-content')}</label>
                          <div className="col-md-5">
                            <Form.Item name={'extend_content'}>
                              <Switch className="ch-switch" checked={extend} defaultChecked={false} onChange={changeExtend} />
                            </Form.Item>
                          </div>
                        </div>
                      </>
                    )}

                    <>
                      <h4 className="text-primary mb-2 mt-4">{t('ai-translations')}</h4>
                      <p>
                        {t('by-enabling-this-option-our-translation-ai-will-automatically-translate-and-add-the-missing-description-and-tags')}
                        <br />
                        {t('translations-from-your-import-file-to-match-the-language-setup-of-your-deployment-if-you-disable-this')}
                        <br /> {t('feature-then-we-use-the-available-descriptions-to-match-the-language-setup-of-your-deployment')}
                      </p>
                      <div className="row">
                        <label className="col-md-3 col-form-label">{t('ai-translations')}</label>
                        <div className="col-md-5">
                          <Form.Item name={'overwrite_content'}>
                            <Switch className="ch-switch" checked={aitranslation} defaultChecked={true} onChange={setAiTranslation} />
                          </Form.Item>
                        </div>
                      </div>
                    </>

                    {(selectedContent == 'Movies' || selectedContent == 'Series') && (
                      <>
                        <h4 className="text-primary mb-2 mt-4">{t('imdb-gap-fill')}</h4>
                        <p>
                          {t('by-enabling-this-option-our-our-system-will-use-the-imdb-database-to-fill-the-gaps-of-the-metadata-tags-your-import')}
                          <br />
                          {t('file-lacks-on-each-field-where-you-want-auto-fill-select-imdb-gap-fill-from-the-dropdown-list')}
                        </p>
                        <div className="row">
                          <label className="col-md-3 col-form-label">{t('enable-gap-fill')}</label>
                          <div className="col-md-5">
                            <Form.Item name={'gaps_content'}>
                              <Switch className="ch-switch" checked={fillGapsWithIMDB} defaultChecked={true} onChange={setFillGapsWithIMDB} />
                            </Form.Item>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
            {selectedContent != '' && !isAdding && selectedContent != 'Seasons' && (
              <div className="card-bg-light pl-5 pt-4 mt-4 pb-5">
                <h4 className="text-primary mb-2 mt-4">{t('automation')}</h4>
                <p>{t('select-the-bundles-you-want-to-add-your-content-to-and-if-categories-should-be-generated-based-on-the-tags')}</p>
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('bundle-selection')}</label>
                  <div className="col-md-5">
                    <Form.Item name={'bundles'}>
                      <Select
                        showSearch
                        mode="multiple"
                        className="default-select"
                        allowClear
                        filterOption={(input, option) => {
                          return ((option?.name as string) ?? '').toLowerCase().includes(input.toLowerCase());
                        }}
                        options={bundles}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('add-to-categories')}</label>
                  <div className="col-md-5">
                    <Form.Item extra={t('add-your-imported-content-to-the-selected-bundles-categories-based-on-the-imported-tags')} name={'add_content_bundle'}>
                      <Switch className="ch-switch" checked={addToCats} defaultChecked={true} onChange={setAddToCats} />
                    </Form.Item>
                  </div>
                </div>
                <div className="row">
                  <label className="col-md-3 col-form-label">{t('create-categories')}</label>
                  <div className="col-md-5">
                    <Form.Item extra={t('create-categories-in-the-selected-bundles-from-the-imported-tags-and-add-the-imported-content-to-it')} name={'create_categories_tags'}>
                      <Switch className="ch-switch" checked={createCats} defaultChecked={true} onChange={setCreateCats} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            )}
            {selectedContent != '' && !isAdding && selectedContent != 'Seasons' && (
              <div className="card-bg-light pl-5 pt-4 mt-4 pb-5">
                <h4 className="text-primary mb-2 mt-4">{t('management')}</h4>
                <p>{t('avoid-doubles-to-be-imported-and-keep-your-content-catagelogue-clean')}</p>

                <div className="row">
                  <label className="col-md-3 col-form-label">{t('avoid-doubles')}</label>
                  <div className="col-md-5">
                    <Form.Item extra={t('add-your-imported-content-to-the-selected-bundles-categories-based-on-the-imported-tags')} name={'add_content_bundle'}>
                      <Switch className="ch-switch" checked={avoidDoubles} defaultChecked={true} onChange={setAvoidDoubles} />
                    </Form.Item>
                  </div>
                </div>
              </div>
            )}
            {isAdding && (
              <div className="card-bg-light p-5 mt-4">
                <div className="pl-2 pb-4">
                  <div className="row text-center h-100 w-100">
                    <div className="col-12 align-self-center pt-5 pb-5">
                      <Spin size="large" spinning={true} />
                    </div>
                    <div className="col-12 align-self-center">
                      <div style={{ padding: 50 }}>{t('do-not-refresh-your-browser-it-would-break-the-import-process')}</div>
                      {numberOfImports > 0 && (
                        <div style={{ padding: 5 }}>
                          {t('number-of-items-to-be-imported')} {numberOfImports}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedContent != '' && !isAdding && excel.length > 0 && (
              <div className="pt-5">
                <h4 className="text-primary mt-4 ml-2" style={{ marginTop: 15 }}>
                  {t('excel-model-mappings')}
                </h4>
                <p className="ml-2">
                  {t('use-the-dropdowns-on-the-left-side-to-map-your-excel-columns-with-our-data-model-or-use-the-overrides')}
                  <br />
                  {t('to-set-the-data-fields-you-are-missing-from-your-excel')}
                </p>
                <div className="mt-4">
                  <div className="mt-1 mb-5">
                    <div className="p-2">
                      <div className="card-bg-light p-5">
                        {tab1 == 'Series' ||
                          (tab1 == 'Courses' && (
                            <div className="pl-2 pb-4">
                              <h2 className="text-primary">{tab1}</h2>
                            </div>
                          ))}
                        {fillGapsWithIMDB && (
                          <div className="card-bg-light p-5 mb-3 bg-white">
                            <div className="row ">
                              <div className="col-md-6">
                                <h4 className="text-primary">{t('imdb-auto-fill')}</h4>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <label className="col-md-3 col-form-label">IMDB ID</label>
                                <div className="col-md-5">
                                  <Form.Item initialValue={getDefaulValueExcelColumn('imdbid')} name={'IMDB'}>
                                    <Select defaultValue={getDefaulValueExcelColumn('imdbid')} className="default-select" defaultActiveFirstOption={true}>
                                      {columns.map((el: any, index: any) => {
                                        if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                          return null;
                                        }

                                        return (
                                          <Select.Option value={index} key={index + el.name}>
                                            {el}
                                          </Select.Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="row">
                                <label className="col-md-3 col-form-label">{t('imdb-fill-all-metadata')}</label>
                                <div className="col-md-5" style={{ marginLeft: 0 }}>
                                  <Form.Item className="col" name={'delete_content'}>
                                    <div className="row">
                                      <div>
                                        <Switch className="ch-switch" checked={autoFill} defaultChecked={false} onChange={setAutoFill} />
                                      </div>
                                      <div className="mt-2 ml-1">{autoFill ? 'Enabled' : 'Disabled'}</div>
                                    </div>
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {(overwrite || update) && (
                          <div className="card-bg-light p-5 mb-3 bg-white">
                            <div className="row ">
                              <div className="col-md-6">
                                <h4 className="text-primary">{t('content-mapping-id')}</h4>
                              </div>
                            </div>
                            <div>
                              <div className="row">
                                <label className="col-md-3 col-form-label">{t('content-id')}</label>
                                <div className="col-md-5">
                                  <Form.Item initialValue={getDefaulValueExcelColumn('_Id')} name={'_Id'}>
                                    <Select defaultValue={getDefaulValueExcelColumn('_Id')} className="default-select" defaultActiveFirstOption={true}>
                                      {columns.map((el: any, index: any) => {
                                        if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                          return null;
                                        }
                                        return (
                                          <Select.Option value={index} key={index + el.name}>
                                            {el}
                                          </Select.Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {!autoFill && (
                          <div className="card-bg-light p-5 mb-3 bg-white">
                            <div className="row ">
                              <div className="col-md-6">
                                <h4 className="text-primary">{t('general')}</h4>
                              </div>
                              {update && (
                                <div className="col-md-6 text-right">
                                  <Form.Item name={'delete_content'}>
                                    <div className="row">
                                      <div className="col mt-2">{general1 ? 'Enabled' : 'Disabled'}</div>
                                      <div>
                                        <Switch className="ch-switch" checked={general1} defaultChecked={false} onChange={changeGeneral1} />
                                      </div>
                                    </div>
                                  </Form.Item>
                                </div>
                              )}
                            </div>
                            {general1 && (
                              <div>
                                {keys.map((key: any, index: any) => {
                                  if (fillGapsWithIMDB && key.name == 'IMDB') {
                                    return null;
                                  }
                                  if (selectedContent == 'Seasons' && key.name == 'Series') {
                                    return null;
                                  }
                                  return (
                                    <div className="row" key={index + key.name}>
                                      <label className="col-md-3 col-form-label">{key.name}</label>
                                      <div className="col-md-5">
                                        <Form.Item name={key.name.replace(' ', '_')} initialValue={getDefaulValueExcelColumn(key.name)}>
                                          <Select defaultValue={getDefaulValueExcelColumn(key.name)} className="default-select" defaultActiveFirstOption={true}>
                                            {columns.map((el: any, index: any) => {
                                              if ((!fillGapsWithIMDB || key.name == 'Name') && (el == 'IMDB_Auto_Fill' || el == 'Empty_Value')) {
                                                return null;
                                              }

                                              return (
                                                <Select.Option value={index} key={index + key.name}>
                                                  {el}
                                                </Select.Option>
                                              );
                                            })}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )}
                        {options.map((key_: any, index: any) => {
                          return optionsDivs(key_, index, columns, '');
                        })}
                      </div>
                    </div>

                    {tab2 != '' && !autoFill && (
                      <div className="p-2">
                        <div className="card-bg-light p-5">
                          <div className="pl-2 pb-4">
                            <h2 className="text-primary">{tab2}:</h2>
                          </div>
                          {(overwrite || update || append) && selectedContent == 'Series' && (
                            <div className="card-bg-light p-5 mb-3 bg-white">
                              <div className="row ">
                                <div className="col-md-6">
                                  <h4 className="text-primary">{t('content-mapping-id')}</h4>
                                </div>
                              </div>
                              <div>
                                <div className="row">
                                  <label className="col-md-3 col-form-label">{t('series-content-id')}</label>
                                  <div className="col-md-5">
                                    <Form.Item initialValue={getDefaulValueExcelColumn('_id')} name={'series_id_1'}>
                                      <Select defaultValue={getDefaulValueExcelColumn('_id')} className="default-select" defaultActiveFirstOption={true}>
                                        {columns.map((el: any, index: any) => {
                                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                            return null;
                                          }
                                          return (
                                            <Select.Option value={index} key={index + el.name}>
                                              {el}
                                            </Select.Option>
                                          );
                                        })}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="row">
                                  <label className="col-md-3 col-form-label">{t('season-content-id')}</label>
                                  <div className="col-md-5">
                                    <Form.Item initialValue={getDefaulValueExcelColumn('series_id')} name={'season_id_1'}>
                                      <Select defaultValue={getDefaulValueExcelColumn('series_id')} className="default-select" defaultActiveFirstOption={true}>
                                        {columns1.map((el: any, index: any) => {
                                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                            return null;
                                          }
                                          return (
                                            <Select.Option value={index} key={index + el.name}>
                                              {el}
                                            </Select.Option>
                                          );
                                        })}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="card-bg-light p-5 mb-3 bg-white">
                            <div className="row">
                              <div className="col-md-6">
                                <h4 className="text-primary">{t('general')}</h4>
                              </div>
                              {update && (
                                <div className="col-md-6 text-right">
                                  <Form.Item name={'delete_content' + 'keys1'}>
                                    <div className="row">
                                      <div className="col mt-2">{general2 ? 'Enabled' : 'Disabled'}</div>
                                      <div>
                                        <Switch className="ch-switch" checked={general2} defaultChecked={false} onChange={changeGeneral2} />
                                      </div>
                                    </div>
                                  </Form.Item>
                                </div>
                              )}
                            </div>
                            {general2 && (
                              <div>
                                {keys1.map((key: any, index: any) => {
                                  return (
                                    <div key={index + key.name + 'keys1'} className="row">
                                      <label className="col-md-3 col-form-label">{key.name}</label>
                                      <div className="col-md-5">
                                        <Form.Item name={key.name.replace(' ', '_') + '_key1'} initialValue={getDefaulValueExcelColumn1(key.name)}>
                                          <Select defaultValue={getDefaulValueExcelColumn1(key.name)} className="default-select" defaultActiveFirstOption={true}>
                                            {columns1.map((el: any, index: any) => {
                                              if ((!fillGapsWithIMDB || key.name == 'Series') && (el == 'IMDB_Auto_Fill' || el == 'Empty_Value')) {
                                                return null;
                                              }
                                              return (
                                                <Select.Option value={index} key={index + key.name}>
                                                  {el}
                                                </Select.Option>
                                              );
                                            })}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>

                          {options1.map((key_: any, index: any) => {
                            return optionsDivs(key_, index, columns1, '_key1');
                          })}
                        </div>
                      </div>
                    )}
                    {tab3 != '' && (
                      <div className="p-2">
                        <div className="card-bg-light p-5">
                          <div className="pl-2 pb-4">
                            <h2 className="text-primary">{tab3}:</h2>
                          </div>
                          {(overwrite || update || append) && selectedContent == 'Series' && !autoFill && (
                            <div className="card-bg-light p-5 mb-3 bg-white">
                              <div className="row ">
                                <div className="col-md-6">
                                  <h4 className="text-primary">{t('content-mapping-id')}</h4>
                                </div>
                              </div>
                              <div>
                                <div className="row">
                                  <label className="col-md-3 col-form-label">{t('season-id')}</label>
                                  <div className="col-md-5">
                                    <Form.Item initialValue={getDefaulValueExcelColumn1('season_id')} name={'season_id_2'}>
                                      <Select defaultValue={getDefaulValueExcelColumn1('season_id')} className="default-select" defaultActiveFirstOption={true}>
                                        {columns1.map((el: any, index: any) => {
                                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                            return null;
                                          }
                                          return (
                                            <Select.Option value={index} key={index + el.name}>
                                              {el}
                                            </Select.Option>
                                          );
                                        })}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                                <div className="row">
                                  <label className="col-md-3 col-form-label">{t('episode-id')}</label>
                                  <div className="col-md-5">
                                    <Form.Item initialValue={getDefaulValueExcelColumn2('season_id')} name={'episode_id_2'}>
                                      <Select defaultValue={getDefaulValueExcelColumn2('season_id')} className="default-select" defaultActiveFirstOption={true}>
                                        {columns2.map((el: any, index: any) => {
                                          if (!fillGapsWithIMDB && el == 'IMDB_Auto_Fill') {
                                            return null;
                                          }
                                          return (
                                            <Select.Option value={index} key={index + el.name}>
                                              {el}
                                            </Select.Option>
                                          );
                                        })}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {!autoFill && (
                            <div className="card-bg-light p-5 mb-3 bg-white">
                              <div className="row">
                                <div className="col-md-6">
                                  <h4 className="text-primary">{t('general')}</h4>
                                </div>
                                {update && (
                                  <div className="col-md-6 text-right">
                                    <Form.Item name={'delete_content' + 'keys2'}>
                                      <div className="row">
                                        <div className="col mt-2">{general3 ? 'Enabled' : 'Disabled'}</div>
                                        <div>
                                          <Switch className="ch-switch" checked={general3} defaultChecked={false} onChange={changeGeneral3} />
                                        </div>
                                      </div>
                                    </Form.Item>
                                  </div>
                                )}
                              </div>
                              {general3 && (
                                <div>
                                  {keys2.map((key: any, index: any) => {
                                    return (
                                      <div key={index + key.name + 'keys2'} className="row">
                                        <label className="col-md-3 col-form-label">{key.name}</label>
                                        <div className="col-md-5">
                                          <Form.Item name={key.name.replace(' ', '_') + '_key2'} initialValue={getDefaulValueExcelColumn2(key.name)}>
                                            <Select defaultValue={getDefaulValueExcelColumn2(key.name)} className="default-select" defaultActiveFirstOption={true}>
                                              {columns2.map((el: any, index: any) => {
                                                if ((!fillGapsWithIMDB || key.name == 'Season') && (el == 'IMDB_Auto_Fill' || el == 'Empty_Value')) {
                                                  return null;
                                                }
                                                return (
                                                  <Select.Option value={index} key={index + key.name}>
                                                    {el}
                                                  </Select.Option>
                                                );
                                              })}
                                            </Select>
                                          </Form.Item>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )}
                          {options2.map((key_: any, index: any) => {
                            return optionsDivs(key_, index, columns2, '_key2');
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {isAdding && (
              <div className="pt-5">
                <h4 className="text-primary mt-4 ml-2" style={{ marginTop: 15 }}>
                  {' '}
                </h4>
              </div>
            )}
          </div>
        </div>
        <div className="text-right">
          {excel.length > 0 && selectedContent != '' && (
            <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
              {t('import-content')}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};
export default Importer;