import { Button, Form, Input, Modal, notification } from 'antd';
import { Spin } from '../../spinner';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import appService from '../../../../services/areas/appbuilder/applications/application';
import * as HttpStatusCodes  from 'http-status-codes';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { useParams } from 'react-router-dom';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { IMSNameParams } from '../../../../pages/dashboard';

export type LayoutProps = {
  cloningFinished: () => void;
  application: any;
  onCancel: () => void;
};

const LayoutModel = (props: LayoutProps) => {
  const { cloningFinished, application, onCancel } = props;
  const [cloning, setCloning] = useState(false);
  const [form] = useForm();
  const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);

  useEffect(() => {
    if (application != undefined) {
     // form.setFieldsValue(application);
    }
  }, [application]);

  const handleSubmit = async (values: any) => {
    if (values.branding.name != application.branding.name) {
      if (values.branding.packageid != application.branding.packageid) {
        setCloning(true);
        let responseApp = await appService.Add_Application({
          query_params: {
            cms: deployment?.name ?? '',
            instance: clientName,
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', '').APPBUILDER.IMAGES,
          },
          ...application,
          deployment: application.deployment,
          client: application.client,
          tp: 'sZP7IYe6T6Pg3Sbi0lhL06k1Tu5iFSaZIlf-ClA6IOkg0S5r0lho0lUeClb6FOPlUY3zWokgbgjNIOf9flPe3uRL3ofZFDC_3lB-3QatCZzrIQfZFSeLCKhk3lbo3oBcImfVfK4_bQgZCYxNWoryIQXzImf90Sbc0Sbi3lai0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lRc3L0Z0Lbk0SezFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikf6i6CDrebKjNIOfVfKXpIwPqdDxzU6fVfKINbK4zU6fVfKgqbZfVfGxNsK4pf6i6UwIqbZfVfGUgCKjLfgzVfG3gWKxydDkibK4LbogqW6f9UwPkImi6IK41Uw4ZIY06Tg-Uya',
          branding: {
            ...application.branding,
            name: values.branding.name,
            packageid: values.branding.packageid,
          },
        });

        if (responseApp && responseApp.status_code === HttpStatusCodes.OK) {
          notification.success({
            message: t('app-added-successfully'),
          });
          cloningFinished();
        }
      }
    }
  };
  const nothing = () => {};

  return (
    <Modal visible={true}  width={'60%'} footer={false} closable={!cloning} onCancel={cloning ? nothing : onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">
              {t('clone')} {application.branding.name} {t('app')}
            </h4>
            <div className="row mt-5">
              <Form
                className="col-12"
                form={form}
                onFinish={async (values) => {
                  await handleSubmit(values);
                }}
                onFinishFailed={(errorInfo) => {}}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col card-bg-light p-4 mb-2 mr-4">
                      <div style={{ height: 280 }}>
                        {!cloning && (
                          <>
                            <h5 className="text-primary">{t('app-setup')}</h5>
                            <p>{t('make-sure-to-use-a-different-name-package')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('app-name')}</label>
                              <Form.Item
                                className="col-md-5"
                                rules={[
                                  {
                                    required: true,
                                    message: t('app-name-is-required'),
                                  },
                                ]}
                                name={['branding', 'name']}
                              >
                                <Input className="form-control bg-white font-size-14 text-default" placeholder={'YourTV'} />
                              </Form.Item>
                            </div>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('app-package')}</label>
                              <Form.Item
                                className="col-md-5"
                                rules={[
                                  {
                                    required: true,
                                    message: t('package-name-is-required'),
                                  },
                                ]}
                                name={['branding', 'packageid']}
                              >
                                <Input className="form-control bg-white font-size-14 text-default" placeholder={'com.yourname.apps'} />
                              </Form.Item>
                            </div>
                          </>
                        )}
                        {cloning && (
                          <div className="text-center w-100 pt-5">
                            <div className="col-md-12 align-self-center" style={{ height: 140 }}>
                              <Spin size="large" spinning={true} />
                            </div>
                            <div className="col-md-12 align-self-center" style={{ height: 20 }}>
                              {t('cloning')}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-0 mt-3 text-right">
                    <Button htmlType="submit" disabled={cloning} loading={cloning} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                      {t('start-cloning')}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
