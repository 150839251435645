import { Form, notification } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { BackButton } from '../../../../common/components/BackButton';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { Button } from '../../../../common/components/form';
import { PageTitle } from '../../../../common/components/pageTitle';
import { CustomersContext } from '../../../../contexts/customersContext';
import { useAddMutation } from '../../../../services/areas/customers/customerService';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { CustomerFormFields } from './shared/form';
import { useQuery } from '@tanstack/react-query';
import * as TaxService from '../../../../services/areas/billing/taxService';
import { ErrorModal } from '../../../../common/components/ErrorModal/ErrorModal';
import { useTranslation } from 'react-i18next';
import { CommonContext } from '../../../../contexts/commonContext';

const Add = () => {
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const history = useHistory();
  const { clientName, service: serviceName } = useParams<{ clientName: string; service: string }>();
  const { deployment } = React.useContext(CustomersContext);
  const path = STORAGE_ENDPOINTS(clientName, deployment?.name, '').CUSTOMERS.CUSTOMERS;
  const {t} = useTranslation();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [modalData, setModalData] = useState([] as any);

  const { isLoading, mutate } = useAddMutation(
    { params: { instance: clientName, cms: deployment?.name || '', crm: serviceName, path: path } },
    {
      onSuccess: () => {
        notification.success({ message: t('customer-saved') });
        history.push(redirectUrl + '/list');
      },
      onError: (error) => {
        if ((error as any)?.message?.includes(t('customer-already-exists'))) {
          notification.error({ message: t('username-is-in-use-by-another-customer') });
        } else {
          notification.error({ message: t('customer-could-not-be-saved-please-try-again-later') });
        }
      },
    }
  );
  const [form] = Form.useForm();

  const { countries } = useContext(CommonContext);

  const { data: tax_rates } = useQuery(['taxes'], async () => {
    return (
      await TaxService.list({
        params: {
          cms: deployment?.name ?? '',
          crm: serviceName ?? '',
          instance: clientName,
          page: 1,
          limit: 9999,
        },
      })
    ).data.docs;
  });

  // const { data: shoppingCarts } = useShoppingCarts({
  //   params: {
  //     instance: clientName,
  //     cms: deployment?.name ?? '',
  //     crm: serviceName,
  //     page: 1,
  //     limit: 9999,
  //   },
  // });

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-12">
          <BackButton text={t('back-to-customer-list')} url={url.split('/').slice(0, -1).join('/')} />
        </div>
        <div className="col-md-6 mb-2">
          <PageTitle title={t('add-customer')} subtitle={t('manually-add-a-new-customer-to-this-service')} />
        </div>
      </div>
      <ErrorModal
        isOpenModal={openErrorModal}
        data={modalData}
        setIsOpen={(isOpen) => {
          setOpenErrorModal(isOpen);
        }}
      />
      <div className="row">
        <div className="col-12">
          <Form
            {...{ form }}
            onFinish={(values) => {
              if (!countries) {
                return;
              }

              // TODO Rewrite this
              if (values.mobile === '') {
                values.mobile = undefined;
              }
              if (values.phone === '') {
                values.phone = undefined;
              }
              if (values.email === '') {
                values.email = undefined;
              }

              const country = countries.find((x) => x.iso3 === form.getFieldValue('billing_country'));

              if (!country || !tax_rates) {
                return;
              }

              if (values?.subscriptions?.base?.plan && values?.subscriptions?.base?.plan?._id === undefined) {
                values.subscriptions.base.plan = undefined;
              }

              if (values?.subscriptions?.base?.product && values?.subscriptions?.base?.product?._id === undefined) {
                values.subscriptions.base.product = undefined;
              }

              const billing_country = country.iso3;
              const billing_currency = country.currency;
              const billing_taxrate = tax_rates != undefined && tax_rates[0] != undefined && tax_rates[0].rates != undefined && tax_rates[0].rates.find((r) => r.country == country.iso3) != undefined ? tax_rates[0].rates.find((r) => r.country == country.iso3)!.percentage : 0;
              mutate({
                ...values,
                billing_country,
                billing_currency,
                billing_taxrate,
              });
            }}
            onFinishFailed={(errorInfo) => {
              setModalData(errorInfo?.errorFields);
              setOpenErrorModal(true);
            }}
          >
            <CustomerFormFields hideDates={true} shoppingCartSelected={false} />

            <div className="text-right">
              <Button htmlType="submit" loading={isLoading} disabled={isLoading} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                {t('add-customer')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default Add;
