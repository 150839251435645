import { Form, Modal, Select, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import appMessages from '../../../../services/areas/appbuilder/applications/messages';
import * as HttpStatusCodes  from 'http-status-codes';
import { useEffectOnce } from 'react-use';

export type LayoutProps = {
  visible: boolean;
  onSubmit: (component: [], name: string, index:any) => void;
  onCancel: () => void;
  data: [] | any;
  type: string;
  index: any;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, onSubmit, onCancel, data, type } = props;
  const [form] = useForm();
const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [messages, setMessages] = useState([] as any);
  const [message, setMessage] = useState([] as any);
  const [loading, setLoading] = useState(true);

  const onSubmitLocal = (values: any) => {
    var messageOut = messages.find((m) => m._id == message);
    let dataOut = {
      ...data,
      translations: {
        ...messageOut,
      },
    };

    onSubmit(dataOut, type, props.index);
  };

  useEffect(() => {
    getMessages();
    if (data != undefined) {
      setMessage(data);
      form.setFieldsValue(data);
    }
    setLoading(false);
  }, [data, form]);

  const getMessages = async () => {
    let response = await appMessages.List_Messages({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      pageNumber: 0,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      setMessages(resp.data);
    }
  };

  const setSelectMessageLocal = (value: any) => {
    setMessage(value);
  };
  return (
    <Modal visible={visible} style={{  borderRadius:5}} width={'80%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">{t('edit-promo-message')}</h4>
            <div className="row mt-5">
              <div className="col-12">
                {!loading && (
                  <Form form={form} onFinish={onSubmitLocal} onFinishFailed={(errorInfo) => {}}>
                    <div className="col">
                      <div className="row" style={{ padding: 15 }}>
                        <div className="col card-bg-light p-4 mb-2 mr-1" style={{ paddingLeft: 0 }}>
                          <h5 className="text-primary">{t('content')}</h5>
                          <p>{t('select-the-message-you-want-to-use-for-this-element')}</p>
                          <div className="row">
                            <label className="col-md-2 col-form-label">{t('message')}</label>
                            <div className="col" style={{ paddingLeft: 0 }}>
                              <Form.Item style={{ padding: 0 }} className="col-md-5" name={['translations', '_id']}>
                                <Select onChange={setSelectMessageLocal} className="default-select" placeholder={t('select-message')}>
                                  {messages.map((message) => {
                                    return (
                                      <Select.Option value={message._id} key={message._id}>
                                        {message.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 p-0 mt-3">
                      <button type="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                        {t('update-message')}
                      </button>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
