import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { baseOptions } from './BaseOptions';
import { useTranslation } from 'react-i18next';
import { ChartInput } from '@mwaretv/database/build/backend/util/queryGenerator';

export const GaugeChart = ({ chartInput }: { chartInput: ChartInput }) => {
  const { t } = useTranslation();
  
  const firstSeries = chartInput.series && chartInput.series.length > 0 ? chartInput.series[0] : undefined;

  const gaugeValue = firstSeries?.data != null && firstSeries.data.length > 0 ? firstSeries.data[0].value : undefined;
  
  const standardOptions: any = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: -2,
            fontSize: '22px',
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      colors: firstSeries?.percentageWarning && gaugeValue && gaugeValue > firstSeries?.percentageWarning ? "#ff0000" : "#485fe0",
      type: 'gradient',
      gradient: {
        shade: 'light',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91]
      },
    },
    labels: [''],
    noData: {
      text: t('chart-design-no-data'),
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#505972',
        fontSize: '28px',
        fontFamily: undefined
      }
    }
  };
  
  const chartOptions: ApexOptions = {
    ...baseOptions,
    ...standardOptions,
  };

  return <ReactApexChart options={chartOptions} series={gaugeValue ? [gaugeValue] : []} type="radialBar" width={400} />;
};
