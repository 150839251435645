import { Button, Form, Input, Modal, Select, Switch } from 'antd';
import { Spin } from '../../spinner';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

type IUpdateLanguageModalProps = {
  visible: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  initialData: any;
  existingRatingNames: string[];
  operationMode: "add" | "edit";
};

const RatingModal = (props: IUpdateLanguageModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  let [form] = useForm();
  const { t } = useTranslation();

  const [isFetchingData] = useState<boolean>(false);
  useEffect(() => {
    form.setFieldsValue(initialData);
  },[initialData]);

  return (
    <Modal open={visible} style={{ borderRadius: 5 }} width={640} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4>{t('add-update-rating')}</h4>
            {!isFetchingData && (
              <Form form={form} onFinish={(values) => onSubmit(values)} onFinishFailed={(errorInfo) => {}}>
                <label className="col-form-label">{t('rating-identifier')}</label>
                <Form.Item
                  name="name"
                  extra={t('this-identifier-needs-to-match-the-original-identifiers-for-you-region-to-match-up-with-automated-metadata-such-as-epg-movies-and-series-imports')}
                  className="form-group mb-0"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    }
                  ]}
                >
                  <Input  onInput={(e:any) => e.target.value = e.target.value.toUpperCase()} className="form-control bg-white font-size-14 text-default" placeholder={t('your-rating-identifier-here')} />
                </Form.Item>
                <Form.Item name="type" className="form-group mb-0">
                  <Select style={{ width: '100%' }} defaultValue={'Select a Rating Type'}>
                    <Select.Option value={'Kids (all)'} key={1}>
                      Kids (all)
                    </Select.Option>
                    <Select.Option value={'Older Kids 7+'} key={2}>
                      Older Kids 7+
                    </Select.Option>
                    <Select.Option value={'Teens 13+'} key={3}>
                      Teens 13+
                    </Select.Option>
                    <Select.Option value={'Young Adults 16+'} key={4}>
                      Young Adults 16+
                    </Select.Option>
                    <Select.Option value={'Adults 18+'} key={5}>
                      Adults 18+
                    </Select.Option>
                  </Select>
                </Form.Item>
                <label className="col-form-label">{t('show-parental')}</label>
                <Form.Item name="locked" valuePropName={'checked'} extra={'Show a modal prompting for a parental code for Channels, Recordings, CatchupTV, Cams, TV Shows & Programs that exceed the age rating.'} className="form-group mb-0">
                  <Switch className="ch-switch" checked={false} defaultChecked={false} />
                </Form.Item>

                <div className="col-md-12 p-0">
                  <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                    {t('submit')}
                  </Button>
                </div>
              </Form>
            )}
            {isFetchingData && (
              <div className="row text-center h-100 w-100">
                <div className="col-12 align-self-center">
                  <Spin size="large" spinning={true} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RatingModal;
