import { Button, DatePicker, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import cmsMessages from '../../../../../../services/areas/appbuilder/applications/messages/index';
import { IMSNameParams } from '../../../../../dashboard';
import MessageDisclaimer from '../../../../../../common/components/AppBuilder/MessageDisclaimer/Translations';
import { AppBuilderContext } from '../../../../../../contexts/appbuilderContext';
import { STORAGE_ENDPOINTS } from '../../../../../../services/storage_paths';
import ImageUploader from '../../../../../../common/components/ImageUploader/ImageUploader';
import { useEffectOnce } from 'react-use';
import dayjs from 'dayjs';

export const MessageDetails = () => {
  const history = useHistory();
  const [translations, setTranslations] = useState([] as any);
  const [form] = useForm();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const { deployment, service } = useContext(AppBuilderContext);
  const { clientName } = useParams<IMSNameParams>();
  const [isAdding, setIsAdding] = useState(false);
  const [image, setImage] = useState([] as any);
  const [date, setSelectedDate] = useState(null);
  const params = useParams<IMSNameParams & { deployment: string }>();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    let trans = [] as any;
    translations.forEach((translation) => {
      trans.push({
        name: translation.name,
        description: translation.description,
      });
    });

    const message = {
      _id: params._id,
      name: values.name,
      date: dayjs(date).toString(),
      image: image.url === undefined ? (!Array.isArray(image) ? image : '') : image.url.replace('https://cloudtv.akamaized.net', ''),
      translations: trans,
      query_params: {
        cms: deployment?.name ?? '',
        instance: params.clientName,
      },
    };

    const response = await cmsMessages.Update_Message(message);

    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  const handleFormFill = async () => {
    setIsAdding(true);

    const response = await cmsMessages.Get_Message({
      query_params: {
        cms: deployment?.name ?? '',
        instance: params.clientName,
      },
      _id: params._id ?? '',
    });

    if (response && response.status_code === HttpStatusCodes.OK) {
      const resp = response.data as any;

      setTranslations(resp.translations);
      setImage(resp.image);
      setSelectedDate(resp.date);
      form.setFieldsValue(resp);
      setIsAdding(false);
    }
  };

  useEffectOnce(() => {
    (async () => await handleFormFill())();
  });

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}`}>{t('back-to-message-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('message-details')}</h2>
        </div>
      </div>
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {}}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('message')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row">
                <label className="col-md-2 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-3"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('my-message-name')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('publish-date')}</label>
                <Form.Item
                  className="col-md-3"
                  rules={[
                    {
                      required: true,
                      message: t('date-is-required'),
                    },
                  ]}
                >
                  <DatePicker className="form-control bg-white font-size-14 text-default" value={date ? dayjs(date) : null} onChange={handleDateChange} />
                </Form.Item>
              </div>
            </div>
            <div className="mb-5">
              <ImageUploader
                onSubmit={(values: any) => {
                  setImage(values);
                }}
                initialData={{
                  client: clientName,
                  deployment: deployment,
                  service: undefined,
                  uploadedImage: image,
                  uploadName: t('image'),
                  uploadType: 'tile',
                  path: STORAGE_ENDPOINTS(params.clientName, deployment?.name, service?.name).APPBUILDER.IMAGES,
                }}
              />
            </div>

            {!isAdding && <MessageDisclaimer fullEditor={false} data={translations} service_name={service?.name} clientName={deployment?.name} setTranslations={setTranslations}></MessageDisclaimer>}
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-message')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
