import { Button, Form, notification, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { CustomNotification } from '../../Notification';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import appService from '../../../../services/areas/appbuilder/applications/application';
import EditText from '../Edit/Edit_Text_Player';
import EditList from '../Edit/Edit_List';
import EditComponents from '../Edit/Edit_Components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBlockBrick, faList, faTextSize } from '@fortawesome/pro-regular-svg-icons';
import PlayerCentric from '../../../../assets/images/appbuilder/Centric.png';
import PlayerMinimal from '../../../../assets/images/appbuilder/Minimal.png';
import PlayerBigButtons from '../../../../assets/images/appbuilder/BigButton.png';
import PlayerSleek from '../../../../assets/images/appbuilder/Sleek.png';
import PlayerAudio from '../../../../assets/images/appbuilder/AudioBig.png';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';

const Players = (params: { type: any }) => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [theme, setTheme] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();
  const { id } = useParams<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [application, setApplication] = useState([] as any);
  const [template, setTemplate] = useState('Select a Template');
  const [players, setPlayers] = useState([] as any);
  const [player, setPlayer] = useState([] as any);
  const [setting, setSettings] = useState({
    texts: {
      title_color: '#ffffff',
      title_size: 'ExtraLarge', //Small, Normal, Large, Extra Large
      title_bold: false,

      subtext_color: '#ffffff',
      subtext_size: 'Large', //Small, Normal, Large, Extra Large
      subtext_bold: false,

      show_title: true,
      show_subtext: true,
    },

    list: {
      general: {
        background_color: '#00000060',
      },
      texts: {
        title_color: '#ffffff',
        title_size: 'ExtraLarge',
        title_bold: false,
        subtext_color: '#ffffff',
        subtext_size: 'Large',
        subtext_bold: false,
        show_title: true,
        show_subtext: true,
      },
    },
    components: {
      button: {
        _id: '',
      },
      input: {
        _id: '',
      },
    },
  });
  const [editData, setEditData] = useState([] as any);
  const [editType, setEditType] = useState('');

  const [EditTextMessageModal, setEditTextMessageModal] = useState(false);
  const [EditShowListModal, setEditShowListModal] = useState(false);
  const [editComponentModal, setEditComponentModal] = useState(false);

  const editComponent = async (data: any) => {
    var player = [] as any;
    if (params.type == 'Channel Player') {
      player = {
        channels: {
          type: template,
          ...data,
        },
      };
    }
    if (params.type == 'Radio Player') {
      player = {
        radio: {
          type: template,
          ...data,
        },
      };
    }
    if (params.type == 'CatchupTV Player') {
      player = {
        catchuptv: {
          type: template,
          ...data,
        },
      };
    }
    if (params.type == 'Recording Player') {
      player = {
        recordings: {
          type: template,
          ...data,
        },
      };
    }
    if (params.type == 'Movie Player') {
      player = {
        movies: {
          type: template,
          ...data,
        },
      };
    }
    if (params.type == 'Series Player') {
      player = {
        series: {
          type: template,
          ...data,
        },
      };
    }
    if (params.type == 'Music Player') {
      player = {
        music: {
          type: template,
          ...data,
        },
      };
    }
    if (params.type == 'Podcast Player') {
      player = {
        podcasts: {
          type: template,
          ...data,
        },
      };
    }
    if (params.type == 'Courses Player') {
      player = {
        courses: {
          type: template,
          ...data,
        },
      };
    }
    if (params.type == 'Cam Player') {
      player = {
        cams: {
          type: template,
          ...data,
        },
      };
    }
    if (params.type == 'Shorts Player') {
      player = {
        shorts: {
          type: template,
          ...data,
        },
      };
    }

    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...application,
      players: {
        ...application.players,
        ...player,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });

      setSettings(data);
      setEditTextMessageModal(false);
      setEditComponentModal(false);
      setEditShowListModal(false);
      getMenuItems();
    }
  };
  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    var player = [] as any;
    if (params.type == 'Channel Player') {
      player = {
        channels: {
          ...setting,
          type: template,
        },
      };
    }
    if (params.type == 'Radio Player') {
      player = {
        radio: {
          ...setting,
          type: template,
        },
      };
    }
    if (params.type == 'CatchupTV Player') {
      player = {
        catchuptv: {
          ...setting,
          type: template,
        },
      };
    }
    if (params.type == 'Recording Player') {
      player = {
        recordings: {
          ...setting,
          type: template,
        },
      };
    }
    if (params.type == 'Movie Player') {
      player = {
        movies: {
          ...setting,
          type: template,
        },
      };
    }
    if (params.type == 'Series Player') {
      player = {
        series: {
          ...setting,
          type: template,
        },
      };
    }
    if (params.type == 'Music Player') {
      player = {
        music: {
          ...setting,
          type: template,
        },
      };
    }
    if (params.type == 'Podcast Player') {
      player = {
        podcasts: {
          ...setting,
          type: template,
        },
      };
    }
    if (params.type == 'Courses Player') {
      player = {
        courses: {
          ...setting,
          type: template,
        },
      };
    }
    if (params.type == 'Cam Player') {
      player = {
        cams: {
          ...setting,
          type: template,
        },
      };
    }
    if (params.type == 'Shorts Player') {
      player = {
        shorts: {
          ...setting,
          type: template,
        },
      };
    }
    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...application,
      players: {
        ...application.players,
        ...player,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
      getMenuItems();
    } else CustomNotification({ error: response.errors });
    setIsLoading(false);
  };

  const getMenuItems = async () => {
    setIsLoading(true);
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      setApplication(resp);
      setTheme(resp.settings.theme);

      var players_ = [] as any;
      if (resp.players != undefined) {
        if (params.type != 'Music Player' && params.type != 'Podcast Player' && params.type != 'Radio Player') {
          if (resp.players.channels && params.type != 'Channel Player') {
            players_.push({
              name: 'Channels',
              settings: resp.players.channels,
            });
          }
          if (resp.players.catchuptv && params.type != 'CatchupTV Player') {
            players_.push({
              name: 'CatchupTV',
              settings: resp.players.catchuptv,
            });
          }
          if (resp.players.recordings && params.type != 'Recording Player') {
            players_.push({
              name: 'Recordings',
              settings: resp.players.recordings,
            });
          }
          if (resp.players.movies && params.type != 'Movie Player') {
            players_.push({
              name: 'Movies',
              settings: resp.players.movies,
            });
          }
          if (resp.players.series && params.type != 'Series Player') {
            players_.push({
              name: 'Series',
              settings: resp.players.series,
            });
          }
          if (resp.players.courses && params.type != 'Courses Player') {
            players_.push({
              name: 'Courses',
              settings: resp.players.courses,
            });
          }
          if (resp.players.cams && params.type != 'Cam Player') {
            players_.push({
              name: 'Cams',
              settings: resp.players.cams,
            });
          }
          if (resp.players.shorts && params.type != 'Shorts Player') {
            players_.push({
              name: 'Shorts',
              settings: resp.players.shorts,
            });
          }
        }
        if (params.type == 'Music Player' || params.type == 'Podcast Player' || params.type == 'Radio Player') {
          if (resp.players.radio && params.type != 'Radio Player') {
            players_.push({
              name: 'Radio',
              settings: resp.players.radio,
            });
          }
          if (resp.players.music && params.type != 'Music Player') {
            players_.push({
              name: 'Music',
              settings: resp.players.music,
            });
          }
          if (resp.players.podcasts && params.type != 'Podcast Player') {
            players_.push({
              name: 'Podcasts',
              settings: resp.players.podcasts,
            });
          }
        }
        setPlayers(players_);
      }
      switch (params.type) {
        case 'Channel Player':
          if (resp.players != undefined) {
            if (resp.players.channels != undefined) {
              setTemplate(resp.players.channels.type);
              setSettings(resp.players.channels);
              form.setFieldsValue(resp.players.channels);
            }
          }
          break;
        case 'Radio Player':
          if (resp.players != undefined) {
            if (resp.players.radio != undefined) {
              setTemplate(resp.players.radio.type);
              setSettings(resp.players.radio);
              form.setFieldsValue(resp.players.radio);
            }
          }
          break;
        case 'CatchupTV Player':
          if (resp.players != undefined) {
            if (resp.players.catchuptv != undefined) {
              setTemplate(resp.players.catchuptv.type);
              setSettings(resp.players.catchuptv);
              form.setFieldsValue(resp.players.catchuptv);
            }
          }
          break;
        case 'Recording Player':
          if (resp.players != undefined) {
            if (resp.players.recordings != undefined) {
              setTemplate(resp.players.recordings.type);
              setSettings(resp.players.recordings);
              form.setFieldsValue(resp.players.recordings);
            }
          }
          break;
        case 'Cam Player':
          if (resp.players != undefined) {
            if (resp.players.cams != undefined) {
              setTemplate(resp.players.cams.type);
              setSettings(resp.players.cams);
              form.setFieldsValue(resp.players.cams);
            }
          }
          break;
        case 'Shorts Player':
          if (resp.players != undefined) {
            if (resp.players.shorts != undefined) {
              setTemplate(resp.players.shorts.type);
              setSettings(resp.players.shorts);
              form.setFieldsValue(resp.players.shorts);
            }
          }
          break;
        case 'Movie Player':
          if (resp.players != undefined) {
            if (resp.players.movies != undefined) {
              setTemplate(resp.players.movies.type);
              setSettings(resp.players.movies);
              form.setFieldsValue(resp.players.movies);
            }
          }
          break;
        case 'Series Player':
          if (resp.players != undefined) {
            if (resp.players.series != undefined) {
              setTemplate(resp.players.series.type);
              setSettings(resp.players.series);
              form.setFieldsValue(resp.players.series);
            }
          }
          break;
        case 'Courses Player':
          if (resp.players != undefined) {
            if (resp.players.courses != undefined) {
              setTemplate(resp.players.courses.type);
              setSettings(resp.players.courses);
              form.setFieldsValue(resp.players.courses);
            }
          }
          break;
        case 'Music Player':
          if (resp.players != undefined) {
            if (resp.players.music != undefined) {
              setTemplate(resp.players.music.type);
              setSettings(resp.players.music);
              form.setFieldsValue(resp.players.music);
            }
          }
          break;
        case 'Podcast Player':
          if (resp.players != undefined) {
            if (resp.players.podcasts != undefined) {
              setTemplate(resp.players.podcasts.type);
              setSettings(resp.players.podcasts);
              form.setFieldsValue(resp.players.podcasts);
            }
          }
          break;
      }
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    getMenuItems();
  });

  const selectTemplate = (value: any) => {
    setTemplate(value);
  };

  const getEditButtons = (element: any) => {
    return (
      <div className="row w-100 h-100" style={{ marginLeft: 15, marginBottom: 10 }}>
        <div className="row w-100 h-100" style={{ height: '100vh', width: '100vh', backgroundColor: '#222222', borderRadius: 5 }}>
          <div className="col"></div>
          <div style={{ width: 5, height: 45, cursor: 'pointer', borderTopLeftRadius: 5, borderBottomLeftRadius: 5, backgroundColor: '#ffffff', opacity: '1', marginTop: 5, marginBottom: 5 }}></div>
          {element.list && params.type.indexOf('Radio') < 0 && params.type.indexOf('Movie') < 0 && params.type.indexOf('Music') < 0 && params.type.indexOf('Podcast') < 0 && (
            <div style={{ padding: 0, width: 80, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: '#ffffff', opacity: '1', marginTop: 5, marginBottom: 5 }}>
              <div
                onClick={() => {
                  setEditData(element);
                  setEditType(element.type);
                  setEditShowListModal(true);
                }}
                className="h-100 d-flex align-items-center justify-content-center text-center"
              >
                <div>
                  <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faList} />
                  <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>CONTENT</div>
                </div>
              </div>
            </div>
          )}

          {element.texts && (
            <div style={{ padding: 0, width: 80, height: 45, borderRight: '1px solid #333333', cursor: 'pointer', backgroundColor: '#ffffff', opacity: '1', marginTop: 5, marginBottom: 5 }}>
              <div
                onClick={() => {
                  setEditData(element);
                  setEditType(element.type);
                  setEditTextMessageModal(true);
                }}
                className="h-100 d-flex align-items-center justify-content-center text-center"
              >
                <div>
                  <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faTextSize} />
                  <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>TEXTS</div>
                </div>
              </div>
            </div>
          )}
          <div
            className="ant-card-hoverable"
            style={{
              padding: 0,
              width: 70,
              height: 45,

              cursor: 'pointer',
              backgroundColor: '#ffffff',
              opacity: '1',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <div
              onClick={() => {
                setEditData(element);
                setEditType('Player');
                setEditComponentModal(true);
              }}
              className="h-100 d-flex align-items-center justify-content-center text-center"
            >
              <div>
                <FontAwesomeIcon style={{ cursor: 'pointer', color: '#333333' }} size={'1x'} icon={faBlockBrick} />
                <div style={{ color: '#333333', fontSize: 8, fontWeight: 'bold' }}>COMPONENTS</div>
              </div>
            </div>
          </div>

          <div style={{ width: 5, height: 45, cursor: 'pointer', borderTopRightRadius: 5, borderBottomRightRadius: 5, backgroundColor: '#ffffff', opacity: '1', marginTop: 5, marginBottom: 5, marginRight: 5 }}></div>
        </div>
      </div>
    );
  };
  const setSettingsFromOtherPlayer = () => {
    var find = players.find((p) => p.name == player);
    editComponent(find.settings);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <EditText
          theme={theme}
          visible={EditTextMessageModal}
          onSubmit={editComponent}
          onCancel={() => {
            setEditTextMessageModal(false);
          }}
          data={editData}
          type={editType}
          index={0}
        />
        <EditList
          theme={theme}
          visible={EditShowListModal}
          onSubmit={editComponent}
          onCancel={() => {
            setEditShowListModal(false);
          }}
          data={editData}
          type={editType}
          index={0}
        />
        <EditComponents
          visible={editComponentModal}
          onSubmit={editComponent}
          onCancel={() => {
            setEditComponentModal(false);
          }}
          application={application}
          data={editData}
          type={editType}
          index={0}
        />
        <div className="col-md-12 mb-2">
          <div className="container-fluid">
            <Form
              form={form}
              onFinish={async (values) => {
                await handleSubmit(values);
              }}
              onFinishFailed={(errorInfo) => {
                
              }}
            >
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4" style={{ paddingLeft: 0 }}>
                      <div className="card-bg-light p-3">
                        <h5 className="text-primary mb-4" style={{ paddingLeft: 0 }}>
                          {t('player-template')}
                        </h5>
                        {params.type != 'Music Player' && params.type != 'Podcast Player' && params.type != 'Radio Player' && (
                          <div className="col">
                            <Form.Item
                              style={{ padding: 0 }}
                              className="col-md-12"
                              rules={[
                                {
                                  required: true,
                                  message: t('template-selection-is-required'),
                                },
                              ]}
                              name="type"
                            >
                              <Select defaultValue={template} onChange={selectTemplate} className="default-select" placeholder="- Select Type -">
                                <Select.Option value={'Centric'} key={'Centric'}>
                                  {t('centric')}
                                </Select.Option>
                                <Select.Option value={'Sleek'} key={'Sleek'}>
                                  {t('sleek')}
                                </Select.Option>
                                <Select.Option value={'Minimal'} key={'Minimal'}>
                                  {t('minimal')}
                                </Select.Option>
                                <Select.Option value={'Big Buttons'} key={'Big Buttons'}>
                                  {t('big-buttons')}
                                </Select.Option>
                                {/* <Select.Option value={'Standard'} key={'Standard'}>Standard</Select.Option> */}
                                {/* <Select.Option value={'Modern'} key={'Modern'}>Modern</Select.Option> */}
                              </Select>
                            </Form.Item>
                          </div>
                        )}
                        {(params.type == 'Music Player' || params.type == 'Podcast Player' || params.type == 'Radio Player') && (
                          <div className="col">
                            <Form.Item
                              style={{ padding: 0 }}
                              className="col-md-12"
                              rules={[
                                {
                                  required: true,
                                  message: 'Template selection is required',
                                },
                              ]}
                              name="type"
                            >
                              <Select defaultValue={template} onChange={selectTemplate} className="default-select" placeholder="- Select Type -">
                                <Select.Option value={'Audio'} key={'Audio'}>
                                  {t('audio')}
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        )}
                        <div className="text-right mr-3 mt-3">
                          <Button loading={isLoading} htmlType="submit" className="mb-4 ant-btn btn btn-secondary rounded-pill width-200 height-48">
                            {t('update')}
                          </Button>
                        </div>
                      </div>
                      {players.length > 0 && (
                        <div className="card-bg-light p-3 mt-4">
                          <h5 className="text-primary" style={{ paddingLeft: 0 }}>
                            {t('copy-settings')}
                          </h5>
                          <div className="mb-4">{t('use-this-if-you-want-to-copy-settings-from-a-previous-setup-player')}</div>

                          <div className="col">
                            <Form.Item style={{ padding: 0 }} className="col-md-12">
                              <Select defaultValue={player} onChange={(value) => setPlayer(value)} className="default-select" placeholder={t('select-player-to-copy')}>
                                {players.map((player, index) => {
                                  return (
                                    <Select.Option value={player.name} key={index}>
                                      {player.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>

                          <div className="text-right mr-3 mt-3">
                            <Button loading={isLoading} onClick={() => setSettingsFromOtherPlayer()} className="mb-4 ant-btn btn btn-secondary rounded-pill width-200 height-48">
                              {t('copy-and-save-settings')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-md-8">
                      <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                        <div className="row">
                          {template != 'Select a Template' && getEditButtons(setting)}
                          {template == 'Minimal' && <img src={PlayerMinimal} alt={''} width={'100%'}></img>}
                          {template == 'Sleek' && <img src={PlayerSleek} alt={''} width={'100%'}></img>}
                          {template == 'Centric' && <img src={PlayerCentric} alt={''} width={'100%'}></img>}
                          {template == 'Big Buttons' && <img src={PlayerBigButtons} alt={''} width={'100%'}></img>}
                          {template == 'Audio' && <img src={PlayerAudio} alt={''} width={'100%'}></img>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Players;
