import { Button, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useState } from 'react';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import cmsDisclaimer from '../../../../../../services/areas/appbuilder/applications/services/index';
import { IMSNameParams } from '../../../../../dashboard';
import MessageDisclaimer from '../../../../../../common/components/AppBuilder/MessageDisclaimer/Translations';
import { AppBuilderContext } from '../../../../../../contexts/appbuilderContext';

const AddAlbum = () => {
  const history = useHistory();
  const [form] = useForm();
const { t } = useTranslation();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(AppBuilderContext);
  const [isAdding, setIsAdding] = useState(false);
  const [translations, setTranslations] = useState([] as any);

  const handleSubmit = async (values: any) => {
    setIsAdding(true);
    var lang = [] as any;
    translations.forEach((element) => {
      lang.push({
        name: element.name,
        description: element.description,
      });
    });
    let response = await cmsDisclaimer.Add_Services_Texts({
      name: values.name,
      translations: lang,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
    });
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('added-successfully'),
      });
      history.push(redirectUrl);
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-12 mb-2">
          <Link to={`${redirectUrl}/`}>{t('back-to-list')}</Link>
          <div className="row-md-12" style={{ borderBottom: '1px solid #cfcfcf' }}></div>
          <h2 className="page-title mt-4">{t('add-service-text')}</h2>
        </div>
      </div>
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <h4 className="text-primary mt-5 mb-3">{t('service-text')}</h4>
            <div className="card-bg-light pl-5 pt-3">
              <div className="row mt-4">
                <label className="col-md-2 col-form-label">{t('name')}</label>
                <Form.Item
                  className="col-md-3"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: t('name-is-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default" placeholder={t('my-name')} />
                </Form.Item>
              </div>
              <div className="row-md-10 mb-5 mr-5">
                <MessageDisclaimer fullEditor={true} service_name={service?.name} clientName={deployment?.name} setTranslations={setTranslations}></MessageDisclaimer>
              </div>
            </div>
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('add-service-text')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddAlbum;
