import { AutoComplete, Button, Input, } from 'antd';

import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentContext } from '../../../../../contexts/contentContext';
import { IMSNameParams } from '../../../../dashboard';
import cmsContent from '../../../../../services/areas/content/series/series';
import cmsLists from '../../../../../services/areas/content/series/lists';
import * as HttpStatusCodes  from 'http-status-codes';
import { useEffectOnce } from 'react-use';
import NestedLists from '../../../../../common/components/Content/Lists/NestedLists';
import moment from 'moment';
import { Spin } from '../../../../../common/components/spinner';
import { useTranslation } from 'react-i18next';

const Albums = () => {
    const { clientName } = useParams<IMSNameParams>();
    const { deployment } = useContext(ContentContext)
    const [loading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    // const [items, setItems] = useState([] as any);
    // const [list, setList] = useState([] as any);
    // const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
    // const [options_, setOptions_] = useState<{ value: string; label: string }[]>([]);

    // const [selectedItem, setSelectedItem] = useState([] as any);
    // const [selectedList, setSelectedList] = useState([] as any);

    // const [lists, setLists] = useState<{ value: any; label: string }[]>([]);
    // const [lists_, setLists_] = useState<{ value: any; label: string }[]>([]);

    // const getData = async () => {
    //     setIsLoading(true);
    //     let response = await cmsContent.AutoComplete_List_Series({
    //         query_params: {
    //             instance: clientName,
    //             cms: deployment?.name ?? ''
    //         },
    //     });
    //     if (response && response.status_code === HttpStatusCodes.OK) {
    //         var data_ = response.data.data;
            
    //         setItems(data_)
    //         var dataOut = [] as any;
    //         data_.forEach((item: any, index: any) => {
    //             dataOut.push(
    //                 {
    //                     value: item.name,
    //                     label: [renderImage("https://cloudtv.akamaized.net/" + item.images.widescreen), renderTitle(item.name)],

    //                 }
    //             )
    //         });
    //         setOptions(dataOut)
    //         setOptions_(dataOut)
    //     }
    //     setIsLoading(false);
    // };
    // const getList = async () => {
    //     setIsLoading(true);
    //     let response = await cmsLists.List_Lists({
    //         pageNumber: 0,
    //         pageSize: 1000,
    //         query_params: {
    //             instance: clientName,
    //             cms: deployment?.name ?? ''
    //         }
    //     });
    //     if (response && response.status_code === HttpStatusCodes.OK) {
    //         var data_ = response.data.data;
    //         setList(data_)
    //         var dataOut = [] as any;
    //         data_.forEach((item: any, index: any) => {
    //             dataOut.push(
    //                 {
    //                     value: item.name,
    //                     label: renderTitle(item.name),
    //                 }
    //             )
    //         });
    //         setLists(dataOut)
    //         setLists_(dataOut)
    //     }
    //     setIsLoading(false);
    // };

    // const addList = async () => {
    //     setIsLoading(true);
    //     if (selectedList != undefined) {
    //         if (selectedList.length != 0) {
    //             var items = [
    //                 ...selectedList.items,
    //                 selectedItem
    //             ]
    //             
    //             let response = await cmsLists.Update_List({
    //                 query_params: {
    //                     instance: clientName,
    //                     cms: deployment?.name ?? '',
    //                 },
    //                 _id: selectedList._id,
    //                 name: selectedList.name,
    //                 items: items,
    //                 publish: moment().format('llll')
    //             });

    //         }
    //     } else {
    //         await cmsLists.Add_List({
    //             query_params: {
    //                 instance: clientName,
    //                 cms: deployment?.name ?? '',
    //             },
    //             name: selectedList.name,
    //             items: [
    //                 ...selectedList.items,
    //                 selectedItem._id
    //             ],
    //             publish: moment().format('llll')
    //         });
    //     }
    //     await getData()
    //     await getList()
    //     setIsLoading(false);
    // };

    // const renderTitle = (title: string) => (
    //     <span style={{ marginLeft: 20 }}>
    //         {title}
    //     </span>
    // );
    // const renderImage = (image: string) => (
    //     <span>
    //         <img src={image} style={{ width: 150 }}></img>
    //     </span>
    // );

    // useEffectOnce(() => {
    //     (async () => await getData())();
    //     (async () => await getList())();
    // });

    // const onChange = (data: any) => {
    //     var test = items.find((x: any) => x.name == data);
    //     if (test != undefined) {
    //         setSelectedItem(test);
    //     }
    // };
    // const handleSearch = (value: string) => {
    //     let res = [] as any;
    //     res = options_.filter(o => o.value.indexOf(value) > -1)
    //     setOptions(res);
    // };
    // const onChange2 = (data: any) => {
    //     if (list.length > 0) {
    //         var test = list.find((x: any) => x.name == data);
    //         if (test != undefined) {
    //             setSelectedList(test)
    //         } else {
    //             setSelectedList({
    //                 name: data,
    //                 items: []
    //             })
    //         }
    //     } else {
    //         setSelectedList({
    //             name: data,
    //             items: []
    //         })
    //     }
    // };
    // const handleSearch2 = (value: string) => {
    //     let res = [] as any;
    //     res = lists_.filter(o => o.value.indexOf(value) > -1)
    //     setLists_(res);
    // };

    return (
        <div className="container-fluid wrapper">
            <div className="row">
                <div className="col-md-6 mb-2">
                    <h2 className="page-title">{t('series-lists')}</h2>
                    <p className="h6 font-weight-normal line-height-26">
                        {t('content-lists-are-used-to-build-up-your-home-page-in-the-apps-you-can-create-lists-for-trending-featured-new-or-upcoming-content-content-lists-can-be-created-from-any-kind-of-content-type')}
                    </p>
                </div>
                <div className="col-md-6"></div>
            </div>


            <div className='row'>
                <div className="col-12 mt-5 card-bg-light pt-3">
                    {loading &&
                        <div>
                            <div className="text-center  h-100 w-100">
                                <div className="col-md-12 align-self-center">
                                    <Spin size="large" spinning={true} />
                                </div>
                            </div>
                        </div>
                    }
                    {!loading &&
                        <NestedLists type={"Series"}></NestedLists>
                    }
                </div>
            </div>
        </div>
    );
};

export default Albums;
