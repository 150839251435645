import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Table from '../../../../../common/components/Content/Table';
import { IMSNameParams } from '../../../../dashboard';
import cmsSeries from '../../../../../services/areas/content/series/series';
import { useContext, useState } from 'react';
import { ContentContext } from '../../../../../contexts/contentContext';
import * as HttpStatusCodes  from 'http-status-codes';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import { Button } from 'antd';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import { useTranslation } from 'react-i18next';
import { NotVisibleForRole } from '../../../../../common/components/VisibleForRole';
import { TVMSRoles } from '../../../../../constants/TVMSRoles';

const Albums = () => {
  const { clientName } = useParams<IMSNameParams & { deployment: string }>();
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const addUrl = url;//.split('/').slice(0, -1).join('/');
  let params = useParams<IMSNameParams & { deployment: string }>();
  const { deployment } = useContext(ContentContext);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const [exporting, setExporting] = useState(false);
  const { t } = useTranslation();
  
  const exportContent = async () => {
    setExporting(true);
    let values = {
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsSeries.Export_Series(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      var series = response.data.data as any;
      var translationsSeasons = [] as any;
      var translationsEpisodes = [] as any;
      var episodes = [] as any;
      var seasons = [] as any;
      var languages = [] as any;
      var currencies = [] as any;
      var tags = [] as any;

      languagesDeployment.forEach((langauge: any) => {
        languages.push({
          language: langauge.name,
        });
      });

      series.forEach((serie: any, index: any) => {
        var seasons_ = serie.seasons;
        seasons_.forEach((season: any, index_: any) => {
          var seasonsTranslations = season.translations;
          seasonsTranslations.forEach((seasonTranslation: any) => {
            //add season translations
            translationsSeasons.push({
              name: seasonTranslation.name,
              season_name: season.name,
              description: seasonTranslation.description,
            });
          });

          const tagsTranslations = season.tags;
          if (tagsTranslations != undefined && tagsTranslations[0] != undefined && tagsTranslations[0].tag != undefined) {
            tagsTranslations.forEach((trans: any) => {
              trans.tag.forEach((tag: any) => {
                tags.push({
                  _id: season.name,
                  name: tag.name,
                  description: tag.description,
                });
              });
            });
          }

          // //lets do episodes
          var episodes_ = season.episodes;
          episodes_.forEach((episode: any, index__: any) => {
            var episodeTranslations = episode.translations;
            episodeTranslations.forEach((episodeTranslation: any) => {
              //add episode translations
              translationsEpisodes.push({
                name: episodeTranslation.name,
                episode_name: episode.name,
                description: episodeTranslation.description,
              });
            });

            if (serie.payperview_currencies) {
              serie.payperview_currencies.forEach((currency: any) => {
                currencies.push({
                  _id: serie.name,
                  ...currency,
                });
              });
            }
            delete serie.payperview_currencies;

            delete series[index].seasons[index_].episodes[index__].translations;
            //add episodes
            // series[index].seasons[index_].episodes[index__].season = season._id;
            series[index].seasons[index_].episodes[index__] = {
              ...series[index].seasons[index_].episodes[index__],
            };
            episodes.push(series[index].seasons[index_].episodes[index__]);
          });
          delete series[index].seasons[index_].translations;
          delete series[index].seasons[index_].tags;
          delete series[index].seasons[index_].episodes;
          series[index].seasons[index_] = {
            ...series[index].seasons[index_],
          };
          //add season
          seasons.push(series[index].seasons[index_]);
        });
        delete series[index].seasons;
      });

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(series);
      const worksheet1 = XLSX.utils.json_to_sheet(seasons);
      const worksheet2 = XLSX.utils.json_to_sheet(episodes);
      const worksheet3 = XLSX.utils.json_to_sheet(currencies);

      languages.forEach((language: any, index: any) => {
        var episodes = translationsEpisodes.filter((e: any) => e.name == language.language);
        var data = [] as any;
        episodes.forEach((episode: any, index_: any) => {
          var seasons = translationsSeasons.filter((e: any) => e.name == language.language);
          var test = seasons[index_];
          //var season = '';
          var season_translation = '';
          if (test != undefined) {
            //season = test.season_name;
            season_translation = test.description;
          }
          data.push({
            //season: season,
            season_translation: season_translation,
            episode: episode.episode_name,
            episode_translation: episode.description,
          });
        });
        languages[index].data = data;
      });

      XLSX.utils.book_append_sheet(workbook, worksheet, t('series'));
      XLSX.utils.book_append_sheet(workbook, worksheet1, t('seasons'));
      XLSX.utils.book_append_sheet(workbook, worksheet2, t('episodes'));
      XLSX.utils.book_append_sheet(workbook, worksheet3, t('currencies'));

      languages.forEach((language: any) => {
        XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(language.data), language.language);

        const tagsPerLanguage = tags.filter((l: any) => l.name === language.language);
        if (tagsPerLanguage !== undefined) {
          const result2 = tagsPerLanguage.map((obj: any) => ({ _id: obj['_id'], tags: obj['description'] }));
          XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(result2), 'tags_' + language.language);
        }
      });

      XLSX.writeFile(workbook, 'Series_' + clientName + '_' + deployment?.name + '_' + moment().format('YYYY-MM-DD') + '.xlsx');
    }
    setExporting(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('series')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('all-your-series-seasons-episodes-in-the-system-you-can-add-as-many-as-you-want')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start"></div>
        <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start">
        <NotVisibleForRole roles={[TVMSRoles.Provider]}>
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${redirectUrl}/actions/importer-excel`}>
            {t('import-series')}
          </Link>
          </NotVisibleForRole>
          <NotVisibleForRole roles={[TVMSRoles.Provider]}>
          <Button disabled={exporting} loading={exporting} className="btn btn-secondary rounded-pill btn-default float-right width-150 height-48" onClick={exportContent}>
            {t('export-series')}
          </Button>
          </NotVisibleForRole>
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${addUrl}/add`}>
            {t('add-series')}
          </Link>
        </div>
      </div>
   
      <div className="row">
        <div className="col-12 mt-4">
          <Table service_name={params.deployment} clientName={clientName} type={'series'} variant={'normal'}></Table>
        </div>
      </div>
    </div>
  );
};

export default Albums;
