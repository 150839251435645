import { Button, Col, Row, Tooltip } from 'antd';
import * as HttpStatusCodes from 'http-status-codes';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { ICMS_Lists } from '../../../interfaces/forms/common/ICMS_Lists';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import ReactBCTable from '../ReactBCTable';
import { IReactBCTableColumn } from '../ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { faMemo, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cmsAgeRatings from '../../../services/areas/appbuilder/applications/ageratings/index';
import cmsMessages from '../../../services/areas/appbuilder/applications/messages/index';
import cmsDisclaimers from '../../../services/areas/appbuilder/applications/disclaimer/index';
import cmsLogins from '../../../services/areas/appbuilder/applications/login/index';
import cmsRegistrations from '../../../services/areas/appbuilder/applications/registration/index';
import cmsAppUpdates from '../../../services/areas/appbuilder/applications/appupdate/index';
import cmsServices from '../../../services/areas/appbuilder/applications/services/index';
import appService from '../../../services/areas/appbuilder/applications/application';
import { useRouteMatch } from 'react-router';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Table = (params: { service_name: any; clientName: any; type: any }) => {
  const { url } = useRouteMatch();
  const { id } = useParams<any>();
  const [data, setData] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [tableSearchState, setTableSearchState] = useState<ICMS_Lists>({
    pageNumber: 1,
    pageSize: 50,
    query_params: {
      instance: params.clientName,
      cms: params.service_name ?? '',
    },
  });
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);
  const { t } = useTranslation();

  useEffectOnce(() => {
    getContent(tableSearchState);
  });

  const deleteItem = (id: string, app_id?: string) => {
    switch (params.type) {
      case 'ageratings':
        (async () => await deleteAgeRating(id))();
        break;
      case 'messages':
        (async () => await deleteMessages(id))();
        break;
      case 'disclaimers':
        (async () => await deleteDisclaimer(id))();
        break;
      case 'services':
        (async () => await deleteServices(id))();
        break;
      case 'logins':
        (async () => await deleteLogin(id))();
        break;
      case 'registrations':
        (async () => await deleteRegistration(id))();
        break;
      case 'appupdates':
        (async () => await deleteAppUpdates(id, app_id))();
        break;
    }
  };

  const openDetails = (_id: string) => {
    return `${url}/details/${_id}`;
  };

  useEffectOnce(() => {
    getContent(tableSearchState);
  });

  const getContent = (tableSearchState) => {
    switch (params.type) {
      case 'ageratings':
        (async () => await getAgeRatings(tableSearchState))();
        break;
      case 'disclaimers':
        (async () => await getDiclaimer(tableSearchState))();
        break;
      case 'logins':
        (async () => await getLogin(tableSearchState))();
        break;
      case 'services':
        (async () => await getServices(tableSearchState))();
        break;
      case 'registrations':
        (async () => await getRegistration(tableSearchState))();
        break;
      case 'messages':
        (async () => await getMessages(tableSearchState))();
        break;
      case 'appupdates':
        (async () => await getAppUpdates(tableSearchState))();
        break;
    }
  };

  //channels
  const deleteAgeRating = async (_id: string) => {
    setIsLoading(true);
    let response = await cmsAgeRatings.Delete_AgeRating({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAgeRatings(tableSearchState);
    }
    setIsLoading(false);
  };
  const getAgeRatings = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsAgeRatings.List_AgeRatings(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setIsLoading(false);
  };

  const deleteMessages = async (_id: string) => {
    setIsLoading(true);
    let response = await cmsMessages.Delete_Message({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getMessages(tableSearchState);
    }
    setIsLoading(false);
  };
  const getMessages = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsMessages.List_Messages(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setIsLoading(false);
  };

  const deleteDisclaimer = async (_id: string) => {
    setIsLoading(true);
    let response = await cmsDisclaimers.Delete_Disclaimers_Texts({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getDiclaimer(tableSearchState);
    }
    setIsLoading(false);
  };
  const getDiclaimer = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsDisclaimers.List_Disclaimers_Texts(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setIsLoading(false);
  };

  const deleteLogin = async (_id: string) => {
    setIsLoading(true);
    let response = await cmsLogins.Delete_Logins_Texts({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getLogin(tableSearchState);
    }
    setIsLoading(false);
  };
  const getLogin = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsLogins.List_Logins_Texts(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setIsLoading(false);
  };

  const deleteServices = async (_id: string) => {
    setIsLoading(true);
    let response = await cmsServices.Delete_Services_Texts({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getServices(tableSearchState);
    }
    setIsLoading(false);
  };
  const getServices = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsServices.List_Services_Texts(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setIsLoading(false);
  };

  const deleteRegistration = async (_id: string) => {
    setIsLoading(true);
    let response = await cmsRegistrations.Delete_Registrations_Texts({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getRegistration(tableSearchState);
    }
    setIsLoading(false);
  };
  const getRegistration = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsRegistrations.List_Registrations_Texts(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
      setPagination(response.data);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setIsLoading(false);
  };

  const deleteAppUpdates = async (_id: string, app_id: any) => {
    setIsLoading(true);
    let response = await cmsAppUpdates.Delete_AppUpdate({
      _id: _id,
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getUpdates(app_id);
    }
  };
  const getUpdates = async (app_id) => {
    let response = await cmsAppUpdates.List_AppUpdate({
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
      pageNumber: undefined,
      pageSize: undefined,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      var updates = [] as any;
      if (resp.data.length > 0) {
        updates = resp.data.filter((r) => r.app_id == app_id);
      }
      getApplication(updates, app_id);
    }
  };

  const getApplication = async (updates, app_id) => {
    let response = await appService.Get_Application({
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
      _id: app_id,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      updateApplication(updates, resp);
    }
  };

  const updateApplication = async (updates: any, application: any) => {
    let response = await appService.Update_Application({
      query_params: {
        cms: params.service_name,
        instance: params.clientName,
      },
      _id: application._id,
      cms: params.service_name,
      instance: params.clientName,
      ...application,
      ota: updates,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      getAppUpdates(tableSearchState);
    }
  };

  const getAppUpdates = async (list: ICMS_Lists) => {
    setIsLoading(true);
    let response = await cmsAppUpdates.List_AppUpdate(list);
    if (response && response.status_code === HttpStatusCodes.OK) {
      var updates = response.data.data;
      setData(updates);
      setPagination(response.data);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    setIsLoading(false);
  };

  const columns: IReactBCTableColumn<any>[] = [
    {
      title: t('name'),
      dataKey: 'name',
      width: '100%',
    },
    {
      dataKey: 'actions',
      title: t('actions'),

      cell: (row) => {
        return (
          <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
            <Row className="align-items-center">
              <div onClick={() => deleteItem(row._id, params.type == 'appupdates' ? row.app_id : '')}>
                <Tooltip placement="top" title={`Details`}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faTrashCan} />
                    <div>{t('delete')}</div>
                  </Button>
                </Tooltip>
              </div>
            </Row>
            <Row className="align-items-center">
              <a href={openDetails(row._id)}>
                <Tooltip placement="top" title={`Details`}>
                  <Button type="text" className="mx-2 cursor-pointer" style={{ height: '100%' }}>
                    <FontAwesomeIcon className="action-icon" icon={faMemo} />
                    <div>{t('open-details')}</div>
                  </Button>
                </Tooltip>
              </a>
            </Row>
          </Col>
        );
      },
    },
  ];

  return (
    <div>
      <ReactBCTable tableId={'cms_table'} columns={columns} data={data} onChange={(pageNumber, pageSize, pageSort, filters) => getContent({ pageNumber, pageSize, pageSort, filters })} isLoading={isLoading} totalRecords={pagination.count} pageSize={pagination.pageSize} currentPage={pagination.pageNumber} />
    </div>
  );
};
export default Table;
