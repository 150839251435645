import { Button, Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cmsService from '../../../../../../../services/areas/content/cams/cams';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import { Cams } from '../../../../../../../interfaces/forms/areas/content/cams/cams';
import CamStreams from '../../../../../../../common/components/Content/CamStreams/CamStreams';
import InteractiveTV from '../../../../../../../common/components/Content/InteractiveTV/InteractiveTV';
import transcoderService from '../../../../../../../services/areas/content/transcoders/transcoders/index';

const AddNewMusicAlbum = (data: any) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(ContentContext);
  const [isAdding, setIsAdding] = useState(false);
  const [security, setSecurity] = useState([] as any);
  const [interactivetv, setInteractivetv] = useState([] as any);
  const [multiscreen, setMultiscreen] = useState([] as any);
  const [transcoders, setTranscoders] = useState([] as any);

  const [form] = useForm();
const { t } = useTranslation();

  useEffect(() => {
    getTranscoders();
    setMultiscreen(data.data.multiscreen);
    setInteractivetv(data.data.interactivetv);
    setSecurity(data.data.security);
    form.setFieldsValue(data.data);
  }, [data]);

  const handleSubmit = async (values: Cams) => {
    setIsAdding(true);
    if (data.data.payperview != undefined) {
      if (data.data.payperview.currencies != undefined) {
        if (data.data.payperview.currencies.length > 0) {
          if (data.data.payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          }
        }
      }
    }

    var transcoder = undefined;
    if (interactivetv.transcoder != undefined) {
      var test = transcoders.find((t) => t._id == interactivetv.transcoder);
      if (test != undefined) {
        transcoder = test;
      }
    }

    values = {
      _id: _id,
      ...data.data,
      ...values,
      interactivetv: {
        catchuptv_enabled: interactivetv.catchuptv_enabled,
        recordings_enabled: interactivetv.recordings_enabled,
        pausetv_enabled: interactivetv.pausetv_enabled,
        alternative_url: values.interactivetv?.alternative_url,
        catchuptv_days: values.interactivetv?.catchuptv_days,
        transcoder: transcoder,
      },
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Update_Cam(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('updated-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
    setIsAdding(false);
  };

  const getTranscoders = async () => {
    let response = await transcoderService.List_Transcoders({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      setTranscoders(response.data.data);
    }
  };

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          values.transcoder = form.getFieldValue('interactivetv.transcoder');
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <InteractiveTV data={interactivetv} setInteractiveTV={setInteractivetv} />

            <CamStreams />
          </div>
        </div>
        <div className="text-right">
          <Button htmlType="submit" disabled={isAdding} loading={isAdding} className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
            {t('update-cam')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
