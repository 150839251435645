import { Card, notification, Tabs, Typography, Form as AntdForm } from 'antd';
import React, { useContext } from 'react';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { Form, Modal, PrimaryButton, PrimaryButtonSM } from '../../../../common/components/form';
import { PageTitle } from '../../../../common/components/pageTitle';
import { IPaymentProvider } from '@mwaretv/payment/dist/definitions';
import { usePaymentProviderSchemas } from './paymentProviderSchemas';
import { Link, useParams } from 'react-router-dom';
import { PaymentMethodFields } from './shared/form';
import { usePaymentProviderAdd } from '../../../../services/areas/billing/paymentProviderService';
import { BillingContext } from '../../../../contexts/billingContext';
import { IMSNameParams } from '../../../dashboard';
import { useTranslation } from 'react-i18next';

const List = () => {
  const { service } = useContext(BillingContext);
  const { data: allPaymentProviders } = usePaymentProviderSchemas();
  const { t } = useTranslation();
  const availableCountries = service?.countries.map((x) => x.iso3);
  const availableCurrencies = service?.currencies.map((x) => x.isoCurrency);

  // Payment providers must include supported countries / currencies (or 'Any')
  const paymentProviders = allPaymentProviders?.filter((provider) => {
    const hasCurrency = provider.currencies === 'Any' || provider.currencies.some((currency) => availableCurrencies?.includes(currency));
    const hasCountry = provider.countries === 'Any' || provider.countries.some((country) => availableCountries?.includes(country));

    return hasCurrency && hasCountry;
  });

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('payment-gateways')} subtitle={t('select-a-payment-gateway-and-configure-it-your-new-gateway-will-be-stored-under-my-payment-gateways')} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <p>
            {t('showing')} {paymentProviders?.length ?? 0} {t('out-of')} {allPaymentProviders?.length ?? 0} {t('providers-available-for-this-service-based-on-currencies-and-countries')}
          </p>
          <RegionTabs />
        </div>
      </div>
    </ContainerWrapper>
  );
};

const RegionTabs = () => {
  const { service } = useContext(BillingContext);
  const { data: allPaymentProviders } = usePaymentProviderSchemas();
  const { t } = useTranslation();
  const availableCountries = service?.countries.map((x) => x.iso3);
  const availableCurrencies = service?.currencies.map((x) => x.isoCurrency);

  // Payment providers must include supported countries / currencies (or 'Any')
  const paymentProviders =
    allPaymentProviders?.filter((provider) => {
      const hasCurrency = provider.currencies === 'Any' || provider.currencies.find((currency) => availableCurrencies?.includes(currency)) != null;
      const hasCountry = provider.countries === 'Any' || provider.countries.find((country) => availableCountries?.includes(country)) != null;

      return hasCurrency && hasCountry;
    }) ?? [];

  type Region = IPaymentProvider['regions'][number];

  const regions: Region[] = [t('global'), t('asia'), t('africa'), t('north-america'), t('south-america'), t('europe'), t('oceania'), t('antarctica')];

  const makeProviderTab = (region: Region) => ({
    label: region,
    key: region.toLowerCase().replace(' ', '-'),
    children: <ProviderGrid providers={paymentProviders.filter((x) => x.regions.includes(region))} />,
  });

  return <Tabs className="default-tab" items={regions.map((region) => makeProviderTab(region))} />;
};

type ProviderGridProps = {
  providers: IPaymentProvider[];
};

const ProviderGrid = ({ providers }: ProviderGridProps) => {
  return (
    <div className="row">
      {providers.map((provider, i) => (
        <div className="col-12 col-md-6 mb-4" key={i}>
          <ProviderCard {...{ provider }} />
        </div>
      ))}
    </div>
  );
};

type ProviderCardProps = {
  provider: IPaymentProvider;
};

const ProviderCard = ({ provider }: ProviderCardProps) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <>
      <Card>
        <img height={100} src={provider.logo} alt={provider.name} />
        <div className="card-bg-light p-3">
          <p className="mt-1">{provider.helpText}</p>
        </div>
        <div className="text-right">
          <PrimaryButton onClick={() => setModalOpen(true)}>{t('setup-gateway')}</PrimaryButton>
        </div>
      </Card>
      <ProviderModal {...{ provider }} open={modalOpen} onCancel={() => setModalOpen(false)} />
    </>
  );
};

type ProviderModalProps = {
  provider: IPaymentProvider;
  open: boolean;
  onCancel: () => void;
};

export const ProviderModal = ({ provider, open, onCancel }: ProviderModalProps) => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const [form] = AntdForm.useForm();
  const { t } = useTranslation();
  const { isLoading, mutate } = usePaymentProviderAdd(
    { params: { instance: clientName, cms: deployment?.name ?? '', crm: service?.name || '' } },
    {
      onSuccess: () => {
        notification.success({ message: t('payment-gateway-saved') });
        form.resetFields();
        onCancel();
      },
      onError: () => {
        notification.error({
          message: t('payment-gateway-could-not-be-saved-please-try-again-later'),
        });
      },
    }
  );

  const getProviderLogo = (logo: string) => {
    if (logo.includes('localhost')) {
      return new URL(new URL(logo).pathname, process.env.REACT_APP_BACKEND_API_BASE_URL).href;
    } else {
      return logo;
    }
  };
  return (
    <Modal
      {...{ open, onCancel }}
      width={1000}
      onOk={() => {
        form.submit();
      }}
    >
      <div className="container">
        <div className="row mt-3">
          <div className="col col-lg-6 mt-4">
            <div className="card-bg-light p-3 pb-3 mt-2 mb-2">
              <img style={{ marginLeft: -10, marginBottom: -10 }} height={75} src={provider ? getProviderLogo(provider.logo) : undefined} alt={provider?.name} />
            </div>
            <div className="card-bg-light p-3 pb-3 mb-2">
              <Typography.Title level={5} className="mt-2">
                {t('setup-information')}
              </Typography.Title>
              {(provider?.infotext as string)?.split('\n').map((text) => (
                <p className="mt-3">{text}</p>
              ))}
            </div>
            <div className="card-bg-light p-3 pb-3 mb-2">
              <Typography.Title level={5} className="mt-2">
                {t('payment-methods')}
              </Typography.Title>
              <div className="mt-3 mb-3">
                {provider.methods.map((method, i) => (
                  <img key={i} className="mr-2" height={48} src={getProviderLogo(method.logo)} alt={provider.name} />
                ))}
              </div>
            </div>
            <div className="card-bg-light p-3 pb-3 mb-2">
              <Typography.Title level={5} className="mt-2">
                {t('regions')}
              </Typography.Title>
              <div className="mt-3 mb-3">
                <ul>
                  {provider.regions.map((region, i) => (
                    <li key={i}>{region}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="card-bg-light p-3 mb-2">
              <Typography.Title level={5} className="mt-3">
                {t('supported-countries')}
              </Typography.Title>
              <ul>{provider.countries === 'Any' ? provider.countries : provider.countries.map((country, i) => <li key={i}>{country}</li>)}</ul>
            </div>

            <div className="card-bg-light p-3 mb-2">
              <Typography.Title level={5} className="mt-3">
                {t('supported-currencies')}
              </Typography.Title>

              <ul>{provider.currencies === 'Any' ? provider.currencies : provider.currencies.map((country, i) => <li key={i}>{country}</li>)}</ul>
            </div>

            <div className="card-bg-light p-3 pb-4 mb-2">
              <Typography.Title level={5} className="mt-2">
                {t('more-info')}
              </Typography.Title>
              <a target="_blank" rel="noreferrer" href={provider?.url || ''}>
                {t('visit-website')}
              </a>
            </div>
          </div>
          <div className="col col-lg-6">
            <div>
              <Typography.Title level={5}>{t('gateway-settings')}</Typography.Title>
            </div>
            <Form
              {...{ form }}
              layout="vertical"
              initialValues={{ payment_provider_id: provider.id }}
              disabled={isLoading}
              onFinish={(values) => {
                mutate(values);
              }}
            >
              <PaymentMethodFields />
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default List;
