import NestedCurations from '../../../../../common/components/Content/Curations/NestedCurations';
import { useTranslation } from 'react-i18next';

const Albums = () => {
    const { t } = useTranslation();
    return (
        <div className="container-fluid wrapper">
            <div className="row">
                <div className="col-md-6 mb-2">
                    <h2 className="page-title">{t('series-curations')}</h2>
                    <p className="h6 font-weight-normal line-height-26">
                        {t('content-curations-are-used-to-build-up-your-search-page-in-the-apps-you-can-create-curations-for-trending-featured-new-or-upcoming-content-content-curations-can-be-created-from-any-kind-of-content-type')}
                    </p>
                </div>
                <div className="col-md-6"></div>
            </div>


            <div className='row'>
                <div className="col-12 mt-5 card-bg-light pt-3">
               
                        <NestedCurations type={"Series"}></NestedCurations>
                
                </div>
            </div>
        </div>
    );
};

export default Albums;
