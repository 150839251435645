
import {  useState } from 'react';
import { IReactBCTableColumn } from '../../ReactBCTable/Interfaces/IReactBCTableColumn.interface';
import { IPaginatedAndFilterResponse, PaginatedAndFilterResponseDefaultValues } from '../../../../interfaces/responses/common/IPaginatedAndFilterResponse';
import ReactBCTable from '../../ReactBCTable';
import ReactDragCurationView from 'react-drag-listview';
import { useTranslation } from 'react-i18next';

const ExpandedPackageRender = (params: { package: any; deployment: any; clientName: any; type: any; data: any; isLoading: boolean; onSubmit: any }) => {
  const [data, setData] = useState(params.data);
  const [pagination, setPagination] = useState<IPaginatedAndFilterResponse>(PaginatedAndFilterResponseDefaultValues);
  const { t } = useTranslation();
  const dragProps = {
    onDragEnd(fromIndex: any, toIndex: any) {
      const data_ = [...data.categories];
      const item = data_.splice(fromIndex, 1)[0];
      data_.splice(toIndex, 0, item);
      var out = {
        ...data,
        categories: data_,
      };
      setData(out);
      params.onSubmit(out);
    },
    lineClassName: 'global-drag-line',
    handleSelector: '.drag-handle',
  };

  const categoryColumns: IReactBCTableColumn<[]>[] = [
    {
      title: t('image'),
      dataKey: 'images',
      width: '10%',
      cell: (row: any) => (
        <div className="text-center">
          <img alt={params.type} style={{ height: '100px' }} src={'https://cloudtv.akamaized.net/' + (params.type == "Movies" ? row.images.poster : params.type == "Series"|| params.type == "Courses" || params.type == "Games" || params.type == "Shorts"  ? row.images.widescreen : row.images.square)} />
        </div>
      ),
    },
    {
      title: t('content-name'),
      dataKey: 'name',
      cell: (row: any) => <span>{row.name}</span>,
    },

  ];

  return (
    <div className="row mt-2 ml-2">
      <div className="col-md-12 mt-4">
        <ReactDragCurationView {...dragProps}>
          <ReactBCTable key={JSON.stringify(data.items)} isLoading={params.isLoading} tableId={`tablebundles`} data={data.items} pageSize={pagination.pageSize} totalRecords={pagination.count} columns={categoryColumns} />
        </ReactDragCurationView>
      </div>
    </div>
  );
};
export default ExpandedPackageRender;
