import axiosInstance from '../../../../utils/axios';
import { useQuery } from '@tanstack/react-query';
import { ISMSGateway } from '@mwaretv/communication/dist/sms/definitions/interfaces/ISMSGateway';

export const useSmsGatewaySchemas = () =>
  useQuery(
    ['smsSchemas'],
    async () => {
      const response = await axiosInstance.get<ISMSGateway[]>(`/v1/billing/sms-gateway-schemas`);
      return response.data;
    },
    {
      retry: true,
    }
  );
