import React from 'react';
import { Input, Item, TextArea } from '../../../../../common/components/form';
import { Form, Switch } from 'antd';
import { IPushGateway } from '@mwaretv/communication/dist/push/definitions/interfaces/IPushGateway';

import { useTranslation } from 'react-i18next';

type PushGatewayFormProps = {
  gateway: IPushGateway | null;
};

const PushGatewayForm = ({ gateway }: PushGatewayFormProps) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();

  return (
    <div className="col-12  mt-3">
      {!gateway && <span>{t('invalid-push-gateway')}</span>}
      {gateway && (
        <>
          <div className="card-bg-light pl-3 pr-3 pt-3 mt-2 pb-4">
            <Item name={['push_gateway_id']} hidden />
            <Item name={['name']} label={t('name')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: t('name-is-required') }]}>
              <Input />
            </Item>
          </div>
          <div className="card-bg-light pl-3 pr-3 pt-3 mt-2 pb-4">
            <Item name={['enabled']} label={t('enabled')} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Switch defaultChecked={form.getFieldValue('enabled')} />
            </Item>
          </div>
          <div className="card-bg-light pl-3 pr-3 pt-3 mt-2 pb-4">
            {Object.keys(gateway.fields).map((k, i) => {
              console.log(gateway.fields)
              return (
                <div key={i}>
                  {gateway.fields[k].type === 'string' &&  gateway.fields[k].label !== 'Private Key value from service account json' && (
                    <Item className="mt-3" name={['settings', k]} label={gateway.fields[k].label} labelCol={{ span: 24 }}>
                      <Input />
                    </Item>
                  )}
                  {gateway.fields[k].type == 'string' && gateway.fields[k].label === 'Private Key value from service account json' && (
                    <Item className="mt-3" name={['settings', k]} label={gateway.fields[k].label} labelCol={{ span: 24 }}>
                      <TextArea rows={6} />
                    </Item>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default PushGatewayForm;
