import { ChartInput } from '@mwaretv/database/build/backend/util/queryGenerator';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

export const ColumnChart = ({ chartInput }: { chartInput: ChartInput }) => {
  const { t } = useTranslation();
  
  const firstSeries = chartInput.series && chartInput.series.length > 0 ? chartInput.series[0] : undefined;

  const options = {
    chart: {
      animations: {
        enabled: true,
      },
      background: '',
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#5868bd'],
    xaxis: {
      categories: firstSeries?.data?.map((item) => {
        return item.key;
      }) ?? [],
    },
    yaxis: {
      decimalsInFloat: firstSeries?.decimals ?? 0,
    },
  }
  
  const chartOptions: ApexOptions = {
    ...options,
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    noData: {
      text: t('chart-design-no-data'),
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#505972',
        fontSize: '28px',
        fontFamily: undefined
      }
    }
  };

  const series = chartInput.series?.map(seriesInstance => {
    return {
      name: seriesInstance.dataLabel ?? t('amount'),
      data: seriesInstance.data?.map((item) => {
        return item.value;
      }) ?? [],
    };
  });

  return <ReactApexChart options={chartOptions} series={series} type="bar" height="300" />;
};
