import { Button, Form, Input, Modal, Select, notification } from 'antd';
import { Spin } from '../../spinner';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import appService from '../../../../services/areas/appbuilder/applications/application';
import * as HttpStatusCodes  from 'http-status-codes';
import { IMSNameParams } from '../../../../pages/dashboard';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { useParams } from 'react-router-dom';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { useEffectOnce } from 'react-use';
import { IABP_Lists } from '../../../../interfaces/forms/common/IABP_Lists';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base';

export type LayoutProps = {
  cloningFinished: () => void;
  application: any;
  visible: boolean;
  onCancel: () => void;
};

const LayoutModel = (props: LayoutProps) => {
  const { visible, cloningFinished, application, onCancel } = props;
  const [cloning, setCloning] = useState(false);
  const [form] = useForm();
  const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [allServices, setAllServices] = useState([] as any);
  const [selectedService, setSelectedService] = useState([] as any);
  const [languages, setLanguages] = useState([] as any);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];

  const setAllLanguages = () => {
    var languagesOut = [] as any;
    languagesDeployment.forEach((element, index) => {
      languagesOut.push({
        label: languagesDeployment[index].name,
        value: languagesDeployment[index].name,
      });
    });
    setLanguages(languagesOut);
  };

  useEffect(() => {
    setAllLanguages();
  }, [languagesDeployment]);

  useEffectOnce(() => {
    setAllServices(deployment?.services);
  });

  useEffect(() => {
    if (application != undefined) {
      form.setFieldsValue(application);
    }
  }, [application]);

  const handleSubmit = async (values: any) => {
    if (values.branding.name != application.branding.name) {
      if (values.branding.packageid != application.branding.packageid) {
        var langs = [] as any;
        languages.forEach((language) => {
          var lang_ = languagesDeployment.find((l) => l.name == language.label);
          if (lang_ != undefined) {
            langs.push({
              name: lang_.name,
              code: lang_.code,
            });
          }
        });

        setCloning(true);
        let responseApp = await appService.Add_Application({
          query_params: {
            cms: deployment?.name ?? '',
            instance: clientName,
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', '').APPBUILDER.IMAGES,
          },
          ...application,
          deployment: deployment?.name,
          client: clientName,
          tp: 'sZP7IYe6T6Pg3Sbi0lhL06k1Tu5iFSaZIlf-ClA6IOkg0S5r0lho0lUeClb6FOPlUY3zWokgbgjNIOf9flPe3uRL3ofZFDC_3lB-3QatCZzrIQfZFSeLCKhk3lbo3oBcImfVfK4_bQgZCYxNWoryIQXzImf90Sbc0Sbi3lai0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lRc3L0Z0Lbk0SezFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikf6i6CDrebKjNIOfVfKXpIwPqdDxzU6fVfKINbK4zU6fVfKgqbZfVfGxNsK4pf6i6UwIqbZfVfGUgCKjLfgzVfG3gWKxydDkibK4LbogqW6f9UwPkImi6IK41Uw4ZIY06Tg-Uya',
          branding: {
            ...application.branding,
            name: values.branding.name,
            deployment: deployment?.name,
            packageid: values.branding.packageid,
            service: selectedService.name,
            appid: selectedService.appid,
          },
          settings: {
            ...(application.settings as any),
            languages: langs,
          },
        });
        if (responseApp && responseApp.status_code === HttpStatusCodes.OK) {
          var resp = responseApp.data as any;
          notification.success({
            message: t('app-added-successfully'),
          });
          cloningFinished();
        }
      } else {
        alert(t('make-sure-to-use-a-unique-package-id-change-it-and-try-again'));
      }
    } else {
      alert(t('make-sure-to-use-a-unique-app-name-change-it-and-try-again'));
    }
  };

  // const updateComponents = (resp) => {
  //   if (resp.components?.buttons) {
  //     for (var button of resp.components?.buttons) {
  //       updateComponent('button', button._id);
  //     }
  //   }
  //   if (resp.components?.keyboards) {
  //     for (var keyboard of resp.components?.keyboards) {
  //       updateComponent('keyboards', keyboard._id);
  //     }
  //   }
  //   if (resp.components?.inputs) {
  //     for (var keyboard of resp.components?.keyboards) {
  //       updateComponent('keyboards', keyboard._id);
  //     }
  //   }
  //   if (resp.components?.modals) {
  //     for (var keyboard of resp.components?.keyboards) {
  //       updateComponent('keyboards', keyboard._id);
  //     }
  //   }
  //   if (resp.components?.switches) {
  //     for (var keyboard of resp.components?.keyboards) {
  //       updateComponent('keyboards', keyboard._id);
  //     }
  //   }
  //   if (resp.components?.dropdowns) {
  //     for (var keyboard of resp.components?.keyboards) {
  //       updateComponent('keyboards', keyboard._id);
  //     }
  //   }
  // };
  // const updateComponent = (newId, component) => {
  //   var headerItems = application.screens[0].header;
  //   var heroItems = application.screens[0].hero;
  //   var contentItems = application.screens[0].content;
  //   var footerItems = application.screens[0].footer;

  //   if (headerItems) {
  //     for (var headerItem of headerItems) {
  //       if (headerItem.menus && headerItem.menus.components[component]) {
  //         headerItem.menus.components[component] = newId;
  //       }
  //       if (headerItem.filterbars && headerItem.filterbars.components[component]) {
  //         headerItem.filterbars.components[component] = newId;
  //       }
  //       if (headerItem.searchbars && headerItem.searchbars.components[component]) {
  //         headerItem.searchbars.components[component] = newId;
  //       }
  //     }
  //   }
  //   if (heroItems) {
  //     for (var heroItem of heroItems) {
  //       if (heroItem.details_audio_standard && heroItem.details_audio_standard.components[component]) {
  //         heroItem.details_audio_standard.components[component] = newId;
  //       }
  //       if (heroItem.details_audiostandard && heroItem.details_audiostandard.components[component]) {
  //         heroItem.details_audiostandard.components[component] = newId;
  //       }
  //       if (heroItem.details_top && heroItem.details_top.components[component]) {
  //         heroItem.details_top.components[component] = newId;
  //       }
  //       if (heroItem.details_video_standard && heroItem.details_video_standard.components[component]) {
  //         heroItem.details_video_standard.components[component] = newId;
  //       }
  //       if (heroItem.singles && heroItem.singles.components[component]) {
  //         heroItem.singles.components[component] = newId;
  //       }
  //       if (heroItem.sliders && heroItem.sliders.components[component]) {
  //         heroItem.sliders.components[component] = newId;
  //       }
  //       if (heroItem.players && heroItem.players.components[component]) {
  //         heroItem.players.components[component] = newId;
  //       }
  //     }
  //   }
  //   if (contentItems) {
  //     for (var contentItem of contentItems) {
  //       if (contentItem.filterbars && contentItem.filterbars.components[component]) {
  //         contentItem.filterbars.components[component] = newId;
  //       }
  //       if (contentItem.genre_rails && contentItem.genre_rails.components[component]) {
  //         contentItem.genre_rails.components[component] = newId;
  //       }
  //       if (contentItem.guide_horizontal && contentItem.guide_horizontal.components[component]) {
  //         contentItem.guide_horizontal.components[component] = newId;
  //       }
  //       if (contentItem.list_downloads && contentItem.list_downloads.components[component]) {
  //         contentItem.list_downloads.components[component] = newId;
  //       }
  //       if (contentItem.long_grids && contentItem.long_grids.components[component]) {
  //         contentItem.long_grids.components[component] = newId;
  //       }
  //       if (contentItem.long_rails && contentItem.long_rails.components[component]) {
  //         contentItem.long_rails.components[component] = newId;
  //       }
  //       if (contentItem.menu_rails && contentItem.menu_rails.components[component]) {
  //         contentItem.menu_rails.components[component] = newId;
  //       }
  //       if (contentItem.menus && contentItem.menus.components[component]) {
  //         contentItem.menus.components[component] = newId;
  //       }
  //       if (contentItem.morphing_rails && contentItem.morphing_rails.components[component]) {
  //         contentItem.morphing_rails.components[component] = newId;
  //       }
  //       if (contentItem.player_grids && contentItem.player_grids.components[component]) {
  //         contentItem.player_grids.components[component] = newId;
  //       }
  //       if (contentItem.player_rails && contentItem.player_rails.components[component]) {
  //         contentItem.player_rails.components[component] = newId;
  //       }
  //       if (contentItem.rectangle_grids && contentItem.rectangle_grids.components[component]) {
  //         contentItem.rectangle_grids.components[component] = newId;
  //       }
  //       if (contentItem.rectangle_rails && contentItem.rectangle_rails.components[component]) {
  //         contentItem.rectangle_rails.components[component] = newId;
  //       }
  //       if (contentItem.round_rails && contentItem.round_rails.components[component]) {
  //         contentItem.round_rails.components[component] = newId;
  //       }
  //       if (contentItem.searchbars && contentItem.searchbars.components[component]) {
  //         contentItem.searchbars.components[component] = newId;
  //       }
  //       if (contentItem.square_grids && contentItem.square_grids.components[component]) {
  //         contentItem.square_grids.components[component] = newId;
  //       }
  //       if (contentItem.square_rails && contentItem.square_rails.components[component]) {
  //         contentItem.square_rails.components[component] = newId;
  //       }
  //       if (contentItem.survey && contentItem.survey.components[component]) {
  //         contentItem.survey.components[component] = newId;
  //       }
  //       if (contentItem.tile_grids && contentItem.tile_grids.components[component]) {
  //         contentItem.tile_grids.components[component] = newId;
  //       }
  //       if (contentItem.tile_rails && contentItem.tile_rails.components[component]) {
  //         contentItem.tile_rails.components[component] = newId;
  //       }
  //       if (contentItem.top10_rails && contentItem.top10_rails.components[component]) {
  //         contentItem.top10_rails.components[component] = newId;
  //       }
  //       if (contentItem.widescreen_grids && contentItem.widescreen_grids.components[component]) {
  //         contentItem.widescreen_grids.components[component] = newId;
  //       }
  //       if (contentItem.widescreen_rails && contentItem.widescreen_rails.components[component]) {
  //         contentItem.widescreen_rails.components[component] = newId;
  //       }
  //     }
  //   }
  //   if (footerItems) {
  //     for (var footerItem of footerItems) {
  //       if (footerItem.menus && footerItem.menus.components[component]) {
  //         footerItem.menus.components[component] = newId;
  //       }
  //     }
  //   }
  // };



  const setServiceSelection = (service) => {
    var serviceOut = allServices.find((s) => s.name === service);
    setSelectedService(serviceOut);
  };

  const [packages, setPackages] = useState([] as any);

  const packageid = Form.useWatch(['branding', 'packageid'], form);
  const checkPackageId = (packageId) => {
    var test = packages.find((p) => p.name == packageId);
    if (test == undefined) {
      return true;
    } else {
      return false;
    }
  };
  const [tableSearchState, setTableSearchState] = useState<IABP_Lists>({
    pageNumber: 1,
    pageSize: 10,
    query_params: {
      cms: deployment?.name ?? '',
      instance: clientName,
    },
  });
  const loadPackages = async () => {
    let response = await appService.List_Application_Dirs(tableSearchState);
    if (response && response.status_code === HttpStatusCodes.OK) {
      if (response.data.data) {
        setPackages(response.data.data);
      }
    }
  };
  useEffectOnce(() => {
    loadPackages();
  });

  const onBlurCheckPackageId = () => {
    var test = checkPackageId(packageid);
    if (test == false) {
      alert(t('this-package-id-has-been-taken-already-please-choose-another-one'));
    }
  };
  const nothing = () => {};
  return (
    <Modal visible={visible}  width={'60%'} footer={false} closable={!cloning} onCancel={cloning ? nothing : onCancel}>
      <div className="p-5 mt-4" style={{ border: '1px solid #ebebeb' }}>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h4 className="text-primary">
              {t('install')} {application.branding.name} {t('app')}
            </h4>
            <div className="row mt-5">
              <Form
                className="col-12"
                form={form}
                onFinish={async (values) => {
                  await handleSubmit(values);
                }}
                onFinishFailed={(errorInfo) => {}}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="col card-bg-light p-4 mb-2 mr-4">
                      <div>
                        {!cloning && (
                          <>
                            <h5 className="text-primary">{t('app-setup')}</h5>
                            <p>{t('make-sure-to-use-a-different-name-package')}</p>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('app-name')}</label>
                              <Form.Item
                                className="col-md-5"
                                rules={[
                                  {
                                    required: true,
                                    message: t('app-name-is-required'),
                                  },
                                ]}
                                name={['branding', 'name']}
                              >
                                <Input className="form-control bg-white font-size-14 text-default" placeholder={'YourTV'} />
                              </Form.Item>
                            </div>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('app-package')}</label>
                              <Form.Item
                                className="col-md-5"
                                rules={[
                                  {
                                    required: true,
                                    message: t('package-name-is-required'),
                                  },
                                ]}
                                name={['branding', 'packageid']}
                              >
                                <Input onBlur={() => onBlurCheckPackageId()} className="form-control bg-white font-size-14 text-default" placeholder={'com.yourname.apps'} />
                              </Form.Item>
                            </div>

                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('service-selection')}</label>
                              <Form.Item className="col-md-5" name={['branding', 'service']}>
                                <Select onChange={setServiceSelection} className="default-select" placeholder={t('select-service')}>
                                  {allServices.map((service) => {
                                    return (
                                      <Select.Option value={service.name} key={service.name}>
                                        {service.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="row">
                              <label className="col-md-4 col-form-label">{t('app-languages')}</label>
                              <div className="col-md-5">
                                <Form.Item style={{ padding: 0 }} name={['settings', 'languages', 'name']}>
                                  <Select
                                    showSearch
                                    mode="multiple"
                                    className="default-select"
                                    allowClear
                                    filterOption={(input, option) => {
                                      return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
                                    }}
                                    options={languages}
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </>
                        )}
                        {cloning && (
                          <div className="text-center w-100 pt-5">
                            <div className="col-md-12 align-self-center" style={{ height: 140 }}>
                              <Spin size="large" spinning={true} />
                            </div>
                            <div className="col-md-12 align-self-center" style={{ height: 20 }}>
                              {t('installing')}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 p-0 mt-3 text-right">
                    <Button htmlType="submit" disabled={cloning} loading={cloning} className="mt-4 ant-btn btn btn-secondary rounded-pill width-240 height-48">
                      {t('start-installing')}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModel;
