import React, { useContext, useState } from 'react';
import { Button, Form, Select, Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInboxOut } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { IMSNameParams } from '../../../pages/dashboard';
import { useParams } from 'react-router-dom';

import cmsAlbums from '../../../services/areas/content/albums/albums';
import cmsMovies from '../../../services/areas/content/movies/movies';
import cmsShorts from '../../../services/areas/content/shorts/shorts';
import cmsSeries from '../../../services/areas/content/series/series';
import cmsPodcasts from '../../../services/areas/content/podcasts/podcasts';
import cmsCourse from '../../../services/areas/content/courses/courses';

import { STORAGE_ENDPOINTS } from '../../../services/storage_paths';
import * as HttpStatusCodes  from 'http-status-codes';
import { AuthContext } from '../../../context_api/AuthContext';
import { TVMSRoles } from '../../../constants/TVMSRoles';
import { ICMS_Lists } from '../../../interfaces/forms/common/ICMS_Lists';
import { useEffectOnce } from 'react-use';
import { ContentContext } from '../../../contexts/contentContext';

const base_url = process.env.REACT_APP_BACKEND_API_BASE_URL;

const ObjectStoragUploader = (props: { type: string }) => {
  const [selectedFile, setSelectedFile] = useState([] as any);
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();
  const [data, setData] = useState([] as any);
  const [subData, setSubData] = useState([] as any);
  const { state: AuthState } = useContext(AuthContext);
  const isProvider = AuthState.user?.role === TVMSRoles.Provider ? true : false;
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState([] as any);
  const [subContent, setSubContent] = useState([] as any);

  const filterProvider = {
    id: 'provider.name',
    value: { filterValue: AuthState.user?.name ?? 'jasdfklafijaskdflas', filterType: 'text' },
  } as any;

  const filterValidationAccepted = {
    id: 'provider_status',
    value: { filterValue: 'accepted', filterType: 'text' },
  } as any;

  const filterValidationEmpty = {
    id: 'provider_status',
    value: { filterValue: '', filterType: 'text' },
  } as any;

  const filterValidationUndefined = {
    id: 'provider_status',
    value: { filterValue: 'undefined', filterType: 'undefined' },
  } as any;

  const [tableSearchState] = useState<ICMS_Lists>({
    pageNumber: 0,
    pageSize: 10000,
    filters: isProvider ? [filterProvider] : [filterValidationUndefined, filterValidationEmpty, filterValidationAccepted],
    query_params: {
      cms: deployment?.name ?? '',
      instance: clientName ?? '',
    },
  });

  const handleFileChange = (event) => {
    console.log(event);
    setSelectedFile(event.file);
  };

  const getMultipartUploadId = () => {
    const formData = new FormData();
    formData.append('client', clientName);
    formData.append('deployment', deployment?.name ?? '');
    formData.append('originalname', selectedFile.name);
    formData.append('uploadPath', props.type + '/streams');

    fetch(base_url + '/api/v1/common/uploadid_objectstorage', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        handleFileUpload(data.data);
      });
  };
  const handleFileUpload = (uploadId) => {
    if (!selectedFile) {
      alert(t('please-select-a-file-to-upload'));
      return;
    }
    const chunkSize = 5 * 1024 * 1024; // 5MB (adjust based on your requirements)
    const totalChunks = Math.ceil(selectedFile.size / chunkSize);
    const chunkProgress = 100 / totalChunks;
    let chunkNumber = 0;
    let start = 0;
    let end = 0 + chunkSize;
    let ETags = [] as any;
    let bucketLocation = '';
    const uploadNextChunk = async () => {
      if (chunkNumber <= totalChunks && Number((chunkNumber + 1) * chunkProgress) <= 100) {
        const formData = new FormData();
        if (chunkNumber <= totalChunks - 1) {
          const chunk = selectedFile.slice(start, end);
          formData.append('file', chunk);
        } else {
          const chunk = selectedFile.slice(start, selectedFile.size);
          formData.append('file', chunk);
        }

        formData.append('chunkNumber', String(chunkNumber));
        formData.append('totalChunks', String(totalChunks));
        formData.append('originalname', selectedFile.name);
        formData.append('client', clientName);
        formData.append('deployment', deployment?.name ?? '');
        formData.append('uploadId', uploadId);
        formData.append('uploadPath', props.type + '/streams');

        fetch(base_url +'/api/v1/common/upload_objectstorage', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            ETags.push({ PartNumber: chunkNumber + 1, ETag: data.data.result.ETag });
            setProgress(Number((chunkNumber + 1) * chunkProgress));
            bucketLocation = data.data.bucketLocation
            chunkNumber++;
            start = end;
            end = start + chunkSize;
            uploadNextChunk();
          })
          .catch((error) => {
            console.error('Error uploading chunk:', error);
          });
      } else {
        //combine it back together
        handleFileUploadComplete(ETags, uploadId, bucketLocation);
      }
    };
    uploadNextChunk();
  };
  const handleFileUploadComplete = (ETags, uploadId, bucketLocation) => {
    const formData = new FormData();
    formData.append('client', clientName);
    formData.append('deployment', deployment?.name ?? '');
    formData.append('uploadedParts', JSON.stringify(ETags));
    formData.append('uploadId', uploadId);
    formData.append('uploadPath', props.type + '/streams');
    formData.append('originalname', selectedFile.name);

    fetch(base_url + '/api/v1/common/complete_objectstorage', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        updateItem('https://appcloud.akamaized.net/' + bucketLocation + '/' + clientName + '-' + deployment?.name + '/' + props.type + '/streams/' + selectedFile.name);
        setProgress(100);
      });
  };

  const updateItem = async (url) => {
    switch (props.type) {
      case 'albums':
        await updateAlbum(url);
        break;
      case 'movies':
        await updateMovie(url);
        break;
      case 'shorts':
        await updateShort(url);
        break;
      case 'seasons':
        await updateSeason(url);
        break;
      case 'podcasts':
        await updatePodcast(url);
        break;
      case 'courses':
        await updateCourse(url);
        break;
    }
  };

  const getContent = async (tableSearchState) => {
    switch (props.type) {
      case 'albums':
        await getAlbums(tableSearchState);
        break;
      case 'movies':
        await getMovies(tableSearchState);
        break;
      case 'shorts':
        await getShorts(tableSearchState);
        break;
      case 'seasons':
        await getSeasons(tableSearchState);
        break;
      case 'podcasts':
        await getPodcasts(tableSearchState);
        break;
      case 'courses':
        await getCourses(tableSearchState);
        break;
    }
  };

  useEffectOnce(() => {
    async function fetchData() {
      setIsLoading(true);
      await getContent(tableSearchState);
      setIsLoading(false);
    }
    fetchData();
  });

  //courses
  const updateCourse = async (url) => {
    var updateData = {
      ...subContent,
      streams: {
        courses: {
          url: url,
        },
      },
    };
    const response = await cmsCourse.Update_Course({
      ...content,
      ...updateData,
      query_params: {
        cms: deployment?.name,
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name ?? '', deployment?.name ?? '').CONTENT.COURSES.STREAMS,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
    }
  };
  const getCourses = async (list) => {
    const response = await cmsCourse.List_Courses({
      ...list,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
    }
  };

  //seasons
  const getSeasons = async (list) => {
    const response = await cmsSeries.List_Seasons({
      ...list,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
    }
  };
  const updateSeason = async (url) => {
    var updateData = {
      ...subContent,
      streams: {
        series: {
          url: url,
        },
      },
    };
    const response = await cmsSeries.Update_Season({
      ...content,
      ...updateData,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name ?? '', deployment?.name ?? '').CONTENT.SERIES.STREAMS,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
    }
  };

  //podcasts
  const updatePodcast = async (url) => {
    var updateData = {
      ...subContent,
      streams: {
        podcasts: {
          url: url,
        },
      },
    };
    const response = await cmsPodcasts.Update_Podcasts({
      ...content,
      ...updateData,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name ?? '', deployment?.name ?? '').CONTENT.PODCASTS.STREAMS,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
    }
  };
  const getPodcasts = async (list) => {
    const response = await cmsPodcasts.List_Podcasts({
      ...list,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
    }
  };

  //albums
  const updateAlbum = async (url) => {
    var updateData = {
      ...subContent,
      streams: {
        albums: {
          url: url,
        },
      },
    };
    const response = await cmsAlbums.Update_Album({
      ...content,
      ...updateData,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName ?? '',
      },
    });
    if (response.status_code === HttpStatusCodes.OK) {
    }
  };
  const getAlbums = async (list) => {
    const response = await cmsAlbums.List_Albums({
      ...list,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
    }
  };

  //movies
  const updateMovie = async (url) => {
    const response = await cmsMovies.Update_Movie({
      ...content,
      streams: {
        movies: {
          url: url,
        },
      },
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name ?? '', deployment?.name ?? '').CONTENT.MOVIES.STREAMS,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
    }
  };
  const getMovies = async (list) => {
    const response = await cmsMovies.List_Movies({
      ...list,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
    }
  };

  //shorts
  const updateShort = async (url) => {
    const response = await cmsShorts.Update_Shorts({
      ...content,
      streams: {
        shorts: {
          url: url,
        },
      },
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name ?? '', deployment?.name ?? '').CONTENT.SHORTS.STREAMS,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
    }
  };
  const getShorts = async (list) => {
    const response = await cmsShorts.List_Shorts({
      ...list,
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName ?? '',
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
    }
  };

  const selectContent = (values) => {
    console.log(values);
    var content = data.find((d) => d._id === values);
    setContent(content);
    if (props.type === 'Courses') {
      setSubData(content.lessons);
    }
    if (props.type === 'Albums') {
      setSubData(content.songs);
    }
    if (props.type === 'Podcasts') {
      setSubData(content.podcasts);
    }
    if (props.type === 'Series') {
      setSubData(content.seasons);
    }
  };
  const selectSubContent = (values) => {
    var content = subData.find((d) => d._id === values._id);
    setSubContent(content);
    if (props.type === 'Seasons') {
      setSubData(content.seasons);
    }
  };

  return (
    <div className="card mt-4 pb-4">
      <div className="card-body card-ch-padding">
        <h4 className="text-primary mt-5 mb-3">{t('upload-movie')}</h4>
        <div className="card-bg-light pl-5 pt-4 pb-4" style={{ height: '100vh' }}>
          <div className="row-md-12">
            <h4 className="text-primary mb-2 mt-4">{t('content-item')}</h4>
            <p>{t('select-the-content-item-where-you-are-uploading-content-for')}</p>
            <div className="row">
              <div className="col-md-5">
                <Form.Item name={'content'}>
                  <Select
                    onSelect={selectContent}
                    showSearch
                    loading={isLoading}
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
                    }}
                    options={isLoading ? [] : data.map((item) => ({ value: item._id, label: item.name }))}
                  ></Select>
                </Form.Item>
              </div>
            </div>
            {subData.length > 0 && (
              <div className="row">
                <div className="col-md-5">
                  <Form.Item name={'subcontent'}>
                    <Select
                      onSelect={selectSubContent}
                      showSearch
                      loading={isLoading}
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase());
                      }}
                      options={isLoading ? [] : data.map((item) => ({ value: item._id, label: item.name }))}
                    ></Select>
                  </Form.Item>
                </div>
              </div>
            )}

            <h4 className="text-primary mb-2 mt-4">{t('select-content')}</h4>
            <p>{t('select-the-content-you-want-to-upload')}</p>
            <div className="row">
              <div className="col">
                <Upload.Dragger listType="text" maxCount={1} showUploadList={false} multiple={false} accept={'.mp4, .mp3'} customRequest={handleFileChange} onRemove={() => {}}>
                  <div className="media d-inline-flex align-items-center">
                    <FontAwesomeIcon size="2x" className="mr-3" icon={faInboxOut} color="#1ac884" />
                    <div className="media-body text-left my-3">
                      <h6 className="mt-0 text-primary">{t('upload-your-file')}</h6>
                      <p className="mb-0 h6 icon-gray font-weight-normal">
                        {t('drag-files-here-or')} <span className="text-secondary"> {t('browse')}</span>
                      </p>
                    </div>
                  </div>
                </Upload.Dragger>
                <div className={'row-md-12 pt-2'}>
                  <div>{selectedFile?.name}</div>
                  {progress > 0 && <div style={{ width: progress + '%', height: 2, backgroundColor: '#485Fe0' }}>{progress}%</div>}
                </div>
              </div>
              <div className="col"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="col text-right pr-5 pt-2">
        <Button className="ant-btn btn btn-secondary rounded-pill width-240 height-48" onClick={getMultipartUploadId}>
          {t('start-upload')}
        </Button>
      </div>
    </div>
  );
};

export default ObjectStoragUploader;
