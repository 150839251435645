import { Tabs } from 'antd';
import { useContext, useState } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookSection, faEarth, faFolderTree, faGalleryThumbnails, faIdCard, faIdCardClip, faQrcode, faTv, faUserMagnifyingGlass, faUserPlus, faUserShield } from '@fortawesome/pro-regular-svg-icons';
import Languages from '../../../../../common/components/AppBuilder/Authentication/Languages';
import Onboarding from '../../../../../common/components/AppBuilder/Authentication/Onboarding';
import Services from '../../../../../common/components/AppBuilder/Authentication/Services';
import Login from '../../../../../common/components/AppBuilder/Authentication/Login';
import Registration from '../../../../../common/components/AppBuilder/Authentication/Registration';
import Profiles from '../../../../../common/components/AppBuilder/Authentication/Profiles';
import Connect from '../../../../../common/components/AppBuilder/Authentication/Connect';
import Disclaimer from '../../../../../common/components/AppBuilder/Authentication/Disclaimer';
import Getstarted from '../../../../../common/components/AppBuilder/Authentication/Getstarted';
import PWA from '../../../../../common/components/AppBuilder/Authentication/Pwa';
import Forgot from '../../../../../common/components/AppBuilder/Authentication/Forgot';
import { faHtml5 } from '@fortawesome/free-brands-svg-icons';
import { useEffectOnce } from 'react-use';
import { AppBuilderContext } from '../../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../dashboard';
import * as HttpStatusCodes  from 'http-status-codes';
import appService from '../../../../../services/areas/appbuilder/applications/application';
import MenuBuilder from './helpers/menu';

const Authentication = () => {
  const [menus, setMenus] = useState([] as any);
  const { id } = useParams<any>();
  const [activeKeyAuth, setActiveKeyAuth] = useState('Languages');
  const { TabPane } = Tabs;
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const [isLoading, setIsLoading] = useState(false);
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [app, setApplication] = useState([] as any);

  const getIcon = (name: any) => {
    switch (name) {
      case 'Languages':
        return faEarth;
      case 'Disclaimer':
        return faBookSection;
      case 'Onboarding':
        return faGalleryThumbnails;
      case 'Services':
        return faFolderTree;
      case 'Login':
        return faUserShield;
      case 'Get Started':
        return faUserPlus;
      case 'Forgot':
        return faUserMagnifyingGlass;
      case 'PWA':
        return faHtml5;
      case 'Registration':
        return faIdCard;
      case 'Profiles':
        return faIdCardClip;
      case 'Connect Code':
        return faQrcode;
      default:
        return faTv;
    }
  };

  const getAuthPage = (type: any) => {
    switch (type) {
      case 'Languages':
        return <Languages />;
      case 'Disclaimer':
        return <Disclaimer />;
      case 'Onboarding':
        return <Onboarding />;
      case 'Services':
        return <Services />;
      case 'Login':
        return <Login />;
      case 'Get Started':
        return <Getstarted />;
      case 'Forgot':
        return <Forgot />;
      case 'PWA':
        return <PWA />;
      case 'Registration':
        return <Registration />;
      case 'Profiles':
        return <Profiles />;
      case 'Connect Code':
        return <Connect />;
    }
  };
  const getAppItems = async () => {
    setIsLoading(true);
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      setApplication(resp);
      var menu = [] as any;

      menu.push({
        ...menus,
        name: 'Languages',
      });
      if (resp.settings.enable_onboarding) {
        menu.push({
          ...menus,
          name: 'Onboarding',
        });
      }
      if (resp.settings.enable_register) {
        menu.push({
          ...menus,
          name: 'Registration',
        });
      }
      if (resp.settings.enable_profiles) {
        menu.push({
          ...menus,
          name: 'Profiles',
        });
      }
      if (resp.settings.enable_connectcode) {
        menu.push({
          ...menus,
          name: 'Connect Code',
        });
      }
      if (!resp.settings.enable_anonymous) {
        menu.push({
          ...menus,
          name: 'Login',
        });
      }
      if (!resp.settings.enable_anonymous && resp.settings.enable_forgot) {
        menu.push({
          ...menus,
          name: 'Get Started',
        });
      }
      if (!resp.settings.enable_signup) {
        menu.push({
          ...menus,
          name: 'Forgot',
        });
      }
      if (!resp.settings.enable_nested) {
        menu.push({
          ...menus,
          name: 'Services',
        });
      }
      menu.push({
        ...menus,
        name: 'PWA',
      });
      setMenus(menu);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    getAppItems();
  });
  return (
    <div className="container-fluid wrapper">
      <div className="row-md-12">
        <MenuBuilder application={app} redirectUrl={redirectUrl} id={id}></MenuBuilder>
        <div className="col-md-12 mb-2" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="card">
            <div className="row">
              <div className="col-12  mt-3">
                {!isLoading && (
                  <Tabs className="highlight-tab" onChange={(value) => setActiveKeyAuth(value)} defaultActiveKey={'Languages'}>
                    {menus.map((menu: any) => {
                      return (
                        <TabPane
                          tab={
                            <div className="col text-center" style={{}}>
                              <div style={{ width: 100 }}>
                                {' '}
                                <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={getIcon(menu.name) as any} />
                              </div>
                              <div style={{ fontSize: 12, color: '#999' }}>{menu.name}</div>
                            </div>
                          }
                          key={menu.name}
                        >
                          {activeKeyAuth == menu.name && <>{getAuthPage(menu.name)}</>}
                        </TabPane>
                      );
                    })}
                  </Tabs>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Authentication;
