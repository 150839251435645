import { useContext, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Table from '../../../../../common/components/Content/Table';
import { ContentContext } from '../../../../../contexts/contentContext';
import { IMSNameParams } from '../../../../dashboard';
import cmsCourses from '../../../../../services/areas/content/courses/courses';
import * as HttpStatusCodes  from 'http-status-codes';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import * as XLSX from 'xlsx';
import { Button } from 'antd';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { NotVisibleForRole } from '../../../../../common/components/VisibleForRole';
import { TVMSRoles } from '../../../../../constants/TVMSRoles';

const Albums = () => {
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const { t } = useTranslation();
  const { deployment } = useContext(ContentContext);
  const { url } = useRouteMatch();
  const [exporting, setExporting] = useState(false);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const redirectUrl = url.split('/').slice(0, -1).join('/');

  const exportContent = async () => {
    setExporting(true);
    let values = {
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsCourses.Export_Courses(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      var translationsCourses = [] as any;
      var courses = response.data.data;
      var lessons = [] as any;
      var languages = [] as any;
      var translationsLessons = [] as any;
      var currencies = [] as any;
      var tags = [] as any;
      var translations = [] as any;

      languagesDeployment.forEach((langauge: any) => {
        languages.push({
          language: langauge.name,
        });
      });

      var courses_ = courses;
      if (courses_ != undefined) {
        courses_.forEach((course: any, index1: any) => {
          const tagsTranslations = course.tags;
          if (tagsTranslations != undefined && tagsTranslations[0] != undefined && tagsTranslations[0].tag != undefined) {
            tagsTranslations.forEach((trans: any) => {
              trans.tag.forEach((tag: any) => {
                tags.push({
                  _id: course.name,
                  name: tag.name,
                  description: tag.description,
                });
              });
            });
          }
          delete course.tags;

          var courseTranslations = course.translations;
          courseTranslations.forEach((courseTranslation: any, index) => {
            translations.push({
              name: courseTranslation.name,
              course_name: course.name,
              description: courseTranslation.description,
            });
          });
          delete course.translations;

          if (course.payperview_currencies) {
            course.payperview_currencies.forEach((currency: any) => {
              currencies.push({
                course_id: course.name,
                ...currency,
              });
            });
          }
          delete course.payperview_currencies;

          var lessons_ = course.lessons;
          if (lessons_ != undefined) {
            lessons_.forEach((lesson: any, index2: any) => {
              var lessonTranslations = lesson.translations;
              lessonTranslations.forEach((lessonTranslation: any) => {
                translationsLessons.push({
                  name: lessonTranslation.name,
                  lesson_name: lesson.name,
                  description: lessonTranslation.description,
                });
              });
              delete courses[index1].lessons[index2].translations;

              courses[index1].lessons[index2].course = course.name;

              lessons.push(courses[index1].lessons[index2]);
            });
            delete courses[index1].lessons;
            delete courses[index1].translations;
          }
        });
      }

      const workbook = XLSX.utils.book_new();
      const worksheet1 = XLSX.utils.json_to_sheet(courses);
      const worksheet2 = XLSX.utils.json_to_sheet(lessons);
      const worksheet3 = XLSX.utils.json_to_sheet(currencies);
      const worksheet4 = XLSX.utils.json_to_sheet(languages);

      languages.forEach((language: any, index: any) => {
        var lessons = translationsLessons.filter((e: any) => e.name == language.language);
        var data = [] as any;
        lessons.forEach((lesson: any, index_: any) => {
          var courses = translationsCourses.filter((e: any) => e.name == language.language);
          var test = courses[index_];
          var course = '';
          var course_translation = '';
          if (test != undefined) {
            course = test.course_name;
            course_translation = test.description;
          }
          data.push({
            course: course,
            course_translation: course_translation,
            lesson: lesson.lesson_name,
            lesson_translation: lesson.description,
          });
        });
        languages[index].data = data;
      });

      XLSX.utils.book_append_sheet(workbook, worksheet1, t('courses'));
      XLSX.utils.book_append_sheet(workbook, worksheet2, t('lessons'));
      XLSX.utils.book_append_sheet(workbook, worksheet3, t('currencies'));
      XLSX.utils.book_append_sheet(workbook, worksheet4, t('languages'));

      languages.forEach((language: any) => {
        XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(language.data), language.language);

        const tagsPerLanguage = tags.filter((l: any) => l.name === language.language);
        if (tagsPerLanguage !== undefined) {
          const result2 = tagsPerLanguage.map((obj: any) => ({ _id: obj['_id'], tags: obj['description'] }));
          XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(result2), 'tags_' + language.language);
        }
      });

      XLSX.writeFile(workbook, 'Courses_' + clientName + '_' + deployment?.name + '_' + moment().format('YYYY-MM-DD') + '.xlsx');
    }
    setExporting(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('your-courses')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('all-your-courses-in-the-system-you-can-add-as-many-as-you-want')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start"></div>
        <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start">
        <NotVisibleForRole roles={[TVMSRoles.Provider]}>
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${redirectUrl}/actions/importer-excel`}>
            {t('import-courses')}
          </Link>
          </NotVisibleForRole>
          <NotVisibleForRole roles={[TVMSRoles.Provider]}>
          <Button disabled={exporting} loading={exporting} className="btn btn-secondary rounded-pill btn-default float-right width-150 height-48" onClick={exportContent}>
            {t('export-courses')}
          </Button>
          </NotVisibleForRole>
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
            {t('add-course')}
          </Link>
        </div>
      </div>
 
      <div className="row">
        <div className="col-12 mt-4">
          <Table service_name={service_name} clientName={clientName} type={'courses'} variant={'normal'}></Table>
        </div>
      </div>
    </div>
  );
};

export default Albums;
