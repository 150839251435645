import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router'
import RadioIndex from './radios'
import RadioAdd from './radios/add'
import RadioDetails from './radios/details'
import RadioBundles from './bundles'
import RadioBundleContent from './bundles/content'
import Lists from './lists'
import Settings from './settings'
import ListContent from './lists/content'
import Providers from './providers';
import Curations from './curations';
import CurationContent from './curations/content';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}><RadioAdd /></Route>
      <Route path={`${path}/details/:_id`}><RadioDetails /></Route>
      <Route path={`${path}/bundles/content/:package/:_id/:category/:index`}><RadioBundleContent /></Route>
      <Route path={`${path}/bundles`}><RadioBundles /></Route>
      <Route path={`${path}/curations/content/:package/:_id/:index`}><CurationContent /></Route>
      <Route path={`${path}/curations`}><Curations /></Route>
      <Route path={`${path}/lists/content/:package/:_id/:index`}><ListContent /></Route>
      <Route path={`${path}/lists`}><Lists /></Route>
      <Route path={`${path}/settings`}><Settings /></Route>
      <Route path={`${path}/providers`}><Providers /></Route>
      <Route path={`${path}/`}><RadioIndex /></Route>
    </Switch>
  )
}

export default Index
