import { Form, Input, Select, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';
import transcoderService from '../../../../services/areas/content/transcoders/transcoders/index';
import { IMSNameParams } from '../../../../pages/dashboard';
import { useParams } from 'react-router-dom';
import * as HttpStatusCodes  from 'http-status-codes';

const InteractiveTV = (params: { setInteractiveTV: any; data?: any }) => {
  let [form] = useForm();
  const [catchuptv_enabled, setCatchupTVEnabled] = useState(false);
  const [recordings_enabled, setRecordingEnabled] = useState(false);
  const [pausetv_enabled, setPauseTVEnabled] = useState(false);
  const [preview_enabled,setPreviewEnabled] = useState(false)
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState([] as any);
  const [transcoder, setTranscoder] = useState([] as any);
  const {t} = useTranslation();

  useEffectOnce(() => {
    getTranscoders();
    form.setFieldsValue(params.data);
    params.setInteractiveTV({
      catchuptv_enabled: catchuptv_enabled,
      recordings_enabled: recordings_enabled,
      pausetv_enabled: pausetv_enabled,
      preview_enabled: preview_enabled,
      alternative_url: '',
      catchuptv_days: 0,
      transcoder: transcoder,
    });
  });

  useEffect(() => {
    if (params.data != undefined) {
      if (params.data.catchuptv_enabled != undefined) {
        form.setFieldsValue(params.data);
        setCatchupTVEnabled(params.data.catchuptv_enabled);
        setRecordingEnabled(params.data.recordings_enabled);
        setPauseTVEnabled(params.data.pausetv_enabled);
        setPreviewEnabled(params.data.preview_enabled ?? false);
        setTranscoder(params.data.transcoder);
      }
    }
  }, [params.data]);

  const getTranscoders = async () => {
    setIsloading(true);
    let response = await transcoderService.List_Transcoders({
      query_params: {
        instance: clientName,
        cms: service_name || '',
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data);
    }
    setIsloading(false);
  };
  const setSelectedTranscoderLocal = (value) => {
    params.setInteractiveTV({
      catchuptv_enabled: catchuptv_enabled,
      recordings_enabled: recordings_enabled,
      pausetv_enabled: pausetv_enabled,
      preview_enabled: preview_enabled,
      alternative_url: '',
      catchuptv_days: 0,
      transcoder: value.key,
    });
  };

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">InteractiveTV</h4>
      <div className="row">
        <div className="col-md-12 card-bg-light p-5">
        <div className="row ml-2">
            <label className="col-md-3 col-form-label">{t('preview-images')}</label>
            <Form.Item extra={t('if-preview-images-are-enabled-on-flussonic-and-can-serve-as-a-fallback-for-missing-epg-images')} className="col-md-5" name={['interactivetv', 'preview_enabled']}>
              <Switch
                onChange={(checked) => {
                  setPreviewEnabled(checked);
                }}
                checked={preview_enabled}
                className="ch-switch mt-2"
                defaultChecked={false}
              />
            </Form.Item>
          </div>
          <div className="row ml-2">
            <label className="col-md-3 col-form-label">{t('replay')}</label>
            <Form.Item extra={t('give-customers-the-option-to-replay-programs-from-this-channel')} className="col-md-5" name={['interactivetv', 'catchuptv_enabled']}>
              <Switch
                onChange={(checked) => {
                  params.setInteractiveTV({
                    catchuptv_enabled: checked,
                    recordings_enabled: recordings_enabled,
                    pausetv_enabled: pausetv_enabled,
                  });
                  setCatchupTVEnabled(checked);
                }}
                checked={catchuptv_enabled}
                className="ch-switch mt-2"
                defaultChecked={false}
              />
            </Form.Item>
          </div>
          <div className="row ml-2">
            <label className="col-md-3 col-form-label">{t('replay-retention-override-days')}</label>
            <Form.Item
              extra={
                <>
                  <div>
                    {t('this-is-an-override-for-the-global-setting-in-channels-settings-leave-at-0-to-use-the-global-setting')}
                    <br />
                    {t('number-of-days-your-customers-can-watch-back-on-replay-be-sure-not-to-exceed-the-number-of-days-you-set-as-the-guide-retention-setting-in-guide-settings')}
                  </div>
                </>
              }
              className="col-md-5"
              name={['interactivetv', 'catchuptv_days']}
            >
              <Input type={'number'} className="form-control bg-white font-size-14 text-default" placeholder="3" defaultValue={0} />
            </Form.Item>
          </div>
          <div className="row ml-2">
            <label className="col-md-3 col-form-label">{t('recording')}</label>
            <Form.Item extra={t('give-customers-the-option-to-record-programs-from-this-channel')} className="col-md-5" name={['interactivetv', 'recordings_enabled']}>
              <Switch
                onChange={(checked) => {
                  params.setInteractiveTV({
                    catchuptv_enabled: catchuptv_enabled,
                    recordings_enabled: checked,
                    pausetv_enabled: pausetv_enabled,
                  });
                  setRecordingEnabled(checked);
                }}
                checked={recordings_enabled}
                className="ch-switch mt-2"
                defaultChecked={false}
              />
            </Form.Item>
          </div>
          {recordings_enabled && (
            <div className="row ml-2 mb-2">
              <label className="col-md-3 col-form-label">{t('recording-transcoder')}</label>
              <div className="col-md-9">
                <Select onChange={(value) => setSelectedTranscoderLocal(value)} defaultValue={transcoder} className={'default-select col-md-6'} placeholder={t('select-transcoder')} labelInValue>
                  {data.map((transcoder) => {
                    return (
                      <Select.Option value={transcoder._id} key={transcoder._id}>
                        {transcoder.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}
          <div className="row ml-2">
            <label className="col-md-3 col-form-label">{t('pause')}</label>
            <Form.Item extra={t('give-customers-the-option-to-pause-this-channel')} className="col-md-5" name={['interactivetv', 'pausetv_enabled']}>
              <Switch
                onChange={(checked) => {
                  params.setInteractiveTV({
                    catchuptv_enabled: catchuptv_enabled,
                    recordings_enabled: recordings_enabled,
                    pausetv_enabled: checked,
                  });
                  setPauseTVEnabled(checked);
                }}
                checked={pausetv_enabled}
                className="ch-switch mt-2"
                defaultChecked={false}
              />
            </Form.Item>
          </div>
          <div className="row ml-2">
            <label className="col-md-3 col-form-label">{t('alternative-url')}</label>
            <Form.Item extra={t('only-use-this-when-the-normal-urls-are-not-sufficient')} className="col-md-5" name={['interactivetv', 'alternative_url']}>
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InteractiveTV;
