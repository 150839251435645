export const searchIMDBApi = async (imdbid: any, type: any) => {
  if (type == 'movies') {
    var path = 'https://api.thecloudof.media/get-movie?imdbid=' + imdbid + '&filterSpecials=true'; // - search movies by tmdb id'
    //438631
    let response = await fetch(path, {
      method: 'GET',
      headers: {
        Authorization:
          'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYXhSZXF1ZXN0Q291bnQiOiItMSIsIm1heFJlcXVlc3RDb3VudEluV2luZG93IjoiLTEiLCJpYXQiOjE3MTU2MDE2OTR9.rRzUfHdLdg4Lj7Z_Yb7l3oL3p53_V9-LoV4NbafrsxVdH9Nr2yjaTwcvzIgvwSlZ_fMgdF64bG3tWRYFobnHMIJWfzlMNZgCwZXF3MzwCNN6db4Z7a200-UJoCSWwYxohZsUJyxhJRpHU57A6R6f08PwaadAGZqmp6pvy8TWBam-cIM_w2K4YFyh440QiwiesRWi65wo0vsZNzsuElXgwplX5jkafhuNrX1_CrNOPL2FEF8NhBfC3isgOOGtw3QGvecAXYO9AFElXK3LlsnKu4wIbqFvXsBiyYd_m-V_Y5aY7bMpMg4ghRX4nz7BFSdPnh4oEahS49N6SL4bY2RMEg4it19ZiXZRs8iKOobIGCZ0s5Kkl2NtR8SJA0WAgQSLrO6lJ28sC89ZgyvsjZ3UowxdiZ84IH3gpO9muA03umaRJzjgCaAtDHPG4WFLbi50eSDBhGCsqJUh0DxYh-FLixLqRrbB9aoSjwlT7iXpJ3d0vnhT58uRxyrPa8mp0x_O',
      },
    });
    let result = await response.json();
    let data = result[0];
    let returnData = [] as any;

    if (data != undefined) {
      returnData.name = data.name ?? '';
      returnData.background = data.backdrop_url != null ? data.backdrop_url : '';
      returnData.poster = data.poster_url != null ? data.poster_url : '';
      returnData.logo = data.logo_url != null ? data.logo_url : '';
      returnData.description = data.overview ?? '';
      returnData.imdb = data.imdb_id ?? '';
      returnData.tmdb = data.tmdb_id ?? '';
      returnData.language = '';
      returnData.rating = data.popularity / 100 ?? '0';
      returnData.trailer = data.trailer_url != null ? data.trailer_url : '';
      returnData.released = data.release_year_clean ?? '';
      returnData.runtime = data.runtime ?? '';
      returnData.duration = 0;
      returnData.studio = data.studio ?? '';
      returnData.director = data.director ?? '';

      if (data.cast != null) {
        var cast = [] as any;
        var castIn = data.cast;
        if (castIn != undefined) {
          castIn.forEach((element, index) => {
            if (index < 10 && element.department == 'Acting') {
              cast.push({
                name: element.name,
                image: element.image_url,
              });
            }
          });
          returnData.actors = cast;
        } else {
          returnData.actors = [];
        }
      } else {
        returnData.actors = [];
      }

      var tagsOut = [] as any;
      var tags = data.genres;
      if (tags != undefined && tags != null) {
        tags.forEach((tag: any) => {
          tagsOut.push({
            name: tag,
          });
        });
      }
      returnData.tags = tagsOut;

      return returnData;
    }
  } else {
    var path = 'https://api.thecloudof.media/get-serie?imdbid=' + imdbid + '&filterSpecials=true'; // - search movies by tmdb id'
    let response = await fetch(path, {
      method: 'GET',
      headers: {
        Authorization:
          'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYXhSZXF1ZXN0Q291bnQiOiItMSIsIm1heFJlcXVlc3RDb3VudEluV2luZG93IjoiLTEiLCJpYXQiOjE3MTU2MDE2OTR9.rRzUfHdLdg4Lj7Z_Yb7l3oL3p53_V9-LoV4NbafrsxVdH9Nr2yjaTwcvzIgvwSlZ_fMgdF64bG3tWRYFobnHMIJWfzlMNZgCwZXF3MzwCNN6db4Z7a200-UJoCSWwYxohZsUJyxhJRpHU57A6R6f08PwaadAGZqmp6pvy8TWBam-cIM_w2K4YFyh440QiwiesRWi65wo0vsZNzsuElXgwplX5jkafhuNrX1_CrNOPL2FEF8NhBfC3isgOOGtw3QGvecAXYO9AFElXK3LlsnKu4wIbqFvXsBiyYd_m-V_Y5aY7bMpMg4ghRX4nz7BFSdPnh4oEahS49N6SL4bY2RMEg4it19ZiXZRs8iKOobIGCZ0s5Kkl2NtR8SJA0WAgQSLrO6lJ28sC89ZgyvsjZ3UowxdiZ84IH3gpO9muA03umaRJzjgCaAtDHPG4WFLbi50eSDBhGCsqJUh0DxYh-FLixLqRrbB9aoSjwlT7iXpJ3d0vnhT58uRxyrPa8mp0x_O',
      },
    });
    let result = await response.json();
    let data = result[0];
    let returnData = [] as any;

    if (data != undefined) {
      returnData.name = data.name ?? '';
      returnData.background = data.backdrop_url != null ? data.backdrop_url : '';
      returnData.poster = data.poster_url != null ? data.poster_url : '';
      returnData.logo = data.logo_url != null ? data.logo_url : '';
      returnData.description = data.overview ?? '';
      returnData.imdb = data.imdb_id ?? '';
      returnData.tmdb = data.tmbd_id ?? '';
      returnData.language = '';
      returnData.rating = data.popularity / 100 ?? '0';
      returnData.trailer = data.trailer_url != null ? data.trailer_url : '';
      returnData.released = data.release_year_clean ?? '';
      returnData.runtime = '';
      returnData.duration = 0;
      returnData.studio = '';
      returnData.director = data.director ?? '';

      returnData.tags = tagsOut;

      var seasonsOut = [] as any;
      var seasons = data.seasons;
      if (seasons != undefined && seasons != null) {
        seasons.forEach((season) => {
          var episodes = season.episodes;
          var episodesOut = [] as any;
          if (episodes != undefined && episodes != null) {
            episodes.forEach((episode) => {
              episodesOut.push({
                name: episode.name,
                episode_image: episode.image_url != null ? episode.image_url : data.backdrop_url != null ? data.backdrop_url : '',
                description: episode.overview,
                duration: episode.runtime ? episode.runtime * 60 : 0,
                number: episode.episode_nr ?? 0,
              });
            });
          }

          var actorsOut = [] as any;
          if (data.cast != null) {
            var cast = [] as any;
            var castIn = data.cast;
            if (castIn != undefined) {
              castIn.forEach((element, index) => {
                if (index < 10 && element.department == 'Acting') {
                  cast.push({
                    name: element.name,
                    image: element.image_url,
                  });
                }
              });
              actorsOut = cast;
            }
          }

          var tagsOut = [] as any;
          var tags = data.genres;
          if (tags != undefined && tags != null) {
            tags.forEach((tag: any) => {
              tagsOut.push({
                name: tag,
              });
            });
          }
          returnData.tags = tagsOut;

          seasonsOut.push({
            name: season.name ?? '',
            number: season.season_nr ?? 0,
            rating: (returnData.rating = data.popularity / 100 ?? '0'),
            studio: '',
            released: data.release_year_clean ?? '',
            language: '',
            background: data.backdrop_url != null ? data.backdrop_url : '',
            poster: season.poster_path != null ? season.poster_path : '',
            logo: data.logo_url != null ? data.logo_url : '',
            description: season.overview ?? '',
            actors: actorsOut,
            tags: tagsOut,
            episodes: episodesOut,
          });
        });
      }
      returnData['seasons'] = seasonsOut;
      return returnData;
    }
  }
};

export const searchMovies = async (search: any) => {
  var path = 'https://api.thecloudof.media/get-movie?querySize=19&title=' + search + '&filterSpecials=true';
  try {
    let response = await fetch(path, {
      method: 'GET',
      headers: {
        Authorization:
          'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYXhSZXF1ZXN0Q291bnQiOiItMSIsIm1heFJlcXVlc3RDb3VudEluV2luZG93IjoiLTEiLCJpYXQiOjE3MTU2MDE2OTR9.rRzUfHdLdg4Lj7Z_Yb7l3oL3p53_V9-LoV4NbafrsxVdH9Nr2yjaTwcvzIgvwSlZ_fMgdF64bG3tWRYFobnHMIJWfzlMNZgCwZXF3MzwCNN6db4Z7a200-UJoCSWwYxohZsUJyxhJRpHU57A6R6f08PwaadAGZqmp6pvy8TWBam-cIM_w2K4YFyh440QiwiesRWi65wo0vsZNzsuElXgwplX5jkafhuNrX1_CrNOPL2FEF8NhBfC3isgOOGtw3QGvecAXYO9AFElXK3LlsnKu4wIbqFvXsBiyYd_m-V_Y5aY7bMpMg4ghRX4nz7BFSdPnh4oEahS49N6SL4bY2RMEg4it19ZiXZRs8iKOobIGCZ0s5Kkl2NtR8SJA0WAgQSLrO6lJ28sC89ZgyvsjZ3UowxdiZ84IH3gpO9muA03umaRJzjgCaAtDHPG4WFLbi50eSDBhGCsqJUh0DxYh-FLixLqRrbB9aoSjwlT7iXpJ3d0vnhT58uRxyrPa8mp0x_O',
      },
    });
    let result = await response.json();
    let data = result;
    return data;
  } catch (error) {}
};
export const searchSeries = async (search: any) => {
  var path = 'https://api.thecloudof.media/get-serie?querySize=100&title=' + search + '&filterSpecials=true';
  try {
    let response = await fetch(path, {
      method: 'GET',
      headers: {
        Authorization:
          'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJtYXhSZXF1ZXN0Q291bnQiOiItMSIsIm1heFJlcXVlc3RDb3VudEluV2luZG93IjoiLTEiLCJpYXQiOjE3MTU2MDE2OTR9.rRzUfHdLdg4Lj7Z_Yb7l3oL3p53_V9-LoV4NbafrsxVdH9Nr2yjaTwcvzIgvwSlZ_fMgdF64bG3tWRYFobnHMIJWfzlMNZgCwZXF3MzwCNN6db4Z7a200-UJoCSWwYxohZsUJyxhJRpHU57A6R6f08PwaadAGZqmp6pvy8TWBam-cIM_w2K4YFyh440QiwiesRWi65wo0vsZNzsuElXgwplX5jkafhuNrX1_CrNOPL2FEF8NhBfC3isgOOGtw3QGvecAXYO9AFElXK3LlsnKu4wIbqFvXsBiyYd_m-V_Y5aY7bMpMg4ghRX4nz7BFSdPnh4oEahS49N6SL4bY2RMEg4it19ZiXZRs8iKOobIGCZ0s5Kkl2NtR8SJA0WAgQSLrO6lJ28sC89ZgyvsjZ3UowxdiZ84IH3gpO9muA03umaRJzjgCaAtDHPG4WFLbi50eSDBhGCsqJUh0DxYh-FLixLqRrbB9aoSjwlT7iXpJ3d0vnhT58uRxyrPa8mp0x_O',
      },
    });
    let result = await response.json();
    let data = result;
    return data;
  } catch (error) {}
};
