import { Modal, Tabs } from 'antd';
import { useState } from 'react';

import Tab1 from './tabs/General';
import Tab2 from './tabs/External';
import { Spin } from '../../../../../common/components/spinner';
import { useTranslation } from 'react-i18next';

enum EnumTabKeys {
  TAB1 = 'tab1',
  TAB2 = 'tab2',
}
type IUpdatePackageModalProps = {
  visible: boolean;
  onSubmit: (values: any, newCat) => void;
  onCancel: () => void;
  initialData: any;
};

const CategoryModal = (props: IUpdatePackageModalProps) => {
  const { visible, onSubmit, onCancel, initialData } = props;
  const [activeKey, setActiveKey] = useState<string>(EnumTabKeys.TAB1);
  const { TabPane } = Tabs;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  return (
    <Modal visible={visible} style={{ borderRadius: 5 }} width={960} footer={false} closable={true} onCancel={onCancel}>
      <div className="container-fluid wrapper">
        <div className="row">
          <div className="col-md-12 mb-2">
            <h4>
              {initialData.type} {t('bundle')}
            </h4>
            <p>{t('choose-an-easy-to-work-with-name-for-your-bundle-it-will-only-be-used-in-internally-during-setup-of-your-products')}</p>
          </div>
        </div>
        <div className="row">
          {isLoading && (
            <div className="row text-center h-100 w-100">
              <div className="col-12 align-self-center">
                <Spin size="large" spinning={true} />
              </div>
            </div>
          )}
          {!isLoading && (
            <div className="col-12  mt-3">
              <Tabs activeKey={activeKey} className="default-tab" onChange={(activeKey) => setActiveKey(activeKey)} defaultActiveKey={EnumTabKeys.TAB1}>
                <TabPane tab={t('general')} key={EnumTabKeys.TAB1}>
                  {activeKey === EnumTabKeys.TAB1 && <Tab1 onCancel={props.onCancel} onSubmit={props.onSubmit} initialData={props.initialData} />}
                </TabPane>
                {(props.initialData.type == 'Movies') && (
                  <TabPane tab={'External'} key={EnumTabKeys.TAB2} disabled={initialData._id == undefined ? true : false}>
                    {activeKey === EnumTabKeys.TAB2 && <Tab2 onCancel={props.onCancel} onSubmit={props.onSubmit} initialData={props.initialData} />}
                  </TabPane>
                )}
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CategoryModal;
