import { Modal, Spin } from 'antd';
import { Deployment } from '@mwaretv/database/build/backend/models/deployment/deployment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import common from '../../../../../../../services/common';

import cmsAlbums from '../../../../../../../services/areas/content/albums/albums';
import cmsPodcasts from '../../../../../../../services/areas/content/podcasts/podcasts';
import cmsMovies from '../../../../../../../services/areas/content/movies/movies';
import cmsSeries from '../../../../../../../services/areas/content/series/series';
import cmsShorts from '../../../../../../../services/areas/content/shorts/shorts';
import cmsChannels from '../../../../../../../services/areas/content/channels/channels';
import cmsRadios from '../../../../../../../services/areas/content/radio/radio';
import cmsCams from '../../../../../../../services/areas/content/cams/cams';
import cmsCourses from '../../../../../../../services/areas/content/courses/courses';
import cmsGames from '../../../../../../../services/areas/content/games/games';

import baseTranslation from '../../../../../../../pages/areas/appbuilder/translations/helper/english.json';
import translationService from '../../../../../../../services/areas/appbuilder/applications/translations';
import cmsApps from '../../../../../../../services/areas/appbuilder/applications/application';

import cmsAlbumsBundles from '../../../../../../../services/areas/content/albums/bundles';
import cmsPodcastsBundles from '../../../../../../../services/areas/content/podcasts/bundles';
import cmsMoviesBundles from '../../../../../../../services/areas/content/movies/bundles';
import cmsSeriesBundles from '../../../../../../../services/areas/content/series/bundles';
import cmsShortsBundles from '../../../../../../../services/areas/content/shorts/bundles';
import cmsChannelsBundles from '../../../../../../../services/areas/content/channels/bundles';
import cmsRadiosBundles from '../../../../../../../services/areas/content/radio/bundles';
import cmsCamsBundles from '../../../../../../../services/areas/content/cams/bundles';
import cmsCoursesBundles from '../../../../../../../services/areas/content/courses/bundles';
import cmsGamesBundles from '../../../../../../../services/areas/content/games/bundles';

import cmsAlbumsLists from '../../../../../../../services/areas/content/albums/lists';
import cmsPodcastsLists from '../../../../../../../services/areas/content/podcasts/lists';
import cmsMoviesLists from '../../../../../../../services/areas/content/movies/lists';
import cmsSeriesLists from '../../../../../../../services/areas/content/series/lists';
import cmsShortsLists from '../../../../../../../services/areas/content/shorts/lists';
import cmsChannelsLists from '../../../../../../../services/areas/content/channels/lists';
import cmsRadiosLists from '../../../../../../../services/areas/content/radio/lists';
import cmsCamsLists from '../../../../../../../services/areas/content/cams/lists';
import cmsCoursesLists from '../../../../../../../services/areas/content/courses/lists';
import cmsGamesLists from '../../../../../../../services/areas/content/games/lists';

import * as HttpStatusCodes  from 'http-status-codes';
import { STORAGE_ENDPOINTS } from '../../../../../../../services/storage_paths';
import axios from 'axios';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base';

export type LayoutProps = {
  visible: boolean;
  clientName: string;
  deployment: Deployment;
  onCancel: () => void;
  onSubmit: () => void;
};

const DeploymentTransfer = (props: LayoutProps) => {
  const { visible, onCancel, clientName, deployment, onSubmit } = props;
  const [isLoading, setLoading] = useState(false);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {};
    fetchData();
  }, [props]);

  const startTransfer = async () => {
    setLoading(true);
    await startAlbumBundles();
    await startCamsBundles();
    await startChannelsBundles();
    await startCoursesBundles();
    await startGamesBundles();
    await startMoviesBundles();
    await startPodcastsBundles();
    await startRadiosBundles();
    await startSeriesBundles();
    await startShortsBundles();

    await startAlbumsLists();
    await startCamsLists();
    await startChannelsLists();
    await startCoursesLists();
    await startGamesLists();
    await startMoviesLists();
    await startPodcastsLists();
    await startRadiosLists();
    await startSeriesLists();
    await startShortsLists();

    await startAlbums();
    await startCams();
    await startChannels();
    await startCourses();
    await startGames();
    await startMovies();
    await startPodcasts();
    await startRadios();
    await startSeasons();
    await startSeries();
    await startShorts();

    await startApps();


    setLoading(false);
  };

  const startApps = async () => {
    let response = await cmsApps.List_Applications({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var apps = response.data.data;
      for (var app of apps) {
        await getImageBuffer(app.branding?.background_images.portrait, 'appbuilder', STORAGE_ENDPOINTS(clientName, deployment?.name, '').APPBUILDER.IMAGES);
        await getImageBuffer(app.branding?.background_images.landscape, 'appbuilder', STORAGE_ENDPOINTS(clientName, deployment?.name, '').APPBUILDER.IMAGES);

        await getImageBuffer(app.branding?.logos.favicon, 'appbuilder', STORAGE_ENDPOINTS(clientName, deployment?.name, '').APPBUILDER.IMAGES);
        await getImageBuffer(app.branding?.logos.icon, 'appbuilder', STORAGE_ENDPOINTS(clientName, deployment?.name, '').APPBUILDER.IMAGES);
        await getImageBuffer(app.branding?.logos.logo, 'appbuilder', STORAGE_ENDPOINTS(clientName, deployment?.name, '').APPBUILDER.IMAGES);
      }
    }
  };

  const startAlbums = async () => {
    let response = await cmsAlbums.List_Albums({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var albums = response.data.data;
      for (var album of albums) {
        await getImageBuffer(album.images?.square, 'albums', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.ALBUMS.IMAGES);
        await getImageBuffer(album.images?.tile, 'albums', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.ALBUMS.IMAGES);

        await getImageBuffer(album.images?.square + '.webp', 'albums', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.ALBUMS.IMAGES);
        await getImageBuffer(album.images?.tile + '.webp', 'albums', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.ALBUMS.IMAGES);
      }
    }
  };
  const startCams = async () => {
    let response = await cmsCams.List_Cams({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var cams = response.data.data;
      for (var cam of cams) {
        await getImageBuffer(cam.images?.square, 'cams', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CAMS.IMAGES);
        await getImageBuffer(cam.images?.tile, 'cams', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CAMS.IMAGES);

        await getImageBuffer(cam.images?.square + '.webp', 'cams', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CAMS.IMAGES);
        await getImageBuffer(cam.images?.tile + '.webp', 'cams', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CAMS.IMAGES);
      }
    }
  };
  const startChannels = async () => {
    let response = await cmsChannels.List_Channels({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var channels = response.data.data;
      for (var channel of channels) {
        await getImageBuffer(channel.images?.square, 'channels', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CHANNELS.IMAGES);
        await getImageBuffer(channel.images?.tile, 'channels', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CHANNELS.IMAGES);

        await getImageBuffer(channel.images?.square + '.webp', 'channels', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CHANNELS.IMAGES);
        await getImageBuffer(channel.images?.tile + '.webp', 'channels', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CHANNELS.IMAGES);
      }
    }
  };
  const startCourses = async () => {
    let response = await cmsCourses.List_Courses({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var courses = response.data.data;
      for (var course of courses) {
        await getImageBuffer(course.images?.poster, 'courses', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.COURSES.IMAGES);
        await getImageBuffer(course.images?.widescreen, 'courses', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.COURSES.IMAGES);
        await getImageBuffer(course.images?.background, 'courses', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.COURSES.IMAGES);

        await getImageBuffer(course.images?.poster + '.webp', 'courses', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.COURSES.IMAGES);
        await getImageBuffer(course.images?.widescreen + '.webp', 'courses', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.COURSES.IMAGES);
        await getImageBuffer(course.images?.background + '.webp', 'courses', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.COURSES.IMAGES);

        for (var lesson of course.lessons) {
          await getImageBuffer(lesson.images?.widescreen, 'courses', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.COURSES.IMAGES);
          await getImageBuffer(lesson.images?.widescreen + '.webp', 'courses', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.COURSES.IMAGES);
        }
      }
    }
  };
  const startGames = async () => {
    let response = await cmsGames.List_Games({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var games = response.data.data;
      for (var game of games) {
        await getImageBuffer(game.images?.widescreen, 'games', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.GAMES.IMAGES);
        await getImageBuffer(game.images?.background, 'games', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.GAMES.IMAGES);

        await getImageBuffer(game.images?.widescreen + '.webp', 'games', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.GAMES.IMAGES);
        await getImageBuffer(game.images?.background + '.webp', 'games', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.GAMES.IMAGES);
      }
    }
  };
  const startMovies = async () => {
    let response = await cmsMovies.List_Movies({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var movies = response.data.data;
      for (var movie of movies) {
        await getImageBuffer(movie.images?.poster, 'movies', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.MOVIES.IMAGES);
        await getImageBuffer(movie.images?.background, 'movies', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.MOVIES.IMAGES);
        await getImageBuffer(movie.images?.logo, 'movies', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.MOVIES.IMAGES);

        await getImageBuffer(movie.images?.poster + '.webp', 'movies', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.MOVIES.IMAGES);
        await getImageBuffer(movie.images?.background + '.webp', 'movies', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.MOVIES.IMAGES);
        await getImageBuffer(movie.images?.logo + '.webp', 'movies', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.MOVIES.IMAGES);
      }
    }
  };
  const startPodcasts = async () => {
    let response = await cmsPodcasts.List_Podcasts({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var podcasts = response.data.data;
      for (var podcast of podcasts) {
        await getImageBuffer(podcast.images?.square, 'podcasts', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.PODCASTS.IMAGES);
        await getImageBuffer(podcast.images?.tile, 'podcasts', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.PODCASTS.IMAGES);

        await getImageBuffer(podcast.images?.square + '.webp', 'podcasts', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.PODCASTS.IMAGES);
        await getImageBuffer(podcast.images?.tile + '.webp', 'podcasts', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.PODCASTS.IMAGES);
      }
    }
  };
  const startRadios = async () => {
    let response = await cmsRadios.List_Radios({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var radios = response.data.data;
      for (var radio of radios) {
        await getImageBuffer(radio.images?.square, 'radios', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.RADIOS.IMAGES);
        await getImageBuffer(radio.images?.tile, 'radio', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.RADIOS.IMAGES);

        await getImageBuffer(radio.images?.square + '.webp', 'radios', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.RADIOS.IMAGES);
        await getImageBuffer(radio.images?.tile + '.webp', 'radio', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.RADIOS.IMAGES);
      }
    }
  };
  const startSeries = async () => {
    let response = await cmsSeries.List_Seriess({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var series = response.data.data;
      for (var serie of series) {
        await getImageBuffer(serie.images?.widescreen, 'series', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.IMAGES);
        await getImageBuffer(serie.images?.logo, 'series', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.IMAGES);

        await getImageBuffer(serie.images?.widescreen + '.webp', 'series', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.IMAGES);
        await getImageBuffer(serie.images?.logo + '.webp', 'series', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.IMAGES);
      }
    }
  };
  const startSeasons = async () => {
    let response = await cmsSeries.List_Seasons({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var seasons = response.data.data as any;
      for (var season of seasons) {
        await getImageBuffer(season.images?.poster, 'series', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.IMAGES);
        await getImageBuffer(season.images?.background, 'series', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.IMAGES);

        await getImageBuffer(season.images?.poster + '.webp', 'series', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.IMAGES);
        await getImageBuffer(season.images?.background + '.webp', 'series', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.IMAGES);

        for (var episode of season.episodes) {
          await getImageBuffer(episode.images?.widescreen, 'series', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.IMAGES);

          await getImageBuffer(episode.images?.widescreen + '.webp', 'series', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.IMAGES);
        }
      }
    }
  };
  const startShorts = async () => {
    let response = await cmsShorts.List_Shorts({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var shorts = response.data.data;
      for (var short of shorts) {
        await getImageBuffer(short.images?.widescreen, 'shorts', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SHORTS.IMAGES);
        await getImageBuffer(short.images?.background, 'shorts', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SHORTS.IMAGES);

        await getImageBuffer(short.images?.widescreen + '.webp', 'shorts', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SHORTS.IMAGES);
        await getImageBuffer(short.images?.background + '.webp', 'shorts', STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SHORTS.IMAGES);
      }
    }
  };
  ////
  const startAlbumBundles = async () => {
    let response = await cmsAlbumsBundles.List_Album_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var albums = response.data as any;
      console.log(albums);
      for (var album of albums) {
        await cmsCamsBundles.Publish_Cam_Bundles({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.ALBUMS.BUNDLES,
          },
          name: album.name,
          categories: [],
          _id: album._id,
        });
      }
    }
  };
  const startCamsBundles = async () => {
    let response = await cmsCamsBundles.List_Cam_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var cams = response.data as any;
      for (var cam of cams) {
        await cmsCamsBundles.Publish_Cam_Bundles({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CAMS.BUNDLES,
          },
          name: cam.name,
          categories: [],
          _id: cam._id,
        });
      }
    }
  };
  const startChannelsBundles = async () => {
    let response = await cmsChannelsBundles.List_Channel_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var channels = response.data as any;
      for (var channel of channels) {
        await cmsCamsBundles.Publish_Cam_Bundles({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CHANNELS.BUNDLES,
          },
          name: channel.name,
          categories: [],
          _id: channel._id,
        });
      }
    }
  };
  const startCoursesBundles = async () => {
    let response = await cmsCoursesBundles.List_Course_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var courses = response.data as any;
      for (var course of courses) {
        await cmsCamsBundles.Publish_Cam_Bundles({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.COURSES.BUNDLES,
          },
          name: course.name,
          categories: [],
          _id: course._id,
        });
      }
    }
  };
  const startGamesBundles = async () => {
    let response = await cmsGamesBundles.List_Games_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var games = response.data as any;
      for (var game of games) {
        await cmsCamsBundles.Publish_Cam_Bundles({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.GAMES.BUNDLES,
          },
          name: game.name,
          categories: [],
          _id: game._id,
        });
      }
    }
  };
  const startMoviesBundles = async () => {
    let response = await cmsMoviesBundles.List_Movie_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var movies = response.data as any;
      for (var movie of movies) {
        await cmsCamsBundles.Publish_Cam_Bundles({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.MOVIES.BUNDLES,
          },
          name: movie.name,
          categories: [],
          _id: movie._id,
        });
      }
    }
  };
  const startPodcastsBundles = async () => {
    let response = await cmsPodcastsBundles.List_Podcast_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var podcasts = response.data as any;
      for (var podcast of podcasts) {
        await cmsCamsBundles.Publish_Cam_Bundles({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.PODCASTS.BUNDLES,
          },
          name: podcast.name,
          categories: [],
          _id: podcast._id,
        });
      }
    }
  };
  const startRadiosBundles = async () => {
    let response = await cmsRadiosBundles.List_Radio_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var radios = response.data as any;
      for (var radio of radios) {
        await cmsCamsBundles.Publish_Cam_Bundles({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.RADIOS.BUNDLES,
          },
          name: radio.name,
          categories: [],
          _id: radio._id,
        });
      }
    }
  };
  const startSeriesBundles = async () => {
    let response = await cmsSeriesBundles.List_Series_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var series = response.data as any;
      for (var serie of series) {
        await cmsCamsBundles.Publish_Cam_Bundles({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.BUNDLES,
          },
          name: serie.name,
          categories: [],
          _id: serie._id,
        });
      }
    }
  };
  const startShortsBundles = async () => {
    let response = await cmsShortsBundles.List_Shorts_Bundless({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var shorts = response.data as any;
      for (var short of shorts) {
        await cmsCamsBundles.Publish_Cam_Bundles({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SHORTS.BUNDLES,
          },
          name: short.name,
          categories: [],
          _id: short._id,
        });
      }
    }
  };
  ////
  const startAlbumsLists = async () => {
    let response = await cmsAlbumsLists.List_Lists({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var albums = response.data.data;
      for (var album of albums) {
        await cmsAlbumsLists.Publish_List({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.ALBUMS.LISTS,
          },
          _id: album._id,
          name: album.name,
          publish: '',
        });
      }
    }
  };
  const startCamsLists = async () => {
    let response = await cmsCamsLists.List_Lists({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var cams = response.data.data;
      for (var cam of cams) {
        await cmsAlbumsLists.Publish_List({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CAMS.LISTS,
          },
          _id: cam._id,
          name: cam.name,
          publish: '',
        });
      }
    }
  };
  const startChannelsLists = async () => {
    let response = await cmsChannelsLists.List_Lists({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var channels = response.data.data;
      for (var channel of channels) {
        await cmsAlbumsLists.Publish_List({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.CHANNELS.LISTS,
          },
          _id: channel._id,
          name: channel.name,
          publish: '',
        });
      }
    }
  };
  const startCoursesLists = async () => {
    let response = await cmsCoursesLists.List_Lists({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var courses = response.data.data;
      for (var course of courses) {
        await cmsAlbumsLists.Publish_List({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.COURSES.LISTS,
          },
          _id: course._id,
          name: course.name,
          publish: '',
        });
      }
    }
  };
  const startGamesLists = async () => {
    let response = await cmsGamesLists.List_Lists({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var games = response.data.data;
      for (var game of games) {
        await cmsAlbumsLists.Publish_List({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.GAMES.LISTS,
          },
          _id: game._id,
          name: game.name,
          publish: '',
        });
      }
    }
  };
  const startMoviesLists = async () => {
    let response = await cmsMoviesLists.List_Lists({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var movies = response.data.data;
      for (var movie of movies) {
        await cmsAlbumsLists.Publish_List({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.MOVIES.LISTS,
          },
          _id: movie._id,
          name: movie.name,
          publish: '',
        });
      }
    }
  };
  const startPodcastsLists = async () => {
    let response = await cmsPodcastsLists.List_Lists({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var podcasts = response.data.data;
      for (var podcast of podcasts) {
        await cmsAlbumsLists.Publish_List({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.PODCASTS.LISTS,
          },
          _id: podcast._id,
          name: podcast.name,
          publish: '',
        });
      }
    }
  };
  const startRadiosLists = async () => {
    let response = await cmsRadiosLists.List_Lists({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var radios = response.data.data;
      for (var radio of radios) {
        await cmsAlbumsLists.Publish_List({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.RADIOS.LISTS,
          },
          _id: radio._id,
          name: radio.name,
          publish: '',
        });
      }
    }
  };
  const startSeriesLists = async () => {
    let response = await cmsSeriesLists.List_Lists({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var series = response.data.data;
      for (var serie of series) {
        await cmsAlbumsLists.Publish_List({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SERIES.LISTS,
          },
          _id: serie._id,
          name: serie.name,
          publish: '',
        });
      }
    }
  };
  const startShortsLists = async () => {
    let response = await cmsShortsLists.List_Lists({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 1,
      pageSize: 100000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var shorts = response.data.data;
      for (var short of shorts) {
        await cmsAlbumsLists.Publish_List({
          query_params: {
            instance: clientName,
            cms: deployment?.name ?? '',
            path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.SHORTS.LISTS,
          },
          _id: short._id,
          name: short.name,
          publish: '',
        });
      }
    }
  };

  const getImageBuffer = async (fileName, type, path) => {
    try {
      const res = await axios.get(fileName.indexOf('https://') > -1 ? fileName : 'https://cloudtv.akamaized.net/' + fileName.replace('https://cloudtv.akamaized.net/', ''), { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        const binaryDataBuffer = res.data;
        const bufferArray = new Uint8Array(binaryDataBuffer).buffer;
        const blob = new Blob([bufferArray]);
        var fileName_ = fileName.replace('https://cloudtv.akamaized.net/', '').split('/');
        common.UploadBuffer({
          image: blob,
          query_params: {
            path: path,
            name: fileName_[fileName_.length - 1],
            client: clientName,
            deployment: deployment.name,
          },
        });
      }
    } catch (e) {}
  };
  return (
    <Modal visible={visible} style={{ border: '1px solid #ebebeb', borderRadius: 10 }} width={'60%'} footer={false} closable={true} onCancel={onCancel}>
      <div className="p-2 mt-4" style={{ border: '1px solid #ebebeb', height: 495 }}>
        <div className="row justify-content-center" style={{ height: '100%' }}>
          <div className="col-md-12" style={{ height: '100%' }}>
            <div className="p-2">
              <h5> {t('transfer')}</h5>
            </div>
            <div className="row" style={{ height: '100%' }}>
              <div className="col-12" style={{ height: '100%' }}>
                <div style={{ width: '100%', height: '100%' }}>
                  {isLoading && (
                    <div className="text-center w-100" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', position: 'absolute', zIndex: 9999 }}>
                      <div className="col-md-12 align-self-center" style={{ paddingTop: 175 }}>
                        <Spin size="large" spinning={true} />
                      </div>
                    </div>
                  )}
                  {!isLoading && (
                    <div className="col-12" style={{ paddingLeft: 0, height: '100%' }}>
                      <div className="col-md-12 p-0 mt-3">
                        <div style={{ height: 300, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Hit the transfer button to start transfering from NetStorage to ObjectStorage<br/><br/>You have to manually publish the app translation files!</div>
                        <button type="button" onClick={startTransfer} className="btn btn-secondary float-right rounded-pill mt-1 width-240 height-48">
                          {t('transfer')}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeploymentTransfer;
