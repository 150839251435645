import { Button, Row, Table, Tooltip, notification } from 'antd';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { CustomNotification } from '../../Notification';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../pages/dashboard';
import appService from '../../../../services/areas/appbuilder/applications/application';
import EditModal from '../Edit/Edit_Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { useTranslation } from 'react-i18next';

const Modal = () => {
  const { clientName } = useParams<IMSNameParams>();
  const [theme, setTheme] = useState([] as any);
  const { deployment } = useContext(AppBuilderContext);
  const { id } = useParams<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [modals, setModals] = useState([] as any);
  const { t } = useTranslation();
  const newModal = {
    name: '',

    header_color: '#333333',
    background_color: '#000000',
    border_radius: 0,
    border_width: 0,
    border_color: '#999999',

    title_color: '#ffffff',
    title_bold: false,
    subtext_color: '#ffffff',
    subtext_bold: false,
    show_title: true,
    show_subtext: true,

    components: {
      button: {
        _id: '',
      },
      input: {
        _id: '',
      },
    },
  };

  const [editData, setEditData] = useState([] as any);
  const [editType, setEditType] = useState('');
  const [application, setApplication] = useState([] as any);
  const [EditShowModalModal, setEditShowModalModal] = useState(false);

  const editComponent = async (data: any, type: any) => {
    setIsLoading(true);
    var modalsOut = modals;
    if (type == "Add") {
      modalsOut.push(data);
    } else {
      var index = modalsOut.findIndex((b) => b._id == data._id);
      if (index > -1) {
        modalsOut[index] = data;
      } else {
        modalsOut.push(data);
      }
    }
    setModals(modalsOut);
    let response = await appService.Update_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
        path: STORAGE_ENDPOINTS(clientName, deployment?.name, '', application.branding.packageid).APPBUILDER.APP,
      },
      _id: id,
      client: clientName,
      deployment: deployment?.name ?? '',
      ...application,
      components: {
        ...application.components,
        modals: modalsOut,
      },
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      setEditShowModalModal(false);
      setIsLoading(false);
    } else {
      CustomNotification({ error: response.errors });
      setIsLoading(false);
    }
  };

  const getAppItems = async () => {
    setIsLoading(true);
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      setModals(resp.components.modals);
      setApplication(resp);
      setTheme(resp.theme?.colors);
    }
    setIsLoading(false);
  };
  useEffectOnce(() => {
    getAppItems();
  });

  const columns = [
    {
      title: t('name'),
      dataIndex: 'name',
    },
    {
      title: t('header'),
      dataIndex: 'header_color',
      width: 100,
      render: (row: any, record: any) => {
        return (
          <div
            className="height-48 font-size-14 text-default"
            style={{
              backgroundColor: row,
              borderRadius: '5px',
              border: '#e9edf4 1px solid',
              width: 48,
            }}
          ></div>
        );
      },
    },
    {
      title: t('border'),
      dataIndex: 'border_color',
      width: 100,
      render: (row: any, record: any) => {
        return (
          <div
            className="height-48 font-size-14 text-default"
            style={{
              backgroundColor: row,
              borderRadius: '5px',
              border: '#e9edf4 1px solid',
              width: 48,
            }}
          ></div>
        );
      },
    },
    {
      title: t('background'),
      dataIndex: 'background_color',
      width: 100,
      render: (row: any, record: any) => {
        return (
          <div
            className="height-48 font-size-14 text-default"
            style={{
              backgroundColor: row,
              borderRadius: '5px',
              border: '#e9edf4 1px solid',
              width: 48,
            }}
          ></div>
        );
      },
    },
    {
      title: t('action'),
      width: 85,
      render: (row: any) => {
        return (
          <Row className="align-items-center">
            <Tooltip placement="top" title={`Edit Modal`}>
              <Button
                onClick={() => {
                  setEditData(row);
                  setEditType('Update');
                  setEditShowModalModal(true);
                }}
                className="mx-2 cursor-pointer"
                style={{ height: '100%' }}
                type="text"
              >
                <FontAwesomeIcon className="action-icon fa-trash-icon" icon={faEdit} />
                <div>{t('edit')}</div>
              </Button>
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        {!isLoading && (
          <EditModal
            inputs={application.components?.inputs}
            buttons={application?.components?.buttons}
            theme={theme}
            visible={EditShowModalModal}
            onSubmit={editComponent}
            onCancel={() => {
              setEditShowModalModal(false);
            }}
            data={editData}
            type={editType}
            index={0}
          />
        )}
        <div className="col-md-12 mb-2">
          <div className="container-fluid">
            <div className="row-md-12">
              <div className="row">
                <div className="col-md-12 mb-2">
                  <h2 className="page-title pt-4">{t('modals')}</h2>
                  <p className="h6 font-weight-normal line-height-26">{t('modals-are-used-throughout-the-app-you-can-think-of-authentication-hero-elements-etc')}</p>
                </div>
                <div className="col-md-6 text-right"></div>
              </div>
              <div className="row mt-3">
                <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start"></div>
                <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start mb-3">
                  <Button
                    loading={isLoading}
                    className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48"
                    onClick={() => {
                      setEditData(newModal);
                      setEditType('Add');
                      setEditShowModalModal(true);
                    }}
                  >
                    {t('add-modal')}
                  </Button>
                </div>
              </div>
              <Table pagination={false} rowClassName="editable-row" loading={isLoading} columns={columns} dataSource={modals} key={JSON.stringify(modals)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Modal;
