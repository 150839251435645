import { Badge, Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import IMSDashboardHeader from '../../../common/components/IMSDashboardHeader';
import MWare_Logo from '../../../assets/images/logo.png';
import MWare_Icon from '../../../assets/images/icon.png';
import Dashboard from './dashboard';
import * as DeploymentService from '../../../services/deployments/deployment';
import { useQuery } from '@tanstack/react-query';
import { ContentContextProvider } from '../../../contexts/contentContext';
import { faDashboard, faShieldCheck, faTv, faCreditCard, faFileArrowUp, faCogs, faPhotoFilmMusic } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DRM from './drm';
import Actions from './actions';
import Channels from './channels';
import Radios from './radios';
import Epg from './epg';
import Cams from './cams';
import Movies from './movies';
import Apps from './apps';
import Series from './series';
import Albums from './albums';
import Podcasts from './podcasts';
import Games from './games';
import Courses from './courses';
import PPV from './ppv';
import Providers from './providers';
import Tags from './tags';
import Nests from './nests';
import Player from './player';
import Shorts from './shorts';
import { useEffectOnce, useLocation } from 'react-use';
import { AuthContext } from '../../../context_api/AuthContext';
import { useContext } from 'react';
import { hasRole } from '../../../utils/Auth/role.auth';
import Transcoders from './transcoders';
import Params from './params';
import { useTranslation } from 'react-i18next';
import { getOpenKeys, getSelectedKey } from '../../../utils/menu';
import cmsService from '../../../services/areas/content/epg/epg_settings';
import * as HttpStatusCodes from 'http-status-codes';

const Content = () => {
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();
  const { state: AuthState } = useContext(AuthContext);
  const isClient = hasRole(AuthState.user!, clientName, ['superadmin', 'admin']);
  const isMware = hasRole(AuthState.user!, clientName, ['superadmin']);
  const isProvider = AuthState.user?.role === 'Provider' ? true : false;
  const history = useHistory();
  var locSplit = location?.pathname?.split('/');
  var redirectPath = '';
  if (locSplit != undefined) {
    redirectPath = locSplit.slice(0, 4 - locSplit.length).join('/');
  }
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [epgSettings, setEpgSettings] = useState([] as any);
  const getSettings = async () => {
    var values = {
      query_params: {
        instance: clientName,
        cms: deployment ?? '',
      },
    };
    let response = await cmsService.Details_Epg_Settings(values as any);
    if (response !== undefined && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;

      setEpgSettings(resp);
    }
  };

  useEffectOnce(() => {
    (async () => await getSettings())();
  });

  useEffectOnce(() => {
    const str = window.location.href;
    const parts = str.split('/');
    const lastPart = parts[parts.length - 1];
    if (!isClient && lastPart == 'content') {
      history.push(url + '/channels/channels');
    }
  });

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });

  return (
    <Layout>
      <Layout.Sider
        width="300"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => {
          setCollapsed(value);
          window.dispatchEvent(new Event('resize'));
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
            padding: 40,
            paddingTop: 57,
            paddingBottom: 56,
            marginBottom: 20,
            borderBottom: '1px #444 solid',
            backgroundColor: '#002140',
          }}
        >
          {!isClient && (
            <Link to={'/'} onClick={() => {}} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </Link>
          )}
          {isClient && (
            <a href={redirectPath} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </a>
          )}
        </div>
        {isProvider && (
          <Menu
            mode="inline"
            inlineCollapsed={collapsed}
            theme="dark"
            defaultSelectedKeys={getSelectedKey(location?.pathname ?? "", "content")}
            defaultOpenKeys={getOpenKeys(location?.pathname ?? "", "content")}
            items={[
              isClient
                ? {
                    icon: <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />,
                    label: (
                      <div>
                        <span>{t('dashboard')}</span>
                      </div>
                    ),

                    key: 'dashboard',
                    children: [
                      {
                        label: (
                          <Link to={`${url}`}>
                            <span>{t('dashboard')}</span>
                          </Link>
                        ),
                        key: 'dashboard-dashboard',
                      },
                    ],
                  }
                : {
                    label: <></>,
                    key: 'empty',
                  },
              {
                icon: <FontAwesomeIcon icon={faTv} size="lg" style={{ width: '20', height: '20' }} />,
                label: (
                  <div>
                    <span>{t('media')}</span>
                  </div>
                ),
                key: 'media',
                children: [
                  {
                    label: (
                      <div>
                        <span>{t('channels')}</span>
                      </div>
                    ),
                    key: 'channels',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/channels/channels`}>
                            <span>{t('channels')}</span>
                          </Link>
                        ),
                        key: 'channels-channels-channels',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('radio')}</span>
                      </div>
                    ),
                    key: 'radio',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/radios/radios`}>
                            <span>{t('radio')}</span>
                          </Link>
                        ),
                        key: 'radio-radios-radios',
                      },
                    ],
                  },

                  {
                    label: (
                      <div>
                        <span>{t('cams')}</span>
                      </div>
                    ),
                    key: 'cams',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/cams/cams`}>
                            <span>{t('cams')}</span>
                          </Link>
                        ),
                        key: 'cams-cams',
                      },
                    ],
                  },

                  {
                    label: (
                      <div>
                        <span>{t('movies')}</span>
                      </div>
                    ),
                    key: 'movies',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/movies/movies`}>
                            <span>{t('movies')}</span>
                          </Link>
                        ),
                        key: 'movies-movies',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('games')}</span>
                      </div>
                    ),
                    key: 'games',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/games/games`}>
                            <span>{t('games')}</span>
                          </Link>
                        ),
                        key: 'games-games',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('shorts')}</span>
                      </div>
                    ),
                    key: 'shorts',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/shorts/shorts`}>
                            <span>{t('shorts')}</span>
                          </Link>
                        ),
                        key: 'shorts-shorts',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('series')}</span>
                      </div>
                    ),
                    key: 'series',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/series/series`}>
                            <span>{t('series')}</span>
                          </Link>
                        ),
                        key: 'series-series',
                      },
                      {
                        label: (
                          <Link to={`${url}/series/seasons`}>
                            <span>{t('seasons')}</span>
                          </Link>
                        ),
                        key: 'series-seasons',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('courses')}</span>
                      </div>
                    ),
                    key: 'courses',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/courses/courses`}>
                            <span>{t('courses')}</span>
                          </Link>
                        ),
                        key: 'course-course',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('albums')}</span>
                      </div>
                    ),
                    key: 'albums',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/albums/albums`}>
                            <span>{t('albums')}</span>
                          </Link>
                        ),
                        key: 'albums-albums',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('podcasts')}</span>
                      </div>
                    ),
                    key: 'podcasts',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/podcasts/podcasts`}>
                            <span>{t('podcasts')}</span>
                          </Link>
                        ),
                        key: 'podcasts-podcasts',
                      },
                    ],
                  },
                ],
              },
            ]}
          />
        )}
        {!isProvider && (
          <Menu
            mode="inline"
            inlineCollapsed={collapsed}
            theme="dark"
            defaultSelectedKeys={getSelectedKey(location?.pathname ?? "", "content")}
            defaultOpenKeys={getOpenKeys(location?.pathname ?? "", "content")}
            items={[
              isClient
                ? {
                    icon: <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />,
                    label: (
                      <div>
                        <span>{t('dashboard')}</span>
                      </div>
                    ),

                    key: 'dashboard',
                    children: [
                      {
                        label: (
                          <Link to={`${url}`}>
                            <span>{t('dashboard')}</span>
                          </Link>
                        ),
                        key: 'dashboard-dashboard',
                      },
                    ],
                  }
                : {
                    label: <></>,
                    key: 'empty',
                  },
              {
                icon: <FontAwesomeIcon icon={faPhotoFilmMusic} size="lg" style={{ width: '20', height: '20' }} />,
                label: (
                  <div>
                    <span>{t('media')}</span>
                  </div>
                ),
                key: 'media',
                children: [
                  {
                    label: (
                      <div>
                        <span>{t('channels')}</span>
                      </div>
                    ),
                    key: 'channels',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/channels/channels`}>
                            <span>{t('channels')}</span>
                          </Link>
                        ),
                        key: 'channels-channels-channels',
                      },
                      {
                        label: (
                          <Link to={`${url}/channels/bundles`}>
                            <span>{t('bundles')}</span>
                          </Link>
                        ),
                        key: 'channels-channels-bundles',
                      },
                      // {
                      //   label: (
                      //     <Link  to={`${url}/channels/mosaic`}>
                      //       <span >Channel Mosaic</span>
                      //     </Link>
                      //   ),
                      //   key: 'channels-mosaic',
                      // },
                      {
                        label: (
                          <Link to={`${url}/channels/lists`}>
                            <span>{t('lists')}</span>
                          </Link>
                        ),
                        key: 'channels-lists',
                      },
                      {
                        label: (
                          <Link to={`${url}/channels/curations`}>
                            <span>{t('curations')}</span>
                          </Link>
                        ),
                        key: 'channels-curations',
                      },
                      {
                        label: (
                          <Link to={`${url}/channels/providers`}>
                            <span>{t('providers')}</span>
                          </Link>
                        ),
                        key: 'channels-providers',
                      },
                      // {
                      //   label: (
                      //     <Link to={`${url}/channels/settings`}>
                      //       <span>{t('settings')}</span>
                      //     </Link>
                      //   ),
                      //   key: 'channels-settings',
                      // },
                      {
                        label: (
                          <Link to={`${url}/transcoders/transcoders`}>
                            <span>{t('transcoders')}</span>
                          </Link>
                        ),
                        key: 'channels-transcoders',
                      },
                      {
                        label: (
                          <Link to={`${url}/actions/importer-excel`}>
                            <span>{t('import')}</span>
                          </Link>
                        ),
                        key: 'channels-import',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('radio')}</span>
                      </div>
                    ),
                    key: 'radio',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/radios/radios`}>
                            <span>{t('radio')}</span>
                          </Link>
                        ),
                        key: 'radio-radios-radios',
                      },
                      {
                        label: (
                          <Link to={`${url}/radios/bundles`}>
                            <span>{t('bundles')}</span>
                          </Link>
                        ),
                        key: 'radio-radios-bundles',
                      },
                      {
                        label: (
                          <Link to={`${url}/radios/lists`}>
                            <span>{t('lists')}</span>
                          </Link>
                        ),
                        key: 'radio-lists',
                      },
                      {
                        label: (
                          <Link to={`${url}/radios/curations`}>
                            <span>{t('curations')}</span>
                          </Link>
                        ),
                        key: 'radio-curations',
                      },
                      {
                        label: (
                          <Link to={`${url}/radio/providers`}>
                            <span>{t('providers')}</span>
                          </Link>
                        ),
                        key: 'radio-providers',
                      },
                      {
                        label: (
                          <Link to={`${url}/actions/importer-excel`}>
                            <span>{t('import')}</span>
                          </Link>
                        ),
                        key: 'radio-import',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>
                          {t('guide')}
                          {(epgSettings.na_text == undefined || epgSettings.na_text == '') && <Badge style={{ marginLeft: 5 }} count={'!'} size={'small'}></Badge>}
                        </span>
                      </div>
                    ),
                    key: 'tvguide',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/epg/epg_urls`}>
                            <span>{t('sources')}</span>
                          </Link>
                        ),
                        key: 'tvguide-urls',
                      },
                      {
                        label: (
                          <Link to={`${url}/epg/epg_linking`}>
                            <span>{t('linking')}</span>
                          </Link>
                        ),
                        key: 'tvguide-linking',
                      },
                      {
                        label: (
                          <Link to={`${url}/epg/epg_settings`}>
                            <span>
                              {t('settings')}
                              {(epgSettings.na_text == undefined || epgSettings.na_text == '') && <Badge style={{ marginLeft: 5 }} count={'!'} size={'small'}></Badge>}
                            </span>
                          </Link>
                        ),
                        key: 'tvguide-settings',
                      },
                    ],
                  },

                  {
                    label: (
                      <div>
                        <span>{t('cams')}</span>
                      </div>
                    ),
                    key: 'cams',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/cams/cams`}>
                            <span>{t('cams')}</span>
                          </Link>
                        ),
                        key: 'cams-cams',
                      },
                      {
                        label: (
                          <Link to={`${url}/cams/bundles`}>
                            <span>{t('bundles')}</span>
                          </Link>
                        ),
                        key: 'cams-bundles',
                      },
                      // {
                      //   label: (
                      //     <Link  to={`${url}/channels/lists`}>
                      //       <span >Cam Mosaic</span>
                      //     </Link>
                      //   ),
                      //   key: 'cams-mosaic',
                      // },
                      {
                        label: (
                          <Link to={`${url}/cams/lists`}>
                            <span>{t('lists')}</span>
                          </Link>
                        ),
                        key: 'cams-lists',
                      },
                      {
                        label: (
                          <Link to={`${url}/cams/curations`}>
                            <span>{t('curations')}</span>
                          </Link>
                        ),
                        key: 'cams-curations',
                      },
                      {
                        label: (
                          <Link to={`${url}/cams/providers`}>
                            <span>{t('providers')}</span>
                          </Link>
                        ),
                        key: 'cams-providers',
                      },
                      {
                        label: (
                          <Link to={`${url}/cams/settings`}>
                            <span>{t('settings')}</span>
                          </Link>
                        ),
                        key: 'cams-settings',
                      },
                      {
                        label: (
                          <Link to={`${url}/transcoders/transcoders`}>
                            <span>{t('transcoders')}</span>
                          </Link>
                        ),
                        key: 'cams-transcoders',
                      },
                      {
                        label: (
                          <Link to={`${url}/actions/importer-excel`}>
                            <span>{t('import')}</span>
                          </Link>
                        ),
                        key: 'cams-import',
                      },
                    ],
                  },

                  {
                    label: (
                      <div>
                        <span>{t('movies')}</span>
                      </div>
                    ),
                    key: 'movies',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/movies/movies`}>
                            <span>{t('movies')}</span>
                          </Link>
                        ),
                        key: 'movies-movies',
                      },
                      {
                        label: (
                          <Link to={`${url}/movies/bundles`}>
                            <span>{t('bundles')}</span>
                          </Link>
                        ),
                        key: 'movies-bundles',
                      },
                      {
                        label: (
                          <Link to={`${url}/movies/lists`}>
                            <span>{t('lists')}</span>
                          </Link>
                        ),
                        key: 'movies-lists',
                      },
                      {
                        label: (
                          <Link to={`${url}/movies/curations`}>
                            <span>{t('curations')}</span>
                          </Link>
                        ),
                        key: 'movies-curations',
                      },
                      {
                        label: (
                          <Link to={`${url}/movies/providers`}>
                            <span>{t('providers')}</span>
                          </Link>
                        ),
                        key: 'movies-providers',
                      },
                      {
                        label: (
                          <Link to={`${url}/movies/search`}>
                            <span>{t('search')}</span>
                          </Link>
                        ),
                        key: 'movies-search',
                      },
                      {
                        label: (
                          <Link to={`${url}/actions/importer-excel`}>
                            <span>{t('import')}</span>
                          </Link>
                        ),
                        key: 'movies-import',
                      },

                      {
                        label: (
                          <Link to={`${url}/movies/upload`}>
                            <span>{t('upload')}</span>
                          </Link>
                        ),
                        key: 'movies-upload',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('games')}</span>
                      </div>
                    ),
                    key: 'games',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/games/games`}>
                            <span>{t('games')}</span>
                          </Link>
                        ),
                        key: 'games-games',
                      },
                      {
                        label: (
                          <Link to={`${url}/games/bundles`}>
                            <span>{t('bundles')}</span>
                          </Link>
                        ),
                        key: 'games-bundles',
                      },
                      {
                        label: (
                          <Link to={`${url}/games/lists`}>
                            <span>{t('lists')}</span>
                          </Link>
                        ),
                        key: 'games-lists',
                      },
                      {
                        label: (
                          <Link to={`${url}/games/curations`}>
                            <span>{t('curations')}</span>
                          </Link>
                        ),
                        key: 'games-curations',
                      },
                      {
                        label: (
                          <Link to={`${url}/games/providers`}>
                            <span>{t('providers')}</span>
                          </Link>
                        ),
                        key: 'games-providers',
                      },
                      {
                        label: (
                          <Link to={`${url}/actions/importer-excel`}>
                            <span>{t('import')}</span>
                          </Link>
                        ),
                        key: 'games-import',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('apps')}</span>
                      </div>
                    ),
                    key: 'apps',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/apps/apps`}>
                            <span>{t('apps')}</span>
                          </Link>
                        ),
                        key: 'apps-apps',
                      },
                      {
                        label: (
                          <Link to={`${url}/apps/bundles`}>
                            <span>{t('bundles')}</span>
                          </Link>
                        ),
                        key: 'apps-bundles',
                      },
                      {
                        label: (
                          <Link to={`${url}/apps/lists`}>
                            <span>{t('lists')}</span>
                          </Link>
                        ),
                        key: 'apps-lists',
                      },
                      {
                        label: (
                          <Link to={`${url}/apps/curations`}>
                            <span>{t('curations')}</span>
                          </Link>
                        ),
                        key: 'apps-curations',
                      },
                      {
                        label: (
                          <Link to={`${url}/actions/importer-excel`}>
                            <span>{t('import')}</span>
                          </Link>
                        ),
                        key: 'apps-import',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('shorts')}</span>
                      </div>
                    ),
                    key: 'shorts',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/shorts/shorts`}>
                            <span>{t('shorts')}</span>
                          </Link>
                        ),
                        key: 'shorts-shorts',
                      },
                      {
                        label: (
                          <Link to={`${url}/shorts/bundles`}>
                            <span>{t('bundles')}</span>
                          </Link>
                        ),
                        key: 'shorts-bundles',
                      },
                      {
                        label: (
                          <Link to={`${url}/shorts/lists`}>
                            <span>{t('lists')}</span>
                          </Link>
                        ),
                        key: 'shorts-lists',
                      },
                      {
                        label: (
                          <Link to={`${url}/shorts/curations`}>
                            <span>{t('curations')}</span>
                          </Link>
                        ),
                        key: 'shorts-curations',
                      },
                      {
                        label: (
                          <Link to={`${url}/shorts/providers`}>
                            <span>{t('providers')}</span>
                          </Link>
                        ),
                        key: 'shorts-providers',
                      },
                      {
                        label: (
                          <Link to={`${url}/actions/importer-excel`}>
                            <span>{t('import')}</span>
                          </Link>
                        ),
                        key: 'shorts-import',
                      },
                      {
                        label: (
                          <Link to={`${url}/shorts/upload`}>
                            <span>{t('upload')}</span>
                          </Link>
                        ),
                        key: 'shorts-upload',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('series')}</span>
                      </div>
                    ),
                    key: 'series',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/series/series`}>
                            <span>{t('series')}</span>
                          </Link>
                        ),
                        key: 'series-series',
                      },
                      {
                        label: (
                          <Link to={`${url}/series/seasons`}>
                            <span>{t('seasons')}</span>
                          </Link>
                        ),
                        key: 'series-seasons',
                      },
                      {
                        label: (
                          <Link to={`${url}/series/bundles`}>
                            <span>{t('bundles')}</span>
                          </Link>
                        ),
                        key: 'series-bundles',
                      },
                      {
                        label: (
                          <Link to={`${url}/series/lists`}>
                            <span>{t('lists')}</span>
                          </Link>
                        ),
                        key: 'series-lists',
                      },
                      {
                        label: (
                          <Link to={`${url}/series/curations`}>
                            <span>{t('curations')}</span>
                          </Link>
                        ),
                        key: 'series-curations',
                      },
                      {
                        label: (
                          <Link to={`${url}/series/providers`}>
                            <span>{t('providers')}</span>
                          </Link>
                        ),
                        key: 'series-providers',
                      },
                      {
                        label: (
                          <Link to={`${url}/series/search`}>
                            <span>{t('search')}</span>
                          </Link>
                        ),
                        key: 'series-search',
                      },
                      {
                        label: (
                          <Link to={`${url}/actions/importer-excel`}>
                            <span>{t('import')}</span>
                          </Link>
                        ),
                        key: 'series-import',
                      },
                      {
                        label: (
                          <Link to={`${url}/series/upload`}>
                            <span>{t('upload')}</span>
                          </Link>
                        ),
                        key: 'series-upload',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('courses')}</span>
                      </div>
                    ),
                    key: 'courses',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/courses/courses`}>
                            <span>{t('courses')}</span>
                          </Link>
                        ),
                        key: 'course-course',
                      },
                      {
                        label: (
                          <Link to={`${url}/courses/bundles`}>
                            <span>{t('bundles')}</span>
                          </Link>
                        ),
                        key: 'course-bundles',
                      },
                      {
                        label: (
                          <Link to={`${url}/courses/lists`}>
                            <span>{t('lists')}</span>
                          </Link>
                        ),
                        key: 'course-lists',
                      },
                      {
                        label: (
                          <Link to={`${url}/courses/curations`}>
                            <span>{t('curations')}</span>
                          </Link>
                        ),
                        key: 'course-curations',
                      },
                      {
                        label: (
                          <Link to={`${url}/courses/providers`}>
                            <span>{t('providers')}</span>
                          </Link>
                        ),
                        key: 'course-providers',
                      },
                      {
                        label: (
                          <Link to={`${url}/actions/importer-excel`}>
                            <span>{t('import')}</span>
                          </Link>
                        ),
                        key: 'course-import',
                      },
                      {
                        label: (
                          <Link to={`${url}/courses/upload`}>
                            <span>{t('upload')}</span>
                          </Link>
                        ),
                        key: 'course-upload',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('albums')}</span>
                      </div>
                    ),
                    key: 'albums',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/albums/albums`}>
                            <span>{t('albums')}</span>
                          </Link>
                        ),
                        key: 'albums-albums',
                      },
                      {
                        label: (
                          <Link to={`${url}/albums/bundles`}>
                            <span>{t('bundles')}</span>
                          </Link>
                        ),
                        key: 'albums-bundles',
                      },
                      {
                        label: (
                          <Link to={`${url}/albums/lists`}>
                            <span>{t('lists')}</span>
                          </Link>
                        ),
                        key: 'albums-lists',
                      },
                      {
                        label: (
                          <Link to={`${url}/albums/curations`}>
                            <span>{t('curations')}</span>
                          </Link>
                        ),
                        key: 'albums-curations',
                      },
                      {
                        label: (
                          <Link to={`${url}/albums/providers`}>
                            <span>{t('providers')}</span>
                          </Link>
                        ),
                        key: 'albums-providers',
                      },
                      {
                        label: (
                          <Link to={`${url}/actions/importer-excel`}>
                            <span>{t('import')}</span>
                          </Link>
                        ),
                        key: 'album-import',
                      },
                      {
                        label: (
                          <Link to={`${url}/albums/upload`}>
                            <span>{t('upload')}</span>
                          </Link>
                        ),
                        key: 'albums-upload',
                      },
                    ],
                  },
                  {
                    label: (
                      <div>
                        <span>{t('podcasts')}</span>
                      </div>
                    ),
                    key: 'podcasts',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/podcasts/podcasts`}>
                            <span>{t('podcasts')}</span>
                          </Link>
                        ),
                        key: 'podcasts-podcasts',
                      },
                      {
                        label: (
                          <Link to={`${url}/podcasts/bundles`}>
                            <span>{t('bundles')}</span>
                          </Link>
                        ),
                        key: 'podcasts-bundles',
                      },
                      {
                        label: (
                          <Link to={`${url}/podcasts/lists`}>
                            <span>{t('lists')}</span>
                          </Link>
                        ),
                        key: 'podcasts-lists',
                      },
                      {
                        label: (
                          <Link to={`${url}/podcasts/curations`}>
                            <span>{t('curations')}</span>
                          </Link>
                        ),
                        key: 'podcasts-curations',
                      },
                      {
                        label: (
                          <Link to={`${url}/podcasts/providers`}>
                            <span>{t('providers')}</span>
                          </Link>
                        ),
                        key: 'podcasts-providers',
                      },
                      {
                        label: (
                          <Link to={`${url}/actions/importer-excel`}>
                            <span>{t('import')}</span>
                          </Link>
                        ),
                        key: 'podcasts-import',
                      },
                      {
                        label: (
                          <Link to={`${url}/podcasts/upload`}>
                            <span>{t('upload')}</span>
                          </Link>
                        ),
                        key: 'podcasts-upload',
                      },
                    ],
                  },
                ],
              },
              {
                icon: <FontAwesomeIcon icon={faCogs} size="lg" style={{ width: '20', height: '20' }} />,
                label: (
                  <div>
                    <span>{t('settings')}</span>
                  </div>
                ),
                key: 'settings',
                children: [
                  {
                    label: (
                      <Link to={`${url}/providers/providers`}>
                        <span>{t('providers')}</span>
                      </Link>
                    ),
                    key: 'providers',
                  },
                  {
                    label: (
                      <Link to={`${url}/tags/tags`}>
                        <span>{t('tags')}</span>
                      </Link>
                    ),
                    key: 'tags',
                  },
                  {
                    label: (
                      <Link to={`${url}/nests/nests`}>
                        <span>{t('nests')}</span>
                      </Link>
                    ),
                    key: 'nests',
                  },
                  {
                    label: isMware ? (
                      <Link to={`${url}/params/params`}>
                        <span>{t('parameters')}</span>
                      </Link>
                    ) : (
                      <></>
                    ),
                    key: 'params',
                  },
                  // {
                  //   label: (
                  //     <Link  to={`${url}/player/player`}>
                  //       <span >{t('player')}</span>
                  //     </Link>
                  //   ),
                  //   key: 'nestssss',
                  // },
                ],
              },
              isMware
                ? {
                    icon: <FontAwesomeIcon icon={faShieldCheck} size="lg" style={{ width: '20', height: '20' }} />,
                    label: (
                      <div>
                        <span>{t('drm-and-tokens')}</span>
                      </div>
                    ),
                    key: 'drm-tokens',
                    children: [
                      {
                        label: (
                          <Link to={`${url}/drm/buydrm`}>
                            <span>BuyDRM</span>
                          </Link>
                        ),
                        key: 'drm-tokens-buydrm',
                      },
                      {
                        label: (
                          <Link to={`${url}/drm/irdeto`}>
                            <span>{t('irdeto')}</span>
                          </Link>
                        ),
                        key: 'drm-tokens-irdeto',
                      },
                      {
                        label: (
                          <Link to={`${url}/drm/token-settings`}>
                            <span>{t('tokens')}</span>
                          </Link>
                        ),
                        key: 'drm-token-settings',
                      },
                    ],
                  }
                : {
                    label: <></>,
                    key: 'empty',
                  },
              {
                icon: <FontAwesomeIcon icon={faCreditCard} size="lg" style={{ width: '20', height: '20' }} />,
                label: (
                  <div>
                    <span>{t('pay-per-view')}</span>
                  </div>
                ),
                key: 'ppv-settings',
                children: [
                  {
                    label: (
                      <Link to={`${url}/ppv/ppvrules`}>
                        <span>{t('rules')}</span>
                      </Link>
                    ),
                    key: 'ppv',
                  },
                ],
              },
              {
                icon: <FontAwesomeIcon icon={faFileArrowUp} size="lg" style={{ width: '20', height: '20' }} />,
                label: (
                  <div>
                    <span>{t('importer')}</span>
                  </div>
                ),
                key: 'import',
                children: [
                  {
                    label: (
                      <Link to={`${url}/actions/importer-excel`}>
                        <span>{t('importer')}</span>
                      </Link>
                    ),
                    key: 'content-import',
                  },
                ],
              },
            ]}
          />
        )}
      </Layout.Sider>
      <Layout>
        <div style={{ paddingLeft: 0, width: '100%' }}>
          <div>
            <IMSDashboardHeader
              {...{
                isSideBarShow: true,
                path,
              }}
            />
          </div>
          {/* {isLoading && <Spin />} */}
          {isError && <span>{t('something-went-wrong')}</span>}
          {!isLoading && !isError && data && (
            <ContentContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
              <Switch>
                <Route path={`${path}/podcasts`}>
                  <Podcasts />
                </Route>
                <Route path={`${path}/albums`}>
                  <Albums />
                </Route>
                <Route path={`${path}/series`}>
                  <Series />
                </Route>
                <Route path={`${path}/movies`}>
                  <Movies />
                </Route>
                <Route path={`${path}/shorts`}>
                  <Shorts />
                </Route>
                <Route path={`${path}/games`}>
                  <Games />
                </Route>
                <Route path={`${path}/apps`}>
                  <Apps />
                </Route>
                <Route path={`${path}/cams`}>
                  <Cams />
                </Route>
                <Route path={`${path}/courses`}>
                  <Courses />
                </Route>
                <Route path={`${path}/epg`}>
                  <Epg />
                </Route>
                <Route path={`${path}/radios`}>
                  <Radios />
                </Route>
                <Route path={`${path}/channels`}>
                  <Channels />
                </Route>
                {isMware && (
                  <Route path={`${path}/drm`}>
                    <DRM />
                  </Route>
                )}
                <Route path={`${path}/ppv`}>
                  <PPV />
                </Route>
                <Route path={`${path}/actions`}>
                  <Actions />
                </Route>
                <Route path={`${path}/transcoders`}>
                  <Transcoders />
                </Route>
                <Route path={`${path}/providers`}>
                  <Providers />
                </Route>
                <Route path={`${path}/tags`}>
                  <Tags />
                </Route>
                <Route path={`${path}/nests`}>
                  <Nests />
                </Route>
                <Route path={`${path}/params`}>
                  <Params />
                </Route>
                <Route path={`${path}/player`}>
                  <Player />
                </Route>
                <Route path={`${path}`}>{isClient && <Dashboard />}</Route>
              </Switch>
            </ContentContextProvider>
          )}
        </div>
      </Layout>
    </Layout>
  );
};

export default Content;

