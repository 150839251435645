import { useContext, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Table from '../../../../../common/components/Content/Table';
import { ContentContext } from '../../../../../contexts/contentContext';
import { IMSNameParams } from '../../../../dashboard';
import cmsPodcasts from '../../../../../services/areas/content/podcasts/podcasts';
import * as HttpStatusCodes  from 'http-status-codes';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import { MW_BASE_DB_LANGUAGE } from '@mwaretv/database/build/backend/models/base/language';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { NotVisibleForRole } from '../../../../../common/components/VisibleForRole';
import { TVMSRoles } from '../../../../../constants/TVMSRoles';

const Podcasts = () => {
  const { url } = useRouteMatch();
  const { clientName, deployment: service_name } = useParams<IMSNameParams & { deployment: string }>();
  const [exporting, setExporting] = useState(false);
  const { deployment } = useContext(ContentContext);
  const languagesDeployment = (deployment?.languages as unknown as MW_BASE_DB_LANGUAGE[]) || [];
  const redirectUrl = url.split('/').slice(0, -1).join('/');
  const { t } = useTranslation();

  const exportContent = async () => {
    setExporting(true);
    let values = {
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsPodcasts.Export_Podcasts(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      var podcasts = response.data.data as any;
      var translations = [] as any;
      var episodes = [] as any;
      var languages = [] as any;
      var currencies = [] as any;
      var tags = [] as any;

      languagesDeployment.forEach((langauge: any) => {
        languages.push({
          language: langauge.name,
        });
      });

      podcasts.forEach((podcast: any, index: any) => {
        var podcastTranslations = podcast.translations;
        podcastTranslations.forEach((podcastTranslation: any) => {
          translations.push({
            podcast: podcastTranslation.name,
            podcast_name: podcast.name,
            description: podcastTranslation.description,
          });
        });
        delete podcast.translations;

        const tagsTranslations = podcast.tags;
        if (tagsTranslations != undefined && tagsTranslations[0] != undefined && tagsTranslations[0].tag != undefined) {
          tagsTranslations.forEach((trans: any) => {
            trans.tag.forEach((tag: any) => {
              tags.push({
                _id: podcast.name,
                name: tag.name,
                description: tag.description,
              });
            });
          });
        }
        delete podcast.tags;

        if (podcast.payperview_currencies) {
          podcast.payperview_currencies.forEach((currency: any) => {
            currencies.push({
              podcast_id: podcast.name,
              ...currency,
            });
          });
        }
        delete podcast.payperview_currencies;

        var podcastEpisodes = podcast.episodes;
        podcastEpisodes.forEach((podcastEpisode: any) => {
          episodes.push({
            podcast: podcast.name,
            name: podcastEpisode.name,
            producer: podcastEpisode.producer,
            writer: podcastEpisode.writer,
            duration: podcastEpisode.duration,
            stream: podcastEpisode.stream,
          });
        });
        delete podcasts[index].episodes;
        delete podcasts[index].translations;
      });

      const workbook = XLSX.utils.book_new();
      const worksheet1 = XLSX.utils.json_to_sheet(podcasts);
      const worksheet2 = XLSX.utils.json_to_sheet(episodes);
      const worksheet3 = XLSX.utils.json_to_sheet(currencies);
      const worksheet4 = XLSX.utils.json_to_sheet(languages);

      languages.forEach((language: any, index: any) => {
        var podcasts = translations.filter((e: any) => e.name == language.language);
        var data = [] as any;
        podcasts.forEach((podcast: any, index) => {
          data.push({
            podcast: podcast.podcast_name,
            podcast_translation: podcast.description,
            tag: tags[index],
          });
        });
        languages[index].data = data;
      });

      XLSX.utils.book_append_sheet(workbook, worksheet1, t('podcasts'));
      XLSX.utils.book_append_sheet(workbook, worksheet2, t('pods'));
      XLSX.utils.book_append_sheet(workbook, worksheet3, t('currencies'));
      XLSX.utils.book_append_sheet(workbook, worksheet4, t('languages'));

      languages.forEach((language: any) => {
        const tagsPerLanguage = tags.filter((l: any) => l.name === language.language);
        if (tagsPerLanguage !== undefined) {
          const result2 = tagsPerLanguage.map((obj: any) => ({ _id: obj['_id'], tags: obj['description'] }));
          XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(result2), 'tags_' + language.language);
        }
      });
      XLSX.writeFile(workbook, 'Podcasts_' + clientName + '_' + deployment?.name + '_' + moment().format('YYYY-MM-DD') + '.xlsx');
    }
    setExporting(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <h2 className="page-title">{t('podcasts')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('all-your-podcasts-in-the-system-you-can-add-as-many-as-you-want')}</p>
        </div>
        <div className="col-md-6"></div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-4 mb-md-2 d-flex justify-content-center justify-content-md-start align-items-start"></div>
        <div className="col-md-5 d-flex justify-content-center justify-content-md-end align-items-start">
        <NotVisibleForRole roles={[TVMSRoles.Provider]}>
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${redirectUrl}/actions/importer-excel`}>
            {t('import-podcasts')}
          </Link>
          </NotVisibleForRole>
          <NotVisibleForRole roles={[TVMSRoles.Provider]}>
          <Button disabled={exporting} loading={exporting} className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" onClick={exportContent}>
            {t('export-podcasts')}
          </Button>
          </NotVisibleForRole>
          <Link className="btn btn-secondary rounded-pill btn-default float-right width-240 height-48" to={`${url}/add`}>
            {t('add-podcast')}
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mt-4">
          <Table service_name={service_name} clientName={clientName} type={'podcasts'} variant={'normal'}></Table>
        </div>
      </div>
    </div>
  );
};

export default Podcasts;
