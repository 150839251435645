import { allPlaceholders } from '@mwaretv/communication/dist/templates/index';
import { Input, Item, TextArea } from '../../../../../../common/components/form';
import { useTranslation } from 'react-i18next';

const SMSTemplateForm = () => {
  const {t} = useTranslation();
  return (
    <>
      <Item label={t('name')} name={['name']} rules={[{ required: true, message: t('name-is-required') }]}>
        <Input />
      </Item>
      <Item
        label={t('content')}
        name={['content']}
        rules={[{ required: true, message: t('content-is-required') }]}
        extra={
          <>
            <p>{t('you-can-use-the-following-placeholders-to-use-data-in-the-template')}</p>
            <br />
            {allPlaceholders.map((p) => (
              <p key={p.placeholder}>
                <b>&#123;&#123; {p.placeholder} &#125;&#125;</b> - {p.description}
              </p>
            ))}
          </>
        }
      >
        <TextArea showCount maxLength={160} autoSize={true} size="large" />
      </Item>
    </>
  );
};

export default SMSTemplateForm;
