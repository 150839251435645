import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../dashboard';
import { Button, Row, Tabs } from 'antd';
import { ContainerWrapper } from '../../../../common/components/containerWrapper';
import { PageTitle } from '../../../../common/components/pageTitle';
import { useTranslation } from 'react-i18next';
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { BillingContext } from '../../../../contexts/billingContext';
import * as XLSX from 'xlsx';
import SUBSCRIPTIONS_REPORT_SERVICE from '../../../../services/areas/reporting/subscriptionsReportService/subscriptionsReportService';
import { SubscriptionsReportInput } from "@mwaretv/database/build/backend/models/reporting/subscriptionsReport/subscriptionsReportInput"; 
import dayjs from 'dayjs';
import { SubscriptionsReportTable } from './SubscriptionsReportTable';
import moment from 'moment';
dayjs.extend(localizedFormat);

export const SubscriptionsReport = () => {
  const { clientName } = useParams<IMSNameParams>();
  const { deployment, service } = useContext(BillingContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [subscriptionsReportInput, setSubscriptionsReportInput] = useState<SubscriptionsReportInput | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {

        const subscriptionsReportInput = await SUBSCRIPTIONS_REPORT_SERVICE.Get_SubscriptionsReportInput(clientName, deployment?.name ?? "", service?.name ?? "");

        setSubscriptionsReportInput(subscriptionsReportInput);
      } catch (err) {
        console.log(err);
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const exportSubscriptionsReport = async () => {
    const workbook = XLSX.utils.book_new();

    const worksheetThisMonth = XLSX.utils.json_to_sheet(subscriptionsReportInput?.thisMonth ?? []);
    XLSX.utils.sheet_add_aoa(
      worksheetThisMonth,
      [[t('subscription-name'), t('chart-design-count-operator'), t('amount-including-vat'), t('amount-vat'), t('amount-excluding-vat')]],
      { origin: 0 }
    );
    XLSX.utils.book_append_sheet(workbook, worksheetThisMonth, dayjs().format('MMM'));

    const worksheetPreviousMonth = XLSX.utils.json_to_sheet(subscriptionsReportInput?.previousMonth ?? []);
    XLSX.utils.sheet_add_aoa(
      worksheetPreviousMonth,
      [[t('subscription-name'), t('chart-design-count-operator'), t('amount-including-vat'), t('amount-vat'), t('amount-excluding-vat')]],
      { origin: 0 }
    );
    XLSX.utils.book_append_sheet(workbook, worksheetPreviousMonth, dayjs().subtract(1, "month").format('MMM'));

    XLSX.writeFile(workbook, 'Subscriptions_report_' + dayjs().format('YYYY-MM-DD') + '.xlsx');
  };

  return (
    <ContainerWrapper>
      <div className="row">
        <div className="col-md-6 mb-2">
          <PageTitle title={t('subscriptions-report')} subtitle={t('subscriptions-report-subtitle')} />
        </div>
        <div className="col-md-4" />
        <div className="col-md-2 d-flex justify-content-center justify-content-md-end align-items-start">
          <Button disabled={isLoading} loading={isLoading} className="btn btn-secondary rounded-pill btn-default width-240 mt-2 mt-md-0 height-48" onClick={exportSubscriptionsReport}>
            {t('export')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          {!isLoading && !isError && subscriptionsReportInput && (
            <Tabs
              className="default-tab"
              items={[
                {
                  key: 'this-month',
                  label: moment().format('MMMM'),
                  children: (
                    <SubscriptionsReportTable subscriptionsReportData={subscriptionsReportInput?.thisMonth ?? []} />
                  ),
                },
                {
                  key: 'previous-month',
                  label: moment().add(-1, 'month').format('MMMM'),
                  children: (
                    <SubscriptionsReportTable subscriptionsReportData={subscriptionsReportInput?.previousMonth ?? []} />
                  ),
                },
              ]}
            />
          )}
        </div>
      </div>
    </ContainerWrapper>
  );
};
