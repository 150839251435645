import { Button, DatePicker, Form, Input, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useEffectOnce } from 'react-use';
import { CustomNotification } from '../../../../common/components/Notification';
import drmService from '../../../../services/areas/content/drm/drm';
import { IMSNameParams } from '../../../dashboard';
import dayjs from 'dayjs';
import FileUploader from '../../../../common/components/FileUploader/FileUploader';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import { ContentContext } from '../../../../contexts/contentContext';

const TokenSettings = () => {
  const [form] = useForm();
const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState([] as any);
  const [file, setFile] = useState([] as any);
  const [fileName, setFileName] = useState([] as any);
  const [selectedDate, setSelectedDate] = useState('' as any);
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);

  const getDrmDetails = async () => {
    setIsLoading(true);
    let response = await drmService.List_DRM_Settings({
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
      pageNumber: 0,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      setData(response.data.data[0]);
      if (response.data.data[0] != undefined) {
        setFile(response.data.data[0].buydrm?.key_package);
        setFileName(response.data.data[0].buydrm?.key_file ?? '');
        setSelectedDate(dayjs(response.data.data[0].buydrm?.expiration));
      }
      form.setFieldsValue(response.data.data[0]);
    }
    setIsLoading(false);
    setIsSubmitting(false);
  };
  const saveDrmDetails = async (values: any) => {
    setIsSubmitting(true);
    if (data != undefined) {
      
      let response = await drmService.Update_DRM_Setting({
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
          path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.DRM.KEYS,
        },
        _id: data._id,
        buydrm: {
          ...values.buydrm,
          expiration: selectedDate,
          key_package: file.uploaded != undefined ? file.uploaded.url : file ?? '',
          key_file: file.fileName != undefined ? file.fileName : fileName ?? '',
        },
      });
      if (response && response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: t('success'),
        });
      } else CustomNotification({ error: response.errors });
    } else {
      let response = await drmService.Add_DRM_Setting({
        query_params: {
          instance: clientName,
          cms: deployment?.name ?? '',
        },
        buydrm: {
          ...values.buydrm,
          expiration: selectedDate,
          key_package: file.uploaded != undefined ? file.uploaded.url : file ?? '',
          key_file: file.fileName != undefined ? file.fileName : fileName ?? '',
        },
      });
      if (response && response.status_code === HttpStatusCodes.OK) {
        notification.success({
          message: t('success'),
        });
      } else CustomNotification({ error: response.errors });
    }
    await getDrmDetails();
  };

  useEffectOnce(() => {
    (async () => {
      await getDrmDetails();
    })();
  });

  // useEffect(() => {
  //   (() => form.setFieldsValue(data))();
  // }, [data]);

  return (
    <>
      <div className="container-fluid wrapper">
        <div className="row">
          <div className="col-md-6">
            <h2 className="page-title">{t('buydrm-settings')}</h2>
          </div>
        </div>
        <Form
          form={form}
          onFinish={(values) => {
            saveDrmDetails(values);
          }}
          onFinishFailed={(errorInfo) => {
            
          }}
        >
          <div className="card mt-5">
            <div className="card-body card-ch-padding mt-5">
              <div className="row">
                <label className="col-md-2 col-form-label">{t('license-key')}</label>
                <Form.Item className="col-md-5" name={['buydrm', 'license_key']}>
                  <Input className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('your-license-key-obtained-from-buydrm')} />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('expiration-date')}</label>
                <div className="col-md-5">
                  <DatePicker value={selectedDate} defaultValue={selectedDate} onChange={(value) => setSelectedDate(value)} />
                </div>
              </div>

              <div className="row">
                <label className="col-md-2 col-form-label">{t('key-package')}</label>
                <Form.Item className="col-md-5" name={['buydrm', 'key_package']}>
                  <Input disabled={true} className="form-control height-48 bg-white font-size-14 text-default" placeholder={t('upload-your-pem-file-here')} />
                </Form.Item>
              </div>

              <div className="row mb-5">
                <label className="col-md-2 col-form-label"></label>
                <div className="col-md-5">
                  <FileUploader
                    onSubmit={(values: any) => {
                      setFile(values);
                    }}
                    initialData={{
                      notMandatory: file != undefined && file != '' ? true : false,
                      path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.DRM.PEMS,
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('widevine-license-url')}</label>
                <Form.Item
                  className="col-md-5"
                  name={['buydrm', 'license_url_widevine']}
                  rules={[
                    {
                      required: true,
                      message: t('url-is-required'),
                    },
                  ]}
                >
                  <Input type={'url'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="https://licenseurlwidevine.com" />
                </Form.Item>
              </div>
              <div className="row">
                <label className="col-md-2 col-form-label">{t('fairplay-license-url')}</label>
                <Form.Item
                  className="col-md-5"
                  name={['buydrm', 'license_url_fairplay']}
                  rules={[
                    {
                      required: true,
                      message: t('url-is-required'),
                    },
                  ]}
                >
                  <Input type={'url'} className="form-control height-48 bg-white font-size-14 text-default" placeholder="https://licenseurlfairplay.com" />
                </Form.Item>
              </div>
              <div className="text-right mb-4">
                <Button htmlType="submit" loading={isSubmitting} disabled={isLoading || isSubmitting} className="col-md-3 btn btn-secondary rounded-pill width-200 height-48 ml-3">
                  {t('publish-keys')}
                </Button>
                <Button htmlType="submit" loading={isSubmitting} disabled={isLoading || isSubmitting} className="col-md-3 btn btn-secondary rounded-pill width-200 height-48 ml-3">
                  {t('save-settings')}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default TokenSettings;
