import { Form, Input, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { STORAGE_ENDPOINTS } from '../../../../services/storage_paths';
import VideoUploader from '../../VIdeoUploader/VideoUploader';
import { useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AppBuilderContext } from '../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../pages/dashboard';

const Streams = () => {
  const { t } = useTranslation();
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(AppBuilderContext);
  const [file, setFile] = useState([] as any);

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('streams')}</h4>
      <div className="row">
        <div className="col-md-12 card-bg-light p-5">
          <div className="row">
            <label className="col-md-2 col-form-label">{t('url')}</label>
            <Form.Item
              className="col-md-10"
              name={['streams', 'movies', 'url']}
              extra={t('we-recommend-to-use-mp4-or-dash')}
              rules={[
                {
                  required: true,
                  message: t('url-is-required'),
                },
              ]}
            >
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>

          <div className="row">
            <label className="col-md-2 col-form-label">{t('thumbnail-vtt')}</label>
            <Form.Item extra={t('add-netflix-style-thumbnail-preview-search-to-your-content')} className="col-md-10" name={['streams', 'movies', 'thumbnails']}>
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-2 col-form-label">{t('subtitle-vtt')}</label>
            <Form.Item extra={t('add-optional-external-subtitle-tracks')} className="col-md-10" name={['streams', 'movies', 'subtitles']}>
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-2 col-form-label">{t('trailer-url')}</label>
            <Form.Item className="col-md-10" name={['streams', 'movies', 'trailer']} extra={t('we-recommend-to-use-mp4-or-dash')}>
              <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="https://stream.com" />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-2 col-form-label">{t('allow-download')}</label>
            <Form.Item extra={t('give-customers-the-option-to-download-the-content-on-their-mobile-phones-and-play-back-offline')} className="col-md-5" name={['streams', 'movies', 'allow_downloads']} valuePropName="checked">
              <Switch className="ch-switch mt-3" />
            </Form.Item>
          </div>
          <div className="row">
            <label className="col-md-2 col-form-label">{t('expiration-download')}</label>
            <Form.Item extra={t('specify-the-duration-in-days-during-which-downloaded-content-items-will-be-automatically-deleted-from-the-customers-device')} className="col-md-5" name={['streams', 'movies', 'expiration_downloads']}>
              <Input type={'number'} min={1} className="form-control bg-white font-size-14 text-default" placeholder="1" />
            </Form.Item>
          </div>
          {/* <div className="row">
            <h4 className="text-primary mt-5 mb-3">Movie Uploader</h4>

            <div className="col-md-12">
              <div className="row">
                <VideoUploader
                  onSubmit={(values: any) => {
                    setFile(values);
                  }}
                  initialData={{
                    notMandatory: file != undefined && file != '' ? true : false,
                    path: STORAGE_ENDPOINTS(clientName, deployment?.name, '').CONTENT.MOVIES.STREAMS,
                  }}
                ></VideoUploader>
              </div>
            </div>
          </div> */}
        </div>

        <div className="col">
          <h4 className="text-primary mt-5 mb-3">Deeplink</h4>
          <p>Deeplinks are hyperlinks that point directly to specific content within an app.</p>
          <div className="col-md-12 card-bg-light p-5">
            <div className="row">
              <label className="col-md-3 col-form-label">Deeplink</label>
              <Form.Item className="col-md-10" name={['streams', 'movies', 'deeplink']}>
                <Input type={'url'} className="form-control bg-white font-size-14 text-default" placeholder="dplnk://stream.com" />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Streams;
