import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

type IUpdatePackageModalProps = {
  onSubmit: (values: any, newCat) => void;
  onCancel: () => void;
  initialData: any;
};

const CategoryModal = (props: IUpdatePackageModalProps) => {
  const { onSubmit, onCancel, initialData } = props;
  const [externalType, setExternalType] = useState('BrightcoveCmsApi');
  const { t } = useTranslation();
  let [form] = useForm();

  useEffect(() => {
    setExternalType(initialData?.external?.cms_type);
    form.setFieldsValue(initialData.selectedCategory);
  }, [initialData]);

  const onSubmitLocal = (values) => {
    var id = initialData._id;
    onSubmit(
      {
        ...initialData.selectedCategory,
        ...values,
        id,
      },
      false
    );
  };

  return (
    <div className="p-5" style={{ border: '1px solid #e2e2e2', borderTop: 0 }}>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <Form initialValues={initialData} form={form} onFinish={(values) => onSubmitLocal(values)} onFinishFailed={(errorInfo) => {}}>
            {externalType == 'BrightcoveCmsApi' && (
              <div className="card-bg-light pl-5 pt-3 mb-4 p-5">
                <h4 className="text-primary mb-2" style={{ marginTop: 20 }}>
                  {t('brightcove-settings')}
                </h4>
                <label className="col-form-label">{t('playlist-id')}</label>
                <Form.Item
                  name={['external', 'brightcove', 'playlist_id']}
                  className="form-group mb-0"
                  extra={t('external-brightcove-playlist-id-extra')}
                  rules={[
                    {
                      required: true,
                      message: t('playlist-id-required'),
                    },
                  ]}
                >
                  <Input className="form-control bg-white font-size-14 text-default p-3rem" placeholder="J#JDjf23jd" />
                </Form.Item>
      
              </div>
              
            )}
            <div className="col-md-12 p-0">
            {initialData.newCat && (
              <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-5 width-180 height-48">
                {t('add-cms')}
              </Button>
            )}
            {!initialData.newCat && (
              <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-5 width-180 height-48">
                {t('update-cms')}
              </Button>
            )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
