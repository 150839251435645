import { Shorts_Curations } from '../../../../../interfaces/forms/areas/content/shorts/curations';
import { IGetForm } from '../../../../../interfaces/forms/common/ICMSExtraQuerytParams';
import { ICMS_Lists } from '../../../../../interfaces/forms/common/ICMS_Lists';
import { Shorts_Curations as Response } from '../../../../../interfaces/responses/areas/content/shorts/curations';

import { IBaseResponse } from '../../../../../interfaces/responses/IBaseResponse';
import axiosInstance from '../../../../../utils/axios';
import { API_ENDPOINTS } from '../../../../_APIConstants';

class Curation_Service {
  Add_Curation = async (data: Shorts_Curations): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SHORTS.CURATION.ADD, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Curation_List = async (data: ICMS_Lists): Promise<Response> => {
    return await axiosInstance
      .post(API_ENDPOINTS.V1.CMS.SHORTS.CURATION.LIST, data, {
        params: data.query_params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Details_Curation = async (data: IGetForm): Promise<Response> => {
    return await axiosInstance
      .get(`${API_ENDPOINTS.V1.CMS.SHORTS.CURATION.DETAILS}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Update_Curation = async (data: Shorts_Curations): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.SHORTS.CURATION.UPDATE}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };

  Delete_Curation = async (data: IGetForm): Promise<IBaseResponse> => {
    return await axiosInstance
      .delete(`${API_ENDPOINTS.V1.CMS.SHORTS.CURATION.DELETE}/${data._id}`, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
        },
        data,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
  Publish_Curation = async (data: Shorts_Curations): Promise<IBaseResponse> => {
    return await axiosInstance
      .put(`${API_ENDPOINTS.V1.CMS.SHORTS.CURATION.PUBLISH}/${data._id}`, data, {
        params: {
          instance: data.query_params.instance,
          cms: data.query_params.cms,
          path: data.query_params.path,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  };
}

export default new Curation_Service();
