import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router'
import Cams from './cams'
import Bundles from './bundles'
import CamsAdd from './cams/add'
import CamsDetails from './cams/details'
import BundleContent from './bundles/content'
import Lists from './lists'
import Settings from './settings'
import ListContent from './lists/content'
import Providers from './providers';
import Curations from './curations';
import CurationContent from './curations/content';

const Index = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/add`}><CamsAdd /></Route>
      <Route path={`${path}/details/:_id`}><CamsDetails /></Route>
      <Route path={`${path}/bundles/content/:package/:_id/:category/:index`}><BundleContent /></Route>
      <Route path={`${path}/bundles`}><Bundles /></Route>
      <Route path={`${path}/curations/content/:package/:_id/:index`}><CurationContent /></Route>
      <Route path={`${path}/curations`}><Curations /></Route>
      <Route path={`${path}/lists/content/:package/:_id/:index`}><ListContent /></Route>
      <Route path={`${path}/lists`}><Lists /></Route>
      <Route path={`${path}/settings`}><Settings /></Route>
      <Route path={`${path}/providers`}><Providers /></Route>
      <Route path={`${path}/`}><Cams /></Route>
    </Switch>
  )
}

export default Index
