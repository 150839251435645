import { Tabs } from 'antd';
import { useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useParams, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Player from '../../../../../common/components/AppBuilder/Players/Players';
import { faAlbum, faBlockBrick, faBrush, faCamcorder, faCameraSecurity, faCog, faDotCircle, faFillDrip, faFilm, faHistory, faListTree, faPodcast, faRadio, faSchool, faShieldKeyhole, faTableColumns, faTv, faVideo } from '@fortawesome/pro-regular-svg-icons';
import { Spin } from '../../../../../common/components/spinner';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import appService from '../../../../../services/areas/appbuilder/applications/application';
import { AppBuilderContext } from '../../../../../contexts/appbuilderContext';
import { IMSNameParams } from '../../../../dashboard';
import * as HttpStatusCodes  from 'http-status-codes';
import MenuBuilder from './helpers/menu';
import { useTranslation } from 'react-i18next';

const UserInterfaceAppDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [menus, setMenus] = useState([] as any);
  const { id } = useParams<any>();
  const [activeKey, setActiveKey] = useState('Channel Player');
  const { TabPane } = Tabs;
  const { url } = useRouteMatch();
  const redirectUrl = url.split('/').slice(0, -2).join('/');
  const [application, setApplication] = useState([] as any);
  const { clientName } = useParams<IMSNameParams>();
  const { service, deployment } = useContext(AppBuilderContext);
  const { t } = useTranslation();

  const getMenuItems = (resp) => {
    
    setIsLoading(true);
    var menus_ = [] as any;
    if (resp.menus != undefined) {
      switch (resp.menus[0].name) {
        case 'Channels':
          setActiveKey('Channel Player');
          break;
        case 'Radio':
          setActiveKey('Radio Player');
          break;
        case 'Movies':
          setActiveKey('Movie Player');
          break;
        case 'Series':
          setActiveKey('Series Player');
          break;
        case 'Podcasts':
          setActiveKey('Podcast Player');
          break;
        case 'Music':
          setActiveKey('Music Player');
          break;
        case 'Shorts':
          setActiveKey('Short Player');
          break;
        case 'Courses':
          setActiveKey('Course Player');
          break;
        case 'Cams':
          setActiveKey('Cam Player');
          break;
      }
    }
    resp.menus.forEach((menu) => {
      if (menu.name == 'Channels') {
        menus_.push({ name: 'Channel Player' });
        menus_.push({ name: 'CatchupTV Player' });
        menus_.push({ name: 'Recording Player' });
      }
      if (menu.name == 'Movies') {
        menus_.push({ name: 'Movie Player' });
      }
      if (menu.name == 'Series') {
        menus_.push({ name: 'Series Player' });
      }
      if (menu.name == 'Shorts') {
        menus_.push({ name: 'Shorts Player' });
      }
      if (menu.name == 'Radio') {
        menus_.push({ name: 'Radio Player' });
      }
      if (menu.name == 'Cams') {
        menus_.push({ name: 'Cam Player' });
      }
      if (menu.name == 'Courses') {
        menus_.push({ name: 'Courses Player' });
      }
      if (menu.name == 'Music') {
        menus_.push({ name: 'Music Player' });
      }
      if (menu.name == 'Podcasts') {
        menus_.push({ name: 'Podcast Player' });
      }
    });
    setMenus(menus_);
    setIsLoading(false);
  };

  const handleFormFill = async () => {
    let response = await appService.Get_Application({
      query_params: {
        cms: deployment?.name ?? '',
        instance: clientName,
      },
      _id: id,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      var resp = response.data as any;
      setApplication(resp);
      getMenuItems(resp);
    }
  };
  useEffectOnce(() => {
    (async () => await handleFormFill())();
  });

  const getIcon = (name: any) => {
    switch (name) {
      case 'Channel Player':
        return faTv;
      case 'Radio Player':
        return faRadio;
      case 'CatchupTV Player':
        return faHistory;
      case 'Recording Player':
        return faDotCircle;
      case 'Cam Player':
        return faCameraSecurity;
      case 'Movie Player':
        return faFilm;
      case 'Series Player':
        return faVideo;
      case 'Courses Player':
        return faSchool;
      case 'Music Player':
        return faAlbum;
      case 'Podcast Player':
        return faPodcast;
      case 'Shorts Player':
        return faCamcorder;
    }
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row-md-12">
        <div className="row-md-12">
          <div className="col-md-12 mb-2">
            <h2 className="page-title mt-4">{t('app-players')}</h2>
          </div>
          <MenuBuilder application={application} redirectUrl={redirectUrl} id={id}></MenuBuilder>
        </div>
        <div className="col-md-12 mb-2" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div className="card">
            <div className="row">
              <div className="col-12  mt-3">
                {isLoading && (
                  <div>
                    <div className="text-center  h-100 w-100">
                      <div className="col-md-12 align-self-center">
                        <Spin size="large" spinning={true} />
                      </div>
                    </div>
                  </div>
                )}
                {!isLoading && (
                  <Tabs className="highlight-tab" onChange={(activeKey) => setActiveKey(activeKey)} defaultActiveKey={'Channel Player'}>
                    {menus.map((menu: any) => {
                      return (
                        <TabPane
                          tab={
                            <div className="col text-center" style={{}}>
                              <div style={{}}>
                                {' '}
                                <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={getIcon(menu.name) as any} />
                              </div>
                              <div style={{ width: 100, fontSize: 12, color: '#999' }}>{menu.name}</div>
                            </div>
                          }
                          key={menu.name}
                        >
                          {activeKey === menu.name && (
                            <>
                              <Player type={menu.name}></Player>
                            </>
                          )}
                        </TabPane>
                      );
                    })}
                  </Tabs>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserInterfaceAppDetails;
