import { Form, Tabs, notification } from 'antd';
import * as HttpStatusCodes  from 'http-status-codes';
import { useParams } from 'react-router-dom';
import { IMSNameParams } from '../../../../dashboard';
import clientService from '../../../../../services/client';
import { Spin } from '../../../../../common/components/spinner';
import { useTranslation } from 'react-i18next';
import { CardForm } from '../../../../../common/components/form';
import CompanyFields from '../components/tabs/company';
import { useState } from 'react';
import { CustomNotification } from '../../../../../common/components/Notification';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import CommunicationFields from '../components/tabs/communication';
import ServicesFields from '../components/tabs/services';
import SettingsFields from '../components/tabs/settings';
import ScopeFields from '../components/tabs/scope';
import wefact from '../../../../../services/client/wefact';
import { Debtor } from '../../../../../interfaces/responses/clients/debtor';
import { WeFactProduct } from '../../../../../interfaces/responses/clients/weFactProduct';
import { Product } from '../../../../../interfaces/responses/clients/client';
import Bugsnag from '@bugsnag/js';

const ClientDetails = () => {
  const params = useParams<IMSNameParams>();
  const [isLoading, setIsLoading] = useState(true);
  const [clientData, setClientData] = useState<any | undefined>(undefined);
  const [weFactProducts, setWeFactProducts] = useState<WeFactProduct[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [debtorData, setDebtorData] = useState<Debtor | undefined>(undefined);
  const { t } = useTranslation();
  const [clientForm] = Form.useForm();

  useEffectOnce(() => {
    const getClientDetails = async () => {
      setIsLoading(true);

      const clientResponse = await clientService.detailsClient({
        _id: params._id,
      });

      if (clientResponse?.status_code !== HttpStatusCodes.OK) {
        setIsLoading(false);
        return;
      } 

      const resp = clientResponse.data as any;
      setClientData(resp);
      setSelectedProducts(clientResponse.data.weFactProducts ?? []);

      if (!clientResponse.data.weFact_debtorCode) {
        try {
          Bugsnag.notify(new Error(`WeFact debtor code not set for ${clientResponse?.data?.name ?? "unknown"}`), (event) => {
            event.severity = "info";
          });
        }
        catch (error) {
          console.log(error);
        }
        setIsLoading(false);
        return;
      }

      const debtorResponse = await wefact.getWeFactDebtorByDebtorCode({
        debtorCode: clientResponse.data.weFact_debtorCode
      });

      if (debtorResponse?.status_code !== HttpStatusCodes.OK || (debtorResponse.data?.length ?? 0) === 0) {
        setIsLoading(false);
        return;
      }

      setDebtorData(debtorResponse.data[0]);
      setIsLoading(false);
    };
    getClientDetails().catch(error => { 
      console.log(error);
    });
  });

  useEffectOnce(() => {
    const listWeFactProducts = async () => {
      setIsLoading(true);
      const response = await wefact.listWeFactProducts();

      if (response?.status_code !== HttpStatusCodes.OK) {
        setIsLoading(false);
        return;
      } 

      const resp = response.data as any;
      setWeFactProducts(resp);
      setIsLoading(false);
    };
    listWeFactProducts().catch(error => { 
      console.log(error);
    });
  });

  const onFinish = async (values: any) => {
    setIsLoading(true);
    var values_ = {
      ...values,
      weFactProducts: selectedProducts,
      _id: params._id,
    };
    let response = await clientService.updateClient(values_);
    if (response.status_code === HttpStatusCodes.OK)
      notification.success({
        message: t('success'),
        description: t('client-updated-successfully'),
      });
    else
      CustomNotification({
        error: response.errors,
      });
    setIsLoading(false);
  };

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-8">
          <h2 className="page-title">{t('client-details')}</h2>
          <p className="h6 font-weight-normal line-height-26"></p>
        </div>
      </div>
      <div className="row">
        {isLoading && (
          <div className="row text-center h-100 w-100">
            <div className="col-12 align-self-center">
              <Spin size="large" spinning={true} />
            </div>
          </div>
        )}
        {!isLoading && clientData && (
          <div className="col-12  mt-3">
            <Tabs
              className="default-tab"
              items={[
                {
                  key: 'company',
                  label: t('company'),
                  children: (
                    <CardForm 
                      form={clientForm} 
                      initialValues={{
                        ...clientData,
                      }}
                      disabled={isLoading} 
                      onFinish={onFinish}
                    >
                      <CompanyFields isInAddClientMode={false} />
                    </CardForm>
                  ),
                },
                {
                  key: 'communication',
                  label: t('communication'),
                  children: (
                    <CardForm 
                      form={clientForm} 
                      initialValues={{
                        ...clientData,
                      }}
                      disabled={isLoading} 
                      onFinish={onFinish}
                    >
                      <CommunicationFields debtorData={debtorData} />
                    </CardForm>
                  ),
                },
                {
                  key: 'services',
                  label: t('services'),
                  children: (
                    <CardForm 
                      form={clientForm} 
                      initialValues={{
                        ...clientData,
                      }}
                      disabled={isLoading} 
                      onFinish={onFinish}
                    >
                      <ServicesFields weFactProducts={weFactProducts} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} />
                    </CardForm>
                  ),
                },
                {
                  key: 'settings',
                  label: t('settings'),
                  children: (
                    <CardForm 
                      form={clientForm} 
                      initialValues={{
                        ...clientData,
                      }}
                      disabled={isLoading} 
                      onFinish={onFinish}
                    >
                      <SettingsFields />
                    </CardForm>
                  ),
                },
                {
                  key: 'scope',
                  label: t('scope'),
                  children: (
                    <CardForm 
                      form={clientForm} 
                      initialValues={{
                        ...clientData,
                      }}
                      disabled={isLoading} 
                      onFinish={onFinish}
                    >
                      <ScopeFields />
                    </CardForm>
                  ),
                },
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default ClientDetails;
