import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Switch, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import RatingModel from './AgeRatingModal';
import { useTranslation } from 'react-i18next';

const AgeRatings = (params: { service_name: any; clientName: any; setRatingsParent: any; data?: any }) => {
  const [showAddRatingModal, setShowAddRatingModal] = useState<boolean>(false);
  const [showEditRatingModal, setShowEditRatingModal] = useState<boolean>(false);
  const [ratingToEdit, setRatingToEdit] = useState([] as any);
  const [ratings, setRatings] = useState([] as any[]);
  const { t } = useTranslation();
  const ratingsColumns = [
    {
      title: t('content-rating'),
      dataIndex: 'name',
    },
    {
      title: t('age-rating'),
      dataIndex: 'type',
    },
    // {
    //   title: t('parental-locked'),
    //   dataIndex: 'locked',
    //   render: (row: any) => (
    //     <span className="justify-content-start">
    //       <Switch className="ch-switch" defaultChecked={row} checked={row} />
    //     </span>
    //   ),
    // },
    {
      title: t('actions'),
      dataIndex: 'action',
      width: 100,
      render: (tags: any, row: any) => (
        <span className="justify-content-end">
          <Tooltip placement="top" title={t('edit')}>
            <Button
              className="mx-2 cursor-pointer"
              style={{ height: '100%' }}
              onClick={(event) => {
                setRatingToEdit(row);
                setShowEditRatingModal(!showEditRatingModal);
              }}
              type="text"
            >
              <FontAwesomeIcon className="action-icon" icon={faPenToSquare} />
              <div>{t('edit')}</div>
            </Button>
          </Tooltip>
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (params.data != undefined) {
      setRatings(params.data);
    }
  }, [params.data]);

  return (
    <div>
      <h4 className="text-primary mt-5 mb-3">{t('ratings')}</h4>
      <p>{t('make-sure-your-rating-list-in-age-sequential-order-meaning-from-low-age-to-higher-age')}</p>
      <div className="row">
        <div className="col-md-12">
          <div className="">
            <Button
              className="mt-4 mb-4 ant-btn btn btn-secondary rounded-pill width-240 height-48 float-right"
              onClick={() => {
                setShowAddRatingModal(true);
              }}
            >
              {t('add-rating')}
            </Button>
          </div>
          {showEditRatingModal && (
            <RatingModel
              initialData={ratingToEdit}
              onCancel={() => {
                setShowEditRatingModal(false);
              }}
              onSubmit={(values: any) => {
                var newRatings;
                newRatings = ratings.map((rating) => {
                  return rating.name === values.name ? {
                    type: values.type,
                    name: values.name,
                    locked: values.locked
                  } : rating;
                });
                setRatings(newRatings);
                params.setRatingsParent(newRatings);
                setShowEditRatingModal(false);
              }}
              visible={showEditRatingModal}
              existingRatingNames={ratings.map((rating) => {
                return rating.name;
              })}
              operationMode="edit"
            />
          )}
          {showAddRatingModal && (
            <RatingModel
              initialData={undefined}
              onCancel={() => {
                setShowAddRatingModal(false);
              }}
              onSubmit={(values: any) => {
                const newRatings = ratings.concat(values);
                console.log(newRatings)
                setRatings(newRatings);
                params.setRatingsParent(newRatings);
                setShowAddRatingModal(false);
              }}
              visible={showAddRatingModal}
              existingRatingNames={ratings.map((rating) => {
                return rating.name;
              })}
              operationMode="add"
            />
          )}
          <Table
            rowKey={(languagesLocal) => languagesLocal.name}
            columns={ratingsColumns}
            dataSource={ratings}
            key={JSON.stringify(ratings)}
            pagination={{
              position: ['bottomLeft'],
              pageSize: 10,
              hideOnSinglePage: true,
              showTotal: (total, range) => {
                return t('range-0-range-1-of-total-items', { start: range[0], end: range[1], total: total });
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default AgeRatings;
