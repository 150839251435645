import { Button, Card, Form, FormListFieldData, Select, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { DatePicker, FormHeading, Input, InputNumber, Item } from "../../../../../common/components/form";
import { useEffect, useState } from "react";
import { NamePath } from "antd/es/form/interface";
import { AvailableAccumulatorOperators, AvailableArraySizeOperators, AvailableChartDesignEntity, AvailableChartTypes, AvailableChartWidths, AvailableDateTimeOperators, AvailableFilterLogicOperators, AvailableNumberOperators, AvailablePercentageOperators, AvailableSortingOperators, AvailableStringOperators, ChartDesignField, Choice } from "@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignConstants";
import { AvailableChartTypesReporting, AvailableEntitiesReporting, ChartDesignFilterDataTypes, FilterAndOr } from "@mwaretv/database/build/backend/models/reporting/chartdesigns/chartDesignEnums";
import { DeleteOutlined, MinusOutlined, QuestionOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';

export const ChartDesignFormFields  = ({ availableChartDesignEntities, entityType = undefined, chartType = undefined }: { availableChartDesignEntities: AvailableChartDesignEntity[], entityType?: AvailableEntitiesReporting, chartType?: AvailableChartTypesReporting } ) => {
  const [entity, setEntity] = useState<AvailableChartDesignEntity | undefined>(availableChartDesignEntities.find(entity => entity.name === entityType));
  const [chartTypeState, setChartType] = useState<AvailableChartTypesReporting | undefined>(chartType);

  return (
    <>
      <Card>
        <BasicFields availableChartDesignEntities={availableChartDesignEntities} setEntity={setEntity} setChartType={setChartType} />
      </Card>
      {entity &&
      <>
      <Form.List name="series">
        {(series, { add, remove }) => (
          <>
            {series.map((seriesInstance) => (
              <>
                <Card>
                  <GroupQueryFields series={seriesInstance} entity={entity} chartType={chartTypeState} />
                </Card>
                <Card>
                  <FilterQueryFields series={seriesInstance} entity={entity} />
                </Card>
              </>
            ))}
          </>
        )}
      </Form.List>
      </>
      }
    </>
  );
};

const BasicFields = ({ availableChartDesignEntities, setEntity, setChartType }: { availableChartDesignEntities: AvailableChartDesignEntity[], setEntity: (entity?: AvailableChartDesignEntity) => void, setChartType: (chartType: AvailableChartTypesReporting) => void }) => {
  const {t} = useTranslation();
  const form = Form.useFormInstance();
  const [showHelp, setShowHelp] = useState(false);

  return (
    <>
      <div className="row">
        <div className="col-md-11">
          <FormHeading>{t('chart-design-chart-basics')}</FormHeading>
        </div>
        <div className="col-md-1">
          <FormHeading>
            <Button className="btn-primary rounded-pill" icon={<QuestionOutlined />} onClick={() => setShowHelp(!showHelp)} block />
          </FormHeading>
        </div>
      </div>
      <div className="row">
        <Item name={['name']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-chart-name')} rules={[{ required: true, message: t('chart-design-chart-name-is-required')}]} extra={showHelp ? t('chart-design-chart-name-extra') : undefined}>
          <Input />
        </Item>
      </div>
      <Item name={['dashboard']} noStyle>
        <Input type='hidden' />
      </Item>
      <div className="row">
        <Item name={['entityType']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-entity-type')} rules={[{ required: true, message: t('chart-design-entity-type-is-required') }]} extra={showHelp ? t('chart-design-entity-type-extra') : undefined}>
          <Select className="default-select" placeholder={t('chart-design-select-entity-type')}
            onChange={(value) => {
              form.resetFields([
                "series",
              ]);

              setEntity(availableChartDesignEntities.find(entity => entity.name === value));
            }}
          >
            {availableChartDesignEntities.map((entity) => {
              return (
                <Select.Option value={entity.name} key={entity.name}>
                  {t(entity.translationKey ?? "")}
                </Select.Option>
              );
            })}
          </Select>
        </Item>
      </div>
      <div className="row">
        <Item name={['chartType']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-chart-type')} rules={[{ required: true, message: t('chart-design-chart-type-is-required') }]} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-chart-type-extra')}</div> : undefined}>
          <Select className="default-select" placeholder={t('chart-design-select-chart-type')}
            onChange={(value) => {
              form.resetFields([
                "series",
              ]);

              setChartType(value);
            }}
          >
            {AvailableChartTypes.map((chartType) => {
              return (
                <Select.Option value={chartType.name} key={chartType.name}>
                  {t(chartType.translationKey ?? "")}
                </Select.Option>
              );
            })}
          </Select>
        </Item>
        <Item name={['width12column']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-chart-width')} rules={[{ required: true, message: t('chart-design-chart-width-is-required') }]} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-chart-width-extra')}</div> : undefined}>
          <Select className="default-select" placeholder={t('chart-design-select-chart-width')}>
            {AvailableChartWidths.map((chartType) => {
              return (
                <Select.Option value={chartType.value} key={chartType.value}>
                  {t(chartType.translationKey ?? "")}
                </Select.Option>
              );
            })}
          </Select>
        </Item>
      </div>
    </>
  );
};

const GroupQueryFields = ({series, entity, chartType}: {series: FormListFieldData, entity: AvailableChartDesignEntity, chartType?: AvailableChartTypesReporting}) => {
  const {t} = useTranslation();
  const form = Form.useFormInstance();

  const [showHelp, setShowHelp] = useState(false);
  const [percentageOperator, setPercentageOperator] = useState(form.getFieldValue(["series", series.name, "percentageOperator"]));

  return (
    <>
      <div className="row">
        <div className="col-md-11">
          <FormHeading>{t('chart-design-group')}</FormHeading>
        </div>
        <div className="col-md-1">
          <FormHeading>
            <Button className="btn-primary rounded-pill" icon={<QuestionOutlined />} onClick={() => setShowHelp(!showHelp)} block />
          </FormHeading>
        </div>
      </div>
      {(chartType !== AvailableChartTypesReporting.PLAIN_VALUE && chartType !== AvailableChartTypesReporting.GAUGE) &&
      <div className="row">
        <Item name={[series.name, 'groupByFieldName']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-group-fieldname')} rules={[{ required: true, message: t('chart-design-group-fieldname-is-required')}]} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-group-fieldname-extra')}</div> : undefined}>
          <Select className="default-select" placeholder={t('chart-design-select-group-fieldname')}>
            {entity.fields?.filter(field => field.availableForGroupBy).map((groupByField) => {
              return (
                <Select.Option value={groupByField.name} key={groupByField.name}>
                  {t(groupByField.translationKey ?? "")}
                </Select.Option>
              );
            }) ?? []}
          </Select>
        </Item>
        <div className="col-24-md-17" />
      </div>
      }
      <div className="row">
        <AccumulatorFields series={series} entity={entity} showHelp={showHelp} />        
      </div>
      {chartType !== AvailableChartTypesReporting.PLAIN_VALUE && chartType !== AvailableChartTypesReporting.GAUGE &&
      <div className="row">
        <Item name={[series.name, 'sortBy']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-sortby')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-sortby-extra')}</div> : undefined}>
          <Select defaultValue={"ascending"} className="default-select" placeholder={t('chart-design-sortby')}>
          {AvailableSortingOperators.map((operator) => {
            return (
              <Select.Option value={operator.name} key={operator.name}>
                {t(operator.translationKey ?? "")}
              </Select.Option>
            );
          })}
          </Select>
        </Item>
        <Item name={[series.name, 'limit']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-limit')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-limit-extra')}</div> : undefined}>
          <InputNumber />
        </Item>
      </div>
      }
      {chartType === AvailableChartTypesReporting.GAUGE &&
      <div className="row">
        <Item name={[series.name, 'percentageOperator']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-percentage-operator')} rules={[{ required: true, message: t('chart-design-percentage-operator-is-required')}]} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-percentage-operator-extra')}</div> : undefined}>
          <Select className="default-select" placeholder={t('chart-design-percentage-operator')}
            onChange={(value) => {
              setPercentageOperator(value);
            }}
          >
          {AvailablePercentageOperators.map((operator) => {
            return (
              <Select.Option value={operator.name} key={operator.name}>
                {t(operator.translationKey ?? "")}
              </Select.Option>
            );
          })}
          </Select>
        </Item>
        {percentageOperator === "target" && 
        <Item name={[series.name, 'percentageTarget']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-percentage-target')} rules={[{ required: true, message: t('chart-design-percentage-target-is-required')}]}>
          <InputNumber />
        </Item>
        }
        <Item name={[series.name, 'percentageWarning']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-percentage-warning')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-percentage-warning-extra')}</div> : undefined}>
          <InputNumber />
        </Item>
      </div>
      }
    </>
  );
};

const AccumulatorFields = ({series, entity, showHelp}: {series: FormListFieldData, entity: AvailableChartDesignEntity, showHelp: boolean}) => {
  const {t} = useTranslation();
  const form = Form.useFormInstance();
  const [accumulatorOperator, setAccumulatorOperator] = useState(form.getFieldValue(["series", series.name, "accumulator.operator"]));

  return (
    <>
      <Item name={[series.name, `accumulator.operator`]} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-accumulator-operator')} rules={[{ required: true, message: t('chart-design-accumulator-operator-is-required')}]} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-accumulator-operator-extra')}</div> : undefined}>
        <Select className="default-select" placeholder={t('chart-design-select-accumulator-operator')}
          onChange={(value) => {
            form.resetFields([["series", series.name, "accumulator.operandFieldName"]]);
            setAccumulatorOperator(value);
          }}
        >
          {AvailableAccumulatorOperators.map((operator) => {
            return (
              <Select.Option value={operator.name} key={operator.name}>
                {t(operator.translationKey ?? "")}
              </Select.Option>
            );
          })}
        </Select>
      </Item>
      {(!accumulatorOperator || (accumulatorOperator !== "$count" && accumulatorOperator !== "$distinctCount")) &&
      <Item name={[series.name, `accumulator.operandFieldName`]} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-accumulator-operand-fieldname')} rules={[{ required: true, message: t('chart-design-accumulator-operand-fieldname-is-required')}]} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-accumulator-operand-fieldname-extra')}</div> : undefined}>
        <Select className="default-select" placeholder={t('chart-design-select-accumulator-operand-fieldname')}>
          {entity.fields?.filter(field => field.availableForAccumulator).map((accumulatorField) => {
            return (
              <Select.Option value={accumulatorField.name} key={accumulatorField.name}>
                {t(accumulatorField.translationKey ?? "")}
              </Select.Option>
            );
          })}
        </Select>
      </Item>
      }
      {accumulatorOperator === "$distinctCount" &&
      <Item name={[series.name, `accumulator.operandFieldName`]} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-accumulator-operand-fieldname')} rules={[{ required: true, message: t('chart-design-accumulator-operand-fieldname-is-required')}]} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-accumulator-operand-fieldname-extra')}</div> : undefined}>
        <Select className="default-select" placeholder={t('chart-design-select-accumulator-operand-fieldname')}>
          {entity.fields?.filter(field => field.availableForDistinct).map((accumulatorField) => {
            return (
              <Select.Option value={accumulatorField.name} key={accumulatorField.name}>
                {t(accumulatorField.translationKey ?? "")}
              </Select.Option>
            );
          })}
        </Select>
      </Item>
      }
      <Item name={[series.name, `accumulator.displayName`]} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-accumulator-displayname')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-accumulator-displayname-extra')}</div> : undefined}>
        <Input />
      </Item>
      {accumulatorOperator === "$count" &&
        <div className="col-24-md-7" />
      }
    </>
  )

}

const FilterQueryFields = ({series, entity}: {series: FormListFieldData, entity: AvailableChartDesignEntity}) => {
  const {t} = useTranslation();

  const [showHelp, setShowHelp] = useState(false);

  return (
    <>
      <div className="row">
        <div className="col-md-11">
          <FormHeading>{t('chart-design-filters')}</FormHeading>
        </div>
        <div className="col-md-1">
          <FormHeading>
            <Button className="btn-primary rounded-pill" icon={<QuestionOutlined />} onClick={() => setShowHelp(!showHelp)} block />
          </FormHeading>
        </div>
      </div>
      <div className="row">
        <div className="col-24-md-7">
          <Item name={[series.name, 'filterLogicOperator']} labelCol={{ span: 24 }} label={t('chart-design-filter-logic')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-filter-logic-extra')}</div> : undefined}>
            <Select defaultValue={FilterAndOr.AND} className="default-select" placeholder={t('chart-design-select-filter-logic')}>
            {AvailableFilterLogicOperators.map((operator) => {
              return (
                <Select.Option value={operator.name} key={operator.name}>
                  {t(operator.translationKey ?? "")}
                </Select.Option>
              );
            })}
            </Select>
          </Item>
        </div>
      </div>
      <Form.List name={[series.name, "filters"]}>
        {(filters, { add, remove }) => (
          <>
            <div className="row">
              <div className="col-24-md-21" />
              <div className="col-24-md-3">
                <Button type="default" size="large" onClick={() => add()} block>
                  {"+ " + t('chart-design-add-filter')}
                </Button>
              </div>
            </div>
            {filters.map((filter) => (
              <div className="row">
                <FilterFieldSelector series={series} filter={filter} availableFilterFields={entity.fields?.filter(field => field.availableForFilter) ?? []} arrayNamePath={["series", series.name, "filters"]} isArrayFieldSelector={false} showHelp={showHelp} />
                <div className="col-24-md-3">
                  <label>&nbsp;</label>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginLeft: "auto" }}>
                      <Tooltip placement="top" title={t('chart-design-remove-filter')}>
                        <Button style={{ textAlign: "center" }} size="large" type="default" icon={<DeleteOutlined />} onClick={() => remove(filter.name)} block />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </>
  );
}

const FilterFieldSelector = ({series, filter, availableFilterFields, arrayNamePath, isArrayFieldSelector, showHelp}: {series: FormListFieldData, filter: FormListFieldData, availableFilterFields: ChartDesignField[], arrayNamePath: (string | number)[], isArrayFieldSelector: boolean, showHelp: boolean}) => {
  const {t} = useTranslation();
  const form = Form.useFormInstance();
  const [filterField, setFilterField] = useState<ChartDesignField | undefined>(() => {
    const fieldName = form.getFieldValue(arrayNamePath.concat(filter.name).concat("fieldName"));
    return availableFilterFields.find(field => field.name === fieldName);
  });

  // needed to align state with modified filters array that exists within AntD components. otherwise, when removing a filter, the AntD internal array changes, but it's not reflected in our own state.
  useEffect(() => {
    const fieldName = form.getFieldValue(arrayNamePath.concat(filter.name).concat("fieldName"));
    setFilterField(availableFilterFields.find(field => field.name === fieldName)) ;
  },[arrayNamePath, availableFilterFields, filter.name, form]);

  return (
    <>
      <Item name={[filter.name, 'fieldName']} className="col-24-md-7" labelCol={{ span: 24 }} label={isArrayFieldSelector ? t('chart-design-filter-element-fieldname') : t('chart-design-filter-fieldname')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{isArrayFieldSelector ? t('chart-design-filter-element-fieldname-extra') : t('chart-design-filter-fieldname-extra')}</div> : undefined} rules={[{ required: true, message: t('chart-design-filter-fieldname-is-required')}]}>
        <Select className="default-select" placeholder={isArrayFieldSelector ? t('chart-design-select-element-filter-fieldname') : t('chart-design-select-filter-fieldname')}
          onChange={(value) => {
            var operatorNamePath: NamePath = arrayNamePath.concat(filter.name).concat("operator");
            var operandNamePath: NamePath = arrayNamePath.concat(filter.name).concat("operand");
            var elementFiltersNamePath: NamePath = arrayNamePath.concat(filter.name).concat("elementFilters");

            form.resetFields([
              operatorNamePath,
              operandNamePath,
              elementFiltersNamePath,
            ]);

            var dataTypeNamePath: NamePath = arrayNamePath.concat(filter.name).concat("dataType");
            const filterField = availableFilterFields.find(field => field.name === value);

            form.setFieldValue(dataTypeNamePath, filterField?.dataType);
            setFilterField(filterField);
          }}
        >
          {availableFilterFields.map((filterField) => {
            return (
              <Select.Option value={filterField.name} key={filterField.name}>
                {t(filterField.translationKey ?? "")}
              </Select.Option>
            );
          })}
        </Select>
      </Item>
      {!filterField?.dataType &&
        <div className="col-24-md-14" />
      }
      {filterField?.dataType === ChartDesignFilterDataTypes.STRING &&
        <StringOperationSelector filter={filter} showHelp={showHelp} />
      }
      {filterField?.dataType === ChartDesignFilterDataTypes.CHOICE &&
        <ChoiceOperationSelector filter={filter} availableChoices={filterField?.choices ?? []} showHelp={showHelp} />
      }
      {filterField?.dataType === ChartDesignFilterDataTypes.NUMBER &&
        <NumberOperationSelector filter={filter} isArraySizeFilter={filterField.name === "@size"} showHelp={showHelp} />
      }
      {filterField?.dataType === ChartDesignFilterDataTypes.BOOLEAN &&
        <BooleanOperationSelector filter={filter} showHelp={showHelp} />
      }
      {filterField?.dataType === ChartDesignFilterDataTypes.DATETIME &&
        <DateTimeOperationSelector filter={filter} arrayNamePath={arrayNamePath} showHelp={showHelp} />
      }
      {filterField?.dataType === ChartDesignFilterDataTypes.ARRAY &&
        <ArrayOperationSelector series={series} filter={filter} showHelp={showHelp} availableElementFilterFields={filterField?.elementFilterFields ?? []} />
      }
    </>
  );
}

const NumberOperationSelector = ({filter, isArraySizeFilter, showHelp}: {filter: FormListFieldData, isArraySizeFilter: boolean, showHelp: boolean}) => {
  const {t} = useTranslation();
  return (
    <>
    {isArraySizeFilter &&
    // necessary because MongoDB only supports the $eq operator on array size queries
      <Item name={[filter.name, 'operator']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-filter-operator')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-filter-arraysize-extra')}</div> : undefined}>
        <Select disabled defaultValue={"$eq"} className="default-select" placeholder={t('chart-design-select-filter-operator')}>
        {AvailableArraySizeOperators.map((operator) => {
          return (
            <Select.Option value={operator.name} key={operator.name}>
              {t(operator.translationKey ?? "")}
            </Select.Option>
          );
        })}
        </Select>
      </Item>
    }
    {!isArraySizeFilter && 
      <Item name={[filter.name, 'operator']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-filter-operator')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-filter-operator-extra')}</div> : undefined} rules={[{ required: true, message: t('chart-design-filter-operator-is-required')}]}>
        <Select className="default-select" placeholder={t('chart-design-select-filter-operator')}>
          {AvailableNumberOperators.map((operator) => {
            return (
              <Select.Option value={operator.name} key={operator.name}>
                {t(operator.translationKey ?? "")}
              </Select.Option>
            );
          })}
        </Select>
      </Item>
    }
      <Item name={[filter.name, 'operand']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-filter-operand')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-filter-operand-extra')}</div> : undefined} rules={[{ required: true, message: t('chart-design-filter-operand-is-required')}]}>
        <InputNumber />
      </Item>
    </>
  );
}

const BooleanOperationSelector = ({filter, showHelp}: {filter: FormListFieldData, showHelp: boolean}) => {
  const {t} = useTranslation();
  return (
    <>
      <div className="col-24-md-7" />
      <Item name={[filter.name, 'operand']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-filter-operand')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-filter-operand-extra')}</div> : undefined} rules={[{ required: true, message: t('chart-design-filter-operand-is-required')}]}>
        <Select className="default-select" placeholder={t('chart-design-select-filter-operator')}>
          <Select.Option value={true} key={1}>
            {t('yes')}
          </Select.Option>
          <Select.Option value={false} key={0}>
            {t('no')}
          </Select.Option>
        </Select>
      </Item>
    </>
  )
}

const StringOperationSelector = ({filter, showHelp}: {filter: FormListFieldData, showHelp: boolean}) => {
  const {t} = useTranslation();
  return (
    <>
      <Item name={[filter.name, 'operator']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-filter-operator')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-filter-operator-extra')}</div> : undefined} rules={[{ required: true, message: t('chart-design-filter-operator-is-required')}]}>
        <Select className="default-select" placeholder={t('chart-design-select-filter-operator')}>
          {AvailableStringOperators.map((operator) => {
            return (
              <Select.Option value={operator.name} key={operator.name}>
                {t(operator.translationKey ?? "")}
              </Select.Option>
            );
          })}
        </Select>
      </Item>
      <Item name={[filter.name, 'operand']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-filter-operand')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-filter-operand-extra')}</div> : undefined} rules={[{ required: true, message: t('chart-design-filter-operand-is-required')}]}>
        <Input />
      </Item>
    </>
  );
}

const ChoiceOperationSelector = ({filter, availableChoices, showHelp}: {filter: FormListFieldData, availableChoices: Choice[], showHelp: boolean}) => {
  const {t} = useTranslation();
  return (
    <>
      <Item name={[filter.name, 'operator']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-filter-operator')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-filter-operator-extra')}</div> : undefined} rules={[{ required: true, message: t('chart-design-filter-operator-is-required')}]}>
        <Select className="default-select" placeholder={t('chart-design-select-filter-operator')}>
          {AvailableStringOperators.map((operator) => {
            return (
              <Select.Option value={operator.name} key={operator.name}>
                {t(operator.translationKey ?? "")}
              </Select.Option>
            );
          })}
        </Select>
      </Item>
      <Item name={[filter.name, 'operand']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-filter-operand')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-filter-operand-extra')}</div> : undefined} rules={[{ required: true, message: t('chart-design-filter-operand-is-required')}]}>
        <Select 
          className="default-select" 
          showSearch
          allowClear 
          filterOption={(input, option) => {
            return ((option?.key as string) ?? '').toLowerCase().includes(input.toLowerCase());
          }} 
          placeholder={t('chart-design-select-filter-value')}
        >
          {availableChoices.map((choice) => {
            return (
              <Select.Option value={choice.name} key={choice.name}>
                {t(choice.translationKey ?? "")}
              </Select.Option>
            );
          })}
        </Select>
      </Item>
    </>
  );
}

const DateTimeOperationSelector = ({filter, arrayNamePath, showHelp}: {filter: FormListFieldData, arrayNamePath: (string | number)[], showHelp: boolean}) => {
  const {t} = useTranslation();
  const form = Form.useFormInstance();
  const [isSelectedOperatorAPeriod, setIsSelectedOperatorAPeriod] = useState<boolean>(() => {
    const operatorFieldValue = form.getFieldValue(arrayNamePath.concat(filter.name).concat("operator"));
    return checkIsSelectedOperatorAPeriod(operatorFieldValue);
  });
  
  return (
    <>
      <Item name={[filter.name, 'operator']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-filter-operator')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-filter-operator-datetime-extra')}</div> : undefined} rules={[{ required: true, message: t('chart-design-filter-operator-is-required')}]}>
        <Select className="default-select" placeholder={t('chart-design-select-filter-operator')}
          onChange={(value) => {
            setIsSelectedOperatorAPeriod(checkIsSelectedOperatorAPeriod(value));
          }}
        >
          {AvailableDateTimeOperators.map((operator) => {
            return (
              <Select.Option value={operator.name} key={operator.name}>
                {t(operator.translationKey ?? "")}
              </Select.Option>
            );
          })}
        </Select>
      </Item>
      {!isSelectedOperatorAPeriod &&
      <Item name={[filter.name, 'operand']} className="col-24-md-7" labelCol={{ span: 24 }} label={t('chart-design-filter-operand')} extra={showHelp ? <div style={{ whiteSpace: "pre-wrap" }}>{t('chart-design-filter-operand-extra')}</div> : undefined} rules={[{ required: true, message: t('chart-design-filter-operand-is-required')}]}>
        <DatePicker 
          showTime={{ format: 'HH:mm', defaultValue: dayjs().startOf("day") }}
          format="YYYY-MM-DD HH:mm"
          style={{ marginBottom: 0 }} // style needed to override AntD DatePicker standard marginBottom: 30px
        />
       </Item>
      }
      {isSelectedOperatorAPeriod &&
      <div className="col-24-md-7" />
      }
    </>
  );
}

const checkIsSelectedOperatorAPeriod = (operatorFieldValue: string): boolean => {

  return operatorFieldValue === "@today" || operatorFieldValue === "@thisweek" || operatorFieldValue === "@thismonth" || operatorFieldValue === "@thisquarter" || operatorFieldValue === "@thisyear" || operatorFieldValue === "@previousmonth";

}

const ArrayOperationSelector = ({series, filter, availableElementFilterFields, showHelp}: {series: FormListFieldData, filter: FormListFieldData, availableElementFilterFields: ChartDesignField[], showHelp: boolean}) => {
  const {t} = useTranslation();

  return (
    <div className="col-24-md-14">
      <Form.List name={[filter.name, "elementFilters"]}>
        {(elementFilters, { add, remove }) => {
          return (
            <>
              {elementFilters.map((elementFilter) => (
                <div className="row">
                  <FilterFieldSelector series={series} filter={elementFilter} availableFilterFields={availableElementFilterFields} arrayNamePath={["series", series.name, "filters", filter.name, "elementFilters"]} isArrayFieldSelector={true} showHelp={showHelp} />
                  <div className="col-24-md-3">
                    <label>&nbsp;</label>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginLeft: "auto" }}>
                        <Tooltip placement="top" title={t('chart-design-remove-array-element-filter')}>
                          <Button style={{ textAlign: "center" }} size="large" type="default" icon={<MinusOutlined />} onClick={() => remove(elementFilter.name)} block />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="row">
                <div className="col-24-md-16" />
                <div className="col-24-md-8">
                  <div style={{ paddingLeft: "15px "}}>
                    {(!elementFilters || elementFilters.length === 0) &&
                      <label>&nbsp;</label>
                    }
                    <Button type="default" size="large" onClick={() => add()} block>
                      {"+ " + t('chart-design-add-array-element-filter')}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          );
        }}
        </Form.List>
    </div>
  );
}
