import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentContext } from '../../../../../../contexts/contentContext';
import { IMSNameParams } from '../../../../../../pages/dashboard';
import Translations from '../Translations';


type IUpdatePackageModalProps = {
  onSubmit: (values: any) => void;
  onCancel: () => void;
  
  initialData: {
    _id?: string;
    translations?: string;
    type: string;
  };
};

const PackageModal = (props: IUpdatePackageModalProps) => {
  const { onSubmit, onCancel, initialData } = props;
  const { clientName } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [translations, setTranslations] = useState([] as any);
  const { t } = useTranslation();
  let [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(initialData);
    if (initialData?.translations && initialData.translations.length > 0) {
      setTranslations(initialData.translations);
    }
  }, []);



  

  return (
    <div className="p-5" style={{ border: '1px solid #ebebeb', borderTop: 0 }}>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <Form initialValues={initialData} form={form} onFinish={(values) => onSubmit(values)} onFinishFailed={(errorInfo) => {}}>
            <div className="mt-2"></div>
            <Translations service_name={deployment} clientName={clientName} extra={''} data={translations} setTranslations={setTranslations} />
            <Form.Item name="_id">
              <Input hidden value={initialData?._id}></Input>
            </Form.Item>
            <div className="col-md-12 p-0">
              {initialData._id == undefined && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('add-curation')}
                </Button>
              )}
              {initialData._id != undefined && (
                <Button htmlType="submit" className="btn btn-secondary float-right rounded-pill mt-1 width-180 height-48">
                  {t('update-curation')}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PackageModal;
