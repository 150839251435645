import { Button, Modal, Tag, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Client } from '../../../../../interfaces/forms/clients/clients';
import { useEffect, useState } from 'react';
import axios from 'axios';
import * as HttpStatusCodes  from 'http-status-codes';
import clientService from '../../../../../services/client';
import { CustomNotification } from '../../../../../common/components/Notification';
import { useTranslation } from 'react-i18next';

const Clients = (props: Client) => {
  const { _id, name, devices, scope, createdAt, status, onboarding } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [customersPeak, setCustomersPeak] = useState(0);
  const [trafficInGb, setTrafficInGb] = useState(0);
  const [storageInGb, setStorageInGb] = useState(0);
  const [epgUrlChannelCounter, setEpgUrlChannelCounter] = useState(0);
  const [customersPeakPreviousMonth, setCustomersPeakPreviousMonth] = useState(0);
  const [trafficInGbPreviousMonth, setTrafficInGbPreviousMonth] = useState(0);
  const [storageInGbPreviousMonth, setStorageInGbPreviousMonth] = useState(0);
  const history = useHistory();
  const { t } = useTranslation();
  let { confirm } = Modal;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = 'https://cloudtv.akamaized.net/' + name + '/dashboards/dashboard_tvms_overview_customers_peak_input.json';
        const { data } = await axios.get(path);
        if (data) {
          setCustomersPeak(data.monthlyPeak);
          setCustomersPeakPreviousMonth(data.previousMonthlyPeak);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [name]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = 'https://cloudtv.akamaized.net/' + name + '/dashboards/dashboard_tvms_overview_epg_input.json';
        const { data } = await axios.get(path);
        if (data) {
          setEpgUrlChannelCounter(data.epgUrlChannelCounter);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [name]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = 'https://cloudtv.akamaized.net/' + name + '/dashboards/dashboard_tvms_overview_traffic_input.json';
        const { data } = await axios.get(path);
        if (data) {
          setTrafficInGb(data.trafficInGb);
          setTrafficInGbPreviousMonth(data.trafficInGbPreviousMonth);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [name]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = 'https://cloudtv.akamaized.net/' + name + '/dashboards/dashboard_tvms_overview_storage_input.json';
        const { data } = await axios.get(path);
        if (data) {
          setStorageInGb(data.storageInGb);
          setStorageInGbPreviousMonth(data.storageInGbPreviousMonth);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [name]);

  const deleteClient = async () => {
    let response = await clientService.updateClient({
      ...props,
      status: 'Deleted',
    });
    if (response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('success'),
        description: t('client-updated-successfully'),
      });
      history.go(0);
    } else {
      CustomNotification({
        error: response.errors,
      });
    }
  };
  const getStatus = (status: String) => {
    switch (status) {
      case 'Live':
        return '#1ac884';
        break;
      case 'Suspended':
        return 'orange';
        break;
      case 'Stopped':
        return 'crimson';
        break;
      case 'Archived':
        return 'gray';
        break;
      case 'Onboarding':
        return '#485fe0';
        break;
      case 'Trial':
        return '#ffc107';
        break;
      default:
        return '#485fe0';
        break;
    }
  };

  return (
    <div className="rounded shadow" id={`${_id}`} style={{ height: 785, backgroundColor: '#fff', padding: 15,border: '1px solid #efefef', }}>
      <div style={{ height: 665 }}>
        <div className="row-md-12">
          <div className="row-md-12" style={{ display: 'flex', flexDirection: 'row'}}>
            <div>
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold', backgroundColor: getStatus(status) }} color={'#ffffff'}>
                {status}
              </Tag>
            </div>
            {status == 'Onboarding' && (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold', backgroundColor: '#888' }} color={'#ffffff'}>
                  {onboarding}
                </Tag>
              </div>
            )}
          </div>
          <div className="row-md-12">
            <div style={{ color: '#555', textAlign: 'start', paddingTop: 20 }}>
              <div style={{ color: '#485fe0', textAlign: 'start', fontSize: 22, marginTop: -4, fontWeight: 'bold' }}>{name.charAt(0).toUpperCase() + name.slice(1)}</div>
              <div style={{ fontSize: 11, color: '#999', textAlign: 'start'}}>{moment(createdAt).format('ll')}</div>
            </div>
          </div>
          <div className="row-md-12 text-left mt-2" style={{ borderTop: '1px solid #dfdfdf', paddingTop: 15, height:200 }}>
            <table cellSpacing="0" cellPadding="0" className="table" style={{ fontSize: 11 }}>
              <tbody>
                <tr style={{ backgroundColor: 'transparent' }}>
                  <td style={{ border: 'none', padding: 5, paddingLeft: 2 }} width={'50%'}>
                    {t('expected-target')}
                  </td>
                  <td style={{ border: 'none', padding: 5, color: '#999' }}>{scope?.target}</td>
                </tr>
                <tr style={{ backgroundColor: 'transparent' }}>
                  <td style={{ border: 'none', padding: 5, paddingLeft: 2 }} width={'50%'}>
                    {t('expected-deadline-for-the-target')}
                  </td>
                  <td style={{ border: 'none', padding: 5, color: '#999' }}>{scope?.duration}</td>
                </tr>
                <tr style={{ backgroundColor: 'transparent' }}>
                  <td style={{ border: 'none', padding: 5, paddingLeft: 2 }} width={'50%'}>
                    {t('general-business-case')}
                  </td>
                  <td style={{ border: 'none', padding: 5, color: '#999', overflow:'scroll' }}>{scope?.how}</td>
                </tr>
                <tr style={{ backgroundColor: 'transparent' }}>
                  <td style={{ border: 'none', padding: 5, paddingLeft: 2 }} width={'50%'}>
                    {t('expected-softlaunch-date')}
                  </td>
                  <td style={{ border: 'none', padding: 5, color: '#999' }}>{scope?.softlaunch}</td>
                </tr>
                <tr style={{ backgroundColor: 'transparent' }}>
                  <td style={{ border: 'none', padding: 5, paddingLeft: 2 }} width={'50%'}>
                    {t('expected-live-date')}
                  </td>
                  <td style={{ border: 'none', padding: 5, color: '#999' }}>{scope?.live}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row-md-12 text-left mt-2" style={{ borderTop: '1px solid #dfdfdf', paddingTop: 15 }}>
            <table cellSpacing="0" cellPadding="0" className="table" style={{ fontSize: 11 }}>
              <tbody>
                <tr style={{ backgroundColor: 'transparent' }}>
                  <td style={{ border: 'none', padding: 5, paddingLeft: 2 }} width={'50%'} />
                  <td style={{ border: 'none', padding: 5, color: '#999' }} width={'25%'}>Previous month</td>
                  <td style={{ border: 'none', padding: 5, color: '#999' }} width={'25%'}>Current month</td>
                </tr>
                <tr style={{ backgroundColor: 'transparent' }}>
                  <td style={{ border: 'none', padding: 5, paddingTop: 10, paddingBottom: 10, paddingLeft: 2 }} width={'50%'}>
                    {t('customers')}:
                  </td>
                  <td style={{ border: 'none', padding: 5, paddingTop: 10, paddingBottom: 10, color: '#999' }} width={'25%'}>{customersPeakPreviousMonth ?? 0}</td>
                  <td style={{ border: 'none', padding: 5, paddingTop: 10, paddingBottom: 10, color: '#999' }} width={'25%'}>{customersPeak ?? 0}</td>
                </tr>
                <tr style={{ backgroundColor: 'transparent' }}>
                  <td style={{ border: 'none', padding: 5, paddingTop: 10, paddingBottom: 10, paddingLeft: 2 }} width={'50%'}>
                    {t('traffic-cdn')}
                  </td>
                  <td style={{ border: 'none', padding: 5, paddingTop: 10, paddingBottom: 10, color: '#999' }} width={'25%'}>{trafficInGbPreviousMonth ? Math.round(trafficInGbPreviousMonth) : 0}</td>
                  <td style={{ border: 'none', padding: 5, paddingTop: 10, paddingBottom: 10, color: '#999' }} width={'25%'}>{trafficInGb ? Math.round(trafficInGb) : 0}</td>
                </tr>
                <tr style={{ backgroundColor: 'transparent' }}>
                  <td style={{ border: 'none', padding: 5, paddingBottom: 10, paddingLeft: 2 }} width={'50%'}>
                    {t('storage-cdn')}
                  </td>
                  <td style={{ border: 'none', padding: 5, paddingBottom: 10, color: '#999' }} width={'25%'}>{storageInGbPreviousMonth ? Math.round(storageInGbPreviousMonth) : 0}</td>
                  <td style={{ border: 'none', padding: 5, paddingBottom: 10, color: '#999' }} width={'25%'}>{storageInGb ? Math.round(storageInGb) : 0}</td>
                </tr>
                <tr style={{ backgroundColor: 'transparent' }}>
                  <td style={{ border: 'none', padding: 5, paddingLeft: 2 }} width={'50%'}>
                    TV Profile:
                  </td>
                  <td style={{ border: 'none', padding: 5, color: '#999' }} width={'25%'} />
                  <td style={{ border: 'none', padding: 5, color: '#999' }} width={'25%'}>{epgUrlChannelCounter ?? 0}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="row-md-12 text-left" style={{ borderTop: '1px solid #dfdfdf',  height:100}}>
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            {products.cdn && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                CDN
              </Tag>
            )}
            {products.storage && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                {t('storage')}
              </Tag>
            )}
            {products.billing && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                {t('billing')}
              </Tag>
            )}
            {products.flussonic && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                {t('flussonic')}
              </Tag>
            )}
            {products.streaming_support && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                {t('streaming-support')}
              </Tag>
            )}
            {products.managed_service && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                {t('managed-service')}
              </Tag>
            )}
          </div>
        </div> */}
        <div className="row-md-12 text-left" style={{ borderTop: '1px solid #dfdfdf' }}>
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            {devices?.ios && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                {t('iphone-and-ipad')}
              </Tag>
            )}
            {devices?.tvos && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                AppleTV
              </Tag>
            )}
            {devices?.android && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                {t('android-phone-and-tablet')}
              </Tag>
            )}
            {devices?.androidtv && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                AndroidTV
              </Tag>
            )}
            {devices?.firetv && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                FireTV
              </Tag>
            )}
            {devices?.lg && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                {t('lg-webos')}
              </Tag>
            )}
            {devices?.tizen && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                {t('samsung-tizen')}
              </Tag>
            )}
            {devices?.vidaa && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                {t('vidaa')}
              </Tag>
            )}
            {devices?.webtv && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                WebTV
              </Tag>
            )}
            {devices?.kaios && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                KaiOS
              </Tag>
            )}
            {devices?.roku && (
              <Tag style={{ fontSize: 11, lineHeight: 1, fontWeight: 'bold' }} color="geekblue">
                {t('roku')}
              </Tag>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: 100, flexDirection: 'row', alignContent: 'flex-end' }}>
        <div className="text-right" style={{ borderTop: '1px solid #dfdfdf' }}>
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            <Button
              onClick={() => {
                confirm({
                  title: t('are-you-sure-you-want-to-delete-this-client'),
                  okText: t('yes'),
                  okType: 'danger',
                  cancelText: t('no'),
                  onOk() {
                    deleteClient();
                  },
                  onCancel() {},
                });
              }}
              style={{ fontSize: 12, color: '#f13131', borderColor: 'transparent', backgroundColor: '#e60a0a42',borderWidth:1 }}
              className="btn btn-secondary rounded-pill btn-default height-48 mr-1 float-left"
            >
              {t('delete')}
            </Button>
            <Link style={{ fontSize: 12, marginRight: 5, backgroundColor: '#e9ecfb', color: '#485Fe0', borderColor: 'transparent', borderWidth:1 }} className="btn btn-secondary rounded-pill btn-default  height-48" to={`/details/${_id}`}>
              {t('chart-design-page-type-settings')}
            </Link>
            <Link style={{ fontSize: 12, backgroundColor: '#485Fe0', color: '#ffffff', borderColor: 'transparent',borderWidth:1 }} className="btn btn-secondary rounded-pill btn-default  height-48" to={`/client/${name}/${_id}`}>
              {t('and-gt-and-nbsp-dashboard')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
