import { faBuilding, faBuildings, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Menu } from 'antd';
import { useContext, useState } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import MWare_Logo from '../../assets/images/logo.png';
import MWare_Icon from '../../assets/images/icon.png';
import HomeDashboardHeader from '../../common/components/HomeDashboardHeader';
import PageNotFound from '../../common/components/PageNotFound';
import PrivateRoute from '../../common/routes/PrivateRoute';
import DetailsClient from './pages/clients/details';
import { Users } from '../users';
import AllClients from './pages/clients';
import { AuthContext } from '../../context_api/AuthContext';
import { TVMSRoles } from '../../constants/TVMSRoles';
import { isInRoles, VisibleForRole } from '../../common/components/VisibleForRole';
import { useTranslation } from 'react-i18next';
import AddClient from './pages/clients/add';

const HomeDashboard = () => {
  const { state: AuthState } = useContext(AuthContext);
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  if ((AuthState.user!.clients![0] != undefined && !isInRoles(AuthState.user?.role, [TVMSRoles.SuperAdmin, TVMSRoles.Admin])) || (isInRoles(AuthState.user?.role, [TVMSRoles.Admin]) && AuthState.user?.clients?.length === 1)) {
    return <Redirect to={`/client/${AuthState.user!.clients![0]?.name}/${AuthState.user!.clients![0]?._id}`} />;
  }

  return (
    <Layout>
      <Layout.Sider
        width={300}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => {
          setCollapsed(value);
          window.dispatchEvent(new Event('resize'));
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
            padding: 40,
            paddingTop: 57,
            paddingBottom: 56,
            marginBottom: 20,
            borderBottom: '1px #444 solid',
            backgroundColor: '#002140',
          }}
        >
          <Link to={`/`}>
            {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
          </Link>
        </div>
        <VisibleForRole roles={[TVMSRoles.SuperAdmin, TVMSRoles.Admin]}>
          <Menu
            mode="inline"
            theme="dark"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['1']}
            items={[
              {
                icon: <FontAwesomeIcon icon={faBuildings} size="lg" style={{ width: '20', height: '20' }} />,
                label: (
                  <Link to={'/'}>
                    <span>{t('client-overview')}</span>
                  </Link>
                ),
                key: 'clients',
              },
              isInRoles(AuthState.user?.role, [TVMSRoles.SuperAdmin])
                ? {
                    icon: <FontAwesomeIcon icon={faBuilding} size="lg" style={{ width: '20', height: '20' }} />,
                    label: (
                      <Link to={'/add-new-client'}>
                        <span>{t('add-client')}</span>
                      </Link>
                    ),
                    key: 'client',
                  }
                : null,
              isInRoles(AuthState.user?.role, [TVMSRoles.SuperAdmin])
                ? {
                    icon: <FontAwesomeIcon icon={faUsers} size="lg" style={{ width: '20', height: '20' }} />,
                    label: (
                      <Link to={'/users'}>
                        <span>{t('tvms-users')}</span>
                      </Link>
                    ),
                    key: 'users',
                  }
                : null,
            ]}
          ></Menu>
        </VisibleForRole>
      </Layout.Sider>
      <Layout>
        <HomeDashboardHeader />
        <Layout>
          <Switch>
            <PrivateRoute path={`/add-new-client`} allowedRoles={[TVMSRoles.SuperAdmin]} component={AddClient} />
            <Route path={`/details/:_id`} component={DetailsClient} />
            <Route path={`/users`} component={Users} />
            <Route path={`/clients`} component={AllClients} />
            <Route path={`/`} component={() => <Redirect to={`clients`} />} />
            <Route path={`/*`} component={() => <PageNotFound path="" />} />
          </Switch>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default HomeDashboard;
