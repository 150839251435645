import axios from "axios";
import { SubscriptionsReportInput } from "@mwaretv/database/build/backend/models/reporting/subscriptionsReport/subscriptionsReportInput"; 

class SubscriptionsReport_Service {

  Get_SubscriptionsReportInput = async (clientName: string, deploymentName: string, serviceName: string): Promise<SubscriptionsReportInput> => {

    const jsonFileName = `dashboard_billing_subscriptions_report_input.json`;

    const path = 'https://cloudtv.akamaized.net/' + clientName + '/' + deploymentName + '/' + serviceName + '/dashboards/' + jsonFileName;

    const { data } = await axios.get<SubscriptionsReportInput>(path);

    if (data) {

      data.previousMonth = data.previousMonth.filter(value => value.subscription);
      data.thisMonth = data.thisMonth.filter(value => value.subscription);

    }

    return data;

  }

}

const SUBSCRIPTIONS_REPORT_SERVICE = new SubscriptionsReport_Service();
export default SUBSCRIPTIONS_REPORT_SERVICE;
