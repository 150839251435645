import axiosInstance from '../../../../utils/axios';
import { useQuery } from '@tanstack/react-query';

export const usePushGatewaysSchemas = () =>
  useQuery(
    ['pushSchemas'],
    async () => {
      const response = await axiosInstance.get<any[]>(`/v1/billing/push-gateway-schemas`);
      return response.data;
    },
    {
      retry: true,
    }
  );
