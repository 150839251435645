import { Form } from 'antd';
import { Input, Item } from '../../../../../../common/components/form';
import { pushPlaceholders } from '@mwaretv/communication/dist/templates/index';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';

const PushTemplateForm = () => {
  const {t} = useTranslation();
  return (
    <>
      <Item label={t('name')} name={['name']} rules={[{ required: true, message: t('name-is-required') }]}>
        <Input />
      </Item>
      <Form.Item hidden name={['content']} />
      <Item label={t('title')} name={['title']} rules={[{ required: true, message: t('title-is-required')}]}>
        <Input />
      </Item>
      <Item label={t('content')} name={['content']} rules={[{ required: true, message: t('content-is-required')}]}>
        <TextArea rows={10} maxLength={178} />
      </Item>
      <>
        <p className="mt-5">{t('you-can-use-the-following-placeholders-to-use-data-in-the-template')}</p>
        <br />
        {pushPlaceholders.map((p) => (
          <p key={p.placeholder}>
            <b>&#123;&#123; {p.placeholder} &#125;&#125;</b> - {p.description}
          </p>
        ))}
      </>
    </>
  );
};

export default PushTemplateForm;
