import { faBoxes, faBoxesPacking, faDashboard, faMoneyBillTransfer } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { Alert, Layout, Menu } from 'antd';
import { useContext, useState } from 'react';
import { Route, Switch, useParams, useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import MWare_Logo from '../../../assets/images/logo.png';
import MWare_Icon from '../../../assets/images/icon.png';
import IMSDashboardHeader from '../../../common/components/IMSDashboardHeader';
import { BillingContextProvider } from '../../../contexts/billingContext';
import * as DeploymentService from '../../../services/deployments/deployment';
import Dashboard from './dashboard';
import Merchandise from './products';
import Packages from './subscriptions';
import Services from '../billing/services';
import * as ProductService from '../../../services/areas/billing/productService';
import * as PaymentProviderService from '../../../services/areas/billing/paymentProviderService';
import * as TaxService from '../../../services/areas/billing/taxService';
import { useEffectOnce, useLocation } from 'react-use';
import { hasRole } from '../../../utils/Auth/role.auth';
import { AuthContext } from '../../../context_api/AuthContext';
import Taxes from '../billing/settings/taxes';
import { useTranslation } from 'react-i18next';
import { getOpenKeys, getSelectedKey } from '../../../utils/menu';

const Products = () => {
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const { clientName, deployment, service } = useParams<{ clientName: string; deployment: string; service: string }>();
  const { state: AuthState } = useContext(AuthContext);
  const isClient = hasRole(AuthState.user!, clientName, ['superadmin', 'admin']);
  const history = useHistory();
  const { t } = useTranslation();
  var locSplit = location?.pathname?.split('/');
  var redirectPath = '';
  if (locSplit != undefined) {
    redirectPath = locSplit.slice(0, 4 - locSplit.length).join('/');
  }
  const [collapsed, setCollapsed] = useState(false);

  useEffectOnce(() => {
    const str = window.location.href;
    const parts = str.split('/');
    const lastPart = parts[parts.length - 1];
    if (!isClient && lastPart == 'billing') {
      history.push(url + '/shopping-carts');
    }
  });

  const { data, isLoading, isError } = useQuery(['deployment', deployment], async () => {
    return DeploymentService.find(clientName, { name: deployment })
      .then((r) => r.data)
      .catch((e) => Promise.reject(e));
  });

  const taxes = useQuery(
    ['tax', 1, 100],
    async () => {
      return (
        await TaxService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );

  const products = useQuery(
    ['products', 1, 100],
    async () => {
      return (
        await ProductService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );
  const paymentgateways = useQuery(
    ['payment-providers', 1, 100],
    async () => {
      return (
        await PaymentProviderService.list({
          params: {
            cms: deployment ?? '',
            crm: service || '',
            instance: clientName,
            page: 1,
            limit: 10,
          },
        })
      ).data;
    },
    { retry: 3 }
  );

  return (
    <Layout>
      <Layout.Sider width="300" collapsible collapsed={collapsed} onCollapse={(value) => {
          setCollapsed(value);
          window.dispatchEvent(new Event('resize'));
        }}
      >
        <div
          style={{
            justifyContent: 'center',
            alignContent: 'center',
            display: 'flex',
            padding: 40,
            paddingTop: 57,
            paddingBottom: 56,
            marginBottom: 20,
            borderBottom: '1px #444 solid',
            backgroundColor:'#002140'
          }}
        >
          {!isClient && (
            <Link to={'/'} onClick={() => {}} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </Link>
          )}
          {isClient && (
            <a href={redirectPath} key="Mware">
              {!collapsed && <img className="expand" src={MWare_Logo} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
              {collapsed && <img className="expand" src={MWare_Icon} title={'MwareTV'} alt={'MwareTV'} style={{ height: 30 }} />}
            </a>
          )}
        </div>
        <Menu
          mode="inline"
          inlineCollapsed={collapsed}
          theme="dark"
          defaultSelectedKeys={getSelectedKey(location?.pathname ?? "", "inventory")}
          defaultOpenKeys={getOpenKeys(location?.pathname ?? "", "inventory")}
          items={[
            isClient
              ? {
                  icon: <FontAwesomeIcon icon={faDashboard} size="lg" style={{ width: '20', height: '20' }} />,
                  label: (
                    <div>
                      <span >{t('dashboard')}</span>
                    </div>
                  ),
                  key: 'dashboard',
                  children: [
                    {
                      label: (
                        <Link to={`${url}`}>
                          <span >{t('dashboard')}</span>
                        </Link>
                      ),
                      key: 'dashboard-dashboard',
                    },
                  ],
                }
              : {
                  label: <></>,
                  key: 'empty',
                },

            {
              icon: <FontAwesomeIcon icon={faBoxes} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span >{t('inventory')}</span>
                </div>
              ),
              key: 'inventory',
              children: [
                {
                  label: (
                    <Link to={`${url}/packages`}>
                      <span >{t('subscriptions')}</span>
                    </Link>
                  ),
                  key: 'products-pkcs',
                },
                {
                  label: (
                    <Link to={`${url}`}>
                      <span >{t('products')}</span>
                    </Link>
                  ),
                  key: 'products-merch',
                },
              ],
            },

            {
              icon: <FontAwesomeIcon icon={faBoxesPacking} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span >{t('services')}</span>
                </div>
              ),
              key: 'servcs',
              children: [
                {
                  label: (
                    <Link to={`${url}/services`}>
                      <span >{t('services')}</span>
                    </Link>
                  ),
                  key: 'items-services',
                },
              ],
            },
            {
              icon: <FontAwesomeIcon icon={faMoneyBillTransfer} size="lg" style={{ width: '20', height: '20' }} />,
              label: (
                <div>
                  <span >{t('taxes')}</span>
                </div>
              ),
              key: 'txes',
              children: [
                {
                  label: (
                    <div>
                      {taxes.data?.docs.length == 0 && (
                        <Link to={`${url}/settings/taxes`}>
                          <span  style={{ display: 'flex', flexDirection: 'row' }}>
                            {t('taxes')} <div style={{ fontSize: 10, marginLeft: 5 }}>{t('start-here')}</div>
                          </span>
                        </Link>
                      )}
                      {taxes.data?.docs.length != 0 && (
                        <Link to={`${url}/settings/taxes`}>
                          <span  style={{ display: 'flex', flexDirection: 'row' }}>
                            {t('taxes')}
                          </span>
                        </Link>
                      )}
                    </div>
                  ),
                  key: 'settings-taxes',
                },
              ],
            },
          ]}
        />
      </Layout.Sider>
      <Layout>
        <div style={{ paddingLeft: 0, width: '100%' }}>
          <div>
            <IMSDashboardHeader
              {...{
                isSideBarShow: true,
                path,
              }}
            />
          </div>
          {isError && <span>{t('something-went-wrong')}</span>}
          <div className="mt-3 ml-5 mr-5">
            {taxes.data?.docs.length == 0 && (
              <Alert
                className="mt-4"
                message={t('taxes')}
                showIcon
                description={t('you-have-not-yet-setup-any-tax-rules-you-need-the-tax-rules-before-you-can-create-packages')}
                type="error"
                action={
                  <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${url}/settings/taxes`}>
                    {t('setup-taxes')}
                  </Link>
                }
              />
            )}

            {products.data?.docs.length == 0 && (
              <Alert
                className="mt-4"
                message={t('products')}
                showIcon
                description={t('you-have-not-yet-defined-any-products-you-need-products-before-you-can-create-a-shopping-cart')}
                type="error"
                action={
                  <Link className="btn btn-info rounded-pill float-right width-240 height-48" to={`${url}/packages`}>
                    {t('create-product')}
                  </Link>
                }
              />
            )}
          </div>
          {!isLoading && !isError && data && (
            <BillingContextProvider value={{ deployment: data, service: data.services.find((x) => x.name === service) }}>
              <Switch>
                <Route path={`${path}/packages`}>
                  <Packages />
                </Route>
                <Route path={`${path}/merchandise`}>
                  <Merchandise />
                </Route>
                <Route path={`${path}/services`}>
                  <Services />
                </Route>

                <Route path={`${path}/settings/taxes`}>
                  <Taxes />
                </Route>
                <Route path={`${path}`}>{isClient && <Dashboard />}</Route>
              </Switch>
            </BillingContextProvider>
          )}
        </div>
      </Layout>
    </Layout>
  );
};

export default Products;
function setCollapsed(arg0: boolean) {
  throw new Error('Function not implemented.');
}
