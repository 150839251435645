import { Button, Col, notification, Row, Table, Tooltip } from 'antd';
import * as HttpStatusCodes  from 'http-status-codes';
import { useState } from 'react';
import { useParams } from 'react-router';
import { CustomNotification } from '../../../../../common/components/Notification';
import nestsService from '../../../../../services/areas/content/nests/index';
import { IMSNameParams } from '../../../../dashboard';
import { useEffectOnce } from 'react-use';
import AddModal from './add';
import UpdateModal from './details';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import ReactBCTable from '../../../../../common/components/ReactBCTable';
import { useTranslation } from 'react-i18next';

const PpvRules = () => {
  const { clientName, deployment } = useParams<IMSNameParams & { deployment: string }>();
  const [rowToBeUpdate, setRowToBeUpdate] = useState([] as any);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState([] as any);
const {t} = useTranslation();
  const [showAddModal, setShowAddModal] = useState(false);

  const handleSubmit = async (values: any) => {
    setIsloading(true);
    let response = await nestsService.Add_Nests({
      translations: values,
      query_params: {
        cms: deployment,
        instance: clientName,
      },
    });

    if (response && response.status_code === HttpStatusCodes.OK) {
      setShowAddModal(false);
      getNests();
      notification.success({
        message: t('success'),
        description: t('rule-added-successfully'),
      });
    } else
      CustomNotification({
        error: response.errors,
      });
    setIsloading(false);
  };

  const handleUpdate = async (values: any) => {
    setIsloading(true);
    let response = await nestsService.Update_Nests({
      query_params: {
        cms: deployment,
        instance: clientName,
      },
      _id: values._id,
      translations: values.translations,
    });

    if (response && response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('success'),
        description: t('update-successfully'),
      });
      getNests();
      setShowUpdateModal(false);
    }
    setIsloading(false);
  };
  const handleDelete = async (row: any) => {
    setIsloading(true);
    let response = await nestsService.Delete_Nests({
      query_params: {
        cms: deployment,
        instance: clientName,
      },
      _id: row._id,
    });

    if (response && response.status_code === HttpStatusCodes.OK) {
      notification.success({
        message: t('success'),
        description: t('delete-successfully'),
      });
      getNests();
      setShowUpdateModal(false);
    }
    setIsloading(false);
  };

  const getNests = async () => {
    setIsloading(true);
    let response = await nestsService.List_Nests({
      query_params: {
        instance: clientName,
        cms: deployment,
      },
      pageNumber: 1,
      pageSize: 10000,
    });
    if (response && response.status_code === HttpStatusCodes.OK) {
      if (response.data != undefined && response.data.data != undefined) {
        setData(response.data.data);
      }
    }
    setIsloading(false);
  };

  useEffectOnce(() => {
    getNests();
  });

  const handleCancel = () => {
    setShowUpdateModal(false);
    setShowAddModal(false);
  };

  const columns = [
    {
      title: t('nest-name'),
      dataKey: 'translation',
      width: '90%',
      cell: (row: any) => (
        <>
          {row.translations !== undefined && (
            <div>
              {row.translations.map((r, index) => {
                return (
                  <div key={index}>
                    <b>{r.name}: </b>
                    {r.description}
                  </div>
                );
              })}
            </div>
          )}
        </>
      ),
    },
    {
      title: t('actions'),
      dataKey: 'action',
      cell: (row) => (
        <Col style={{ display: 'flex', flexDirection: 'row', height: 60 }}>
          <Tooltip placement="top" title={`Edit`}>
            <Button
              type="text"
              className="mx-2 cursor-pointer"
              onClick={() => {
                handleDelete(row);
              }}
            >
              <FontAwesomeIcon className="action-icon" icon={faTrashCan} />
              <div>{t('delete')}</div>
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={`Edit`}>
            <Button
              type="text"
              className="mx-2 cursor-pointer"
              onClick={() => {
                setRowToBeUpdate(row);
                setShowUpdateModal(true);
              }}
            >
              <FontAwesomeIcon className="action-icon" icon={faEdit} />
              <div>{t('edit')}</div>
            </Button>
          </Tooltip>
        </Col>
      ),
    },
  ];

  return (
    <div className="container-fluid wrapper">
      <div className="row">
        <div className="col-md-7 mb-2">
          <h2 className="page-title">{t('nests')}</h2>
          <p className="h6 font-weight-normal line-height-26">{t('setup-your-content-nests-you-can-use-them-with-your-content-items-you-can-also-create-them-from-the-content-section-to')}</p>
        </div>
        <div className="col-md-5"></div>
      </div>
      <div className="row mt-4">
        <div className="col-md-7 mb-2"></div>
        <div className="col-md-5">
          <Button className="btn btn-secondary rounded-pill btn-default float-right width-180 height-48" onClick={() => setShowAddModal(true)}>
            {t('add-nest')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4">
          <ReactBCTable columns={columns} data={data} isLoading={isLoading} totalRecords={0} pageSize={0} tableId={'134'} onChange={() => {}} hasChild={false} />
        </div>
      </div>
      <AddModal isLoading={isLoading} visible={showAddModal} onSubmit={handleSubmit} onCancel={handleCancel} />
      <UpdateModal isUpdating={isLoading} row={rowToBeUpdate} visible={showUpdateModal} onSubmit={(values) => handleUpdate(values)} onCancel={handleCancel} />
    </div>
  );
};

export default PpvRules;
