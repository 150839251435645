import {
  newCustomerTemplate,
  orderCompleteTemplate,
  orderRenewTemplate,
} from './templates';
import {
  customerPlaceholders,
  orderPlaceholders,
  renewPlaceholders,
  inappPlaceholders,
  inpushPlaceholders
} from './placeholders';
import { Template } from './types/template';
import { Placeholder } from './types';

export const templates: Template[] = [
  newCustomerTemplate,
  orderCompleteTemplate,
  orderRenewTemplate,
];

export const allPlaceholders: Placeholder[] = [].concat(
  orderPlaceholders,
  renewPlaceholders,
  customerPlaceholders
);
export const appPlaceholders: Placeholder[] = [].concat(inappPlaceholders);
export const pushPlaceholders: Placeholder[] = [].concat(inpushPlaceholders);

export { Template, Placeholder } from './types';

export { replacePlaceholdersInText } from './utilities/replacePlaceholders';
