import { SearchOutlined } from '@ant-design/icons';
import { faCaretDown, faCaretRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Dropdown, Empty, Pagination } from 'antd';
import classNames from 'classnames';
import { Fragment, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Column, FilterProps, SortingRule, TableOptions, useExpanded, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { useClickAway } from 'react-use';
import { ReactBCBooleanFilter, ReactBCDateFilter, ReactBCDateRangeFilter, ReactBCNumberFilter, ReactBCTextFilter } from './Filters';
import { IReactBCTableExpandProps } from './Interfaces/IReactBCTableExpandProps.interface';
import { IReactBCTableOptions } from './Interfaces/IReactBCTableOptions.interface';
import { Spin } from '../../components/spinner';
import { useTranslation } from 'react-i18next';

const ReactBCTable = <T extends {}>(props: PropsWithChildren<IReactBCTableOptions<T>>) => {
  const { isLoading, hasChild, onRowSelectionChange, childComponent, hasSelection, hasSingleSelection, onChange, tableId, columns, data, totalRecords, pageSize: tablePageSize, currentPage } = props;
  const { t } = useTranslation();
  let tableColumns: Column[] = useMemo(() => [], [data]);

  if (hasChild) {
    tableColumns.push({
      Header: 'Fold Open',
      width: '10%',
      id: 'expander',
      disableSortBy: true,
      Cell: ({ row, toggleRowExpanded }) => {
        return (
          <span
            {...row.getToggleRowExpandedProps()}
            onClick={() => {
              (row.getToggleRowExpandedProps() as any).onClick();

              toggleRowExpanded(
                page.filter((itemRow) => itemRow.isExpanded && itemRow.id !== row.id).map((item) => item.id),
                false
              );
            }}
          >
            {row.isExpanded ? <FontAwesomeIcon color="#999" size={'2x'} icon={faCaretDown} /> : <FontAwesomeIcon color="#999" size={'2x'} icon={faCaretRight} />}
          </span>
        );
      },
    });
  }

  if (hasSelection || hasSingleSelection) {
    tableColumns.push({
      id: 'selection',
      disableSortBy: false,
      Header: ({ getToggleAllPageRowsSelectedProps }) => {
        if (!hasSingleSelection) {
          return (
            <div className={`ant-table-selection`}>
              <Checkbox
                {...getToggleAllPageRowsSelectedProps()}
                onChange={(e) => {
                  toggleAllRowsSelected(e.target.checked);
                }}
              />
            </div>
          );
        } else return <span></span>;
      },
      Cell: ({ row, selectedFlatRows }) => {
        return (
          <Checkbox
            {...row.getToggleRowSelectedProps()}
            onChange={(e) => {
              if (hasSingleSelection && selectedFlatRows.length > 0) toggleRowSelected(selectedFlatRows[0].id, false);
              toggleRowSelected(row.id, e.target.checked);
            }}
          />
        );
      },
    });
  }

  const [dropdownVisible, setDropdownVisible] = useState<{ [key: string]: boolean }>();

  const filtersVisible: { [key: string]: boolean } = useMemo(() => {
    return {};
  }, []);

  columns.forEach((column) => {
    // eslint-disable-next-line no-empty-pattern
    let dynamicFilter: React.FC<FilterProps<{}>> = ({}: FilterProps<{}>) => <span></span>;

    if (column.filter) {
      filtersVisible[column.dataKey] = false;
      switch (column.filter) {
        case 'text':
          dynamicFilter = ReactBCTextFilter;
          break;
        case 'number':
          dynamicFilter = ReactBCNumberFilter;
          break;
        case 'date':
          dynamicFilter = ReactBCDateFilter;
          break;
        case 'dateRange':
          dynamicFilter = ReactBCDateRangeFilter;
          break;
        case 'boolean':
          dynamicFilter = ReactBCBooleanFilter;
          break;
        default:
          break;
      }
    }

    tableColumns.push({
      customData: { parentColumn: column },
      Header: column.title,
      accessor: column.dataKey,
      width: column.width,

      ...(column.cell && {
        Cell: (row) => column.cell && column.cell(row.row.original as T, row.row.index),
      }),
      disableSortBy: !column.hasSort,
      ...(column.Footer && {
        Footer: (info: any) => {
          if (column.Footer) {
            const allTableRows: T[] = info.rows.map((row: any) => row.original as T);
            return column.Footer(allTableRows);
          }
        },
      }),
      ...(column.filter ? { Filter: dynamicFilter } : { disableFilters: true }),
    } as any);
  });

  tableColumns = useMemo(() => tableColumns, [tableColumns]);

  const filterRef = useRef<HTMLElement>(null);

  useClickAway(filterRef, () => {
    setDropdownVisible((prevState) => ({ ...prevState, ...filtersVisible }));
  });
  const getRowId = (row) => {
    return row._id;
 }
  const tableOptions: TableOptions<{}> = {
    columns: tableColumns,
    data,
    manualSortBy: false,
    disableMultiSort: false,
    manualFilters: true,
    manualPagination: true,
    initialState: {
      pageIndex: 0,
      pageSize: tablePageSize,
      sortBy: [],
    },
    autoResetExpanded: false,
    pageCount: totalRecords,
    autoResetPage: false,
    enableExpanding: true,
    getRowId: data.some(x => (x as any)?._id === undefined) ? undefined : getRowId // https://tanstack.com/table/v8/docs/api/core/row#id - needed to keep track of correct rows, but only possible if type has _id property.
  };
  const tableInstance = useTable(tableOptions, useFilters, useSortBy, useExpanded, usePagination, useRowSelect);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    gotoPage,
    page,
    visibleColumns,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleRowSelected,
    state: { sortBy, filters, pageIndex, pageSize },
  } = tableInstance;

  const RenderRowSubComponent = useCallback(
    ({ row }: IReactBCTableExpandProps) => {
      return childComponent?.(row.original as T, row.index);
    },
    [childComponent]
  );

  const selectedFlatRowsRef = JSON.stringify(selectedFlatRows);

  useEffect(() => {
    onRowSelectionChange?.(selectedFlatRows.map((sfr) => sfr.original as T));
  }, [selectedFlatRowsRef]);

  useEffect(() => {
    
    //setDropdownVisible((prevState) => ({ ...prevState, ...filtersVisible }));
    let sort: SortingRule<{}> | undefined = undefined;
    // if (sortBy && sortBy.length > 0) {
    //   sort = sortBy[0];
    // }
    if (onChange && filters.length > 0) {
      onChange(pageIndex, pageSize, sort, filters);
    }
    // if (onChange && sortBy.length > 0) {
    //   
    //   onChange(pageIndex, pageSize, sort, filters);
    // }
  }, [sortBy, filters]);

  return (
    <div className={`ant-table ant-table-override`}>
      <div className={`ant-table-container`}>
        <Spin size="large" spinning={isLoading}>
          <table {...getTableProps({ className: `table-layout: auto;` })}>
            <thead className={`ant-table-thead`}>
              {headerGroups.map((headerGroup, hgIndex) => (
                <tr
                  {...headerGroup.getHeaderGroupProps({
                    key: `${tableId}_rbct_hg_${hgIndex}`,
                  })}
                >
                  {headerGroup.headers.map((header, hIndex) => {
                    return (
                      <th
                        {...header.getHeaderProps({
                          key: `${tableId}_rbct_h_${hIndex}`,
                          ...(header.customData?.parentColumn?.width ? { style: { width: header.customData?.parentColumn?.width } } : header.width && { style: { width: header.width } }),
                          className: classNames(`ant-table-cell`, {
                            'ant-table-row-expand-icon-cell': header.id === 'expander',
                            'ant-table-selection-column': header.id === 'selection',
                            'ant-table-column-has-sorters': !header.disableSortBy,
                          }),
                        })}
                      >
                        <span
                          className={classNames({
                            'ant-table-filter-column': header.canFilter,
                          })}
                        >
                          <span className="ant-table-column-title">{header.render('Header')}</span>
                          {/* {!header.disableSortBy && (
                            <div
                              {...header.getSortByToggleProps()}
                              className={classNames({
                                'ant-table-column-title': !header.disableSortBy,
                              })}
                            >
                              <div
                                className={classNames({
                                  'ant-table-column-sorters': !header.disableSortBy,
                                })}
                              >
                                <span className="ant-table-column-title">{header.render('Header')}</span>
                                <span className={'ant-table-column-sorter-inner'}>
                                  {header.isSorted
                                    ? header.isSortedDesc
                                      ? [<CaretUpOutlined key={Math.random()} className={`anticon anticon-caret-up ant-table-column-sorter-up`} />, <CaretDownOutlined key={Math.random()} className={`anticon anticon-caret-down ant-table-column-sorter-down active`} />]
                                      : [<CaretUpOutlined key={Math.random()} className={`anticon anticon-caret-up ant-table-column-sorter-up active`} />, <CaretDownOutlined key={Math.random()} className={`anticon anticon-caret-down ant-table-column-sorter-down`} />]
                                    : !header.disableSortBy
                                    ? [<CaretUpOutlined key={Math.random()} className={`anticon anticon-caret-up ant-table-column-sorter-up`} />, <CaretDownOutlined key={Math.random()} className={`anticon anticon-caret-down ant-table-column-sorter-down`} />]
                                    : ''}
                                </span>
                              </div>
                            </div>
                          )} */}
                          {header.canFilter ? (
                            <>
                              <Dropdown placement="bottomRight" visible={dropdownVisible?.[header.id]} overlay={header.render('Filter', { ref: filterRef }) as JSX.Element} trigger={['click']}>
                                <span
                                  role="button"
                                  onClick={() => {
                                    setDropdownVisible((prevState) => ({ ...prevState, ...filtersVisible }));
                                    setDropdownVisible((prevState) => ({ ...prevState, [header.id]: !dropdownVisible?.[header.id] }));
                                  }}
                                  className={`ant-dropdown-trigger ant-table-filter-trigger`}
                                >
                                  <SearchOutlined
                                    style={{
                                      color: header.filterValue ? '#1ac884' : '',
                                    }}
                                  />
                                </span>
                              </Dropdown>
                            </>
                          ) : null}
                        </span>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps({ className: `ant-table-tbody` })}>
              {!isLoading && page.length === 0 && (
                <tr>
                  <td colSpan={visibleColumns.length} className={`ant-table-cell`}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </td>
                </tr>
              )}
              {page.length > 0 &&
                page.map((row, rIndex) => {
                  prepareRow(row);
                  const rowProps = row.getRowProps({
                    key: `${tableId}_rbct_r_${rIndex}`,
                    className: `ant-table-row`,
                  });
                  return (
                    <Fragment key={rowProps.key}>
                      <tr key={`rbct_tr_${rIndex}`} className={rowProps.className}>
                        {row.cells.map((cell, cIndex) => {
                          return (
                            <td
                              {...cell.getCellProps({
                                key: `${tableId}_rbct_c_${cIndex}`,
                                className: classNames(`ant-table-cell`, {
                                  'ant-table-row-expand-icon-cell': cell.column.id === 'expander',
                                  'ant-table-selection-column': cell.column.id === 'selection',
                                }),
                              })}
                            >
                              {cell.render('Cell', {})}
                            </td>
                          );
                        })}
                      </tr>
                      {hasChild && row.isExpanded ? (
                        <tr key={`rbct_tr_n_${rIndex}`} className="ant-table-expanded-row">
                          <td colSpan={visibleColumns.length} style={{ padding: '10px', background: '#ebebeb' }}>
                            {RenderRowSubComponent({
                              row,
                              rowProps,
                              visibleColumns,
                            })}
                          </td>
                        </tr>
                      ) : null}
                    </Fragment>
                  );
                })}
            </tbody>
            <tfoot>
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>

          {totalRecords > pageSize && (
            <div className="react-table-bc-pagination" style={{ padding: '20px' }}>
              <style>{`
           .react-table-bc-pagination .ant-pagination-item-ellipsis {
             margin-top:4px !important;
           }
           .ant-pagination-total-text{
             float:right;
           }
         `}</style>
              <Pagination
                hideOnSinglePage
                showTotal={(total, range) => <div>{t('showing')} {t('range-0-range-1-of-total-items', { start: range[0], end: range[1], total: total })}</div>}
                showSizeChanger={false}
                total={totalRecords}
                pageSize={pageSize}
                current={currentPage}
                onChange={(page) => {
                  if (onChange) {
                    onChange(page, pageSize, undefined, filters);
                  }
                }}
              />
            </div>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default ReactBCTable;
