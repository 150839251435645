import { Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import * as HttpStatusCodes  from 'http-status-codes';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import cmsService from '../../../../../../../services/areas/content/courses/courses';
import { IMSNameParams } from '../../../../../../dashboard';
import { ContentContext } from '../../../../../../../contexts/contentContext';
import SubContent from '../../../../../../../common/components/Content/Content/SubContent';
import { Courses } from '../../../../../../../interfaces/forms/areas/content/courses/courses';
import { useEffectOnce } from 'react-use';

const AddNewMusicAlbum = (data: any) => {
  const { clientName, _id } = useParams<IMSNameParams>();
  const { deployment } = useContext(ContentContext);
  const [lessons, setLessons] = useState([] as any);
  const [form] = useForm();
const { t } = useTranslation();

  useEffectOnce(() => {
    setLessons(data.data.lessons);
    form.setFieldsValue(data.data);
  });

  const handleSubmit = async (values: Courses) => {
    if (data.data.payperview != undefined) {
      if (data.data.payperview.currencies != undefined) {
        if (data.data.payperview.currencies.length > 0) {
          if (data.data.payperview.currencies[0].price != undefined) {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          } else {
            var curs = [] as any;
            deployment?.currencies.forEach((cur, index) => {
              curs.push({
                iso3: cur.isoCurrency,
                credits_rented: 0,
                credits_purchased: 0,
                key: index,
              });
            });
            data.data.payperview = {
              enable: data.data.payperview.enable,
              rule: undefined,
              currencies: curs,
            };
          }
        }
      }
    }
    values = {
      _id: _id,
      ...data.data,
      lessons: values,
      query_params: {
        instance: clientName,
        cms: deployment?.name ?? '',
      },
    };
    let response = await cmsService.Update_Course(values as any);
    if (response.status_code === HttpStatusCodes.OK) {
      setLessons(response.data.lessons);
      notification.success({
        message: t('updated-successfully'),
      });
    } else {
      notification.error({
        message: t('an-error-occured'),
      });
    }
  };

  return (
    <div className="container-fluid ">
      <Form
        form={form}
        onFinish={(values: any) => {
          handleSubmit(values);
        }}
        onFinishFailed={(errorInfo) => {
          
        }}
      >
        <div className="card mt-4 pb-4">
          <div className="card-body card-ch-padding">
            <SubContent onUpdate={handleSubmit} setData={setLessons} type={"Lessons"} data={lessons} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddNewMusicAlbum;
