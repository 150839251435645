import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faBrush, faCog, faFillDrip, faBlockBrick, faListTree, faShieldKeyhole, faTableColumns } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

const MenuBuilder = (params: { redirectUrl; application; id }) => {
  const { redirectUrl, application, id } = params;
  const { t } = useTranslation();

  return (
    <div className="row-md-12">
      <div className="text-right" style={{ backgroundColor: '#dee2e6', padding: 10 }}>
        <div className={'ant-card-hoverable'} onClick={(event) => (window.location.href = `${redirectUrl}/branding/${id}`)} style={{ cursor: 'pointer', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff' }}>
          <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
            <div>
              <div className="text-center">
                <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={faBrush} />
              </div>
              <div className="text-center" style={{ color: '#485Fe0', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                {t('branding')}
              </div>
            </div>
          </div>
        </div>
        <div className={'ant-card-hoverable'} onClick={(event) => (window.location.href = `${redirectUrl}/settings/${id}`)} style={{ cursor: 'pointer', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff' }}>
          <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
            <div>
              <div className="text-center">
                <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={faCog} />
              </div>
              <div className="text-center" style={{ color: '#485Fe0', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7, paddingLeft: 5, paddingRight: 5 }}>
                {t('settings')}
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={(event) => (window.location.href = `${redirectUrl}/components/${id}`)}
          style={{
            cursor: 'pointer',
            width: 64,
            margin: 5,
            borderRadius: 5,
            display: 'inline-table',
            backgroundColor: '#fff',
          }}
        >
          <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
            <div>
              <div className="text-center">
                <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={faBlockBrick} />
              </div>
              <div className="text-center" style={{ color: '#485Fe0', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7, paddingLeft: 5, paddingRight: 5 }}>
                {t('components')}
              </div>
            </div>
          </div>
        </div>
        <div className={'ant-card-hoverable'} onClick={(event) => (window.location.href = `${redirectUrl}/theme/${id}`)} style={{ cursor: 'pointer', width: 64, margin: 5, borderRadius: 5, display: 'inline-table', backgroundColor: '#fff' }}>
          <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
            <div>
              <div className="text-center">
                <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={faFillDrip} />
              </div>
              <div className="text-center" style={{ color: '#485Fe0', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7, paddingLeft: 5, paddingRight: 5 }}>
                {t('theme')}
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={(event) => (window.location.href = `${redirectUrl}/navigators/${id}`)}
          style={{
            cursor: 'pointer',
            width: 64,
            margin: 5,
            borderRadius: 5,
            display: 'inline-table',
            backgroundColor: '#fff',
          }}
        >
          <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
            <div>
              <div className="text-center">
                <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={faListTree} />
              </div>
              <div className="text-center" style={{ color: '#485Fe0', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7, paddingLeft: 5, paddingRight: 5 }}>
                {t('navigators')}
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={(event) => (window.location.href = `${redirectUrl}/authentication/${id}`)}
          style={{
            cursor: 'pointer',
            width: 64,
            margin: 5,
            borderRadius: 5,
            display: 'inline-table',
            backgroundColor: '#fff',
          }}
        >
          <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
            <div>
              <div className="text-center">
                <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={faShieldKeyhole} />
              </div>
              <div className="text-center" style={{ color: '#485Fe0', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                {t('auth-flow')}
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={(event) => (window.location.href = `${redirectUrl}/pages/${id}`)}
          style={{
            cursor: 'pointer',
            width: 64,
            margin: 5,
            borderRadius: 5,
            display: 'inline-table',
            backgroundColor: '#fff',
          }}
        >
          <div className="text-center col-4 justify-content-center" style={{ height: 60, display: 'table-cell', verticalAlign: 'middle', paddingLeft: 0, paddingRight: 0 }}>
            <div>
              <div className="text-center">
                <FontAwesomeIcon style={{ color: '#485Fe0' }} size={'xl'} icon={faTableColumns} />
              </div>
              <div className="text-center" style={{ color: '#485Fe0', fontSize: 9, fontWeight: 'bold', lineHeight: 0.7 }}>
                {t('pages')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MenuBuilder;
