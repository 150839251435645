import { Select, Item, InputNumber } from '../../../../../../common/components/form';
import { Periods } from '@mwaretv/database/build/backend/enums/billing/periods';
import { EmailTemplate } from '../../../../../../interfaces/forms/areas/billing/emailTemplate';
import { SmsTemplate } from '../../../../../../interfaces/forms/areas/billing/smsTemplate';
import { AppTemplate } from '../../../../../../interfaces/forms/areas/billing/appTemplate';
import { useTranslation } from 'react-i18next';
import { PushTemplate } from '../../../../../../interfaces/forms/areas/billing/pushTemplate';


const FormFields = ({ emailTemplates, smsTemplates, appTemplates,pushTemplates }: { emailTemplates: EmailTemplate[]; smsTemplates: SmsTemplate[]; appTemplates: AppTemplate[]; pushTemplates: PushTemplate[]  }) => {
  const {t} = useTranslation();
  return (
    <>
      <Item label={t('time')} name={['time']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('amount-of-time-set-in-period-before-the-expiry-date-of-a-trial-the-customer-will-be-contacted-to-renew-their-subscription')}>
        <InputNumber />
      </Item>
      <Item label={t('period')} name={['period']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('period-to-measure-the-amount-of-time-in-can-be-days-weeks-months-or-years')}>
        <Select className="default-select" options={Object.values(Periods).map((p) => ({ label: p, value: p }))} />
      </Item>
      <Item label={t('email-template')} name={['email_template_id']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('the-email-template-used-to-contact-the-customer-can-be-edited-in-email-templates-under-settings')}>
        <Select className="default-select" options={emailTemplates.map((t) => ({ label: t.name, value: t._id }))} />
      </Item>
      <Item label={t('sms-template')} name={['sms_template_id']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('the-sms-template-used-to-contact-the-customer-can-be-edited-in-sms-templates-under-settings')}>
        <Select className="default-select" options={smsTemplates.map((t) => ({ label: t.name, value: t._id }))} />
      </Item>
      <Item label={t('app-template')} name={['app_template_id']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('the-in-app-template-used-to-contact-the-customer-can-be-edited-in-app-templates-under-settings')}>
        <Select className="default-select" options={appTemplates.map((t) => ({ label: t.name, value: t._id }))} />
      </Item>
      <Item label={t('push-template')} name={['push_template_id']} wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} extra={t('the-in-push-template-used-to-contact-the-customer-can-be-edited-in-app-templates-under-settings')}>
        <Select className="default-select" options={pushTemplates.map((t) => ({ label: t.name, value: t._id }))} />
      </Item>
    </>
  );
};

export default FormFields;
